
import {createOrgResponseModal, createLocationResponseModal, areaParamsType, locationAPIPayload} from './createSolutionModel';
import { AxiosAPIError, handleError } from 'utils/apiError';
import axiosConfig from 'app/axiosConfig';
import { ResponseAreaObject } from './../guided/guidedModel';
import { AxiosError } from 'axios';
import { CreateAreaPayloadInterface } from './createSolutionModel';
import { CM_CLOUD_API_URL } from 'app/constants';
import { LocationDataInterface } from 'features/myLocation/location-model';

export const getOrganizationListAsync = async (): Promise<{
    response: createOrgResponseModal | null;
    error: AxiosAPIError | null;
  }> => {
    const config = {
      baseURL: CM_CLOUD_API_URL ,
    }
    try {
      const data = await axiosConfig.get<createOrgResponseModal>(`/Orgs`, config);
      return { response: data?.data, error: null };
    } catch (err) {
      const errors = err as Error | AxiosError;
      const errr =  { data: null, error: handleError(errors) };
      throw errr
    }
};


export const getLocationListAsync = async (payload: locationAPIPayload): Promise<{
    response: LocationDataInterface[] | null;
    error: AxiosAPIError | null;
  }> => {

    const {root_org_id, orgId, parent_org_id } = payload;
    const data = await axiosConfig.get<createLocationResponseModal>(`api/location?root_org_id=${root_org_id}&org_id=${orgId}&parent_org_id=${parent_org_id}`);
    return { response: data.data.result, error: null };
};

export const ApiGetAreaGuided = async (params: areaParamsType): Promise<{
  data: ResponseAreaObject[] | null;
  error: AxiosAPIError | null;
}> => {
  const { locSelected, orgSelected, parent_org_id } = params;
  const root_org_id = orgSelected && orgSelected[0].rootOrgId;
  const location_id = locSelected && locSelected[0]._id;
  const org_id = orgSelected && orgSelected[0].orgId;
  try {
    const data = await axiosConfig.get<ResponseAreaObject[]>(`/api/area?root_org_id=${root_org_id}&location_id=${location_id}&org_id=${org_id}&parent_org_id=${parent_org_id}`);
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    throw { data: null, error: handleError(errors) };
  }
};

export const ApiCreateAreaGuided = async (
  payload: CreateAreaPayloadInterface
): Promise<{
  data: ResponseAreaObject | null;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.post<ResponseAreaObject>('/api/area', payload);
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    throw { data: null, error: handleError(errors) };
  }
};


