import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'app/hooks';
import useCurrency from 'hooks/currency-hook';
import { useTranslation } from 'react-i18next';
import { fctos } from 'utils/currency';
import { selectSolutionSpecificCurrency } from '../guidedSlice';
import { OutputCostFees } from './opportunityViewModel';
interface ResultMachineData {
  title: string;
  auxQuantity: number | undefined;
  mainQuantity: number | undefined;
  pricePerMachine: number | undefined;
  total: number | undefined;
  url: string;
  description: string;
}

interface OpportunityViewCardProps {
  title: string;
  option: string;
  paybackData: { title: 'ROA' | 'ROS' | 'ROI' | 'Payback Period'; value: string }[];
  totalCost: number;
  hardwareCost: number | undefined;
  machineData: ResultMachineData[];
  pdfView?: boolean;
  costs: OutputCostFees;
}
const OpportunityViewCard = ({
  title,
  option,
  paybackData,
  totalCost,
  machineData,
  pdfView,
  costs
}: OpportunityViewCardProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const height = 'calc(100vh - 600px)';
  const solSpecificCurrency = useAppSelector(selectSolutionSpecificCurrency);

  const { formatToCurrencyWithSymbol } = useCurrency();

  const { t } = useTranslation();

  const getTotalCost = () => {
    return (
      <Tooltip
        title={
          <>
            {costs.info.map((el, i) => {
              return (
                <Stack direction="row" key={i} sx={{ alignItems: 'center' }}>
                  <Typography component="div" variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                    {el.name}
                  </Typography>
                  <Typography component="div" variant="subtitle2">
                    {el.value && typeof el.value === 'number'
                      ? `: ${formatToCurrencyWithSymbol(el.value)}`
                      : `: 0 ${fctos(solSpecificCurrency)}`}
                  </Typography>
                </Stack>
              );
            })}
            {costs.additionalCosts.map((el, i) => {
              return (
                <Stack direction="row" key={i} sx={{ alignItems: 'center' }}>
                  <Typography component="div" variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                    {el.name}
                  </Typography>
                  <Typography component="div" variant="subtitle2">
                    {el.value && typeof el.value === 'number'
                      ? `: ${formatToCurrencyWithSymbol(el.value)}`
                      : `: 0 ${fctos(solSpecificCurrency)}`}
                  </Typography>
                </Stack>
              );
            })}
            {costs.additionalFees.map((el, i) => {
              return (
                <Stack direction="row" key={i} sx={{ alignItems: 'center' }}>
                  <Typography component="div" variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                    <i>{el.name}</i>
                  </Typography>
                  <Typography component="div" variant="subtitle2">
                    <i>
                      {el.value && typeof el.value === 'number'
                        ? `: (${formatToCurrencyWithSymbol(el.value)})`
                        : `: 0 ${fctos(solSpecificCurrency)}`}
                    </i>
                  </Typography>
                </Stack>
              );
            })}
          </>
        }
        arrow
      >
        <InfoIcon sx={{ color: theme.palette.grey[500], ml: 0.5, fontSize: '16px' }} />
      </Tooltip>
    );
  };

  const StyledChip = styled(Chip)(() => ({
    height: '26px',
    '& .MuiChip-deleteIcon': {
      color: mode === 'light' ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)',
      fontSize: '13px',
      backgroundColor: theme.palette.text.primary,
      opacity: '.26',
      height: '20px'
    }
  }));

  return (
    <Box>
      <Typography variant="body2" component="div" sx={{ mt: pdfView ? 2 : 3 }}>
        {option}
      </Typography>
      <Typography variant="h6" sx={{ mb: 0.5, fontSize: 18 }} component="div">
        {title}
      </Typography>
      <Grid container spacing={1}>
        {paybackData.map((d, i) => (
          <Grid item xs={'auto'} key={i}>
            <StyledChip
              clickable={false}
              label={d.title}
              onDelete={() => {}}
              deleteIcon={<Chip disabled clickable={false} label={d.value} size="small" />}
            />
          </Grid>
        ))}
      </Grid>
      <Stack direction="row" sx={{ mt: '19px', mb: 2, alignItems: 'center' }}>
        <Typography fontSize="16" component="div">
          {t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.totalCostLabel') || 'Total Cost'}
        </Typography>
        <Typography fontSize="16" component="div">
          {` : ${formatToCurrencyWithSymbol(totalCost)}`}
        </Typography>
        {getTotalCost()}
      </Stack>
      <List disablePadding sx={{ maxHeight: pdfView ? 'auto' : height, overflowY: 'auto' }}>
        {machineData.map((d, i) => {
          return (
            <Card variant="outlined" sx={{ mb: 2 }} key={i}>
              <Stack direction="row">
                <Box
                  sx={{
                    height: 150,
                    width: 150,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRight: `1px solid ${theme.palette.divider}`
                  }}
                >
                  <Box
                    sx={{
                      height: 100,
                      width: 100,
                      backgroundImage: `url(${d.url})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat'
                    }}
                  ></Box>
                </Box>
                <Stack sx={{ flex: 1, p: 2 }}>
                  <Stack direction="row" sx={{ justifyContent: 'space-between', mb: 0.5 }}>
                    <Typography variant="body1" component="div">
                      {d.title}
                    </Typography>
                    <Tooltip title={d.description}>
                      <InfoIcon sx={{ color: theme.palette.grey[500], fontSize: '16px' }} />
                    </Tooltip>
                  </Stack>
                  <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Typography component="div" variant="body2" color={theme.palette.text.secondary}>
                      {t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.quantity') || 'Quantity'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    {d.mainQuantity ? (
                      <Chip label={`Main\u00A0\u00A0 ${d.mainQuantity || 0}`} size="small" color="primary" />
                    ) : null}
                    {d.auxQuantity ? (
                      <Chip
                        label={`AUX\u00A0\u00A0${d.auxQuantity || 0}`}
                        size="small"
                        variant="filled"
                        color="default"
                      />
                    ) : null}
                  </Stack>
                  <Stack direction="row" sx={{ alignItems: 'center', mt: 1 }}>
                    <Typography component="div" variant="body2" color={theme.palette.text.secondary}>
                      {d.pricePerMachine ? `${formatToCurrencyWithSymbol(d.pricePerMachine)}/machine` : ''}
                      {d.total ? `\u00A0| Total: ${formatToCurrencyWithSymbol(d.total)}` : ''}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          );
        })}
      </List>
    </Box>
  );
};

export default OpportunityViewCard;
