import { Box, Typography } from '@mui/material';
import { AppDispatch } from 'app/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdditionalCosts from './additionalCost';
import { TitlesEnum } from './additonalCostFeesModel';
import { fetchCostFeesCategories, fetchCribSettingsByOrgId, selectAdditionalCategories, selectAdditionalCosts, selectAdditionalFees } from './costFeesSlice';
import { UserOrgType } from 'features/user/userModel';
import { selectUserOrgType } from 'features/guided/guidedSlice';


const AdditionalCostFees = () => {
    const additionalCosts = useSelector(selectAdditionalCosts);
    const additionalFees = useSelector(selectAdditionalFees);
    const categories = useSelector(selectAdditionalCategories);
    const userOrgType = useSelector(selectUserOrgType);

    const dispatch = useDispatch<AppDispatch>();
    
    useEffect(() => {
        dispatch(fetchCribSettingsByOrgId());
        dispatch(fetchCostFeesCategories());
    },[])

    const feesCategories = categories.filter(item => item.category === TitlesEnum.fees);
    const costCategories = categories.filter(item => item.category === TitlesEnum.cost);

    return (
        <Box sx={{width: '64%'}} p={2}>
            <Typography mt={3}>
                These costs and fees will be served as template and propagated to Solution Costs screen.
            </Typography>
            <Typography mt={2} mb={1.2} sx={{fontSize: '18px'}}>
                Costs
            </Typography>
           <AdditionalCosts type={TitlesEnum.cost} data={additionalCosts} categories={costCategories} org_level={true} />

           {
            userOrgType !== UserOrgType.direct && <>
            <Typography mt={2} mb={1.2} sx={{fontSize: '18px'}}>
                Fees
            </Typography>
           <AdditionalCosts type={TitlesEnum.fees} data={additionalFees} categories={feesCategories} org_level={true} /></>
           }
        </Box>
    )
}

export default AdditionalCostFees