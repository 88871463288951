import axiosConfig from "app/axiosConfig";
import { AxiosError } from "axios";
import { AxiosAPIError, handleError } from "utils/apiError";
import { GeneralSettingsPayload, OrganizationPriceGroup } from "./generalModel";

export const ApiPricesOrganization = async (): Promise<{
    data: OrganizationPriceGroup;
    error: AxiosAPIError | null;
  }> => {
    try {
      const response = await axiosConfig.get<OrganizationPriceGroup>(`/api/criborg/prices-organizations`);
      const data = response.data; // Extract the data property from Axios response
      return { data: data, error: null };
    } catch (err) {
      const errors = err as Error | AxiosError;
      throw { data: null, error: handleError(errors) };
    }
};
export const ApiUpdatePricesOrganization = async (payload: GeneralSettingsPayload) => {
    try {
      await axiosConfig.put(`/api/criborg`, payload);
    } catch (err) {
      const errors = err as Error | AxiosError;
      return { data: [], error: handleError(errors) };
    }
}