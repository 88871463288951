export enum customerSalesKeys {
    estimated_revenue_twelve_months = 'estimated_revenue_twelve_months',
    average_gross_profit = 'average_gross_profit',
    additional_halo_revenue = 'additional_halo_revenue',
    gross_profit_margin_percentage_halo_revenue = 'gross_profit_margin_percentage_halo_revenue'
}
export enum CustomerDataKeys {
    consumption_reduction = 'consumption_reduction',
    employees_per_shift = 'employees_per_shift',
    avg_labor_rate_per_hour = 'avg_labor_rate_per_hour',
    total_trip_time = 'total_trip_time',
    po_per_month =  'po_per_month',
    cost_per_po ='cost_per_po',
  }
  export enum ItemInfoKeys {
    items_to_start_the_shift = 'items_to_start_the_shift',
    capacity_for_growth= 'capacity_for_growth',
    safety_stock_percentage = 'safety_stock_percentage',
    restocking_frequency = 'restocking_frequency',
    area_size = 'area_size',
  }
  export enum CostInfoKeys {
    state_sales= 'state_sales',
    freight_estimate = 'freight_estimate',
    stocking_labor='stocking_labor',
    labor_rate_per_hour = 'labor_rate_per_hour',
    estimated_repair ='estimated_repair',
    lease_agreement = 'lease_agreement',
    total_trip_time = 'total_trip_time',
    budget = 'budget',
  }