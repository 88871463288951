import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import { ParsedExcelData } from './parserModel';
//import { revertAll } from 'app/actions';

export interface ParserState {
  value?: ParsedExcelData;
}

const initialState: ParserState = {
  value: {}
};

export const parserSlice = createSlice({
  name: ReducerKeys.parser,
  initialState,
  reducers: {
    addData: (state, action) => {
      state.value = action.payload;
    },
    clearData: (state) => {
      state.value = undefined;
    },
    updateData: (state, action) => {
      const data = state.value;
      let table;
      const { value, rowNo, property } = action.payload;
      let { row } = action.payload;
      if (!data?.table) return state;
      row = { ...row, [property]: parseInt(value) };
      if (data?.table) table = [...data.table];
      if (table) table[rowNo] = row;
      const newData = {
        info: data?.info,
        table
      };
      state.value = newData;
    }
  }
  //extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
});

export const { addData, clearData, updateData } = parserSlice.actions;
export const selectExcelData = (state: RootState) => state.parser.value;
export const { reducer: parserReducer, name: parserKey } = parserSlice;
