import { styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ceil from 'lodash/round';
import toNumber from 'lodash/toNumber';
import { ChangeEvent, memo, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CustomerDataInterface } from 'features/guided/guidedModel';
import useCurrency from 'hooks/currency-hook';
import { useTranslation } from 'react-i18next';
import { currencyToNumber, fcsl, fctol, removeCurrencyformat } from 'utils/currency';
import {
  selectCustomerData,
  selectCustomerSalesOpportunityGuided,
  selectIncludeConsignment,
  selectSolutionSpecificCurrency,
  setCostSliderDirty,
  setCustomerDataGuided,
  setCustomerSalesOpportunityGuided,
  setCustomerViewResultsGuided
} from '../guidedSlice';

const StyledSlider = styled(Slider)({
  height: 4,
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  }
});

type CostInfoFields = {
  label: string;
  key: string;
  min: number;
  max: number;
  sign?: string;
};

const CostInfo = ({ enabled, pdfView }: { enabled?: boolean, pdfView?: boolean }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { formatToFlowCurrency } = useCurrency();
  const salesInfo = useAppSelector(selectCustomerSalesOpportunityGuided);
  const customerData = useAppSelector(selectCustomerData);
  const includeConsignment = useAppSelector(selectIncludeConsignment);
  const solSpecificCurrency = useAppSelector(selectSolutionSpecificCurrency);

  const { estimated_revenue_twelve_months } = salesInfo;
  const costInfoFields: CostInfoFields[] = [
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.CostInfo.consumptionReduction') || 'Consumption Reduction %'}`,
      key: 'consumption_reduction',
      min: 15,
      max: 50
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.CostInfo.employeesPerShiftInputLabel') || '# of Employees Per Shift'}`,
      key: 'employees_per_shift',
      min: 1,
      max: 5000
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.CostInfo.employeeAvgLaborRateInputLabel') || 'Employee Labor Rate/Hr'}`,
      key: 'avg_labor_rate_per_hour',
      min: 10,
      max: 50,
      sign: fcsl(fctol(solSpecificCurrency))
    },
    {
      label: `${
        t('Flow.GuidedTab.CostInfoStep.CostInfo.totalEmployeeTripTimeInputLabel') || 'Total Trip Time Saved (Min)'
      }`,
      key: 'total_trip_time',
      min: 1,
      max: 30
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.CostInfo.poPerShiftInputLabel') || '# POs Per Month'}`,
      key: 'po_per_month',
      min: 1,
      max: 50
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.CostInfo.costPerPoInputLabel') || 'Cost Per PO'}`,
      key: 'cost_per_po',
      min: 20,
      max: 300,
      sign: fcsl(fctol(solSpecificCurrency))
    }
  ];

  /**

Consumption Reduction - Month & Year = ($ Estimated Revenue 12 Month * Estimated Consumption Reduction % /100) /12

Working Capital Saving - Month & Year = $ Estimated Revenue 12 Month /12/12

Employees Productivity -> Saving on Walking time = # of Employees per shift used vending * Employee Labor Rate /hr * (Total Trip time (min) saved per Employee / 60) * 5 days a week * 52 weeks / 12 month

Order management Savings = # POs per Month * Cost per PO -> Month; Year = Month* 12;

Total Savings (Month, Year)
   */
  const {
    consumption_reduction,
    employees_per_shift,
    avg_labor_rate_per_hour,
    total_trip_time,
    po_per_month,
    cost_per_po
  } = customerData;
  const unFormattedEstimatedRevenue =
    estimated_revenue_twelve_months && removeCurrencyformat(estimated_revenue_twelve_months);
  const unFormattedCostPerPO = cost_per_po && removeCurrencyformat(cost_per_po);
  const unFormattedLaborRate = avg_labor_rate_per_hour && removeCurrencyformat(avg_labor_rate_per_hour);

  useMemo(() => {
    const crpy =
      consumption_reduction && estimated_revenue_twelve_months
        ? (Number(unFormattedEstimatedRevenue) * Number(consumption_reduction)) / 100
        : 0;
    const wcpy = includeConsignment ? (unFormattedEstimatedRevenue ? Number(unFormattedEstimatedRevenue) / 12 : 0) : 0;
    const eppy =
      employees_per_shift && total_trip_time && unFormattedLaborRate
        ? ((Number(employees_per_shift) * Number(unFormattedLaborRate) * Number(total_trip_time)) / 60) * 5 * 52
        : 0;

    const ompy = po_per_month && unFormattedCostPerPO ? Number(po_per_month) * Number(unFormattedCostPerPO) * 12 : 0;
    const tspy = crpy + wcpy + eppy + ompy;
    dispatch(
      setCustomerViewResultsGuided({
        consumption_reduction: { per_month: ceil(crpy / 12, 1), per_year: ceil(crpy, 1) },
        working_capital: { per_month: ceil(wcpy / 12, 1), per_year: ceil(wcpy, 1) },
        employee_productivity: { per_month: ceil(eppy / 12, 1), per_year: ceil(eppy, 1) },
        order_management: { per_month: ceil(ompy / 12, 1), per_year: ceil(ompy, 1) },
        total_savings: { per_month: ceil(tspy / 12, 1), per_year: ceil(tspy, 1) }
      })
    );
  }, [
    consumption_reduction,
    employees_per_shift,
    avg_labor_rate_per_hour,
    total_trip_time,
    po_per_month,
    cost_per_po,
    estimated_revenue_twelve_months,
    includeConsignment
  ]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, max: number) => {
    const { name, value } = e.target;
    const val = Number(value) > max ? max : parseFloat(value);
    dispatch(setCostSliderDirty(true));
    dispatch(setCustomerDataGuided({ name, value: val }));
  };

  const handleRevenueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isValidInput = /^\d*\.?\d*$/.test(value);
    if (isValidInput) {
      dispatch(setCostSliderDirty(true));
      const numberedValue = value.replace(/,/g, '');
      const dataParam = { name, value: numberedValue };
      dispatch(setCustomerSalesOpportunityGuided(dataParam));
    }
  };

  const handleSliderChange = (newValue: number | number[], name: string) => {
    dispatch(setCostSliderDirty(true));
    if (typeof newValue === 'number') dispatch(setCustomerDataGuided({ name, value: newValue }));
  };

  return (
    <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
      <Stack spacing={1} direction="column" sx={{ flex: 1 }}>
        <TextField
          value={formatToFlowCurrency(salesInfo.estimated_revenue_twelve_months)}
          name="estimated_revenue_twelve_months"
          label={t('Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.estimatedRevenueShortInputLabel')}
          variant="outlined"
          size="small"
          disabled={enabled ? false : true}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleRevenueChange(currencyToNumber(e))}
          sx={{ flex: 1 }}
        />
      </Stack>
      {costInfoFields.map((c) => (
        <Stack spacing={1} direction="column" key={c.key} sx={{ flex: 1 }}>
          <TextField
            value={customerData[c.key as keyof CustomerDataInterface] || ''}
            inputProps={{ type: 'number', min: c.min, max: c.max }}
            key={c.key}
            name={c.key}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e, c.max)}
            sx={{ width: '100%' }}
            label={c.label}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: c.sign ? <InputAdornment position="start">{c.sign}</InputAdornment> : null
            }}
          />
          {!pdfView && <StyledSlider
            size="medium"
            aria-label="price slider"
            defaultValue={0}
            value={toNumber(customerData[c.key as keyof CustomerDataInterface] || '')}
            name={c.key}
            onChange={(_e: Event, newValue: number | number[]) => handleSliderChange(newValue, c.key)}
            step={1}
            min={c.min}
            max={c.max}
          />}
        </Stack>
      ))}
    </Stack>
  );
};

export default memo(CostInfo);
