import { ThemeOptions } from '@mui/material/styles';

export const lightThemeConfig: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: 'rgba(0, 119, 207, 1)',
      light: 'rgba(102, 178, 255, 0.15)'
    },
    secondary: {
      main: '#FFD20A'
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFF'
    },
    common: {
      black: '#000',
      white: '#fff'
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    warning: {
      main: '#ffa726',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      main: '#29b6f6',
      light: '#4fc3f7',
      dark: '#0288d1',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    success: {
      main: '#66bb6a',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.7)',
      disabled: 'rgba(0, 0, 0, 0.5)'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: 56,
          minHeight: 56,
          justifyContent: 'center'
        }
      }
    }
  }
};
