import { Box, Typography } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowId,
  GridRowParams,
  GridSlotProps,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationDataInterface } from 'features/myLocation/location-model';
import CustomToolbar from './CustomToolbar';
import { FeedbackResponseInterface } from 'features/userFeedbacks/feedback-model';

interface DataGridTableCustomProps {
  rowHeight?: number;
  rows: LocationDataInterface[] | FeedbackResponseInterface[];
  columns: GridColDef[];
  children?: React.ReactNode | React.ReactNode[];
  hideFooterSelectedRowCount?: boolean;
  showCellVerticalBorder?: boolean;
  showColumnRightBorder?: boolean;
  showCellRightBorder?: boolean;
  checkboxSelection?: boolean;
  pagination?: boolean;
  getDetailPanelContent?: ((params: GridRowParams) => React.ReactNode) | undefined;
  handleDetailPanelExpandedRowIdsChange?: () => void;
  openCreateModel?: boolean;
  loading?: boolean;
  rowsPerPageOptions?: number[] | undefined;
  isFeedbackDataGrid?: boolean;
  getRowStyle?: (params: GridRowParams) => React.CSSProperties | undefined;
  handleToggleState?: (arg: boolean) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
}

const DataGridCustom: FC<DataGridTableCustomProps> = (props: DataGridTableCustomProps) => {
  const getDetailPanelHeight = useCallback(() => 100, []);
  const apiRef = useGridApiRef();
  const [searchValue, setSearchValue] = useState('');
  const { rows, columns, loading, rowsPerPageOptions, getDetailPanelContent, checkboxSelection, pagination } = props;
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);
  const { t } = useTranslation();

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    if (newIds.length > 1) {
      setDetailPanelExpandedRowIds([newIds[newIds.length - 1]]);
    } else {
      setDetailPanelExpandedRowIds(newIds);
    }
    if (props.handleToggleState) {
      props.handleToggleState(newIds.length > 0);
    }
  }, []);

  const NoRowsOverlay = () => <Typography>{t('Flow.Errors.noData') || 'No Data to display'}</Typography>;

  const updateSearchValue = React.useMemo(() => {
    return (newValue: string) => {
      apiRef.current.setQuickFilterValues(newValue.split(' ').filter((word) => word !== ''));
    };
  }, [apiRef]);

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    updateSearchValue(newValue);
  };
  const clearSearch = () => {
    setSearchValue('');
    updateSearchValue('');
  };

  return (
    <Box sx={{ height: `calc(100vh - 185px)` }} component={'div'}>
      {rows ? (
        <>
          <DataGridPro
            sx={{
              border: 0
            }}
            {...props}
            loading={loading}
            onPaginationModelChange={(data) => {
              setPageSize(data.pageSize);
              setPage(data.page);
            }}
            pageSizeOptions={rowsPerPageOptions}
            paginationModel={{ pageSize, page }}
            //pageSize={pageSize}
            apiRef={apiRef}
            rows={rows}
            pagination={pagination}
            checkboxSelection={checkboxSelection}
            columns={columns}
            //rowThreshold={0}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            slots={{ toolbar: CustomToolbar as React.JSXElementConstructor<GridSlotProps['toolbar']> }}
            localeText={{
              toolbarExport: `${t('MySolutionsTab.MySolutionTable.Actions.exportLabel') || 'Export'}`
            }}
            slotProps={{
              toolbar: {
                value: searchValue,
                onChange: handleSearchValueChange,
                clearSearch: clearSearch,
                isFeedbackDataGrid: props.isFeedbackDataGrid
              }
            }}
          />
        </>
      ) : (
        <NoRowsOverlay />
      )}
    </Box>
  );
};

export default DataGridCustom;
