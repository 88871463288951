import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReducerKeys } from 'app/reducerKeys';
import { RootState } from 'app/store';
import { getManulArea } from 'features/guided/guided-constants';
import { ResponseAreaObject } from 'features/guided/guidedModel';
import { ApiGetArea } from './solutionBodyAPI';
import { AreaPayload } from './solutionBodyModel';
import { LocationDataInterface } from 'features/myLocation/location-model';

export interface SolutionBodyState {
  area: string | null;
  areas: Array<ResponseAreaObject> | null;
  areaStatus: 'idle' | 'loading' | 'failed' | 'success';
  locStatus: 'idle' | 'loading' | 'failed' | 'success';
  locLoading: boolean;
  selectedArea: ResponseAreaObject | null;
  areaVal: string;
  shareChecked: boolean;
  loading: boolean;
  locations:  Array<LocationDataInterface>;
  selectedStatus: string | undefined;

}

const initialState: SolutionBodyState = {
  area: null,
  areas: null,
  locLoading: false,
  locStatus: 'idle',
  areaStatus: 'idle',
  selectedArea: null,
  areaVal: '',
  locations: [],
  shareChecked: false,
  loading: false,
  selectedStatus: undefined,
};

export const fetchAreaAsyncDefault = createAsyncThunk(
  `${ReducerKeys.solutionBody}/fetchArea`,
  async (params: AreaPayload) => {
    const { data } = await ApiGetArea(params);
    if (data) return { value: data };
    return { value: data };
  }
);




export const solutionBodySlice = createSlice({
  name: ReducerKeys.solutionBody,
  initialState,
  reducers: {
    setSelectedArea: (state, action: PayloadAction<ResponseAreaObject>) => {
      state.selectedArea = action.payload;
      state.area = action.payload.id;
    },
    setAreaValues: (state, action: PayloadAction<string>) => {
      state.areaVal = action.payload;
    },
    setAreasGuided: (state, action: PayloadAction<ResponseAreaObject[]>) => {
      state.areas = action.payload;
    },
    setShareChecked: (state, action: PayloadAction<boolean>) => {
      state.shareChecked = action.payload;
    },
    setStatusFilterSelected: (state, action: PayloadAction<string | undefined>) => {
      state.selectedStatus = action.payload;
    },
  },
  extraReducers: (builder) => 
  builder.addCase(fetchAreaAsyncDefault.pending, (state) => {
    state.areaStatus = 'loading';
    state.loading = true;
  })
  .addCase(fetchAreaAsyncDefault.fulfilled, (state, action) => {
    state.areaStatus = 'success';
    const areas = action.payload.value;
    if (areas && action.payload.value) {
      const defaultAreaObjSelected = areas?.filter((item) => {return item.area_name === state.areaVal});
      state.areas = [...areas, getManulArea(action.payload.value[0])];
      state.selectedArea = defaultAreaObjSelected[0];
      state.loading = false;
    }
    })
  .addCase(fetchAreaAsyncDefault.rejected, (state) => {
    state.areaStatus = 'failed';
    state.loading = false;
  })


});

export const { setAreaValues, setSelectedArea, setAreasGuided, setShareChecked, setStatusFilterSelected } = solutionBodySlice.actions;
export const selectAreas = (state: RootState) => {
  return state.solutionBody.areas;
};

export const selectAreaDefault = (state: RootState) => {
  return state.solutionBody.areaVal;
};
export const selectSolutionBodyState = (state: RootState) => state.solutionBody;
export const selectedAreaSelected = (state: RootState) => {
  return state.solutionBody.selectedArea;
};

export const selectShareChecked = (state: RootState) => {
  return state.solutionBody.shareChecked;
};

export const selectAreaLoadingList = (state: RootState) => state.solutionBody.loading;

export const selectSolutionBodyAreaState = (state: RootState) => state.solutionBody.selectedArea;

export const selectStatusFilterSelectedEdit = (state: RootState) =>
  state.solutionBody.selectedStatus;
  
export const { reducer: solutionBodyReducer, name: solutionBodyKey } = solutionBodySlice;
