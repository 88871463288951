// export enum StepperAutomatedPages {
//     CriteriaGuided = 'criteria_guided',
//     DetailsGuided = 'details_guided',
//     costInfoGuided = 'cost_info',
//     ReviewGuided = 'review_guided',
//     ResultsGuided = 'results_guided'
//   }

  //Note: Pages needs to be in order w.r.t steps
export enum StepperAutomatedPages {
  CriteriaAutomated = 'criteria_automated',
  Upload = 'upload',
  ReviewValidateAutomated = 'review_validate_automated',
  costInfoAutomated = 'cost_info',
  ReviewAutomated = 'review_automated',
  ResultsAutomated = 'results_automated'
}