import {
  AdditionalCostFeesEnum,
  Charge,
  OrganizationAndPriceGroup
} from 'features/additionalCostFees/additonalCostFeesModel';
import { ActiveConnections, Criteria, MachineSelection } from 'features/guided/guidedModel';

export interface MachineSelectionDataInterface {
  machine_key: MachineSelectionResponseData[];
}

interface CostPerCrib {
  [key: string]: {
    [key: string]: {
      crib_sub_type: string;
      crib_certified_price: number;
    }[];
  };
}

export interface MachineSelectionResponseData {
  _id: string;
  crib_type: string;
  crib_name: string;
  crib_id: MachineSelection;
  storage: {
    ppe_safety: string;
    small_cutting_tools: string;
    spare_parts: string;
    medium_sized_consumables: string;
    large_durable_hand_tools: string;
    small_consumables: string;
    liquids_gels_aerosoles: string;
    small_consumables_tools: string;
    large_bulky_consumables: string;
    small_durable_hand_tools: string;
    [key: string]: string;
  };
  potential_cribs: MachineSelection[];
  criteria_score: {
    inventory_shrinkage: string;
    overuse: string;
    trip_time: string;
    stock_out: string;
    floor_space: string;
    restocking_time: string;
    pay_per_use: string;
    stock_rotation: string;
    calibration_repair: string;
    fod_fme: string;
    [key: string]: string;
  };
  machine_sizes: {
    area_sq_ft_main: number;
    area_sq_ft_aux: number;
    [key: string]: number;
  };
  description: string;
  description_lang: { [key: string]: string };
  cost: CostPerCrib;
  machine_type: string;
  image_url: string;
  consumable: boolean;
  durable: boolean;
  aux_limit: number;
  id: string;
}

export interface MachineCalculationParams {
  average_time_per_transaction: number;
  max_time_start_shift_area: number;
}

export interface CribSelectionPostPayload {
  cribs?: string[];
  rfid_machines?: boolean;
  express_machines?: boolean;
  accuport_machines?: boolean;
  machine_calculation_params?: MachineCalculationParams;
  connections?: ActiveConnections;
}

export interface CribSelectionUpdateResponse {
  _id: string;
  id: string;
  org_id: string;
  cribs: MachineSelection[];
  machine_calculation_params: MachineCalculationParams;
  user_id: string;
  delete_status: boolean;
  createdAt: string;
  updatedAt: string;
  __v?: 0;
  accuport_machines: boolean;
  express_machines: boolean;
  rfid_machines: boolean;
  general_org: OrganizationAndPriceGroup;
  criterias: Criteria[];
  [AdditionalCostFeesEnum.additionalCosts]: Charge[];
  [AdditionalCostFeesEnum.additionalFees]: Charge[];
  connections: ActiveConnections;
}

export enum MachineCheckboxOptions {
  express = 'express',
  rfid = 'rfid',
  accuPort = 'accuPort'
}
