import { FC } from 'react';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingContainerProps {}

const LoadingContainer: FC<LoadingContainerProps> = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress />
    </Container>
  );
};

export default LoadingContainer;
