export interface UserDetailResponseModal {
  userName: string;
  firstName: string;
  fullName: string;
  lastName: string;
  vendingPin: string;
  badge: string;
  defaultLanguage: CmUserLanguage | null | undefined;
  supervisorId: string;
  supervisorName: string;
  roles: [];
  tags: [];
  inactive: boolean;
  inviteStatus: number;
  email: string;
  cribAccess: string;
  jobTitle: string;
  lastLogin: string;
  orgAccess: string[];
  orgId: string;
  orgName: string;
  rootOrgId: string;
  partnerId: string;
  _id: string;
  createdDate: string;
  createdBy: string;
  createdByName: string;
  updatedDate: string;
  updatedBy: string;
  updatedByName: string;
  healthStatus: string;
  deletedByName: string;
  imageUrl: string;
  orgType : string;
  priceGroup : string;
  discount : number;
  warning: string;
}

export enum CmUserLanguage {
  Danish = 'da-DK',
  EnglishUK = 'en-GB',
  EnglishUS = 'en-US',
  English = 'english',
  French ='fr-FR',
  EnglishEurope='en-EU'
}

export enum Currency {
  Doller = 'USD',
  Pound = 'GBP',
  Euro = 'EUR'
}

/**
 "en-US" - US $ 
"en-GB" - British Pound
"da-DK" - EURO
"en-EU" - EURO
 */

export const CmUserCurrencyMapping = [
  {
    lang: CmUserLanguage.EnglishUS,
    currency: Currency.Doller
  },
  {
    lang: CmUserLanguage.EnglishUK,
    currency: Currency.Pound
  },
  {
    lang: CmUserLanguage.Danish,
    currency: Currency.Euro
  },
  {
    lang: CmUserLanguage.French,
    currency: Currency.Euro
  },
  {
    lang: CmUserLanguage.EnglishEurope,
    currency: Currency.Euro
  }
];

export enum CurrencySymbol {
  Doller = '$',
  Pound = '£',
  Euro = '€'
}

export const CmUserCurrencySymbolMapping = [
  {
    lang: CmUserLanguage.EnglishUS,
    currency: CurrencySymbol.Doller
  },
  {
    lang: CmUserLanguage.EnglishUK,
    currency: CurrencySymbol.Pound
  },
  {
    lang: CmUserLanguage.Danish,
    currency: CurrencySymbol.Euro
  },
  {
    lang: CmUserLanguage.French,
    currency: CurrencySymbol.Euro
  },
  {
    lang: CmUserLanguage.EnglishEurope,
    currency: CurrencySymbol.Euro
  }
];

export const CurrencytoCurrencySymbolMapping = [
  {
    currency: Currency.Doller,
    currencySign: CurrencySymbol.Doller
  },
  {
    currency: Currency.Pound,
    currencySign: CurrencySymbol.Pound
  },
  {
    currency: Currency.Euro,
    currencySign: CurrencySymbol.Euro
  }
];

export enum UserOrgType {
  direct = 'DIRECT',
  distributor = 'DISTRIBUTOR'
}
