
export interface ProductResponseList {
  POU: string;
  LockerData: {
    LockerVendable: string;
    CompartmentsPerLocker: string;
    CapacityForExpressLocker: number;
    CapacityForProstocklocker: number;
    CapacityForProlocklocker: number;
  };
  CarouselData: {
    CarouselVendable: string;
    RepackForCarousel: string;
    SlotsPerItem: number;
  };
  CoilData: {
    CoilVendable: string;
    NeedRepackForCoil: string;
    CoilPitch: number;
    CoilType: string;
    PreferredShelf: string;
    PreferredRow: string;
    RiserRequired: string;
    FlipBarRequired: string;
    CoilEnd: number;
    RiserRequiredWS: string;
  };
  WeighStationData:Record<string, unknown>;
  ImageFilesData: {
    ItemImageURL?: string;
    RepackedImageUrl?: string;
    RepackingInstructions?: string;
  };
  DemandQuantity: number;
  DemandTimeWindow: string;
  SecurityLevel: string;
  OverallVendability: string;
  ItemCost: number;
  ExpectedGrossProfitMargin: number;
  Height: number;
  Width: number;
  Length: number;
  Weight: number;
  Heavy: string;
  Fragile: string;
  DefaultIssueType: string;
  DefaultIssueQty: number;
  Stackable: string;
  Loose: string;
  StoreVertically: string;
  PreferredMachineType: string;
  SKU: string
}

export enum ExcelColumnKey {
  SKU = 'SKU',
  ItemDescription = 'ItemDescription' ,
  ManufacturerPartNumber = 'ManufacturerPartNumber' ,
  ItemManufacturer = 'ItemManufacturer' ,
  AltItemID = 'AltItemID' ,
  OrgLocalPartNumber = 'OrgLocalPartNumber' ,
  POU = 'POU' ,
  DemandQuantity = 'DemandQuantity' ,
  DemandTimeWindow = 'DemandTimeWindow' ,
  SecurityLevel = 'SecurityLevel' ,
  OverallVendability = 'OverallVendability' ,
  VendabilityNotes = 'VendabilityNotes' ,
  ItemCost = 'ItemCost' ,
  ExpectedGrossProfitMargin = 'ExpectedGrossProfitMargin' ,
  Height = 'Height' ,
  Width = 'Width' ,
  Length = 'Length' ,
  Weight = 'Weight' ,
  Heavy = 'Heavy' ,
  Fragile = 'Fragile' ,
  DefaultIssueType = 'DefaultIssueType' ,
  DefaultIssueQty = 'DefaultIssueQty' ,
  Stackable = 'Stackable' ,
  Loose = 'Loose' ,
  StoreVertically = 'StoreVertically' ,
  PreferredMachineType = 'PreferredMachineType' ,
  LockerVendable = 'LockerVendable',
  CompartmentsPerLocker = 'CompartmentsPerLocker',
  CapacityForExpressLocker = 'CapacityForExpressLocker',
  CapacityForProstockLocker = 'CapacityForProstockLocker',
  CapacityForProlockLocker = 'CapacityForProlockLocker',
  CarouselVendable = 'CarouselVendable',
  RepackForCarousel = 'RepackForCarousel',
  SlotsPerItem = 'SlotsPerItem',
  CoilVendable = 'CoilVendable',
  NeedRepackForCoil = 'NeedRepackForCoil',
  CoilPitch = 'CoilPitch',
  CoilType = 'CoilType',
  PreferredShelf = 'PreferredShelf',
  PreferredRow = 'PreferredRow',
  RiserRequired = 'RiserRequired',
  FlipBarRequired = 'FlipBarRequired',
  CoilEnd = 'CoilEnd',
  RiserRequiredWS = 'RiserRequiredWS',
  RepackingInstructions = 'RepackingInstructions',
  ItemImageURL = 'ItemImageURL',
  RepackedImageUrl = 'RepackedImageUrl'
 }

 export interface ProductListAPIResponse {
  count: number;
  data: ExcelParsedData[]
 }

 export interface UpdatePOURecords {
  records: POURecordsData[];
}

export interface UpdatePayload {
  records: POURecordsData[];
  fileuploadId: string;
  pouValue: string;
}

export interface POURecordsData {
  pouRecordId: string;
  updateRowData: ExcelColumnData[];
}

 export interface ProductListAPIResponse {
  count: number;
  data: ExcelParsedData[]
 }

export interface ExcelParsedData {
  rowNumber: number;
  rowData: ExcelColumnData[];
  rowErrors: ExcelRowColumnError[];
  columnErrors: ExcelColumnError[];
  _id: number;
  id: number | string;
}

export interface ExcelColumnData {
  colKey: ExcelColumnKey;
  value: string | number | null ;
}

export interface ExcelRowColumnError {
  severity: number;
  message: string;
}

export interface ExcelColumnError {
  severity: number;
  message: string;
  colKey: string;
  id: string;
}

export interface ProductAPIPayload {
  selected_pou: string;
  flowId: string | undefined;
  offset: number,
  limit: number,
  severity?: number,
  searchValue: string,
}

export enum SeverityKeys {
  All='All',
  MissingRequiredInvalidData = 'Missing Required Or Invalid Data',
  NoItemPlacement = 'No Item Placement',
  PlacementDataMismatch = 'Placement Data Mismatch',
  OnlySizeAvailable = 'Only Sizes Available',
  ItemCostMissing = 'Item Cost Missing',
}

export const severityMap: { [key in SeverityKeys]: number|null } = {
  [SeverityKeys.All]: null,
  [SeverityKeys.MissingRequiredInvalidData] : 10,
  [SeverityKeys.NoItemPlacement]: 20,
  [SeverityKeys.PlacementDataMismatch]: 30,
  [SeverityKeys.OnlySizeAvailable]: 40,
  [SeverityKeys.ItemCostMissing]: 50
};

export const severityM: {label:SeverityKeys, val: number }[] = [
  {label: SeverityKeys.All, val: 0},
  {label: SeverityKeys.MissingRequiredInvalidData, val: 10},
  {label: SeverityKeys.NoItemPlacement, val: 20},
  {label: SeverityKeys.PlacementDataMismatch, val: 30},
  {label: SeverityKeys.OnlySizeAvailable, val: 40},
  {label: SeverityKeys.ItemCostMissing, val: 50},
]
