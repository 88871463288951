
import axiosConfig from 'app/axiosConfig';
import { AxiosError } from 'axios';
import { AxiosAPIError, handleError } from 'utils/apiError';

import {  CribSelectionPostPayload, CribSelectionUpdateResponse, MachineSelectionResponseData } from './machine-model';

export const getAllCribs = async (): Promise<{
  response: MachineSelectionResponseData[] | undefined;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.get<MachineSelectionResponseData[]>(`/api/crib`);
    return { response: data?.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    const errr =  { data: null, error: handleError(errors) };
    throw errr
  }
};

export const getAllCribsByOrg = async (): Promise<{
  response: CribSelectionUpdateResponse | undefined;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.get<CribSelectionUpdateResponse>(`/api/criborg`);
    return { response: data?.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    const errr =  { data: null, error: handleError(errors) };
    throw errr
  }
};

export const updateMachinesSelectionAsync = async (payload: CribSelectionPostPayload): Promise<{
  response: CribSelectionUpdateResponse;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.put(`/api/criborg`, payload);
    return { response: data?.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    const errr =  { data: null, error: handleError(errors) };
    throw errr
  }
};


