import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

interface LoaderProps {
  circle?: boolean;
  noData?: boolean;
}

const Loader = (props: LoaderProps): JSX.Element => {
  const { circle = true, noData = false } = props;
  if (noData) {
    return (
      <Box className={'loader'}>
        <Typography
          sx={{
            opacity: '0.5'
          }}
        >
          'No data'
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {circle ? <CircularProgress /> : 'Loading ....'}
    </Box>
  );
};

export default Loader;
