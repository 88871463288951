import { FileWithPath } from 'file-selector';
import { Criteria } from 'features/criteria-ranking/criteriaRankingModel';
import { ItemQuantity, SecurityLevel, InventoryType, Item } from 'features/security/securityModel';
import { CostInfoState } from 'features/costInfo/costInfoSlice';
import { ParsedExcelData, RowExcel } from 'components/parser/parserModel';

export enum DataType {
  Incoming,
  Outgoing
}

export interface ResponseCostInfoState {
  state_sales_text: number |  string | undefined;
  labor_rate_per_hour: number |  string | undefined;
  stocking_labor_per_week_hours: number |  string | undefined;
  freight_estimate: number |  string | undefined;
  estimated_repair_mtc_cost_over_term: number |  string | undefined;
  type: DataType.Incoming;
}

export interface ResponseItemData {
  manufacturer_name: string;
  area: string;
  manufacturer_part_number: string;
  description: string;
  consumable: string;
  durable: string;
  current_12_month_usage: number;
  default_issue_type: string;
  default_issue_qty: number;
  length: number;
  width: number;
  height: number;
  customer_item_number: string;
}

export interface ResponseGetFlowDataByID {
  criteria_ranking: Criteria[];
  flow_security: SecurityLevel;
  flow_inventory_data: Item;
  item_data: ResponseItemData[];
  flow_inventory_types: InventoryType[];
  cost_info: ResponseCostInfoState;
  crib_config?: ResponseCribResult;
}

export interface CostInfoStateIncoming extends CostInfoState {
  type: DataType.Outgoing;
}

export type CostPayload = CostInfoStateIncoming | ResponseCostInfoState;

export interface ResponseDataUpload {}

export interface inputParams {
  file: FileWithPath;
  area: string;
  table?: Array<RowExcel>;
  flow_id: string | undefined;
}

export interface GetPreassignedURLResponse {
  file_id: string;
  fileUploadURL: string;
}

export interface GetFileParseResponse {
  POU : string[];
}


export interface PayloadGetPreassignedApi {
  flow_id: string | undefined;
  file_name: string;
}

export interface ResponseCribResult {
  _id: string;
  cost: Array<costInterface>;
  crib_name: string;
  crib_type: string;
  description: string;
  id: string;
}
export interface costInterface {
  distributor_price: string;
  distributor_price_annual: string;
  end_user_price: string;
  end_user_price_annual: string;
}
export interface RequestSaveFileData {
  flowId?: string | null;
  file_url?: string;
  item_data?: ParsedExcelData[];
  security?: SecurityLevel | '';
  criteria_ranking?: Criteria[];
  flow_inventory_data?: ItemQuantity[] | undefined;
  flow_inventory_types?: ('' | InventoryType)[];
  cost_info?: Partial<ResponseCostInfoState>;
}


export interface RetrivePOUResponseList {
  file_id: string,
  file_name: string,
  pou_list: [],
  selected_pou: string
}
