import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import { AlertType, SnackAlertInterface, StepperDataInterface, StepperLoadingInterface, StepperObjectInterface, StepperPages } from './stepperModel';
import { fetchAreaAsync, getLocationList, getOrganizationList,  } from 'features/createSolution/createSolutionSlice';
import { apiFailObject } from './contant';
import { getSharedSolutionsList } from 'features/sharedSolutions/sharedSolutionsSlice';
import { messages } from 'constants/message';
import { getMySolutionsList } from 'features/mySolution/mySolutionsSlice';
import { fetchAreaAsyncDefault } from 'features/solutionBody/solutionBodySlice';

const initialState: StepperDataInterface = {
  value: {
    [StepperPages.Criteria]: false,
    [StepperPages.Upload]: false,
    [StepperPages.Review]: true,
    [StepperPages.costInfoGuided]: true,
    [StepperPages.Results]: true,
    [StepperPages.CriteriaGuided]: true,
    [StepperPages.DetailsGuided]: true,
    [StepperPages.ReviewGuided]: true,
    [StepperPages.ResultsGuided]: true
  },
  loading: false,
  activeStep: undefined,
  gotoStep: 0,
  isDialogueActive: false, 
  snackAlert: {
    open: false,
    type: AlertType.error,
    message: 'some message',
    timeout: 6000
  }
};

interface PayloadInterface {
  name: StepperPages;
  value: boolean;
}

export const stepperSlice = createSlice({
  name: ReducerKeys.stepper,
  initialState,
  reducers: {
    setStepperData: (state, action: PayloadAction<PayloadInterface>) => {
      const { payload } = action;
      state.value[payload.name] = payload.value;
    },
    setStepperInitialValidation: (state, action: PayloadAction<StepperObjectInterface>) => {
      const { payload } = action;
      state.value = payload;
    },
    setStepToSave: (state, action) => {
      state.activeStep = action.payload;
    },
    setStepperLoading: (state, action: PayloadAction<StepperLoadingInterface>) => {
      const { value, next } = action.payload
      state.loading = value;
      if(next === true) state.gotoStep = state.gotoStep + 1;
    },
    setStepperStep: (state, action) => {
      state.gotoStep = action.payload;
    },
    setSnackAlert: (state, action: PayloadAction<SnackAlertInterface>) => {
      state.snackAlert = action.payload;
    },
    setStepperGuidedDialogue: (state, action: PayloadAction<boolean>) => {
      state.isDialogueActive = action.payload;
    },
    clearStepperGuidedState: () => initialState
  },

  extraReducers: (builder) => {
    builder
    .addCase(getOrganizationList.rejected, (state) => {
      state.snackAlert = apiFailObject;
    })
    .addCase(getLocationList.rejected, (state) => {
      state.snackAlert = apiFailObject;
    })
    .addCase(fetchAreaAsync.rejected, (state) => {
      state.snackAlert = { open: true, type: AlertType.error, message: messages.error_no_area, timeout: 6000};
    })
    .addCase(getSharedSolutionsList.rejected, (state) => {
      state.snackAlert = { open: true, type: AlertType.error, message: messages.error_no_sol, timeout: 6000};
    })
    .addCase(getMySolutionsList.rejected, (state) => {
      state.snackAlert = { open: true, type: AlertType.error, message: messages.error_no_sol, timeout: 6000};
    })
    .addCase(fetchAreaAsyncDefault.rejected, (state) => {
      state.snackAlert = { open: true, type: AlertType.error, message: messages.error_no_area, timeout: 6000};
    })
  }

});

export const { setStepperData, clearStepperGuidedState, setStepperInitialValidation, setStepToSave, setStepperStep, setStepperLoading, setSnackAlert, setStepperGuidedDialogue } =
  stepperSlice.actions;
export const selectStepperData = (state: RootState) => state.stepper.value;
export const selectStepToSave = (state: RootState) => state.stepper.activeStep;
export const selectStep = (state: RootState) => state.stepper.gotoStep;
export const selectStepperLoading = (state: RootState) => state.stepper.loading;
export const selectSnackAlert = (state: RootState) => state.stepper.snackAlert;
export const selectIsDialogueActive = (state: RootState) => state.stepper.isDialogueActive;
export const { reducer: stepperReducer, name: stepperKey } = stepperSlice;
