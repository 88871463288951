import { Box, FormControl, Grid, InputLabel, OutlinedInput } from '@mui/material';
import { selectStep } from 'features/stepper/stepperSlice';
import { CmUserLanguage } from 'features/user/userModel';
import i18n from 'i18n/config';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fcsl } from 'utils/currency';
import { CustomerDataKeys } from '../guidedApiKeys';
import { selectCustomerData, setCustomerDataGuided } from '../guidedSlice';

const CustomerDataGuided = () => {
  const customerInfo = useSelector(selectCustomerData);
  const activeStep = useSelector(selectStep);
  const disabled = activeStep === 3 ? true : false;
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, max: number) => {
    const { name, value } = e.target;
    const numberedValue = Number(value);
    dispatch(setCustomerDataGuided({ name, value: numberedValue > max ? max : numberedValue }));
  };

  const customerInputs = [
    { label: `${t('Flow.GuidedTab.CostInfoStep.CostInfo.consumptionReduction') || 'Consumption Reduction'}`, key: CustomerDataKeys.consumption_reduction, min: 1, max: 100, defaultValue: 0 },
    { label: `${t('Flow.GuidedTab.CostInfoStep.CustomerDataSection.employeesPerShiftInputLabel') || '# of Employees Per Shift'}`, key: CustomerDataKeys.employees_per_shift, min: 1, max: 5000, defaultValue: 0 },
    { label: `${t('Flow.GuidedTab.CostInfoStep.CustomerDataSection.employeeAvgLaborRateInputLabel') || 'Employee Average Labor Rate / Hour'}`, key: CustomerDataKeys.avg_labor_rate_per_hour, unit: fcsl(i18n.language as CmUserLanguage), min: 10, max: 50, defaultValue: 0 },
    { label: `${t('Flow.GuidedTab.CostInfoStep.CustomerDataSection.totalEmployeeTripTimeInputLabel') || 'Total Employee Trip Time (min) / Shift'}`, key: CustomerDataKeys.total_trip_time, min: 1, max: 30, defaultValue: 0 },
    { label: `${t('Flow.GuidedTab.CostInfoStep.CustomerDataSection.poPerShiftInputLabel') || '# of PO’s Per Shift'}`, key: CustomerDataKeys.po_per_month, min: 1, max: 50, defaultValue: 0, required: false },
    { label: `${t('Flow.GuidedTab.CostInfoStep.CustomerDataSection.costPerPoInputLabel') || 'Cost Per PO'}`, key:  CustomerDataKeys.cost_per_po, min: 20, max: 300, unit: fcsl(i18n.language as CmUserLanguage), defaultValue: 0 }
  ];

  const height = 'calc(100vh - 410px)';

  return (
    <Box>
      <Box>
        <Box sx={{ height: height, overflowY: 'auto' }}>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            {customerInputs.map((item, i) => {
              return (
                <Grid item xs={12} key={item.label + 'solution-cost'}>
                  <FormControl fullWidth variant="outlined" key={item.label + 'guided' + i}>
                    <InputLabel  error={item.required && !customerInfo[item.key] ? true: false} required={!!item.required} htmlFor="outlined-adornment-amount">{item.label}</InputLabel>
                    <OutlinedInput
                      name={item.key}
                      value={customerInfo[item.key]}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e, item.max)}
                      id="outlined"
                      // type='number'
                      inputProps={{ pattern: '[0-9]*', step: 'any', min: item.min, max: item.max }}
                      disabled={disabled}
                      label={item.label}
                      size="medium"
                      error={item.required && !customerInfo[item.key] ? true: false}
                      endAdornment={item.unit || undefined}
                      required={!!item.required}
                    />
                  </FormControl>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerDataGuided;
