import { get } from 'lodash';
import { GuidedState } from 'features/guided/guidedSlice';
import { ItemQuantityGuided } from 'features/guided/guidedModel';

export const isEmailValid = (email: string) => {
  const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
  return !reg.test(email) ? false : true;
};

interface ValidatedObject {
  [key: string]: string | number | boolean | unknown | undefined;
}

// Function that checks if an object is valid based on specified keys and required keys
export const isValidObject = (obj: GuidedState | ItemQuantityGuided, keys: string[]): boolean => {

  for (const key of keys) {
    // Get the value of the current key from the object using lodash's get() function
    const value = get(obj, key);
    // If the value is undefined, null, an empty string, or false, return false
    if (value === undefined || value === null || value === '' || value === false || value === 0) {
      return false;
    } else if (typeof value === 'object') { // If the value is an object, check its keys recursively
      if (Array.isArray(value)) { // If the value is an array, iterate over its elements
        for (const element of value) {
          if (typeof element === 'object') { // If an element is an object, check its keys recursively
            if (!isValidObject(element, keys)) { // If an element is not valid, return false
              return false;
            }
          }
        }
      } else { // If the value is an object but not an array, check its keys recursively
        if (!isValidObject(value, keys)) { // If the value is not valid, return false
          return false;
        }
      }
    }
  }
  return true;
};

export const validateObject = (object: ValidatedObject, requiredKeys: string[]): boolean => {
  for (const key of requiredKeys) {
    if (!Object.prototype.hasOwnProperty.call(object, key) ||
      object[key] === null || object[key] === undefined || object[key] === 0) {
      return false;
    }
  }
  return true;
};
