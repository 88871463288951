import  { useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { GridRowParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { AppDispatch } from 'app/store';
import { getAllFeedbackLists } from 'features/userFeedbacks/feedbackSlice';
import * as React from 'react';
import { useDispatch } from 'react-redux';

type IconType = React.ReactElement | null;

export type GridActionFeedbackItemProps = {
  label: string;
  icons?: IconType[];
  onClick?: (params: GridRowParams) => void;
  params: GridRowParams;
  onPanelClose?: () => void;
  isPanelOpen?: boolean;
};

const GridActionFeedbackItem = (props: GridActionFeedbackItemProps) => {
  const { label, icons = [], params } = props;
  const isSingleIcon = icons.length === 1;
  const [iconIndex, setIconIndex] = React.useState(0);
  const [isPanelOpen, setIsPanelOpen] = React.useState(false); // Track panel state
  const [isApiCallPending, setIsApiCallPending] = React.useState(false); // Track API call state
  const dispatch = useDispatch<AppDispatch>();

  const apiRef = useGridApiContext();

  useEffect(() => {
    if (isApiCallPending && !isPanelOpen && props.onPanelClose) {
      if (!params.row.read_status) {
        dispatch(getAllFeedbackLists());
      }

      props.onPanelClose();
      setIsApiCallPending(false); 
    }
  }, [isApiCallPending, isPanelOpen, props]);
  
  const handleIconClick = () => {
      if (isPanelOpen) {
        setIsApiCallPending(true); 
        apiRef.current.toggleDetailPanel(params.id);
        setIsPanelOpen(false); 
      } else {
        apiRef.current.toggleDetailPanel(params.id);
        setIsPanelOpen(true);
      }
    if (icons.length > 1) {
      setIconIndex((prevIconIndex) => (prevIconIndex + 1) % icons.length);
    }
  };

  const currentIcon = isSingleIcon ? icons[0] : icons[iconIndex];

    return (
      <>
        <Tooltip title={label} placement="top">
          <IconButton size="small" role="menuitem" aria-label={label} onClick={handleIconClick}>
            {currentIcon}
          </IconButton>
        </Tooltip>
      </>
    );
  
};

export default GridActionFeedbackItem;
