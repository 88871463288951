import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { selectSnackAlert, setSnackAlert } from 'features/stepper/stepperSlice';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

export default function SolAlert() {
  const data = useSelector(selectSnackAlert);
  const dispatch = useDispatch();

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackAlert({ ...data, open: false }));
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={data.open}
        autoHideDuration={data.timeout || 6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={data.type} sx={{ width: '100%' }}>
          {data.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
