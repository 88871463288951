import { CUSTOMER_SUPPORT_ORG_ID } from 'app/constants';
import { useAppSelector } from 'app/hooks';
import { store } from 'app/store';
import { AlertType } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { selectUserDetails } from 'features/user/userSlice';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

interface CustomRouteProps {
  element: JSX.Element;
  orgId?: string;
  isSettings: boolean;
}
const CustomerSupportRoute: React.FC<CustomRouteProps> = (props) => {
  const userDetails = useAppSelector(selectUserDetails);
  const { element, orgId, isSettings } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (isSettings) {
      if (
        userDetails?.rootOrgId !== CUSTOMER_SUPPORT_ORG_ID &&
        (userDetails?.orgId !== userDetails?.rootOrgId)
      ) {
        navigate('/');
        store.dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Access Denied', timeout: 3000 }));
      }
    } else if (userDetails?.rootOrgId !== CUSTOMER_SUPPORT_ORG_ID) {
      navigate('/')
    }
  }, [userDetails, orgId, isSettings, navigate]);

  return element;
};

export default CustomerSupportRoute;
