import React from 'react';
import CircularProgressWithLabel from 'components/circular-progress/CircularProgressWithLabel';
import { Box, Typography } from '@mui/material';

interface ProgressBarWithTextProps {
  progress: number;
  text: string;
}

const ProgressBarWithText: React.FC<ProgressBarWithTextProps> = ({ progress, text }) => {
  return (
    <Box display="flex" alignItems="center">
      <CircularProgressWithLabel value={progress} />
      <Typography variant="body1" marginLeft={'10px'}>
        {text}
      </Typography>
    </Box>
  );
};

export default ProgressBarWithText;
