import { t } from "i18next";
import { CostFeesItem, SolutionCost } from "../guidedModel";

interface CostTranslationKey {
    [key: string]: string;
    HardwareTaxCost: string;
    FreightEstimate: string;
    LicenseAndSupportCostOverTerm: string;
    EstimatedRepairCostsOverTerm: string;
    TotalStockingLaborCostsOverTerm: string;
    CribMasterHostingCostOverTerm: string;
}
interface LegacyTranslationKeys {
    [key: string]: string;
    Total_LicensingSubscription_SupportCostsOverTerm: string,
    HardwareCost: string
}
interface CostItem  {
    name: string;
    value: number | CostFeesItem[]
}
export interface OutputCostFees {
    info: CostItem[],
    additionalCosts: CostItem[],
    additionalFees: CostItem[]
}
export const legacyTranslationKey: LegacyTranslationKeys = {
    // Legacy Support
    Total_LicensingSubscription_SupportCostsOverTerm: t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.licenseNSupportLabel'),
    HardwareCost: t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.hardwareCostLabel'),
}
export const costTranslationKey: CostTranslationKey = {
    // License and support cost
    HardwareTaxCost: t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.hardwareTaxCostLabel'),
    FreightEstimate:  t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.freightEstimateLabel'),
    LicenseAndSupportCostOverTerm: t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.licenseNSupportLabel'),
    EstimatedRepairCostsOverTerm:  t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.estimatedRepairCostsOverTermLabel'),
    // Other Costs
    TotalStockingLaborCostsOverTerm: t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.restockingCostLabel'),
    CribMasterHostingCostOverTerm: t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.hoistingFeesLabel'),
}

export const parseNameValue = (cost: SolutionCost): OutputCostFees  => {
    const filteredCosts: CostItem[] = [];
    const additionalCosts: CostItem[] = [];
    const additionalFees: CostItem[] = [];

    // LEGACY KEYS SUPPORT
    if(!cost?.HardwareTaxCost){
        Object.entries(legacyTranslationKey).forEach(([key, value]) => {
            if (cost[key]) {
                filteredCosts.push({
                    name: value,
                    value: cost[key]
                });
            }
        });
    }
    Object.entries(costTranslationKey).forEach(([key, value]) => {
        if (cost[key]) {
            filteredCosts.push({
                name: value,
                value: cost[key]
            });
        }
    });
    if (cost?.AllAdditionalCostsAndFees?.length) {
        cost.AllAdditionalCostsAndFees?.forEach(item => {
            if(item.chargeCategory === 'fees'){
                additionalFees.push({
                    name: item.costsFeesKey,
                    value: item.costsFeesValue
                })
            }else{
                additionalCosts.push({
                    name: item.costsFeesKey,
                    value: item.costsFeesValue
                })
            }
            
        });
    }
    return {info: filteredCosts, additionalFees, additionalCosts};
};