import ClearIcon from '@mui/icons-material/Clear';
import { Grid, IconButton, TextField } from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

interface customInterface {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;
  isFeedbackDataGrid?: boolean;
}
const CustomToolbar = ({ value, onChange, clearSearch, isFeedbackDataGrid }: customInterface) => {
  const { t } = useTranslation();

  return (
    <Grid container sx={{ width: '100%', px: 1 }} spacing={2}>
      <Grid item xs={6}>
        {!isFeedbackDataGrid && <GridToolbar />}
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TextField
          variant="outlined"
          size="small"
          value={value}
          onChange={onChange}
          placeholder={t('Common.search') || 'Search'}
          InputProps={{
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                onClick={() => clearSearch()}
                sx={{ visibility: value ? 'visible' : 'hidden' }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomToolbar;
