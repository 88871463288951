import { InfoOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { CostInfoState } from 'features/costInfo/costInfoSlice';
import { selectFlowActiveConnection, selectUserOrgType, setFlowActiveConnection } from 'features/guided/guidedSlice';
import { selectStep, setStepperStep } from 'features/stepper/stepperSlice';
import { CmUserLanguage, CurrencySymbol, UserOrgType } from 'features/user/userModel';
import useCurrency from 'hooks/currency-hook';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyToNumber, fcsl } from 'utils/currency';
import { CostInfoKeys } from '../guidedApiKeys';
import { ActiveConnections, Criteria } from '../guidedModel';
import {
  selectCostInfoGuided,
  selectCribMasterHostingData,
  selectedCriteriaList,
  setCostInformationGuided,
  setCribMasterHostingData
} from '../guidedSlice';
import MachineActiveConnections from 'components/machine-active-connection/MachineActiveConnections';
import { selectUserDetails } from 'features/user/userSlice';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { isEqual } from 'lodash';
import { CRIBMASTER_SALES_ID } from 'app/constants';

interface costInputs {
  label: string;
  key: CostInfoKeys;
  required: boolean;
  currency?: boolean;
  unit?: CurrencySymbol | undefined;
  defaultValue?: number;
  max?: number;
}

const SolutionCostGuided = () => {
  const theme = useTheme();
  const costInfo = useAppSelector(selectCostInfoGuided);
  const criteriaList = useAppSelector(selectedCriteriaList);
  const activeStep = useAppSelector(selectStep);
  const userDetails = useAppSelector(selectUserDetails);
  const activeConnection = useAppSelector(selectFlowActiveConnection);

  const userOrgType = useAppSelector(selectUserOrgType);
  const isDirectCustomer = userOrgType === UserOrgType.direct;
  const { formatToFlowCurrency } = useCurrency();

  const disabled = activeStep === 3 ? true : false;
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const cm_hosting_data = useAppSelector(selectCribMasterHostingData);
  const CustomFormStyled = styled(FormControlLabel)(() => ({
    '& .MuiTypography-body1': {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary
    }
  }));

  const costInputs: costInputs[] = [
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.salesTaxInputLabel') || 'State Sales Tax %'}`,
      currency: false,
      key: CostInfoKeys.state_sales,
      required: false,
      defaultValue: 0,
      max: 100
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.freightEstimateInputLabel') || 'Freight Estimate'}`,
      currency: true,
      key: CostInfoKeys.freight_estimate,
      unit: fcsl(i18n.language as CmUserLanguage),
      required: false,
      defaultValue: 0
    },
    {
      label: `${
        t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.stockingLaborInputLabel') || 'Stocking Labor per week (Hrs)'
      }`,
      currency: false,
      key: CostInfoKeys.stocking_labor,
      required: false,
      defaultValue: 0
    },
    {
      label: `${
        t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.stockingLaborRateInputLabel') || 'Stocking Labor Rate'
      }`,
      currency: true,
      key: CostInfoKeys.labor_rate_per_hour,
      unit: fcsl(i18n.language as CmUserLanguage),
      required: true
    },
    {
      label: `${
        t(
          isDirectCustomer
            ? 'Flow.GuidedTab.CostInfoStep.SolutionCostSection.leaseAgreementTermDirectInputLabel'
            : 'Flow.GuidedTab.CostInfoStep.SolutionCostSection.leaseAgreementTermInputLabel'
        ) || 'Lease/VMI Agreement Term (Yrs)'
      }`,
      currency: false,
      key: CostInfoKeys.lease_agreement,
      required: true
    },
    {
      label: `${
        t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.estimatedRepairCostsInputLabel') ||
        'Estimated Repair & MTC Costs Over Term'
      }`,
      currency: true,
      key: CostInfoKeys.estimated_repair,
      unit: fcsl(i18n.language as CmUserLanguage),
      required: true
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.budgetInputLabel') || 'Budget'}`,
      key: CostInfoKeys.budget,
      unit: fcsl(i18n.language as CmUserLanguage),
      required: false
    }
  ];

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, max?: number) => {
    const { name, value } = e.target;
    const numberedValue = max && +value > max ? max.toString() : value;
    const dataParam = { name, value: parseFloat(numberedValue) };
    dispatch(setCostInformationGuided(dataParam));
  };

  const handleCostInformationEdit = () => {
    dispatch(setStepperStep(2));
  };

  const setSelectedConnectionId = (connectionId: ActiveConnections) => {
    dispatch(setFlowActiveConnection(connectionId));
  };

  return (
    <Box>
      <Box>
        <Stack direction={'row'} spacing={1}>
          <Typography variant="h6" fontSize={'16px'}>
            {`${t('Flow.GuidedTab.CostInfoStep.CostInfo.solutionCost') || 'Solution Cost'}`}
          </Typography>
          {disabled && (
            <Tooltip title="Edit Cost Information">
              <EditIcon
                onClick={handleCostInformationEdit}
                sx={{ color: theme.palette.action.active }}
                fontSize="small"
              />
            </Tooltip>
          )}
        </Stack>

        {!disabled && (
          <Typography
            sx={{
              fontSize: '14px',
              color: theme.palette.text.secondary,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <InfoOutlined sx={{ color: theme.palette.text.secondary, fontSize: '15px', marginRight: '5px' }} />
            {`${
              t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.subTitle') ||
              'Basic information to calculate accurate recommendations.'
            }`}
          </Typography>
        )}
        <Box>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            {costInputs.map((item, i) => {
              const key: keyof CostInfoState = item.key;
              if (key === CostInfoKeys.budget && !criteriaList.includes(Criteria.budget)) return null;
              return (
                <Grid item xs={12} key={item.label + 'solution-cost'}>
                  <FormControl fullWidth variant="outlined" key={item.label + 'guided' + i}>
                    <TextField
                      label={item.label}
                      value={item.currency ? formatToFlowCurrency(costInfo[key]) : costInfo[key]}
                      required={item.required}
                      error={item.required && !costInfo[key]}
                      name={item.key}
                      disabled={disabled}
                      id={'solution-cost' + i}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(item.currency ? currencyToNumber(e) : e, item.max)
                      }
                      variant="outlined"
                      InputProps={{
                        endAdornment: item.unit || undefined
                      }}
                    />
                  </FormControl>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <CustomFormStyled
          control={
            <Checkbox
              checked={cm_hosting_data}
              onChange={(e) => dispatch(setCribMasterHostingData(e.target.checked))}
            />
          }
          disabled={disabled}
          label={t('Flow.GuidedTab.ProductInfoStep.cm_hosting_data')}
        />
        {cm_hosting_data && isEqual(userDetails?.orgId, CRIBMASTER_SALES_ID) && (
          <MachineActiveConnections
            selectedConnectionId={activeConnection}
            setSelectedConnectionId={setSelectedConnectionId}
            isLoading={false}
          />
        )}
      </Box>
    </Box>
  );
};

export default SolutionCostGuided;
