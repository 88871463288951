import { Grid, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MXP_PAGE_VIEW } from 'constants/mixpanel';
import AdditionalCostGuided from 'features/guided/details/additional-cost-guided';
import CustomerDataGuided from 'features/guided/details/customer-data';
import CustomerSalesGuided from 'features/guided/details/customer-sales';
import SolutionCostGuided from 'features/guided/details/solution-cost';
import { selectStep } from 'features/stepper/stepperSlice';
import { UserOrgType } from 'features/user/userModel';
import { selectUserOrgType } from 'features/guided/guidedSlice';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';

const CostInformationGuided = () => {
  const flowType = useParams().type as TabsInterface;
  useEffect(() => {
    // Send a default page view event with additional properties
    mixpanel.track_pageview({ page: MXP_PAGE_VIEW.CostInfo, flow: flowType });
  }, []);
  const theme = useTheme();
  const activeStep = useSelector(selectStep);
  const userOrgType = useSelector(selectUserOrgType);
  const { t } = useTranslation();

  const disabled = activeStep === 3 ? true : false;

  return (
    <Box>
      {!disabled && (
        <Box sx={{ marginBottom: '10px', mt: 1, mb: 2 }}>
          <Typography variant="h5" sx={{ fontSize: '18px' }}>
            {t('Flow.GuidedTab.CostInfoStep.title') || 'Cost Information'}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, fontSize: '16px' }}>
            {t('Flow.GuidedTab.CostInfoStep.subTitle') ||
              'Provide information about your cost to serve, customer volumes and opportunity.'}
          </Typography>
        </Box>
      )}
      <Grid container spacing={2} columns={4}>
        <Grid item xs={1}>
          <SolutionCostGuided />
        </Grid>
        <Grid item xs={2}>
          <AdditionalCostGuided disabled={disabled} />
        </Grid>
        <Grid item xs={1}>
          <CustomerSalesGuided />
          {userOrgType === UserOrgType.direct && <CustomerDataGuided />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CostInformationGuided;
