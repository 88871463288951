export enum AlertType {
  error = 'error',
  success = 'success',
  info = 'info',
  warning = 'warning'
}
export interface SnackAlertInterface {
  open: boolean;
  type: AlertType;
  message: string;
  timeout?: number
}
export interface StepperDataInterface {
  value: StepperObjectInterface;
  activeStep?: string;
  gotoStep: number;
  loading: boolean;
  snackAlert: SnackAlertInterface;
  isDialogueActive: boolean, 
}

export enum StepperPages {
  Criteria = 'criteria',
  Upload = 'upload',
  Review = 'review',
  costInfoGuided = 'cost_info',
  Results = 'results',
  CriteriaGuided = 'criteria_guided',
  DetailsGuided = 'details_guided',
  ReviewGuided = 'review_guided',
  ResultsGuided = 'results_guided'
}
export type StepperObjectInterface = {
  [key in StepperPages]: boolean;
};
export type StepperLoadingInterface = {
  value: boolean;
  next?: boolean
}
export enum StepperParams {
  stepInfo = 'step'
}
export enum StepperGuidedPages {
  CriteriaGuided = 'criteria_guided',
  DetailsGuided = 'details_guided',
  costInfoGuided = 'cost_info',
  ReviewGuided = 'review_guided',
  ResultsGuided = 'results_guided'
}