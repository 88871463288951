import React, { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

interface ReplaceFileDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ReplaceFileDialog: FC<ReplaceFileDialogProps> = ({ open, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Replace File</DialogTitle>
      <DialogContent>
        <DialogContentText>
            { t('Flow.AutomatedTab.AreaDetailsProductUpload.replace_file_warning')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary"  sx={{ textTransform: 'none', marginRight: '5px' }}>
          {t('Common.cancelLabel') || 'Cancel'}
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained" sx={{ textTransform: 'none', fontWeight: 600 }}>
        { t('Flow.AutomatedTab.AreaDetailsProductUpload.replace_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReplaceFileDialog;
