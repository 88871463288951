import { Typography } from '@mui/material';
import { useState } from 'react';

interface ReadMore {
  text: string;
  charactersToLoad?: number;
  showRead?: boolean;
}
const ReadMore = ({ text, charactersToLoad = 100, showRead = true }: ReadMore) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const def: boolean = text.length > charactersToLoad ? true : false;
  if (!def) return <span>{text}</span>;

  return (
    <Typography component={'p'} fontSize={'13px'}>
      {isReadMore ? text.slice(0, charactersToLoad) : text}
      {showRead && (
        <span onClick={toggleReadMore} style={{ cursor: 'pointer', color: 'steelblue' }}>
          {isReadMore ? '...read more' : ' show less'}
        </span>
      )}
      {!showRead && (
        <span onClick={toggleReadMore} style={{ cursor: 'pointer', color: 'steelblue' }}>
          {isReadMore ? '...' : ' show less'}
        </span>
      )}
    </Typography>
  );
};

export default ReadMore;
