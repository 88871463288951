import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ActiveConnections } from 'features/guided/guidedModel';
import { useTheme } from '@mui/material/styles';

interface Props {
  selectedConnectionId: ActiveConnections;
  setSelectedConnectionId: (connectionId: ActiveConnections) => void;
  isLoading: boolean;
}

const MachineActiveConnections = ({ selectedConnectionId, setSelectedConnectionId, isLoading }: Props) => {
  const theme = useTheme();

  const connectionData = [
    { value: ActiveConnections.upto_100, name: 'Up to 100' },
    { value: ActiveConnections.upto_500, name: '100 - 500' },
    { value: ActiveConnections.above_500, name: '501+' }
  ];

  const handleConnectionChange = (event: SelectChangeEvent) => {
    const selectedData = event.target.value as ActiveConnections;
    setSelectedConnectionId(selectedData);
  };

  return (
    <Box mt={1}>
      <Stack direction="row" sx={{ mt: 1, mb: 1, alignItems: 'center' }}>
        <Typography fontSize="16" component="div">
          Active Connections
        </Typography>
        <Tooltip
          key={`${123}-tooltip`}
          title={
            <Typography component="div" variant="subtitle2">
              Number of Main ATR’s, FlexSense, & Express ToolBox(s) that Organization is currently using.
            </Typography>
          }
        >
          <InfoIcon sx={{ color: theme.palette.grey[500], ml: 0.5, fontSize: '16px' }} />
        </Tooltip>
      </Stack>

      <FormControl fullWidth sx={{ m: '8px 0px' }}>
        <InputLabel id="active-connection-select-label">Active Connections</InputLabel>
        <Select
          labelId="active-connection-select-label"
          id="active-connection-select"
          value={selectedConnectionId}
          label="Active Connections"
          onChange={handleConnectionChange}
          disabled={isLoading}
          //defaultValue={ActiveConnections.upto_100}
        >
          {connectionData.map((connection) => {
            return (
              <MenuItem key={connection.value} value={connection.value}>
                {isLoading ? <CircularProgress color="primary" size={15} /> : connection.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MachineActiveConnections;
