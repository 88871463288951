import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import { LicenseInfo } from '@mui/x-license-pro';
import { useAppSelector } from 'app/hooks';
import LoadingContainer from 'components/loading-container/LoadingContainer';
import { selectTheme } from 'theme/themeSlice';
import { MUI_LICENSE_KEY } from './constants';
import { router } from './Router';

import './App.scss';
import SolAlert from 'components/mui-alert/mui-alert';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

type AppProps = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: AppProps) => {
  const theme = useAppSelector(selectTheme);

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Suspense fallback={<LoadingContainer />}>
          <RouterProvider router={router} />
          <SolAlert />
        </Suspense>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
