import {
  GridColDef
} from '@mui/x-data-grid-pro';
import { ExcelColumnKey, ExcelParsedData } from './reviewProductModel';
import { createColumnMap } from './utils';

type CustomGridColDef = GridColDef & {
  groupId?: string;
  renderDot?: (value: number) => React.ReactNode;
};

export const columns: CustomGridColDef[] = [  { field: 'SKU', headerName: 'SKU Number' ,width: 130, headerClassName: 'header-grey-background' },

  { field: 'ItemDescription', width: 130, headerName: 'Item Description', headerClassName: 'header-grey-background',editable: true },
  { field: 'ManufacturerPartNumber', width: 130, headerName: 'Manufacturer Part #', headerClassName: 'header-grey-background',editable: true },
  { field: 'ItemManufacturer', width: 130, headerName: 'Item Manufacturer', headerClassName: 'header-grey-background',editable: true },
  { field: 'AltItemID', width: 130, headerName: 'Alt Item ID (SKU)', headerClassName: 'header-grey-background',editable: true },
  { field: 'OrgLocalPartNumber', width: 130, headerName: 'Org. Local Part #', headerClassName: 'header-grey-background',editable: true },
  { field: 'POU', width: 130, headerName: 'All in one Point-Of-Use(POU)', headerClassName: 'header-grey-background',editable: true },

  { field: 'DemandQuantity',  width: 160, headerName: 'Demand Quantity', headerClassName: 'header-grey-background' },
  { field: 'DemandTimeWindow',  width: 130, headerName: 'Demand Time-Window', headerClassName: 'header-grey-background' },
  { field: 'SecurityLevel',  width: 130, headerName: 'Security Level', headerClassName: 'header-grey-background' },
  { field: 'OverallVendability',  width: 130, headerName: 'Overall vendability Y/N', headerClassName: 'header-grey-background' },
  { field: 'VendabilityNotes',  width: 130, headerName: 'Vendability Notes', headerClassName: 'header-grey-background' },
  { field: 'ItemCost', headerName: 'Item Cost',width: 130, headerClassName: 'header-grey-background',editable: true },

  { field: 'ExpectedGrossProfitMargin',  width: 130, headerName: '% Expected Gross Profit Margin', headerClassName: 'header-grey-background' },
  { field: 'Height',  width: 130, headerName: 'Height (inch)', headerClassName: 'header-grey-background'},
  { field: 'Width',  width: 130, headerName: 'Width (inch)', headerClassName: 'header-grey-background' },
  { field: 'Length',  width: 130, headerName: 'Length (inch)', headerClassName: 'header-grey-background'},
  { field: 'Weight',  width: 130, headerName: 'Weight (Lbl)', headerClassName: 'header-grey-background' },
  { field: 'Heavy',  width: 130, headerName: 'Heavy', headerClassName: 'header-grey-background' },
  { field: 'Fragile',  width: 130, headerName: 'Fragile', headerClassName: 'header-grey-background' },
  { field: 'DefaultIssueType',  width: 130, headerName: 'Default Issue Type (EA, GAL, PK, PR, BX, RL, CS)', headerClassName: 'header-grey-background' },
  { field: 'DefaultIssueQty',  width: 130, headerName: 'Default Issue Qty (inside pk, bx, or cs)', headerClassName: 'header-grey-background' },
  { field: 'Stackable',  width: 130, headerName: 'Stackable', headerClassName: 'header-grey-background' },
  { field: 'Loose',  width: 130, headerName: 'Loose', headerClassName: 'header-grey-background' },
  { field: 'StoreVertically',  width: 130, headerName: 'Store vertically', headerClassName: 'header-grey-background' },
  { field: 'PreferredMachineType',  width: 130, headerName: 'Preferred Machine Type', headerClassName: 'header-grey-background' },
  { field: 'LockerVendable', width: 130, headerName: 'Locker Vendable Y/N', headerClassName: 'header-grey-background' },
  { field: 'CompartmentsPerLocker', width: 130, headerName: '# of Compartments per Locker Door', headerClassName: 'header-grey-background' },
  { field: 'CapacityForExpressLocker', width: 130, headerName: 'Capacity for Express Locker', headerClassName: 'header-grey-background' },
  { field: 'CapacityForProstockLocker', width: 130, headerName: 'Capacity for ProStock Locker', headerClassName: 'header-grey-background' },
  { field: 'CapacityForProlockLocker', width: 130, headerName: 'Capacity for ProLock Locker', headerClassName: 'header-grey-background' },
  { field: 'CarouselVendable', width: 130, headerName: 'Carousel Vendable Y/N', headerClassName: 'header-grey-background' },
  { field: 'RepackForCarousel', width: 130, headerName: 'Needs Repack for Carousel Y/N', headerClassName: 'header-grey-background' },
  { field: 'SlotsPerItem', width: 130, headerName: '# of Slots per Item', headerClassName: 'header-grey-background' },
  { field: 'CoilVendable', width: 130, headerName: 'Coil Vendable Y/N', headerClassName: 'header-grey-background' },
  { field: 'NeedRepackForCoil', width: 130, headerName: 'Needs Repack for Coil Y/N', headerClassName: 'header-grey-background' },
  { field: 'CoilPitch', width: 130, headerName: 'Coil Pitch / Number of Items per Coil Row', headerClassName: 'header-grey-background' },
  { field: 'CoilType', width: 130, headerName: 'Coil Type', headerClassName: 'header-grey-background' },
  { field: 'PreferredShelf', width: 130, headerName: 'Preferred Shelf (Any/Bottom)', headerClassName: 'header-grey-background' },
  { field: 'PreferredRow', width: 130, headerName: 'Preferred Row (Side/Middle/Any)', headerClassName: 'header-grey-background' },
  { field: 'RiserRequired', width: 130, headerName: 'Riser Required', headerClassName: 'header-grey-background' },
  { field: 'FlipBarRequired', width: 130, headerName: 'Flip Bar Required', headerClassName: 'header-grey-background' },
  { field: 'CoilEnd', width: 130, headerName: 'Coil End "clock" position', headerClassName: 'header-grey-background' },
  { field: 'RiserRequiredWS', width: 130, headerName: 'Riser Required - WS', headerClassName: 'header-grey-background' },
  { 
    field: 'RepackingInstructions', 
    headerName: 'Repacking Instructions PDF file url', 
    width: 130,
    headerClassName: 'header-grey-background',
  },
  { 
    field: 'ItemImageURL', 
    headerName: 'Item Image URL', 
    width: 130,
    headerClassName: 'header-grey-background',
  },
  { field: 'RepackedImageUrl', headerName: 'Repacked Image Url', width: 130, headerClassName: 'header-grey-background' },
];

export const createRows = (productList: ExcelParsedData[]) => {
  return productList.map((product, index) => {
    const columnMap = createColumnMap(product.rowData);

    return {
        uniqueId: index + 1,
        id: product.id, 
        SKU: columnMap[ExcelColumnKey.SKU] || '',
        ItemDescription: columnMap[ExcelColumnKey.ItemDescription] || '',
        ManufacturerPartNumber: columnMap[ExcelColumnKey.ManufacturerPartNumber] || '',
        ItemManufacturer: columnMap[ExcelColumnKey.ItemManufacturer] || '',
        AltItemID: columnMap[ExcelColumnKey.AltItemID] || '',
        OrgLocalPartNumber: columnMap[ExcelColumnKey.OrgLocalPartNumber] || '',
        POU: columnMap[ExcelColumnKey.POU] || '',
        Height: columnMap[ExcelColumnKey.Height] || '',
        Length: columnMap[ExcelColumnKey.Length] || '',
        Width: columnMap[ExcelColumnKey.Width] || '',
        DemandQuantity: columnMap[ExcelColumnKey.DemandQuantity] || '',
        DemandTimeWindow: columnMap[ExcelColumnKey.DemandTimeWindow] || '',
        SecurityLevel: columnMap[ExcelColumnKey.SecurityLevel] || '',
        OverallVendability: columnMap[ExcelColumnKey.OverallVendability] || '',
        VendabilityNotes: columnMap[ExcelColumnKey.VendabilityNotes] || '',
        ItemCost: columnMap[ExcelColumnKey.ItemCost] || '',
        ExpectedGrossProfitMargin: columnMap[ExcelColumnKey.ExpectedGrossProfitMargin] || '',
        Weight: columnMap[ExcelColumnKey.Weight] || '',
        Heavy: columnMap[ExcelColumnKey.Heavy] || '',
        Fragile: columnMap[ExcelColumnKey.Fragile] || '',
        DefaultIssueType: columnMap[ExcelColumnKey.DefaultIssueType] || '',
        DefaultIssueQty: columnMap[ExcelColumnKey.DefaultIssueQty] || '',
        Stackable: columnMap[ExcelColumnKey.Stackable] || '',
        Loose: columnMap[ExcelColumnKey.Loose] || '',
        StoreVertically: columnMap[ExcelColumnKey.StoreVertically] || '',
        PreferredMachineType: columnMap[ExcelColumnKey.PreferredMachineType] || '',
        LockerVendable: columnMap[ExcelColumnKey.LockerVendable] || '',
        CompartmentsPerLocker: columnMap[ExcelColumnKey.CompartmentsPerLocker] || '',
        CapacityForExpressLocker: columnMap[ExcelColumnKey.CapacityForExpressLocker] || '',
        CapacityForProstockLocker: columnMap[ExcelColumnKey.CapacityForProstockLocker] || '',
        CapacityForProlockLocker: columnMap[ExcelColumnKey.CapacityForProlockLocker] || '',
        CarouselVendable: columnMap[ExcelColumnKey.CarouselVendable] || '',
        RepackForCarousel: columnMap[ExcelColumnKey.RepackForCarousel] || '',
        SlotsPerItem: columnMap[ExcelColumnKey.SlotsPerItem] || '',
        CoilVendable: columnMap[ExcelColumnKey.CoilVendable] || '',
        NeedRepackForCoil: columnMap[ExcelColumnKey.NeedRepackForCoil] || '',
        CoilPitch: columnMap[ExcelColumnKey.CoilPitch] || '',
        CoilType: columnMap[ExcelColumnKey.CoilType] || '',
        PreferredShelf: columnMap[ExcelColumnKey.PreferredShelf] || '',
        PreferredRow: columnMap[ExcelColumnKey.PreferredRow] || '',
        RiserRequired: columnMap[ExcelColumnKey.RiserRequired] || '',
        FlipBarRequired: columnMap[ExcelColumnKey.FlipBarRequired] || '',
        CoilEnd: columnMap[ExcelColumnKey.CoilEnd] || '',
        RiserRequiredWS: columnMap[ExcelColumnKey.RiserRequiredWS] || '',
        RepackingInstructions: columnMap[ExcelColumnKey.RepackingInstructions] || '',
        ItemImageURL: columnMap[ExcelColumnKey.ItemImageURL] || '',
        RepackedImageUrl: columnMap[ExcelColumnKey.RepackedImageUrl] || '',
    };
  }) || [];
};


export const productInformationColumns = [
  { field: 'SKU', headerName: 'SKU',},
  { field: 'ItemDescription', headerName: 'Item Description' },
  { field: 'ManufacturerPartNumber', headerName: 'Manufacturer Part #'},
  { field: 'ItemManufacturer', headerName: 'Item Manufacturer' },
  { field: 'AltItemID', headerName: 'Alt Item ID (SKU)' },
  { field: 'OrgLocalPartNumber', headerName: 'Org. Local Part #' },
  { field: 'POU', headerName: 'All in one Point-of-use(POU)' },
] 

export const productDimensionColumns = [
  { field: 'DemandQuantity', headerName: 'Demand Quantity' },
  { field: 'DemandTimeWindow', headerName: 'Demand Time-Window' },
  { field: 'SecurityLevel', headerName: 'Security Level' },
  { field: 'OverallVendability', headerName: 'Overall vendability Y/N' },
  { field: 'VendabilityNotes', headerName: 'Vendability Notes' },
  { field: 'ItemCost', headerName: 'Item Cost' },
  { field: 'ExpectedGrossProfitMargin', headerName: '% Expected Gross Profit Margin' },
  { field: 'Height', headerName: 'Height',},
  { field: 'Width', headerName: 'Width' },
  { field: 'Length', headerName: 'Length'},
  { field: 'Weight', headerName: 'Weight' },
  { field: 'Heavy', headerName: 'Heavy' },
  { field: 'Fragile', headerName: 'Fragile' },
  { field: 'DefaultIssueType', headerName: 'Default Issue Type (EA, GAL, PK, PR, BX, RL, CS)' },
  { field: 'DefaultIssueQty', headerName: 'Default Issue Qty (inside pk, bx, or cs)' },
  { field: 'Stackable', headerName: 'Stackable' },
  { field: 'Loose', headerName: 'Loose' },
  { field: 'StoreVertically', headerName: 'Store vertically' },
  { field: 'PreferredMachineType', headerName: 'Preferred Machine Type' },
]


export const lockerPlacementColumns = [
  { field: 'LockerVendable', headerName: 'Locker Vendable Y/N' },
  { field: 'CompartmentsPerLocker', headerName: '# of Compartments per Locker Door' },
  { field: 'CapacityForExpressLocker', headerName: 'Capacity for Express Locker' },
  { field: 'CapacityForProstockLocker', headerName: 'Capacity for ProStock Locker' },
  { field: 'CapacityForProlockLocker', headerName: 'Capacity for ProLock Locker' },
]

export const coilPlacementColumns = [
  { field: 'CoilVendable', headerName: 'Coil Vendable Y/N' },
  { field: 'NeedRepackForCoil', headerName: 'Needs Repack for Coil Y/N' },
  { field: 'CoilPitch', headerName: 'Coil Pitch / Number of Items per Coil Row' },
  { field: 'CoilType', headerName: 'Coil Type' },
  { field: 'PreferredShelf', headerName: 'Preferred Shelf (Any/Bottom)' },
  { field: 'PreferredRow', headerName: 'Preferred Row (Side/Middle/Any)' },
  { field: 'RiserRequired', headerName: 'Riser Required' },
  { field: 'FlipBarRequired', headerName: 'Flip Bar Required' },
  { field: 'CoilEnd', headerName: 'Coil End "clock" position' },  
]