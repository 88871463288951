import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ReducerKeys } from 'app/reducerKeys';
import { RootState } from 'app/store';
import { ApiPricesOrganization } from './generalApi';
import { OrganizationPriceGroup } from './generalModel';

const initialState: OrganizationPriceGroup = {
  organizations: [],
  price_groups: [] 
};

export const fetchOrgPrices = createAsyncThunk(
  `${ReducerKeys.generalSettings}/fetchOrgPrices`,
  async () => {
    const { data, error } = await ApiPricesOrganization();
    if (data) return { value: data };
    return { value: data, error };
  }
);

export const generalSettingsSlice = createSlice({
  name: ReducerKeys.generalSettings,
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchOrgPrices.fulfilled, (state, action) => {
        const data = action.payload;
        state.organizations = data.value?.organizations
        state.price_groups = data.value?.price_groups
      })
  }
});

export const selectGeneralSettings = (state: RootState) => state.generalSettings;
export const { reducer: generalSettingsReducer, name: generalSettingsKey } = generalSettingsSlice;
