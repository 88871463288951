import { LocationDataInterface } from 'features/myLocation/location-model';
import {
  Criteria,
  InventoryType,
  ItemQuantityGuided,
  MachineSelection,
  ResponseAreaObject,
  SecurityGuided,
  timeWindowGuided
} from './guidedModel';
import i18next from 'i18n/config';

export const getManulArea = (payload: ResponseAreaObject) => {
  return {
    ...payload,
    _id: 'manual',
    area_name: `${i18next.t('Common.enterManuallyLabel')}`,
    id: 'manual'
  };
};

export const getManulLocation = (payload: LocationDataInterface) => {
  return {
    ...payload,
    location_name: `${i18next.t('Common.enterManuallyLabel')}`,
    id: 'manual'
  };
};

export const getAreaPayload = (name: string, payload: ResponseAreaObject) => {
  return {
    area_name: name,
    root_org_id: payload.root_org_id,
    root_org_name: payload.root_org_name,
    org_id: payload.org_id,
    org_name: payload.org_name,
    location_id: payload.location_id,
    location_name: payload.location_name,
    parent_org_id: payload.parent_org_id || payload.root_org_id
  };
};

export const emptyItemRow: ItemQuantityGuided = {
  type: '',
  quantity: 1,
  security: SecurityGuided.high,
  time_window: timeWindowGuided.weekly,
  category_type: '',
  comment: ''
};

export const machine_selected_automated = [MachineSelection.toolbox,MachineSelection.prolock, MachineSelection.prostock]; // For Automated flow type we have static machine selection array for now

export const criteriasGuidedInventoryType = {
  [Criteria.stock_out]: [InventoryType.Durable, InventoryType.Consumable],
  [Criteria.pay_per_use]: [InventoryType.Consumable],
  [Criteria.overuse]: [InventoryType.Durable, InventoryType.Consumable],
  [Criteria.restocking_time]: [InventoryType.Consumable],
  [Criteria.trip_time]: [InventoryType.Durable, InventoryType.Consumable],
  [Criteria.floor_space]: [InventoryType.Durable, InventoryType.Consumable],
  [Criteria.stock_rotation]: [InventoryType.Durable, InventoryType.Consumable],
  [Criteria.fod_fme]: [InventoryType.Durable],
  [Criteria.inventory_shrinkage]: [InventoryType.Durable, InventoryType.Consumable],
  [Criteria.calibration_repair]: [InventoryType.Durable],
  [Criteria.budget]: [InventoryType.Durable, InventoryType.Consumable]
};
