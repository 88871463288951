
import {
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { ItemInfoState } from '../guidedModel';
import { useTranslation } from 'react-i18next';

type InfoFields = {
  label: string;
  key: keyof ItemInfoState;
  sx?: number;
  required: boolean;
  max?: number;
  defaultValue?: number;
};

interface RecommendedMainMachinesProp {
  item: InfoFields,
  handleItemInfoChange: (e: ChangeEvent<HTMLInputElement>, max: number) => void
  index: number,
  value: string | number | undefined,
  recommendedMainMachines: number,
  disabled: boolean
}

const RecommendedMainMachinesComp = (props: RecommendedMainMachinesProp) => {
  const { t } = useTranslation();
  const {item , index, value, recommendedMainMachines, disabled } = props;
  return (
    <Grid item container key={item.key + index} spacing={2}  >
      <Grid item xs={6}>
        <FormControl fullWidth key={item.label + 'guided-info' + index}>
          <TextField
            name={item.key}
            size='medium'
            value={value || item.defaultValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleItemInfoChange(e, Number(item.max))}
            id={item.label + 'info'}
            type='number'
            inputProps={{ pattern: '[0-9]*', min: 0, max: item.max, step: 'any' }}
            label={item.label}
            required={item.required}
            error={item.required && !value}
            disabled={disabled}
          />
          
        </FormControl>
      </Grid>
    <Grid item xs={6}>
        <FormControl fullWidth key={item.label + 'guided-info-main' + index}>
          <TextField
            name={item.key}
            size='medium'
            value={recommendedMainMachines}
            id={item.label + 'info-main'}
            type='number'
            label={t('Flow.GuidedTab.ProductInfoStep.recommendedMainMachines')}
            disabled={true}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default RecommendedMainMachinesComp;