import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import { Criteria } from './criteriaRankingModel';
import { revertAll } from 'app/actions';

export interface CriteriaRankingState {
  selectedCriteriaList: Criteria[];
}

const initialState: CriteriaRankingState = {
  selectedCriteriaList: []
};

export const criteriaRankingSlice = createSlice({
  name: ReducerKeys.criteriaRanking,
  initialState,
  reducers: {
    setSelectedCriteriaList: (state, action: PayloadAction<Criteria>) => {
      const criteria = action.payload;
      const selectedCriteriaList = state.selectedCriteriaList;
      if (selectedCriteriaList.indexOf(criteria) !== -1) {
        const newSelectedCriteriaList = selectedCriteriaList.filter(
          (e) => e !== criteria
        );
        state.selectedCriteriaList = newSelectedCriteriaList;
      } else {
        state.selectedCriteriaList = [...selectedCriteriaList, criteria];
      }
    },
    setCriteriaOnLoad: (state, action) => {
      state.selectedCriteriaList = action.payload;
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
});

export const { setSelectedCriteriaList, setCriteriaOnLoad } =
  criteriaRankingSlice.actions;

export const selectCriteriaList = (state: RootState) => {
  return state.criteriaRanking.selectedCriteriaList;
};

export const { reducer: criteriaRankingReducer, name: criteriaRankingKey } =
  criteriaRankingSlice;
