import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface GroupHeaderProps {
  headerName: string | undefined;
  isExpanded: boolean;
  onToggleExpansion: () => void;
}

const GroupHeader: React.FC<GroupHeaderProps> = ({ headerName, isExpanded, onToggleExpansion }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="subtitle2">{headerName}</Typography>
      {isExpanded ? (
        <ArrowBackIosIcon
          onClick={onToggleExpansion}
          style={{
            fontSize: 16,
            marginLeft: 4,
            cursor: 'pointer',
          }}
        />
      ) : (
        <ArrowForwardIosIcon
          onClick={onToggleExpansion}
          style={{
            fontSize: 16,
            marginLeft: 4,
            cursor: 'pointer',
          }}
        />
      )}
    </Box>
  );
};

export default GroupHeader;
