import { CostInfoState } from 'features/costInfo/costInfoSlice';
import { CostFeesItem, CustomerSalesOpportunityInterface, SolutionCost } from 'features/guided/guidedModel';
import { GuidedState } from 'features/guided/guidedSlice';
import includes from 'lodash/includes';
import { removeCurrencyformat } from 'utils/currency';

export const getOpportunityValue = (SolCost: SolutionCost, customerSalesOpportunity: CustomerSalesOpportunityInterface,
  costInfo: CostInfoState, initial: GuidedState, index: number) => {
    
    // INITIAL CONTAINS OLD DATA FROM BACKEND USED TO CALCULATE STATIC VALUES ON SLIDER CHANGE
    const initialSolCost: SolutionCost = initial.crib_config.Solutions[index].Solution_Cost;
    const initial_term = initial?.cost_info.lease_agreement ? +initial?.cost_info.lease_agreement : 1;

    
    // 1. CALCULATE VALUES PER MACHINE BASED ON OLD DATA. I.E. BACKEND SEND MAINTAINANCE COST FOR 3 MACHINES FOR 3 YRS TO BE 3000 COST.
    // 2. SO MAINTAINCE FOR 1 YR IS CALCULATED ONLY WHEN WE HAVE OLD COST 3000 AND OLD TERM =  3000 / 3
    // 3. SO WHEN TERM IS CHANGED THROUGH SLIDER USE VALUE FROM STEP 2 REFLECT UPDATED MAINTAINENCE COST ETC ON TERM CHANGE
    const recurring_endCustomer_fees_perYear = initialSolCost.RecurringRecurringEndCustomerFeesOverTerm / initial_term;
    const cribMaster_hostingCost_perYear = (initialSolCost.CribMasterHostingCostOverTerm || 0) / initial_term;
    const additional_recurringCost_perYear = initialSolCost.AdditionalRecurringCostOverTerm / initial_term;
    const LicenseAndSupportCostOverTermPerYear = initialSolCost.LicenseAndSupportCostOverTerm / initial_term;
    
    // BELOW ARE ALL UPDATED VALUES - CHANGED THROUGH SLIDER OR INPUT IN RESULT PAGE
    const {
      additional_halo_revenue = 1,
      average_gross_profit = 1,
      gross_profit_margin_percentage_halo_revenue = 1,
      estimated_revenue_twelve_months = 1
    } = customerSalesOpportunity;
    // NEW TERM DEFINES CHANGES TERM FROM SLIDER
    const { lease_agreement: new_term = 1, stocking_labor = 0 } = costInfo;
    // LOGIC STARTS HERE ================================

    const reccuringArrayKeys = ['fees_recurring_month',
    'fees_recurring_crib_month',
    'costs_recurring_month',
    'costs_recurring_crib_month'];

    const EstimatedRepairCostsOverTerm = initialSolCost.EstimatedRepairCostsOverTerm;
    const HardwareCost = SolCost.HardwareCost;
    const TotalHardware_Software_InstallationCosts_IncludingTax_Freight = SolCost.TotalHardware_Software_InstallationCosts_IncludingTax_Freight;
    const AdditionalInitialImplementationCost = SolCost.AdditionalInitialImplementationCost;
    const InitialEndCustomerImplementationFees  = SolCost.InitialEndCustomerImplementationFees;

    
    const ertm = +removeCurrencyformat(estimated_revenue_twelve_months);
    const ahr = +removeCurrencyformat(additional_halo_revenue);
        
    const GrossProfitMargin = +average_gross_profit / 100;
    const EstimatedRevenuePerYear = ertm;
    const GrossProfitMarginHaloRevenue = +gross_profit_margin_percentage_halo_revenue / 100;
    const HaloRevenuePerYear = ahr;
    const TermOfServiceLeaseAgreement = +new_term;
    const StockingCost = initial.cost_info?.labor_rate_per_hour
    ? +initial.cost_info.labor_rate_per_hour
    : 0;

    const RecurringRecurringEndCustomerFeesOverTerm = recurring_endCustomer_fees_perYear * TermOfServiceLeaseAgreement;
    const TotalStockingLaborCostsOverTerm = StockingCost * +stocking_labor * TermOfServiceLeaseAgreement;
    const CribMasterHostingCostOverTerm = cribMaster_hostingCost_perYear * TermOfServiceLeaseAgreement;
    const AdditionalRecurringCostOverTerm = additional_recurringCost_perYear * TermOfServiceLeaseAgreement;
    const LicenseAndSupportCostOverTerm = LicenseAndSupportCostOverTermPerYear * TermOfServiceLeaseAgreement;
    const Total_LicensingSubscription_SupportCostsOverTerm = LicenseAndSupportCostOverTerm + EstimatedRepairCostsOverTerm;

    const ROS =
            (((GrossProfitMargin * EstimatedRevenuePerYear +
                GrossProfitMarginHaloRevenue * HaloRevenuePerYear) *
                TermOfServiceLeaseAgreement + RecurringRecurringEndCustomerFeesOverTerm -
                Total_LicensingSubscription_SupportCostsOverTerm
                - TotalStockingLaborCostsOverTerm
                - CribMasterHostingCostOverTerm - AdditionalRecurringCostOverTerm) /
                ((EstimatedRevenuePerYear + HaloRevenuePerYear) *
                TermOfServiceLeaseAgreement)) *
                100;

    const ROA =
            (((GrossProfitMargin * EstimatedRevenuePerYear +
                GrossProfitMarginHaloRevenue * HaloRevenuePerYear) *
                TermOfServiceLeaseAgreement + RecurringRecurringEndCustomerFeesOverTerm -
                Total_LicensingSubscription_SupportCostsOverTerm
                - TotalStockingLaborCostsOverTerm
                - CribMasterHostingCostOverTerm - AdditionalRecurringCostOverTerm) /
                HardwareCost) *
            100;

    const ROI =
            (((GrossProfitMargin * EstimatedRevenuePerYear +
                GrossProfitMarginHaloRevenue * HaloRevenuePerYear) *
                TermOfServiceLeaseAgreement + RecurringRecurringEndCustomerFeesOverTerm -
                Total_LicensingSubscription_SupportCostsOverTerm
                - TotalStockingLaborCostsOverTerm
                - CribMasterHostingCostOverTerm - AdditionalRecurringCostOverTerm) /
                (TotalHardware_Software_InstallationCosts_IncludingTax_Freight
                    + AdditionalInitialImplementationCost
                    - InitialEndCustomerImplementationFees)) *
            100;

    const Payback =
            (TotalHardware_Software_InstallationCosts_IncludingTax_Freight
                + AdditionalInitialImplementationCost - InitialEndCustomerImplementationFees) /
            (((GrossProfitMargin * EstimatedRevenuePerYear +
                GrossProfitMarginHaloRevenue * HaloRevenuePerYear
                + RecurringRecurringEndCustomerFeesOverTerm / TermOfServiceLeaseAgreement )
                - Total_LicensingSubscription_SupportCostsOverTerm / TermOfServiceLeaseAgreement
                - TotalStockingLaborCostsOverTerm / TermOfServiceLeaseAgreement
                - CribMasterHostingCostOverTerm / TermOfServiceLeaseAgreement
                - AdditionalRecurringCostOverTerm / TermOfServiceLeaseAgreement
               ) / 12 );

    const TOTALSolutionCost =
               TotalStockingLaborCostsOverTerm +
               Total_LicensingSubscription_SupportCostsOverTerm +
               TotalHardware_Software_InstallationCosts_IncludingTax_Freight
               + CribMasterHostingCostOverTerm
               + AdditionalInitialImplementationCost
               + AdditionalRecurringCostOverTerm
               - InitialEndCustomerImplementationFees
               - RecurringRecurringEndCustomerFeesOverTerm;

    const AllAdditionalCostsAndFees: CostFeesItem[] = [];
    if (initialSolCost.AllAdditionalCostsAndFees.length > 0) {
      initialSolCost.AllAdditionalCostsAndFees.forEach((item) => {
      if (includes(reccuringArrayKeys, item.chargesKey)) {
        const newValue = (item.costsFeesValue / initial_term) * TermOfServiceLeaseAgreement;
         return AllAdditionalCostsAndFees.push({ ...item, costsFeesValue: newValue});
      }
      AllAdditionalCostsAndFees.push(item);
     });
    }
  

      return { ROS, ROA, ROI, Payback,
        TOTALSolutionCost,
        RecurringRecurringEndCustomerFeesOverTerm,
        Total_LicensingSubscription_SupportCostsOverTerm,
        TotalStockingLaborCostsOverTerm,
        CribMasterHostingCostOverTerm,
        AdditionalRecurringCostOverTerm,
        LicenseAndSupportCostOverTerm,
        AllAdditionalCostsAndFees };

};