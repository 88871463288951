import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import DragDropBox from 'components/drag-drop-box/dragDrop.component';
import { MXP_PAGE_VIEW } from 'constants/mixpanel';
import { Criteria } from 'features/guided/guidedModel';
import { selectedCriteriaList } from 'features/guided/guidedSlice';
import { StepperPages } from 'features/stepper/stepperModel';
import { setStepperData } from 'features/stepper/stepperSlice';
import { useDispatch, useSelector } from 'react-redux';
import CriteriaList from './CriteriaList';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';

const CriteriaRankingGuided = () => {
  const markedCriteriaList: Criteria[] = useSelector(selectedCriteriaList);
  const dispatch = useDispatch();
  const flowType = useParams().type as TabsInterface;

  useEffect(() => {
    // Send a default page view event with additional properties
    mixpanel.track_pageview({ page: MXP_PAGE_VIEW.Criteria, flow: flowType });
  }, []);
  useEffect(() => {
    if (markedCriteriaList.length === 0) {
      dispatch(setStepperData({ name: StepperPages.CriteriaGuided, value: false }));
    } else {
      dispatch(setStepperData({ name: StepperPages.CriteriaGuided, value: true }));
    }
  }, [markedCriteriaList]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid xs={8} xl={9} item>
          <CriteriaList />
        </Grid>
        <Grid xs={4} xl={3} item>
          <DragDropBox />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CriteriaRankingGuided;
