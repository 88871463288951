import { useAppDispatch, useAppSelector } from 'app/hooks';
import { messages } from 'constants/message';
import { AdditionalCostFeesEnum } from 'features/additionalCostFees/additonalCostFeesModel';
import { ApiUpdateSolutionGuided } from 'features/guided/guidedAPI';
import { FlowType, ItemQuantityGuided, UpdateSolutionPayloadInterface } from 'features/guided/guidedModel';
import {
  selectCostSliderDirty,
  selectGuidedState,
  selectGuidedToSave,
  setIncludeConsignment,
  setSolutionRequireUpdate
} from 'features/guided/guidedSlice';
import { selectActiveTabIndex } from 'features/recomendation-tab/recommendationTabSlice';
import { AlertType, StepperParams } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { UserOrgType } from 'features/user/userModel';
import { selectUserOrgType } from 'features/guided/guidedSlice';
import get from 'lodash/get';
import pick from 'lodash/pick';
import values from 'lodash/values';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatObjectValues } from 'utils/currency';
import { isValidObject } from 'utils/validators';
import { validateCostFees, validateCostInfo, validateCustomerSales } from './data-validators';
import { StepperAutomatedPages } from 'features/stepperAutomated/stepperAutomatedModel';

const FIELDS_DETAIL = ['flow_inventory_info.items_to_start_the_shift', 'flow_inventory_info.restocking_frequency'];
const FIELDS_COST = [
  'customer_sales_opportunity.average_gross_profit',
  'cost_info.labor_rate_per_hour',
  'cost_info.estimated_repair',
  'cost_info.lease_agreement',
  'customer_sales_opportunity.estimated_revenue_twelve_months'
];

const steps: string[] = Object.values(StepperAutomatedPages);
const useAutomated = () => {
  const { t } = useTranslation();
  const { flowId, type, stepName } = useParams();

  const stepIndex = stepName && steps.indexOf(stepName) !== -1 ? steps.indexOf(stepName) : 0;

  const [loading, setLoading] = useState(false);

  const DEFAULT_AREA_TEXT = `${t('Common.createLabel') || 'Enter Manually'} `;
  const value = useAppSelector(selectActiveTabIndex);
  const guided = useAppSelector(selectGuidedState);
  const requireUpdate = useAppSelector(selectGuidedToSave);
  const isCostSliderDirty = useAppSelector(selectCostSliderDirty);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userOrgType = useSelector(selectUserOrgType);

  const [enabled, setEnabled] = useState(false);

  if (userOrgType === UserOrgType.direct) {
    FIELDS_COST.shift();
  }

  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const step = params.get(StepperParams.stepInfo);

  const payaloadGenerator = () => {
    return {
      flow_type: FlowType.Automated,
      criteria_ranking: guided.criteria_ranking,
      area: get(guided, ['area']) || '',
      flow_inventory_info: guided.flow_inventory_info,
      flow_inventory_data: guided.flow_inventory_data,
      cost_info: formatObjectValues(guided.cost_info),
      customer_sales_opportunity: formatObjectValues(guided.customer_sales_opportunity),
      machine_selection: guided.machine_selection,
      machine_selection_roi: guided.machine_selection_roi,
      completed: false,
      shared: guided.shared,
      rfid_machines: guided.rfid_machines,
      express_machines: guided.express_machines,
      accuport_machines: guided.accuport_machines,
      customer_data: guided.customer_data,
      customer_view_results: guided.customer_view_results,
      cm_hosting_data: guided.cm_hosting_data,
      [AdditionalCostFeesEnum.additionalCosts]: guided[AdditionalCostFeesEnum.additionalCosts].filter(
        (item) => item.charges_key && item.name && item.value
      ),
      [AdditionalCostFeesEnum.additionalFees]: guided[AdditionalCostFeesEnum.additionalFees].filter(
        (item) => item.charges_key && item.name && item.value
      ),
      pouData: guided.pouData,
      selected_pou: guided.selected_pou,
      include_consignment: guided.include_consignment
    };
  };

  const saveData = async (payload: UpdateSolutionPayloadInterface) => {
    setLoading(true);
    if (!flowId || value === 1) return { data: null, error: null };
    const data = { ...payload, flowId };
    try {
      const response = await ApiUpdateSolutionGuided(data, isCostSliderDirty ? 'cost_sliders_guided' : step);
      const includeConsignment = get(response, 'data.include_consignment', false);
      // this impacts working capital calculations on result screen
      if (includeConsignment !== guided.include_consignment) dispatch(setIncludeConsignment(includeConsignment));
      dispatch(setSolutionRequireUpdate(false));
      setLoading(false);
      return { data: response.data, error: null };
    } catch (err) {
      setLoading(false);
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 }));
      return { data: null, error: err };
    }
  };

  // VALIDATE CRITERIA SELECTION PAGE
  useEffect(() => {
    if (stepName === StepperAutomatedPages.CriteriaAutomated) {
      guided.criteria_ranking.length === 0 ? setEnabled(false) : setEnabled(true);
    }
  }, [stepName, guided]);

  // VALIDATE DETAILS SCRREN PAGE
  useEffect(() => {
    if (stepName === StepperAutomatedPages.Upload) {
      // EXTRACT REQUIRED DATA
      const {
        machine_selection,
        express_machines = false,
        rfid_machines = false,
        accuport_machines = false,
        selected_pou
      } = guided;

      // VALIDATE INVENTORY VALIDITY
      let isItemValid = false;
      guided.flow_inventory_data.forEach((item: ItemQuantityGuided) => {
        const pickedItemsToValidate = pick(item, ['']);
        isItemValid = values(pickedItemsToValidate).every((val) => val && val !== '');
      });

      // VALIDATE MACHINE SELECTION AND RFID
      const isProductInfoValid =
        guided.selectedArea?.area_name !== DEFAULT_AREA_TEXT &&
        (machine_selection.length > 0 || express_machines || rfid_machines || accuport_machines) &&
        isItemValid &&
        isValidObject(guided, FIELDS_DETAIL);
      isProductInfoValid && selected_pou != 'null' && selected_pou ? setEnabled(true) : setEnabled(false);
    }
  }, [stepName, guided]);

  // VALIDATE COST INFO PAGE
  useEffect(() => {
    if (stepName === StepperAutomatedPages.costInfoAutomated) {
      const costValidity = validateCostInfo(guided.cost_info);
      const customerSalesValidity = validateCustomerSales(guided.customer_sales_opportunity);
      const additionalCostValidity = validateCostFees(guided.additional_costs);
      const additionalFeesValidity = validateCostFees(guided.service_fees);
      if (costValidity && customerSalesValidity && additionalCostValidity && additionalFeesValidity) {
        setEnabled(true);
      } else {
        setEnabled(false);
      }
    }
  }, [guided, stepName]);

  // VALIDATE REVIEW & VALIDATE PRODUCT LIST

  useEffect(() => {
    if (stepName === StepperAutomatedPages.ReviewValidateAutomated) {
      setEnabled(true);
    }
  }, [stepName]);

  // VALIDATE REVIEW SELECTION PAGE
  useEffect(() => {
    if (stepName === StepperAutomatedPages.ReviewAutomated) {
      guided.approved ? setEnabled(true) : setEnabled(false);
    }
  }, [stepName, guided]);

  // VALIDATE GUIDED RESULT PAGE
  useEffect(() => {
    if (stepName === StepperAutomatedPages.ResultsAutomated) {
      setEnabled(true);
    }
  }, [stepName]);

  // HANDLE NEXT
  const handleNext = async () => {
    const nextIndex = stepIndex === steps.length - 1 ? steps.length - 1 : stepIndex + 1;
    if (requireUpdate) {
      const payload = payaloadGenerator();
      const { data } = await saveData(payload);
      if (data) {
        navigate({
          pathname: `/flow/${flowId}/${type}/${steps[nextIndex]}`,
          search: createSearchParams({
            automated_file_id: data.automated_file_id,
            selected_pou: data.selected_pou
          }).toString()
        });
      }
    } else {
      navigate({
        pathname: `/flow/${flowId}/${type}/${steps[nextIndex]}`,
        search: createSearchParams({
          automated_file_id: guided.automated_file_id,
          selected_pou: guided.selected_pou
        }).toString()
      });
    }
  };

  // HANDLE BACK
  const handleBack = () => {
    if (stepName) {
      const prevIndex = stepIndex === 0 ? 0 : stepIndex - 1;
      navigate({
        pathname: `/flow/${flowId}/${type}/${steps[prevIndex]}`,
        search: createSearchParams({
          automated_file_id: guided.automated_file_id,
          selected_pou: guided.selected_pou
        }).toString()
      });
    }
  };

  // HANDLE FINISH
  const handleFinish = async () => {
    if (stepName) {
      const payload = payaloadGenerator();
      const { data } = await saveData({ ...payload, completed: true });
      if (data) navigate('/');
    }
  };

  // HANDLE DONE
  const handleDone = async () => {
    if (stepName) {
      const payload = payaloadGenerator();
      const { data } = await saveData(payload);
      if (data) navigate('/');
    }
  };
  // HANDLE DONE
  const handleRestore = async () => {
    if (stepName) {
      // STEPNAME
    }
  };
  return { handleBack, handleNext, handleFinish, handleDone, handleRestore, loading, enabled };
};

export default useAutomated;
