import { ThemeOptions } from '@mui/material/styles';
import darkScrollbar from '@mui/material/darkScrollbar';

export const darkThemeConfig: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgba(255, 219, 10, 1)',
      light: 'rgba(102, 178, 255, 0.15)',
      dark: 'rgba(255, 219, 10, 0.75)',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    secondary: {
      main: '#0077CF',
      light: '#0077CF',
      dark: '#0077CF',
      contrastText: '#000000'
    },
    background: {
      default: '#121212',
      paper: '#121212'
    },
    common: {
      black: '#000000',
      white: '#ffffff'
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    warning: {
      main: '#ffa726',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      main: '#29b6f6',
      light: '#4fc3f7',
      dark: '#0288d1',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    success: {
      main: '#66bb6a',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar()
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: 56,
          minHeight: 56,
          justifyContent: 'center'
        }
      }
    }
  }
};
