import React, { ChangeEvent, useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress, Stack, TextField, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import { MachineSelectionState, selectAverageTime, selectCribListByOrgLoading, selectMachineSettingState, selectMaxTime, selectUpdateApiStatus, selectedTabValue, setAverageTime, setMaxTime, updateMachineSelectionAsync } from './machineSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/hooks';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { AlertType } from 'features/stepper/stepperModel';

const NumMainMachine = () => {
  const theme = useTheme();
  const averageTime = useSelector(selectAverageTime);
  const selectedTab = useSelector(selectedTabValue);
  const updateAPIStatus = useSelector(selectUpdateApiStatus);
  
  
  const maxTime = useSelector(selectMaxTime);
  const updateLoading = useSelector(selectCribListByOrgLoading);
  
  const dispatch = useAppDispatch();
  const machineSettingState = useSelector(selectMachineSettingState);
  
  const [machineDataByOrg, setMachineDataByOrg] = useState<MachineSelectionState | undefined>();

  useEffect(() => {
      setMachineDataByOrg(machineSettingState);
  }, []);


  useEffect(() => {
    if(selectedTab == 2) {
      const debounceTimer = setTimeout(() => {
        updateAPI();
      }, 500); 
  
      return () => {
        clearTimeout(debounceTimer);
      };
    }
  }, [averageTime, maxTime]);

  const updateAPI = async () => {
    if (machineDataByOrg) {
      const MachineCalculationParams = {
        average_time_per_transaction: averageTime,
        max_time_start_shift_area: maxTime
      };

      const payload = {
        cribs: machineSettingState?.selected_machines,
        rfid_machines: machineSettingState?.rfid_machine_selected,
        express_machines: machineSettingState?.express_machine_selected,
        accuport_machines: machineSettingState?.accuPort_machine_selected,
        machine_calculation_params: MachineCalculationParams,
      };
      try {
        dispatch(updateMachineSelectionAsync(payload));
        if (updateAPIStatus === 'success') {
          dispatch(setSnackAlert({ open: true, type: AlertType.success, message: 'Updated Successfully', timeout: 1000 }));

        } else if (updateAPIStatus === 'failed') {
          dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Failed to update!', timeout: 1000 }));

        }
      } catch (error) {
        dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Failed to update!', timeout: 1000 }));
      }
    }
  };

  const getToolTipWithLabel = (label: string) => (
    <Stack key={label} direction="row" sx={{ mt: '19px', mb: 2, alignItems: 'center' }}>
      <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={updateLoading}
          >
          <CircularProgress color="inherit" />
        </Backdrop>
      <Typography fontSize="16" component="div">
        {label}
      </Typography>
      <Tooltip
        key={`${label}-tooltip`}
        title={
          <Typography component="div" variant="subtitle2">
            {[
              'NMM = NIT * TPT / MTS',
              <br key={`${label}-br`} />,
              'Where:',
              <br key={`${label}-br2`} />,
              'NMM - expected number of main Machines (round it up)',
              <br key={`${label}-br3`} />,
              'NIT - # Issue Transactions to start shift;',
              <br key={`${label}-br4`} />,
              'TPT - Avg Time per Issue Transaction:',
              <br key={`${label}-br5`} />,
              'MTS - Max Time to Start Shift at the Area}',
              <br key={`${label}-br6`} />,
            ]}
          </Typography>
        }
      >
        <InfoIcon sx={{ color: theme.palette.grey[500], ml: 0.5, fontSize: '16px' }} />
      </Tooltip>
    </Stack>
  );

  const handleAverageTimeChange = (event: React.ChangeEvent<HTMLInputElement>,min:number, max: number) => {
    const value = parseFloat(event.target.value);
    dispatch(setAverageTime(value > max ? max : value));
  };

  const handleMaxTimeChange = (event: React.ChangeEvent<HTMLInputElement>,min:number, max: number) => {
    const value = parseInt(event.target.value);
    dispatch(setMaxTime(value > max ? max : value));
  };

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '15px',
        '& .MuiTextField-root': {
          width: '25ch',
          my: '10px',
        },
      }}
    >
      {getToolTipWithLabel('Average Time per Issue Transaction ')}
      <TextField
        id="average-time"
        label="Average Time per Issue Transaction"
        type="number"
        sx={{margin: 0}}
        value={averageTime}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputProps: {
            min: 1,
            max: 5,
            step: 0.01,
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleAverageTimeChange(e,1, 5)}
      />
      {getToolTipWithLabel('Max Time to Start Shift at the Area')}
      <TextField
        id="max-time"
        label="Max Time to Start Shift at the Area"
        type="number"
        value={maxTime}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputProps: {
            min: 5,
            max: 60,
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleMaxTimeChange(e, 5, 60)}
      />
    </Box>
  );
};

export default NumMainMachine;
