import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import { ResponseAreaObject } from './../guided/guidedModel';
import {
  getOrganizationListAsync,
  getLocationListAsync,
  ApiGetAreaGuided
} from './createSolutionAPI';
import {
  createOrgResponseModal,
  areaParamsType,
  orgResultModal,
  locationAPIPayload,
} from './createSolutionModel';
import { getManulArea, getManulLocation } from 'features/guided/guided-constants';
import { LocationDataInterface } from 'features/myLocation/location-model';
import { StatusValues } from 'features/mySolution/mySolutionsModel';

export interface CreateSolutionState {
  area: string | null;
  areas: Array<ResponseAreaObject> | null;
  selectedArea: ResponseAreaObject | null;
  orgStatus: 'idle' | 'loading' | 'failed' | 'success';
  locStatus: 'idle' | 'loading' | 'failed' | 'success';
  areaStatus: 'idle' | 'loading' | 'failed' | 'success';
  orgLoading: boolean;
  locLoading: boolean;
  areaLoading: boolean;
  filteredOrgs: orgResultModal[] ;
  solution_name: string;
  organization: string;
  location: string;
  locationID: string ;
  areaVal: string;
  organizationArr: createOrgResponseModal | undefined;
  locations: Array<LocationDataInterface> | null;
  selectedLoc: LocationDataInterface | undefined;
  locationValue: string;
  selectedStatus: string;
  statusfilterOptions: StatusValues[];

}

const initialState: CreateSolutionState = {
  area: null,
  areas: null,
  selectedArea: null, // send string id (area id)
  orgStatus: 'idle',
  locStatus: 'idle',
  areaStatus: 'idle',
  orgLoading: false,
  locLoading: false,
  areaLoading: false,
  filteredOrgs: [],
  organizationArr: undefined,
  locations: null,
  solution_name: '',
  organization: '',
  location: '',
  areaVal: '',
  locationID: '',
  selectedLoc: undefined,
  locationValue: '',
  selectedStatus: StatusValues.active,
  statusfilterOptions: [StatusValues.active, StatusValues.onHold, StatusValues.inactive]
};

export const getOrganizationList = createAsyncThunk(`${ReducerKeys.createSolution}/getOrganizationList`,
  async () => {
    const { response } = await getOrganizationListAsync();
    return response;
  }
);

export const getLocationList = createAsyncThunk(
  `${ReducerKeys.createSolution}/getLocationList`,
  async (data: locationAPIPayload) => {
    const { response } = await getLocationListAsync(data);
    if (response) return { value: response };
    return { value: response };
  }
);

export const fetchAreaAsync = createAsyncThunk(
  `${ReducerKeys.createSolution}/fetchArea`,
  async (params: areaParamsType) => {
    const { data } = await ApiGetAreaGuided(params);
    if (data) return { value: data };
    return { value: data };
  }
);

export const createSolutionSlice = createSlice({
  name: ReducerKeys.createSolution,
  initialState,
  reducers: {
    setOrgValues: (state, action: PayloadAction<string>) => {
      state.organization = action.payload;
    },
    setSolutionValues: (state, action: PayloadAction<string>) => {
      state.solution_name = action.payload;
    },
    setLocValues: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
    setAreaValues: (state, action: PayloadAction<string>) => {
      state.areaVal = action.payload;
    },
    setLocations: (state, action: PayloadAction<string>) => {
      state.locationValue = action.payload;
    },
    setFieldDefaultValues: (state) => {
      state.organization = '',
      state.solution_name = '',
      state.location = '',
      state.areaVal = '',
      state.areas = []
    },
    setAreasGuided: (state, action: PayloadAction<ResponseAreaObject[]>) => {
      state.areas = action.payload;
    },
    setLocationsGuided: (state, action: PayloadAction<LocationDataInterface[]>) => {
      state.locations = action.payload;
    },
    setSelectedAreaGuided: (state, action: PayloadAction<ResponseAreaObject>) => {
      state.selectedArea = action.payload;
      state.area = action.payload.id;
    },
    setSelectedLocationGuided: (state, action: PayloadAction<LocationDataInterface>) => {
      state.selectedLoc = action.payload;
      state.locationID = action.payload.id;
    },
    setAreaLoading: (state, action: PayloadAction<boolean>) => {
      state.areaLoading = action.payload;
    },
    setLocLoading: (state, action: PayloadAction<boolean>) => {
      state.locLoading = action.payload;
    },
    setLocationID: (state, action: PayloadAction<string>) => {
      state.locationID = action.payload;
    },
    setFilteredOrg: (state, action: PayloadAction<orgResultModal[] | undefined>) => {
      state.filteredOrgs = action.payload || [];
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationList.pending, (state) => {
        state.orgStatus = 'loading';
        state.orgLoading = true;
      })
      .addCase(getOrganizationList.fulfilled, (state, action) => {
        state.orgStatus = 'success';
        state.orgLoading = false;

        state.organizationArr = action.payload || {} as createOrgResponseModal;
      })
      .addCase(getOrganizationList.rejected, (state) => {
        state.orgStatus = 'failed';
        state.orgLoading = false;
      })

      .addCase(getLocationList.pending, (state) => {
        state.locStatus = 'loading';
        state.locLoading = true;
      })
      .addCase(getLocationList.fulfilled, (state, action) => {
        state.locStatus = 'success';
        
        const locations = action.payload.value;
        if (locations && action.payload.value) {
          const defaultLocObjSelected = locations?.filter((item) => {
            return item.location_name === state.locationValue;
          });
            state.locations = [...locations, getManulLocation( action.payload.value[0])];
            state.selectedLoc = defaultLocObjSelected[0];
            state.locLoading = false;

        }
      })
      .addCase(getLocationList.rejected, (state) => {
        state.locStatus = 'failed';
        state.locLoading = false;
      })

      .addCase(fetchAreaAsync.pending, (state) => {
        state.areaStatus = 'loading';
        state.areaLoading = true;
      })
      .addCase(fetchAreaAsync.fulfilled, (state, action) => {
        state.areaStatus = 'success';
        const areas = action.payload.value;
        if (areas && action.payload.value)
          state.areas = [...areas, getManulArea(action.payload.value[0])];
          state.selectedArea = action.payload.value && action.payload.value[0];
        state.areaLoading = false;

    })
      .addCase(fetchAreaAsync.rejected, (state) => {
        state.areaStatus = 'failed';
        state.areaLoading = false;
      });
  }
});

export const {
  setSelectedAreaGuided,
  setAreasGuided,
  setOrgValues,
  setSolutionValues,
  setLocValues,
  setAreaLoading,
  setLocationID,
  setFilteredOrg,
  setAreaValues,
  setFieldDefaultValues,
  setLocations,
  setSelectedLocationGuided,
  setLocationsGuided,
  setLocLoading
} = createSolutionSlice.actions;
export const selectAreasGuided = (state: RootState) => {
  return state.createSolution.areas;
};
export const selectedAreaGuided = (state: RootState) => {
  return state.createSolution.selectedArea;
};
export const selectedLocationGuided = (state: RootState) => {
  return state.createSolution.selectedLoc;
};
export const selectLocationID = (state: RootState) => state.createSolution.locationID;
export const selectFilteredOrg = (state: RootState) => state.createSolution.filteredOrgs;

export const selectAreaLoading = (state: RootState) => state.createSolution.areaLoading;
export const selectOrgLoading = (state: RootState) => state.createSolution.orgLoading;
export const selectOrgStatus = (state: RootState) => state.createSolution.orgStatus;
export const selectLocLoading = (state: RootState) => state.createSolution.locLoading;

export const selectCreateSolutionState = (state: RootState) => state.createSolution;
export const selectCreateSolutionOrgState = (state: RootState) =>
  state.createSolution.organizationArr;
export const selectCreateSolutionLocationState = (state: RootState) =>
  state.createSolution.locations;

export const statusfilterOptions = (state: RootState) =>
  state.createSolution.statusfilterOptions;
  
export const { reducer: createSolutionReducer, name: createSolutionKey } = createSolutionSlice;
