import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const ErrorElement = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/');
    window.location.reload();
  };
  return (
    <Box sx={{ mt: 6, justifyContent: 'center', display: 'flex' }}>
      <Card sx={{ minWidth: 275, width: 600 }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
          <SentimentVeryDissatisfiedIcon color="primary" sx={{ width: 100, height: 100 }} />
          <Typography variant="h4" component="div" sx={{ mt: 2 }}>
            Ohh no!
          </Typography>
          <Typography variant="body2" component="div" sx={{ mt: 2 }}>
            Something Went wrong. Please try Refreshing.
          </Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 8 }}>
          <Button size="small" onClick={handleButtonClick}>
            Go To Home Page
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ErrorElement;
