import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { DragIndicator } from '@mui/icons-material';
import {
  Badge,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { Criteria } from 'features/guided/guidedModel';
import { selectAllCriteriaList, selectedCriteriaList, setRearrangedCriteriaList } from 'features/guided/guidedSlice';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const StyledItem = styled(ListItem)(() => ({
  height: '50px',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
  padding: '0 20px 0 5px',
  cursor: 'pointer'
}));

const StyledItemText = styled(ListItemText)(() => ({
  marginLeft: '15px',
  '& .MuiListItemText-primary': {}
}));

const DragDropBox = () => {
  const markedCriteriaList: Criteria[] = useSelector(selectedCriteriaList);
  const criteriaList = useSelector(selectAllCriteriaList) || []; 
  const dispatch = useDispatch();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const { t } = useTranslation();
  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (
      !destination ||
      (destination.droppableId === source.droppableId && destination.index === source.index)
    )
      return;
    const newItems = cloneDeep(markedCriteriaList);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(destination.index, 0, removed);
    dispatch(setRearrangedCriteriaList(newItems));
  };
  const height = 'calc(100vh - 360px)';

  return (
    <Box>
      <Paper sx={{ padding: '20px', height: height, overflowY: 'auto' }} elevation={2}>
        <Typography sx={{ fontWeight: 'bold' }}>{t('Flow.GuidedTab.CriteriaStep.RankBox.title') || 'RANK'}</Typography>
        <Typography
          sx={{
            fontWeight: '400',
            marginBottom: '10px',
            color: theme.palette.text.secondary
          }}
        >
          {t('Flow.GuidedTab.CriteriaStep.RankBox.subTitle') || 'Drag and drop selected criteria if you have any changes to the original selection.'}
        </Typography>
        {markedCriteriaList?.length ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="script-table-id">
              {(provided) => (
                <List
                  sx={{ height: height, overflowY: 'auto', padding: '0 2px' }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {markedCriteriaList.map((key, i) => {
                    const criteria = criteriaList.find((item) => item.criteria_key === key);
                    if (!criteria) return null;
                    return (
                      <Draggable draggableId={key} index={i} key={key}>
                        {(provided) => (
                          <StyledItem
                            key={`${key}td${i}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DragIndicator sx={{ color: theme.palette.text.secondary, fontSize: '17px' }} />
                            <StyledItemText primary={criteria.criteria_name} />
                            <Box>
                              <Badge badgeContent={i + 1} color="primary"></Badge>
                            </Box>
                          </StyledItem>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          mode === 'light' && (
            <Grid sx={{ minHeight: '100%', textAlign: 'center' }}>
              <img src="/drag-drop.svg" style={{ width: '68%' }} />
            </Grid>
          )
        )}
      </Paper>
    </Box>
  );
};

export default DragDropBox;
