import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import toNumber from 'lodash/toNumber';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import isEqual from 'lodash/isEqual';
import { ChangeEvent, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectGuidedState, setSelectedMachinesROI } from 'features/guided/guidedSlice';
import { MachineCategories, MachineSelection, MachineSelectionROI } from 'features/guided/guidedModel';
import { setNextBtnStatus } from 'features/machine-selection-roi/machineSelectionRoiSlice';
//CRIB-1155
const MachineSelectionTabROI = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [machineErrorMsg, setMachineErrorMsg] = useState('');
  const [machineTypes, setMachineTypes] = useState<MachineSelection[]>([]);

  const guidedState = useAppSelector(selectGuidedState);
  const selectedMachinesROI = guidedState.machine_selection_roi;
  const flowSettings = guidedState.settings;
  const cribs = get(flowSettings, 'cribs', []);

  const rfidMachines = [
    MachineSelection.accucab,
    MachineSelection.accudrawer,
    MachineSelection.hybrid_accucab_double,
    MachineSelection.hybrid_accucab_single
  ];

  const accuport_machines = [MachineSelection.accuport];
  const express_machines = [MachineSelection.express_toolbox];
  const expressMachinesEnabled = get(guidedState, ['settings', MachineCategories.express_machines], false);
  const rfidMachinedEnabled = get(guidedState, ['settings', MachineCategories.rfid_machines], false);
  const accuportMachinesEnabled = get(guidedState, ['settings', MachineCategories.accuport_machines], false);

  // handle machine options based on categories
  useEffect(() => {
    let machineTypes = [...cribs];
    if (expressMachinesEnabled) {
      machineTypes = [...machineTypes, ...express_machines];
    }
    if (rfidMachinedEnabled) {
      machineTypes = [...machineTypes, ...rfidMachines];
    }
    if (accuportMachinesEnabled) {
      machineTypes = [...machineTypes, ...accuport_machines];
    }
    //If Express ToolBox line is added as selected machine then add Express Locker machine type to the list of machines to select from
    const uniqMachineKeys = uniq(selectedMachinesROI.map((el) => el.machine_type));
    const isExpressToolboxAdded = uniqMachineKeys.find((mc) => mc === MachineSelection.express_toolbox);
    if (isExpressToolboxAdded) {
      machineTypes = [...machineTypes, MachineSelection.express_locker];
    }
    machineTypes = uniq(machineTypes);
    setMachineTypes(machineTypes);
  }, [cribs, selectedMachinesROI, expressMachinesEnabled, rfidMachinedEnabled, accuportMachinesEnabled]);

  /**
    d) At least one record will be required. Next button will only be enables when required criteria is met.
  Basic Machines List:
      Note: 
      * - if WeighStation Double is added if this machine type is added then Aux Qty should be disabled for this type since it doesn’t support Weigh Station double AUX. 
      * - if WeighStation Single is added then Aux Qty should be depended on WeighStation Double Main Qty 
      *    a) if there is NO WeighStation Double added then WeighStation Single AUX should be disabled
          b) if there is WeighStation Double added then WeighStation Single AUX Qty MAX should be enabled and set to number equal to = WeighStation Double/Main QTY.
  
      * b)  If Express Machines check box is selected on Org Settings then add Express ToolBox to the list of machines to choose from.
           - AUX Qty
            for express toolbox will depend on number of Express ToolBox Main - restrict Max number of AUX  as 1 Aux per Main. E.G. if Express Main is 3 then set MAX for Aux as 3(meaning not to allow to enter more then three AUX machines).
          *- If Express ToolBox line is added as selected machine then add Express Locker machine type to the list of machines to select from
           - MAX settings for AUX Qty for Express Locker will be defined by number of Express ToolBox Main.
  
     * c) If RFID Machines checkbox is selected then on Org Settings then add AccuDrawer, AccuCab, Hybrid AccuCab Double, & Hybrid AccuCab Single to the List of Machines to choose from
          * NOTE: Aux Qty should be disabled for these types of Machines if they are added.
  
     * d) If AccuPort machine type is selected in OrgSetting then add “AccuPort” Machine Type to the List Machines to choose from.
          * NOTE: Aux Qty should be disabled for this type of Machine if it’s added.
     */
  // handle errors
  useEffect(() => {
    const machineKeys = selectedMachinesROI.map((el) => el.machine_type);
    const uniqMachineKeys = uniq(machineKeys);
    // none of the machine options are selected from organization setting
    if (!get(machineTypes, 'length', 0)) {
      setMachineErrorMsg(t('Flow.ROITab.MachineSelectionStep.errors.4') || '');
      return;
    }
    //Please add atleast one machine
    if (isEmpty(machineKeys)) {
      setMachineErrorMsg(t('Flow.ROITab.MachineSelectionStep.errors.3') || '');
      return;
    }
    // Machine Type can’t be duplicate
    if (!isEqual(machineKeys, uniqMachineKeys)) {
      setMachineErrorMsg(t('Flow.ROITab.MachineSelectionStep.errors.1') || '');
      return;
    }
    //  Main Qty is required on at least one row
    const mchMainQtyList = selectedMachinesROI.find((mchData) => get(mchData, 'main_qty', 0) > 0);
    if (!mchMainQtyList) {
      setMachineErrorMsg(t('Flow.ROITab.MachineSelectionStep.errors.2') || '');
      return;
    }
    // Atleast one main or aux quantity is required
    const mchMainAuxQty = selectedMachinesROI.find(
      (mchData) => get(mchData, 'main_qty', 0) + get(mchData, 'aux_qty', 0) <= 0
    );
    if (mchMainAuxQty) {
      setMachineErrorMsg(t('Flow.ROITab.MachineSelectionStep.errors.5') || '');
      return;
    }

    // reset empty machine orgs settings error messages if express, rfid or accuport machines are enabled
    if (get(machineTypes, 'length', 0)) {
      setMachineErrorMsg('');
      return;
    }
  }, [selectedMachinesROI, machineErrorMsg, machineTypes]);

  useEffect(() => {
    dispatch(setNextBtnStatus(machineErrorMsg ? false : true));
  }, [machineErrorMsg]);

  const handleMachineSelectionChange = (event: SelectChangeEvent, i: number) => {
    const newMachines = [...selectedMachinesROI];
    const newMachineType = event.target.value as MachineSelection;
    newMachines[i] = {
      ...newMachines[i],
      machine_type: newMachineType,
      aux_qty: isAuxDisabld(newMachineType, selectedMachinesROI) ? 0 : newMachines[i].aux_qty
    };
    dispatch(setSelectedMachinesROI(newMachines));
    setMachineErrorMsg('');
  };

  const handleMainAuxQty = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    i: number,
    type: 'main' | 'aux'
  ) => {
    const val = toNumber(event.target.value);
    const newMachines = [...selectedMachinesROI];
    if (type === 'main') {
      newMachines[i] = { ...newMachines[i], main_qty: val };
    }
    if (type === 'aux') {
      newMachines[i] = { ...newMachines[i], aux_qty: val };
    }
    dispatch(setSelectedMachinesROI(newMachines));
    setMachineErrorMsg('');
  };

  const handleEditComment = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, i: number) => {
    const newMachines = [...selectedMachinesROI];
    newMachines[i] = { ...newMachines[i], comment: event.target.value };
    dispatch(setSelectedMachinesROI(newMachines));
    setMachineErrorMsg('');
  };

  const handleAddMachine = () => {
    dispatch(
      setSelectedMachinesROI([
        ...selectedMachinesROI,
        {
          machine_type: machineTypes[0],
          main_qty: 0,
          aux_qty: 0
        }
      ])
    );
    setMachineErrorMsg('');
  };

  const handleRemoveMachine = (i: number) => {
    const machineList1: MachineSelectionROI[] = selectedMachinesROI.filter((_el, index) => index !== i);
    // set aux quantity as zero if a dependent machine is been removed
    const machineList2 = machineList1.map((machineData) =>
      isAuxDisabld(machineData.machine_type, machineList1) ? { ...machineData, aux_qty: 0 } : machineData
    );
    dispatch(setSelectedMachinesROI(machineList2));
    setMachineErrorMsg('');
  };

  const isAuxDisabld = (machineType: MachineSelection, machines: MachineSelectionROI[]) => {
    //if WeighStation Double is added if this machine type is added then Aux Qty should be disabled for this type since it doesn’t support Weigh Station double AUX. -done
    if (
      machineType === MachineSelection.weighstation_double ||
      machineType === MachineSelection.ecab ||
      machineType === MachineSelection.edrawer
    ) {
      return true;
    }
    if (machineType === MachineSelection.weighstation_single) {
      /*
        a) if there is NO WeighStation Double added then WeighStation Single AUX should be disabled - done 
        b) if there is WeighStation Double added then WeighStation Single AUX Qty MAX should be enabled and
         set to number equal to = WeighStation Double/Main QTY. - 
         */
      return !machines.find((el) => el.machine_type === MachineSelection.weighstation_double);
    }
    // disable aux qunatity for rfid Machines and accuport machines
    if (rfidMachines.find((mc) => mc === machineType) || accuport_machines.find((mc) => mc === machineType)) {
      return true;
    }
    return false;
  };

  const isMainDisabled = (machineType: MachineSelection) => {
    //Disable Main quantity for express locker
    if (machineType === MachineSelection.express_locker) {
      return true;
    }
    return false;
  };

  const avgGridSize = 12 / 7;

  return (
    <Box mt={2}>
      <Box sx={{ flex: 1 }}>
        {selectedMachinesROI.map((mcData, i) => {
          const isMachineSettingChanged = !includes(machineTypes, mcData.machine_type);
          return (
            <Stack direction="row" sx={{ alignItems: 'center' }} key={`${mcData.machine_type}_${i}`}>
              <Box sx={{ flex: 1 }}>
                <Grid container spacing={2} sx={{ p: '8px 0px' }}>
                  <Grid xs={avgGridSize * 2} item>
                    {isMachineSettingChanged ? (
                      <TextField
                        fullWidth
                        InputProps={{ inputProps: { min: 0 } }}
                        type="text"
                        size="medium"
                        label="Machine Type"
                        value={mcData.machine_type}
                        disabled
                      />
                    ) : (
                      <FormControl fullWidth>
                        <InputLabel id={`machine-type-select-label-${i}`}>Machine Type</InputLabel>
                        <Select
                          required
                          size="medium"
                          labelId={`machine-type-select-label-${i}`}
                          id={`machine-type-select-${i}`}
                          value={mcData.machine_type}
                          label="Machine Type"
                          onChange={(e) => handleMachineSelectionChange(e, i)}
                        >
                          {machineTypes.map((mcType, i) => (
                            <MenuItem key={`${mcType} ${i}`} value={mcType}>
                              {startCase(mcType)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid xs={avgGridSize} item>
                    <TextField
                      fullWidth
                      InputProps={{ inputProps: { min: 0 } }}
                      type="number"
                      size="medium"
                      label="Main Qty"
                      value={mcData.main_qty}
                      disabled={isMainDisabled(mcData.machine_type)}
                      onChange={(e) => handleMainAuxQty(e, i, 'main')}
                    />
                  </Grid>

                  <Grid xs={avgGridSize} item>
                    <TextField
                      fullWidth
                      InputProps={{ inputProps: { min: 0 } }}
                      type="number"
                      size="medium"
                      label="Aux Qty"
                      value={mcData.aux_qty}
                      disabled={isAuxDisabld(mcData.machine_type, selectedMachinesROI)}
                      onChange={(e) => handleMainAuxQty(e, i, 'aux')}
                    />
                  </Grid>

                  <Grid xs={avgGridSize * 3} item>
                    <TextField
                      fullWidth
                      size="medium"
                      label="Comments"
                      value={mcData.comment}
                      onChange={(e) => handleEditComment(e, i)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: '20px', p: 2 }}>
                <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => handleRemoveMachine(i)} />
              </Box>
            </Stack>
          );
        })}
      </Box>
      {!!get(machineTypes, 'length', 0) && (
        <Stack direction="row" mt={1}>
          <Button
            size="medium"
            color="primary"
            sx={{ textTransform: 'capitalize', fontWeight: '600' }}
            startIcon={<AddCircleOutlineOutlinedIcon color="primary" />}
            onClick={handleAddMachine}
          >
            {t('Flow.ROITab.MachineSelectionStep.AddMachine')}
          </Button>
        </Stack>
      )}
      <Box mt={1}>{machineErrorMsg && <Alert severity="error">{machineErrorMsg}</Alert>}</Box>
    </Box>
  );
};

export default MachineSelectionTabROI;
