export const roiModalTexts = (t: (key: string) => string, completed?: boolean) => {
    const description = completed ? t('Flow.CommonLabels.CloseFlowModal.warnBody'): t('Flow.CommonLabels.CloseFlowModal.draftBody');
    const buttonDisagree = completed ?  'Continue without saving' : t('Common.cancelLabel')
    const texts = {
        title:  t('Flow.CommonLabels.CloseFlowModal.title') || 'Are you sure you want to close Unfinished Solution?',
        description,
        buttonAgree:  t('Common.saveLabel') || 'Save',
        buttonDisagree
    }
    if(completed) texts.title = '';
    return texts
}
