import { SolutionCost } from "features/guided/guidedModel";
import { GuidedState } from "features/guided/guidedSlice";

export const payBackCalculations = (costs: SolutionCost, term: number | string, guided: GuidedState) => {
    const {customer_data, customer_sales_opportunity, include_consignment } = guided;
    const { consumption_reduction = 0, employees_per_shift = 0, avg_labor_rate_per_hour =  0, total_trip_time = 0, po_per_month = 0, cost_per_po = 0 } = customer_data;
    const { estimated_revenue_twelve_months = 0 } = customer_sales_opportunity;

    // SAVINGS 1
    const ConsumptionReduction = +consumption_reduction;
    const EstimatedRevenuePerYear = +estimated_revenue_twelve_months;
    const ConsumptionReductionPerYear = ( ConsumptionReduction * EstimatedRevenuePerYear ) / 100;

    // SAVINGS 2
    const WorkingCapitalPerYear = include_consignment ? EstimatedRevenuePerYear / 12 : 0;

    // SAVINGS 3
    const EmployeesPerShift = +employees_per_shift;
    const TotalTripTime = +total_trip_time;
    const AvgLaborRatePerHour = +avg_labor_rate_per_hour;
    const EmployeeProductivityPerShift = (( EmployeesPerShift * AvgLaborRatePerHour * TotalTripTime ) / 60 ) * 5 * 52;

    // SAVINGS 4
    const PoPerMonth = +po_per_month;
    const CostPerPo = +cost_per_po;
    const OrderManagementPerYear = PoPerMonth * CostPerPo * 12 ;

    // TOTAL SAVINGS
    const savings = ConsumptionReductionPerYear + WorkingCapitalPerYear + EmployeeProductivityPerShift + OrderManagementPerYear;

    // Convert term to a number if it's a string
    term = term || 1;
    if (typeof term === 'string') term = parseFloat(term);
    // Destructure cost variables with default values
    const {
      Total_LicensingSubscription_SupportCostsOverTerm: TLSSC = 0,
      TotalStockingLaborCostsOverTerm: TSC = 0,
      CribMasterHostingCostOverTerm: CMHT = 0,
      AdditionalInitialImplementationCost: AIIC = 0,
      AdditionalRecurringCostOverTerm: ARCT = 0,
      HardwareCost: HC = 1,
      TotalHardware_Software_InstallationCosts_IncludingTax_Freight: THSIC = 0,
    } = costs;

    // Avoid division by zero for ROA and ROI calculations
    const divisorForROA = HC !== 0 ? HC : 1;
    const divisorForROI = THSIC + AIIC !== 0 ? THSIC + AIIC : 1;
  
    // Calculate term-adjusted savings denominator and avoid division by zero
    const termAdjustedSavingsDenominator = savings - (TLSSC / term) - (TSC / term) - (CMHT / term) - (ARCT / term);
    const divisorForPayback = termAdjustedSavingsDenominator !== 0 ? termAdjustedSavingsDenominator : 1;
  
    // Calculate various metrics
    const ROA = ((savings * term - TLSSC - TSC - CMHT - ARCT) / divisorForROA) * 100;
    const ROI = ((savings * term - TLSSC - TSC - CMHT - ARCT) / divisorForROI) * 100;
    const PAYBACK = divisorForPayback !== 0 ? (THSIC + AIIC) / (divisorForPayback / 12) : 0;
  
    const TOTAL = TSC + TLSSC + THSIC + CMHT + AIIC + ARCT;
  
    // Return calculated metrics
    return { ROI, ROA, PAYBACK, TOTAL };
  };