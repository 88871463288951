import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import { revertAll } from 'app/actions';
import { CostInfoKeys } from 'features/guided/guidedApiKeys';

export interface CostInfoState {
  [CostInfoKeys.state_sales]:   number |  string | undefined;
  [CostInfoKeys.freight_estimate]:  number | string | undefined;
  [CostInfoKeys.stocking_labor]:  number | string | undefined;
  [CostInfoKeys.labor_rate_per_hour]:   number | string | undefined;
  [CostInfoKeys.estimated_repair]:   number | string | undefined;
  [CostInfoKeys.lease_agreement]?:   number | string | undefined;
  [CostInfoKeys.total_trip_time]?:   number | string | undefined;
  [CostInfoKeys.budget]?:   number | string | undefined;
}

const initialState: CostInfoState = {
  state_sales:  undefined,
  freight_estimate: undefined,
  stocking_labor: undefined,
  labor_rate_per_hour: undefined,
  estimated_repair: undefined
};

export interface costInfoElement {
  name: string;
  value: number | string | undefined;
}

export const costInformationSlice = createSlice({
  name: ReducerKeys.costInfo,
  initialState,
  reducers: {
    setCostInformation: (state, action: PayloadAction<costInfoElement>) => {
      const { name, value } = action.payload;
      state[name as keyof typeof initialState] = value;
    },
    setCostInformationOnLoad: (state, action) => {
      const { payload }: { payload: CostInfoState } = action;
      state = payload;
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
});

export const { setCostInformation, setCostInformationOnLoad } = costInformationSlice.actions;

export const selectCostInfoState = (state: RootState) => state.costInfo;

export const { reducer: costInfoReducer, name: costInfoKey } = costInformationSlice;
