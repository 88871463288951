import { Box } from '@mui/material';
import ReviewValidateProduct from 'features/reviewValidateProduct/ReviewValidateProduct';


const ReviewValidateProductList = () => {
  return (
    <Box component={'div'}>
      <ReviewValidateProduct/>
    </Box>
  );
};

export default ReviewValidateProductList;
