import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'app/hooks';
import ConfirmMui from 'components/confirm-box/confirm.mui';
import { selectCostSliderDirty, selectGuidedState, selectIsEdited } from 'features/guided/guidedSlice';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';
import { setStepperGuidedDialogue } from 'features/stepper/stepperSlice';
import { roiModalTexts } from 'features/stepperRoi/stepperRoiTexts';
import useRoiHook from 'hooks/roi-hook';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';


export default function IconBreadcrumbs() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { flowId, type } = useParams();
  const guidedState = useAppSelector(selectGuidedState);
  const [confirm, setConfirm] = useState(false);
  const isCostSliderDirty = useAppSelector(selectCostSliderDirty);
  const edited = useAppSelector(selectIsEdited);
  
  const { handleRestore, handleDone } = useRoiHook();
  
  // TEXT FOR CONFIRMATION BOX
  const roiTexts = roiModalTexts(t, guidedState.completed);

  const handleUnmount = () => {
    if(!flowId) return navigate('/');
    // CASE: 1 IF SOLUTION IS COMPLETED AND EDITED - OFFER RESET
    if(guidedState.completed && edited) return setConfirm(true);

    // CASE: 2 IF SOLUTION IS DIRTY OR, SLIDERS ARE PLAYED WTH OPEN POPUP
    if(edited || isCostSliderDirty) return setConfirm(true)
    return navigate('/')
  }

  const handleRoiDisagree = () => {
    // CASE: 1 IF SOLUTION WAS COMPLETED BEFORE CHANGES ARE MADE AND CLOSE IS CLICKED ASK TO CONFIRM --
    // A. AGREE - SVAE SOLUTION CHANGES SOLUTION MARKED AS INCOMPLETE
    // B. DISAGREE - RESTORES ORIGINAL DATA FROM COMPLETED SOLUTION & SOLUTION REMAINS COMPLETED
    if(guidedState.completed) return handleRestore();

    // IF SOLUTION IS NOT COMPLETED
    // A. AGREE -  SAVE SOLUTION AND REDIRCT - HANDLEDONE() IS USED DIRECTLY
    // B. DISAGREE - HIDE POPUP AS BELOW
    return setConfirm(false);
  }

  const handleGuidedUnmount = () => {
    if(guidedState.initial?.completed && guidedState.edited) return dispatch(setStepperGuidedDialogue(true));
    if(guidedState.edited) return dispatch(setStepperGuidedDialogue(true));
    navigate('/')
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '16px' }} color={'action.active'}>
      <Stack direction={'row'} sx={{ alignItems: ' center', cursor: 'pointer' }} spacing={1}>
        <Typography onClick={type ===TabsInterface.automated ? handleUnmount : TabsInterface.roi ? handleUnmount : handleGuidedUnmount} sx={{ fontStyle: 'normal', fontSize: '16px' }} variant="body1">Dashboard</Typography>
      </Stack>
      <Typography
        sx={{ fontStyle: 'normal', fontSize: '16px' }} variant="body1"
        color="action.active"
      >
        {t('Flow.CommonLabels.activityBreadcrumbLabel') || 'Create New Solution'}
      </Typography>
      <ConfirmMui open={confirm} setOpen={setConfirm} texts={roiTexts} agree={handleDone} disAgree={handleRoiDisagree} />
    </Breadcrumbs>
  );
}
