import { useAppDispatch, useAppSelector } from 'app/hooks';
import { messages } from 'constants/message';
import { AdditionalCostFeesEnum } from 'features/additionalCostFees/additonalCostFeesModel';
import { ApiUpdateSolutionGuided, apiGetRecommendedSolutionGuided } from 'features/guided/guidedAPI';
import { FlowType, ItemQuantityGuided, UpdateSolutionPayloadInterface } from 'features/guided/guidedModel';
import {
  cribConfigDefault,
  selectCostSliderDirty,
  selectGuidedState,
  selectGuidedToSave,
  selectIsDirty,
  selectUserOrgType,
  setCostSliderDirty,
  setCribConfig,
  setIncludeConsignment,
  setInitialCribConfig,
  setSolutionBuiltCurrency,
  setSolutionIsDirty,
  setSolutionRequireUpdate
} from 'features/guided/guidedSlice';
import { selectActiveTabIndex } from 'features/recomendation-tab/recommendationTabSlice';
import { AlertType, StepperGuidedPages } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { UserOrgType } from 'features/user/userModel';
import { pick, values } from 'lodash';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { formatObjectValues } from 'utils/currency';
import { extractValidCribs } from 'utils/helpers';
import { isValidObject } from 'utils/validators';
import { validateCostFees, validateCostInfo, validateCustomerSales } from './data-validators';

const FIELDS_DETAIL = ['flow_inventory_info.items_to_start_the_shift', 'flow_inventory_info.restocking_frequency'];
const FIELDS_COST = [
  'customer_sales_opportunity.average_gross_profit',
  'cost_info.labor_rate_per_hour',
  'cost_info.estimated_repair',
  'cost_info.lease_agreement',
  'customer_sales_opportunity.estimated_revenue_twelve_months'
];

const steps: string[] = Object.values(StepperGuidedPages);
const useGuided = () => {
  const { t } = useTranslation();
  const { flowId = '', type, stepName } = useParams();
  const stepIndex = stepName && steps.indexOf(stepName) !== -1 ? steps.indexOf(stepName) : 0;

  const [loading, setLoading] = useState(false);

  const DEFAULT_AREA_TEXT = `${t('Common.createLabel') || 'Enter Manually'} `;
  const value = useAppSelector(selectActiveTabIndex);
  const guided = useAppSelector(selectGuidedState);
  const requireUpdate = useAppSelector(selectGuidedToSave);
  const isCostSliderDirty = useAppSelector(selectCostSliderDirty);
  const isDirty = useAppSelector(selectIsDirty);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userOrgType = useSelector(selectUserOrgType);

  const [enabled, setEnabled] = useState(false);

  if (userOrgType === UserOrgType.direct) {
    FIELDS_COST.shift();
  }

  // const location = useLocation();
  // const { search } = location;
  // const params = new URLSearchParams(search);
  // const step = params.get(StepperParams.stepInfo);

  const payaloadGenerator = () => {
    const validCosts = extractValidCribs(guided[AdditionalCostFeesEnum.additionalCosts]);
    const validFees = extractValidCribs(guided[AdditionalCostFeesEnum.additionalFees]);
    return {
      flow_type: FlowType.Guided,
      criteria_ranking: guided.criteria_ranking,
      area: get(guided, ['area']) || '',
      flow_inventory_info: guided.flow_inventory_info,
      flow_inventory_data: guided.flow_inventory_data,
      cost_info: formatObjectValues(guided.cost_info),
      customer_sales_opportunity: formatObjectValues(guided.customer_sales_opportunity),
      customer_data: formatObjectValues(guided.customer_data),
      machine_selection: guided.machine_selection,
      machine_selection_roi: guided.machine_selection_roi,
      completed: false,
      shared: guided.shared,
      rfid_machines: guided.rfid_machines,
      express_machines: guided.express_machines,
      accuport_machines: guided.accuport_machines,
      customer_view_results: guided.customer_view_results,
      cm_hosting_data: guided.cm_hosting_data,
      [AdditionalCostFeesEnum.additionalCosts]: validCosts,
      [AdditionalCostFeesEnum.additionalFees]: validFees,
      connections: guided.connections,
      include_consignment: guided.include_consignment
    };
  };

  // SAVE GUIDED CHANGES
  const saveGuidedData = async (payload: UpdateSolutionPayloadInterface) => {
    setLoading(true);
    if (!flowId || value === 1) return { data: null, error: null };
    const data = { ...payload, flowId };
    if (!payload?.completed) dispatch(setCribConfig(cribConfigDefault));
    try {
      const stepToSave =
        stepName === StepperGuidedPages.ResultsGuided && isCostSliderDirty ? 'cost_sliders_guided' : stepName;
      const response = await ApiUpdateSolutionGuided(data, stepToSave);
      const includeConsignment = get(response, 'data.include_consignment', false);
      // this impacts working capital calculations on result screen
      if (includeConsignment !== guided.include_consignment) dispatch(setIncludeConsignment(includeConsignment));
      if (response.data?.crib_config) dispatch(setCribConfig(response.data?.crib_config));
      dispatch(setSolutionRequireUpdate(false));
      setLoading(false);
      return { data: response.data, error: null };
    } catch (err) {
      setLoading(false);
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 }));
      return { data: null, error: err };
    }
  };

  // VALIDATE CRITERIA SELECTION PAGE
  useEffect(() => {
    if (stepName === StepperGuidedPages.CriteriaGuided) {
      guided.criteria_ranking.length === 0 ? setEnabled(false) : setEnabled(true);
    }
  }, [stepName, guided]);

  // VALIDATE DETAILS SCRREN PAGE
  useEffect(() => {
    if (stepName === StepperGuidedPages.DetailsGuided) {
      // EXTRACT REQUIRED DATA
      const { machine_selection, express_machines = false, rfid_machines = false, accuport_machines = false } = guided;

      // VALIDATE INVENTORY VALIDITY
      let isItemValid = true;
      guided.flow_inventory_data.forEach((item: ItemQuantityGuided) => {
        const pickedItemsToValidate = pick(item, ['type', 'quantity', 'security', 'time_window']);
        if (!values(pickedItemsToValidate).every((val) => val && val !== '')) isItemValid = false;

        // CHECK TIME_WINDOW IS PRESENT FOR REQUIRED MACHINE TYPE
        const itemType = pickedItemsToValidate.type;
        if (itemType !== 'small_durable_hand_tools' && itemType !== 'large_durable_hand_tools') {
          if (!pickedItemsToValidate['time_window']) isItemValid = false;
        }
      });

      // VALIDATE MACHINE SELECTION AND RFID
      const isProductInfoValid =
        guided.selectedArea?.area_name !== DEFAULT_AREA_TEXT &&
        (machine_selection.length > 0 || express_machines || rfid_machines || accuport_machines) &&
        isItemValid &&
        isValidObject(guided, FIELDS_DETAIL);
      isProductInfoValid ? setEnabled(true) : setEnabled(false);
    }
  }, [stepName, guided]);

  // VALIDATE COST INFO PAGE
  useEffect(() => {
    if (stepName === StepperGuidedPages.costInfoGuided) {
      const costValidity = validateCostInfo(guided.cost_info);
      const customerSalesValidity = validateCustomerSales(guided.customer_sales_opportunity);
      const additionalCostValidity = validateCostFees(guided.additional_costs);
      const additionalFeesValidity = validateCostFees(guided.service_fees);

      if (costValidity && customerSalesValidity && additionalCostValidity && additionalFeesValidity) {
        setEnabled(true);
      } else {
        setEnabled(false);
      }
    }
  }, [guided, stepName]);

  const getRecommendation = async () => {
    try {
      setLoading(true);
      const result = await apiGetRecommendedSolutionGuided(flowId);
      dispatch(setSolutionIsDirty(false));
      result.data?.crib_config && dispatch(setCribConfig({ ...result.data.crib_config, flowId }));
      result.data?.crib_config && dispatch(setInitialCribConfig({ ...result.data.crib_config, flowId }));
      result.data && setSolutionBuiltCurrency(result.data?.currency);
      // SET LOADING HERE
      setLoading(false);
      setEnabled(true);
      dispatch(setSolutionIsDirty(false));
    } catch (err) {
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 }));
      // CLOSE LOADING HETRE
      setLoading(false);
      setEnabled(false);
    }
  };

  // VALIDATE REVIEW SELECTION PAGE
  useEffect(() => {
    if (stepName === StepperGuidedPages.ReviewGuided) {
      guided.approved ? setEnabled(true) : setEnabled(false);
      if (flowId && isDirty) getRecommendation();
    }
  }, [stepName, guided]);

  // VALIDATE GUIDED RESULT PAGE
  useEffect(() => {
    if (stepName === StepperGuidedPages.ResultsGuided) {
      setEnabled(true);
    }
  }, [stepName]);

  // HANDLE NEXT
  const handleNext = async () => {
    const nextIndex = stepIndex === steps.length - 1 ? steps.length - 1 : stepIndex + 1;
    const payload = payaloadGenerator();
    if (requireUpdate) {
      const { data } = await saveGuidedData({ ...payload, shared: false });
      if (data) {
        navigate(`/flow/${flowId}/${type}/${steps[nextIndex]}`);
      }
    } else {
      navigate(`/flow/${flowId}/${type}/${steps[nextIndex]}`);
    }
  };

  // HANDLE BACK
  const handleBack = () => {
    if (stepName) {
      const prevIndex = stepIndex === 0 ? 0 : stepIndex - 1;
      navigate(`/flow/${flowId}/${type}/${steps[prevIndex]}`);
    }
  };

  // HANDLE FINISH
  const handleFinish = async () => {
    if (stepName) {
      const payload = payaloadGenerator();
      const { data } = await saveGuidedData({ ...payload, completed: true });
      dispatch(setCostSliderDirty(false));
      if (data) navigate('/');
    }
  };

  // HANDLE DONE
  const handleDone = async () => {
    if (stepName) {
      const payload = payaloadGenerator();
      const { data } = await saveGuidedData({ ...payload, shared: false });
      if (data) navigate('/');
    }
  };
  return { handleBack, handleNext, handleFinish, handleDone, loading, enabled };
};

export default useGuided;
