import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import {
  SecurityLevel,
  InventoryType,
  Item,
  ItemQuantity
} from './securityModel';
import { revertAll } from 'app/actions';

export interface SecurityState {
  security: SecurityLevel | '';
  inventoryType: InventoryType | '';
  defaultItems: Item[];
  // items: Item[];
  quantity: number;
  itemQuantityList: ItemQuantity[];
  em: string; // Error Message
}

const initialState: SecurityState = {
  security: '',
  inventoryType: '',
  defaultItems: [],
  //  items: [],
  quantity: 0,
  itemQuantityList: [{ type: '', quantity: 0 }],
  em: ''
};

export const securitySlice = createSlice({
  name: ReducerKeys.security,
  initialState,
  reducers: {
    setSecurity: (state, action: PayloadAction<SecurityLevel>) => {
      state.security = action.payload;
    },
    setInventoryType: (state, action: PayloadAction<InventoryType>) => {
      const inventoryTypePayload = action.payload;
      state.inventoryType = inventoryTypePayload;
      const items = Object.values(Item);
      // state.items = [];
      //state.itemQuantityList = [{ type: '', quantity: 0 }];
      state.defaultItems = items;
      // if (inventoryTypePayload === InventoryType.Consumable) {
      //   state.defaultItems = consumableItems;
      // } else if (inventoryTypePayload === InventoryType.Durable) {
      //   state.defaultItems = durableItems;
      // } else {
      //   state.defaultItems = [...consumableItems, ...durableItems];
      // }
    },
    // setItems: (state, action: PayloadAction<Item[]>) => {
    //   state.items = action.payload;
    // },
    // setQuantity: (state, action: PayloadAction<number>) => {
    //   state.quantity = action.payload < 0 ? 0 : action.payload;
    // },
    setItemQuantityList: (state, action: PayloadAction<ItemQuantity>) => {
      const payload = action.payload;
      const prevList = state.itemQuantityList;
      // list is empty
      if (prevList.length === 0) {
        state.itemQuantityList = [action.payload];
        state.em = '';
        return;
      }
      const itemIndex = prevList.findIndex((ele) => ele.type === payload.type);
      state.em = '';
      //item already added
      if (itemIndex !== -1) {
        if (payload.type === '') {
          state.em = 'Please select item from dropdown list';
        } else if (payload.quantity !== prevList[itemIndex].quantity) {
          // update item quantity
          prevList[itemIndex] = payload;
          state.itemQuantityList = prevList;
        } else {
          state.em = 'Item already added !!';
        }
      } else {
        const lastItemIndex = prevList.length - 1;
        const lastItem = prevList[lastItemIndex];
        if (lastItem.type === '') {
          const newList = [...prevList];
          newList[lastItemIndex] = {
            type: payload.type,
            quantity: payload.quantity
          };
          state.itemQuantityList = newList;
        } else {
          state.itemQuantityList = [...prevList, payload];
        }
      }
    },
    setItemQuantityListOnLoad: (state, action) => {
      state.itemQuantityList = action.payload;
    },
    removeItemQuanity: (state, action: PayloadAction<number>) => {
      const itemIndex = action.payload;
      const newItemList = state.itemQuantityList.filter(
        (_item, i) => i !== itemIndex
      );
      state.itemQuantityList = newItemList;
    },
    setEm: (state, action: PayloadAction<string>) => {
      state.em = action.payload;
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
});

export const {
  setSecurity,
  setInventoryType,
  // setItems,
  // setQuantity,
  setItemQuantityList,
  removeItemQuanity,
  setEm,
  setItemQuantityListOnLoad
} = securitySlice.actions;

export const selectSecurityLevel = (state: RootState) =>
  state.security.security;
export const selectInventoryType = (state: RootState) =>
  state.security.inventoryType;

export const selectDefaultItems = (state: RootState) =>
  state.security.defaultItems;

// export const selectItems = (state: RootState) => state.security.items;

// export const selectQuantity = (state: RootState) => state.security.quantity;
export const selectItemQuantityList = (state: RootState) =>
  state.security.itemQuantityList;

export const selectEm = (state: RootState) => state.security.em;

export const { reducer: securityReducer, name: securityKey } = securitySlice;
