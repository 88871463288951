import { AxiosAPIError } from 'utils/apiError';
import axiosConfig from 'app/axiosConfig';
import { CreateSolutionResponseModal } from 'features/mySolution/mySolutionsModel';

export const getSharedSolutionAsync = async (
  orgIds: string[], status: string[]
): Promise<{
  response: CreateSolutionResponseModal[] | undefined;
  error: AxiosAPIError | null;
}> => {
  const statusVal = status.map(s => s);
  const data = await axiosConfig.get<CreateSolutionResponseModal[]>(`/api/flow/organization/${orgIds}?status=${statusVal}`);
  return { response: data?.data, error: null };
};
