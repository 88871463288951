import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectCostInfoGuided,
  selectCustomerViewResults,
  selectGuidedState,
  selectInitialCribConfig,
  setCribConfig
} from 'features/guided/guidedSlice';
import { ceil } from 'lodash';
import { useSelector } from 'react-redux';
import { payBackCalculations } from 'utils/directViewUtils';
import { CribConfig, CribDetails, Solution } from '../guidedModel';
import OpportunityViewCard from '../opportunity-view/OpportunityViewCard';
import { parseNameValue } from '../opportunity-view/opportunityViewModel';
import CostInfo from './CostInfo';
import SavingsTable from './SavingsTable';

const formatTitle = (origData: string[]) => {
  return uniq(origData).join(' + ');
};

const getMachineData = (solution: Solution, cribDetails?: CribDetails) => {
  const unique = uniq([...solution.Main, ...solution.Aux]);

  return unique.map((title) => {
    const mainCost = get(cribDetails, [title, 'cost'], []).find(
      (c) => c.crib_sub_type === 'main'
    )?.crib_certified_price;
    const auxCost = get(cribDetails, [title, 'cost'], []).find((c) => c.crib_sub_type === 'aux')?.crib_certified_price;
    const auxQuantity = solution.Aux.filter((m) => m === title).length;
    const mainQuantity = solution.Main.filter((m) => m === title).length;

    let totalCost = 0;
    if (mainCost) totalCost = totalCost + mainCost * mainQuantity;
    if (auxCost) totalCost = totalCost + auxCost * auxQuantity;

    const averageCostPerMachine = totalCost ? totalCost / (auxQuantity + mainQuantity) : undefined;

    return {
      title,
      auxQuantity,
      mainQuantity,
      pricePerMachine: averageCostPerMachine,
      total: totalCost,
      url: get(cribDetails, [title, 'imageUrl'], ''),
      description: get(cribDetails, [title, 'description'], '')
    };
  });
};
const DirectView = ({ pdfView, results }: { pdfView?: boolean; results?: CribConfig }) => {
  const dispatch = useAppDispatch();
  let cribConfig = useAppSelector(selectInitialCribConfig);
  const { total_savings } = useAppSelector(selectCustomerViewResults);
  const { lease_agreement = 1 } = useSelector(selectCostInfoGuided);
  const guided = useAppSelector(selectGuidedState);

  cribConfig = results || cribConfig;
  const solLength = get(cribConfig, ['Solutions', 'length'], 0);
  const solErr = cribConfig?.Error;

  useEffect(() => {
    const updatedSolutions = cribConfig.Solutions.map((sol) => {
      const { ROA, ROI, PAYBACK } = payBackCalculations(sol.Solution_Cost, lease_agreement, guided);
      return {
        ...sol,
        Solution_Cost: {
          ...sol.Solution_Cost,
          ROA,
          ROI,
          Payback: PAYBACK
        }
      };
    });
    dispatch(setCribConfig({ ...cribConfig, Solutions: updatedSolutions }));
  }, [total_savings.per_year, lease_agreement]);

  if (!solLength || solErr) {
    return (
      <Container
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          mt: 4
        }}
      >
        <Typography component="div" color="InfoText">
          {solErr ? solErr : 'No Match Found'}
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <CostInfo enabled={true} pdfView={pdfView} />
      <SavingsTable />
      <Grid container spacing={3}>
        {(cribConfig?.Solutions || []).map((solution, i) => {
          const c = solution.Solution_Cost;
          const parsedCosts = parseNameValue(c);
          const title = formatTitle(solution.Main);
          const machineData = getMachineData(solution, cribConfig?.CribDetails);
          const { ROA, ROI, Payback } = solution.Solution_Cost;
          return (
            <Grid item xs={pdfView ? 6 : 4} key={i}>
              <OpportunityViewCard
                title={title}
                pdfView={pdfView}
                option={`Option ${i + 1}`}
                paybackData={[
                  { title: 'ROA', value: `${ceil(ROA, 1)}%` },
                  { title: 'ROI', value: `${ceil(ROI, 1)}%` },
                  { title: 'Payback Period', value: `${ceil(Payback, 1)} MO` }
                ]}
                machineData={machineData}
                totalCost={c.TOTALSolutionCost}
                hardwareCost={c.HardwareCost}
                costs={parsedCosts}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default DirectView;
