import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';
import Chip from '@mui/material/Chip';

interface ResultMachineData {
  title: string;
  auxQuantity: number | undefined;
  mainQuantity: number | undefined;
  pricePerMachine: number | undefined;
  total: number | undefined;
  url: string;
  description: string;
}

interface CustomerViewCardProps {
  title: string;
  option: string;
  machineData: ResultMachineData[];
  pdfView?: boolean;
}
const CustomerViewCard = ({ title, option, machineData, pdfView }: CustomerViewCardProps) => {
  const theme = useTheme();
  const height = 'calc(100vh - 600px)';

  return (
    <Box>
      <Typography variant="body2" component="div" sx={{ mt: 4, lineHeight: 1 }}>
        {option}
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontSize: 18 }} component="div">
        {title}
      </Typography>
      <List disablePadding sx={{ maxHeight: pdfView ? 'auto' : height, overflowY: 'auto' }}>
        {machineData.map((d, i) => {
          return (
            <Card variant="outlined" sx={{ mb: 2 }} key={i}>
              <Stack direction="row">
                <Box
                  sx={{
                    height: 150,
                    width: 150,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRight: `1px solid ${theme.palette.divider}`
                  }}
                >
                  <Box sx={{ height: 100, width: 100 }}>
                    <ImageListItem>
                      <img
                        src={`${d.url}?w=100&h=100&fit=crop&auto=format`}
                        srcSet={`${d.url}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                        alt={d.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Box>
                </Box>
                <Stack sx={{ flex: 1, p: 2 }}>
                  <Stack direction="row" sx={{ justifyContent: 'space-between', mb: 0.5 }}>
                    <Typography variant="body1" component="div">
                      {d.title}
                    </Typography>
                    <Tooltip title={d.description}>
                      <InfoIcon sx={{ color: theme.palette.grey[500], fontSize: '16px' }} />
                    </Tooltip>
                  </Stack>
                  <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Typography component="div" variant="body2" color={theme.palette.text.secondary}>
                      Quantity
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    {d.mainQuantity ? (
                      <Chip label={`Main\u00A0\u00A0 ${d.mainQuantity || 0}`} size="small" color="primary" />
                    ) : null}
                    {d.auxQuantity ? (
                      <Chip
                        label={`AUX\u00A0\u00A0${d.auxQuantity || 0}`}
                        size="small"
                        variant="filled"
                        color="default"
                      />
                    ) : null}
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          );
        })}
      </List>
    </Box>
  );
};

export default CustomerViewCard;
