import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    minWidth: '280px',
    padding: '15px',
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontSize: '16px' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ErrorDialogue({ error }: {error: string | undefined}) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if(error){
        setOpen(true)
    }
    return () => {
        setOpen(false)
    }
  },[error])

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <b>Something Went Wrong</b>
        </BootstrapDialogTitle>
        <DialogContent sx={{minWidth: '450px', maxWidth: '470px'}}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <img src="/robot.svg" alt="robot" />
            </Box>
          <Typography gutterBottom sx={{pt: 2}}>
            Unable to save input parameters.
          </Typography>
          <Typography gutterBottom sx={{pt: 2}}>
            <b>Reason</b>
          </Typography>
          <Typography sx={{fontWeight: 500}}>{error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant='contained'>
            ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}