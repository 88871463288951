import { /*useRef,*/ useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import { AxiosInterceptorsSetup } from './axiosConfig';
import { useAppSelector } from './hooks';
import { selectUser } from 'features/user/userSlice';

export const AxiosInterceptorNavigate = () => {
  //const navigate = useNavigate();
  // const navigateRef = useRef(navigate);
  const { instance } = useMsal();
  const user = useAppSelector(selectUser);
  useEffect(() => {
    AxiosInterceptorsSetup(/*navigateRef,*/ instance, user.accessToken, user.lang);
  }, []);
  return null;
};
