import { IconButton, MenuItem, Tooltip } from '@mui/material';
import { GridRowParams, useGridApiContext } from '@mui/x-data-grid-pro';
import * as React from 'react';

type IconType = React.ReactElement | null;

export type GridActionsCellItemProps = {
  label: string;
  icons?: IconType[];
  visible?: boolean;
  showInMenu?: boolean;
  onClick?: (params: GridRowParams) => void;
  params: GridRowParams;
  isEdit?: boolean;
  handleEdit?: (params: GridRowParams) => void;
};

const GridActionsCellItem = (props: GridActionsCellItemProps) => {
  const { label, icons = [], visible, showInMenu, onClick, params, handleEdit } = props;
  const isSingleIcon = icons.length === 1;
  const [iconIndex, setIconIndex] = React.useState(0);

  const handleClick = () => {
    if (onClick) {
      onClick(params);
    }
  };

  const apiRef = useGridApiContext();

  const handleIconClick = () => {
    if (handleEdit) {
      handleEdit(params);
    } else {
      apiRef.current.toggleDetailPanel(params.id);
    }

    if (icons.length > 1) {
      setIconIndex((prevIconIndex) => (prevIconIndex + 1) % icons.length);
    }
  };

  const currentIcon = isSingleIcon ? icons[0] : icons[iconIndex];

  if (!showInMenu) {
    return (
      <>
        <Tooltip title={label} placement="top">
          <IconButton size="small" role="menuitem" aria-label={label} onClick={handleIconClick}>
            {currentIcon}
          </IconButton>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      {visible && (
        <MenuItem onClick={handleClick}>
          {currentIcon}
          {label}
        </MenuItem>
      )}
    </>
  );
};

export default GridActionsCellItem;
