import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { UpdateReadUnreadToggle } from './feedbackAPI';
import { ReadTogglePayload } from './feedback-model';
import Loader from 'components/loader/Loader';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { AlertType } from 'features/stepper/stepperModel';

const FeedbackPanelContent = ({ params }: { params: GridRowParams }) => {
  const [content, setContent] = useState('');
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetchData = async () => {
      const payload: ReadTogglePayload = {
        read_status: true
      };
      const idAsString = params.id.toString();
  
      try {
        if (!params.row.read_status) {
          await UpdateReadUnreadToggle(payload, idAsString);
        }
  
        setContent(params.row.feedback);
        setLoader(false);
      } catch (error) {
        dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'An error occurred', timeout: 6000 }));
        setLoader(false);
      }
    };

    fetchData();
  }, [params]);

  return (
    <Box sx={{ padding: '10px', height: 'auto' }}>
      {loader ? <Loader/> : content}
    </Box>
  );
};

export default FeedbackPanelContent;
