import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import { ResponseCribResult } from './uploadModel';

export interface UploadState {
  status: 'idle' | 'loading' | 'failed' | 'success';
  area: string;
  loading: boolean;
  flowId: string;
  flowDataResult: Array<ResponseCribResult> | undefined;
}

const initialState: UploadState = {
  status: 'idle',
  area: '',
  loading: true,
  flowId: '',
  flowDataResult: undefined,
};

export const uploadSlice = createSlice({
  name: ReducerKeys.upload,
  initialState,
  reducers: {
    setFlowDataResult: (state, action) => {
      state.flowDataResult = action.payload;
    },
  },
});

export const { setFlowDataResult } = uploadSlice.actions;

export const selectUploadState = (state: RootState) => state.upload;
export const selectFlowId = (state: RootState) => state.upload.flowId;
export const selectFlowData = (state: RootState) => state.upload.flowDataResult;
export const selectUploadStatus = (state: RootState) => state.upload.status;

export const { reducer: uploadReducer, name: uploadKey } = uploadSlice;
