import { Box, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close'
import * as React from 'react';

interface ConfirmText {
  title?: string;
  description?:  string;
  buttonAgree?: string;
  buttonDisagree?: string
}
interface ConfirmMui {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    agree?: () => void;
    disAgree?: () => void;
    texts?: ConfirmText
}
export default function ConfirmMui({ open, setOpen, agree, disAgree, texts = {} }: ConfirmMui) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {title = 'Title here', description = 'some description here', buttonAgree = 'AGREE', buttonDisagree = 'DISAGREE' } = texts;


  const handleClose = (accepted: boolean) => {
    if(accepted && agree) agree(); 
    if(!accepted && disAgree) disAgree();
    setOpen(false)
  };

  return (
      <Dialog fullScreen={fullScreen} open={open} onClose={() =>  setOpen(false)} aria-labelledby="responsive-dialog-title">
        <Box sx={{ pt: 3 }}>
          <IconButton
            aria-label="close"
            onClick={() =>  setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {
          title && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        }
        <DialogContent sx={{minWidth: '300px'}}>
          {
            description && <DialogContentText> {description} </DialogContentText>
          }
        </DialogContent>
        <DialogActions>
          {buttonDisagree && <Button autoFocus onClick={() =>  handleClose(false)}>{buttonDisagree}</Button>}
          {buttonAgree && <Button onClick={() => handleClose(true)} autoFocus>{buttonAgree}</Button>}
        </DialogActions>
      </Dialog>
  );
}
