import axiosConfig from "app/axiosConfig";
import { AxiosError } from "axios";
import { handleError } from "utils/apiError";
import { SettingsUpdatePayload } from "./criteriaModel";

export const ApiUpdateOrgSettings = async (payload: SettingsUpdatePayload) => {
    try {
      await axiosConfig.put(`/api/criborg`, payload);
    } catch (err) {
      const errors = err as Error | AxiosError;
      return { data: [], error: handleError(errors) };
    }
}