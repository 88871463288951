import { InfoOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Box, FormControl, Grid, Stack, TextField, Typography, useTheme } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import { selectUserOrgType } from 'features/guided/guidedSlice';
import { selectStep, setStepperStep } from 'features/stepper/stepperSlice';
import { CmUserLanguage, UserOrgType } from 'features/user/userModel';
import useCurrency from 'hooks/currency-hook';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currencyToNumber, fcsl } from 'utils/currency';
import { customerSalesKeys } from '../guidedApiKeys';
import { CustomerSalesOpportunityInterface } from '../guidedModel';
import { selectCustomerSalesOpportunityGuided, setCustomerSalesOpportunityGuided } from '../guidedSlice';

const CustomerSalesGuided = () => {
  const theme = useTheme();
  const salesInfo = useSelector(selectCustomerSalesOpportunityGuided);
  const activeStep = useSelector(selectStep);

  const { formatToFlowCurrency } = useCurrency();
  const userOrgType = useSelector(selectUserOrgType);
  const isDirectCustomer = userOrgType === UserOrgType.direct;

  const disabled = activeStep === 3 ? true : false;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  //Cost of Current Products usage 12 month *
  const inputsCustomerSalesOpportunity = [
    {
      label: `${
        t(
          isDirectCustomer
            ? 'Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.ERDirectCustomer'
            : 'Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.estimatedRevenueInputLabel'
        ) || 'Estimated Revenue through Machines 12 Month'
      }`,
      key: customerSalesKeys.estimated_revenue_twelve_months,
      unit: fcsl(i18n.language as CmUserLanguage),
      required: true
    },
    {
      label: `${
        t('Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.avgGrossProfitMarginInputLabel') ||
        'Average Gross Profit  Margin %'
      }`,
      key: customerSalesKeys.average_gross_profit,
      required: true
    },
    {
      label: `${
        t('Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.additionalHaloRevenueInputLabel') ||
        'Additional Halo Revenue'
      }`,
      key: customerSalesKeys.additional_halo_revenue,
      unit: fcsl(i18n.language as CmUserLanguage)
    },
    {
      label: `${
        t('Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.grossProfitMarginForHaloInputLabel') ||
        'Gross Profit Margin % For Halo Revenue'
      }`,
      key: customerSalesKeys.gross_profit_margin_percentage_halo_revenue
    }
  ];

  // Filter only revenue if direct customer
  if (isDirectCustomer) inputsCustomerSalesOpportunity.splice(1);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const dataParam = { name, value };
    dispatch(setCustomerSalesOpportunityGuided(dataParam));
  };

  const handleCostInformationEdit = () => {
    dispatch(setStepperStep(2));
  };

  const getErrRequired = (key: string) => {
    let err = false;
    let { gross_profit_margin_percentage_halo_revenue, additional_halo_revenue } = salesInfo;
    if (typeof gross_profit_margin_percentage_halo_revenue === 'string')
      gross_profit_margin_percentage_halo_revenue = parseFloat(gross_profit_margin_percentage_halo_revenue || '0');
    if (typeof additional_halo_revenue === 'string')
      additional_halo_revenue = parseFloat(additional_halo_revenue || '0');
    if (key === customerSalesKeys.additional_halo_revenue) {
      if (!additional_halo_revenue && gross_profit_margin_percentage_halo_revenue) err = true;
    } else {
      if (additional_halo_revenue && !gross_profit_margin_percentage_halo_revenue) err = true;
    }
    return err;
  };

  return (
    <Box>
      <Box>
        <Stack direction={'row'} spacing={1}>
          <Typography variant="h6" fontSize={'16px'}>
            {`${
              t(
                isDirectCustomer
                  ? 'Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.titleDirect'
                  : 'Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.title'
              ) || 'Customer Sales Opportunity'
            }`}
          </Typography>
          {disabled && (
            <Tooltip title="Edit Cost Information">
              <EditIcon
                onClick={handleCostInformationEdit}
                sx={{ color: theme.palette.action.active }}
                fontSize="small"
              />
            </Tooltip>
          )}
        </Stack>
        {!disabled && (
          <Typography
            sx={{
              fontSize: '14px',
              color: theme.palette.text.secondary,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <InfoOutlined sx={{ color: theme.palette.text.secondary, fontSize: '15px', mr: 1 }} />
            {`${
              t(
                isDirectCustomer
                  ? 'Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.subTitleDirect'
                  : 'Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.subTitle'
              ) || 'This information is used to help provide a Return on Asset.'
            }`}
          </Typography>
        )}
      </Box>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {inputsCustomerSalesOpportunity.map((item, i) => {
            if (
              (item.key === customerSalesKeys.additional_halo_revenue ||
                item.key === customerSalesKeys.gross_profit_margin_percentage_halo_revenue) &&
              !(salesInfo.gross_profit_margin_percentage_halo_revenue && salesInfo.additional_halo_revenue)
            ) {
              return (
                <Grid item xs={12} key={item.label + 'customersalesguided' + i}>
                  <FormControl fullWidth>
                    <TextField
                      label={item.label}
                      value={formatToFlowCurrency(salesInfo[item.key])}
                      required={getErrRequired(item.key)}
                      error={getErrRequired(item.key)}
                      name={item.key}
                      disabled={disabled}
                      id={'solution-cost' + i}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(currencyToNumber(e))}
                      variant="outlined"
                      InputProps={{
                        endAdornment: item.unit || undefined
                      }}
                    />
                  </FormControl>
                </Grid>
              );
            }
            return (
              <Grid item xs={12} key={item.label + 'customersalesguided' + i}>
                <FormControl fullWidth>
                  <TextField
                    label={item.label}
                    value={formatToFlowCurrency(salesInfo[item.key])}
                    required={item.required}
                    error={item.required && !salesInfo[item.key as keyof CustomerSalesOpportunityInterface]}
                    disabled={disabled}
                    name={item.key}
                    id={'solution-cost' + i}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(currencyToNumber(e))}
                    variant="outlined"
                    InputProps={{
                      endAdornment: item.unit || undefined
                    }}
                  />
                </FormControl>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomerSalesGuided;
