import { Stack, Card, Theme, Paper, styled, Grid, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { memo } from 'react';
import { startCase } from 'lodash';
import { MachineSelectionROI } from 'features/guided/guidedModel';

interface Props {
  theme: Theme;
  handleEdit?: (stepNum: number) => void;
  title?: string;
  editTitle?: string;
  machines: MachineSelectionROI[];
  labels: string[];
}

const ReviewMachineSelectionDetails = ({ theme, title, handleEdit = () => {}, editTitle, machines, labels }: Props) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['200'],
    ...theme.typography.body2,
    padding: '6px 16px 4px 16px'
  }));

  return (
    <Card>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['100'],
          padding: '8px 16px',
          alignItems: 'center'
        }}
      >
        <Typography variant="body1">{title}</Typography>
        <Tooltip title={`${editTitle} ${title}`}>
          <EditIcon onClick={() => handleEdit(0)} sx={{ color: theme.palette.action.active, height: 15, width: 15 }} />
        </Tooltip>
      </Stack>

      {machines.map(
        (item) =>
          item.machine_type && (
            <Stack sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={2.4}>
                  <Item>
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {labels[0]}
                    </Typography>
                    <Typography variant="body2">{startCase(item.machine_type)}</Typography>
                  </Item>
                </Grid>
                <Grid item xs={2.4}>
                  <Item>
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {labels[1]}
                    </Typography>
                    <Typography variant="body2">{item.main_qty}</Typography>
                  </Item>
                </Grid>
                <Grid item xs={2.4}>
                  <Item>
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {labels[2]}
                    </Typography>
                    <Typography variant="body2">{item.aux_qty}</Typography>
                  </Item>
                </Grid>
                <Grid item xs={4.8}>
                  <Item>
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {labels[3]}
                    </Typography>
                    <Tooltip title={item.comment || ''}>
                      <Typography noWrap variant="body2" sx={{ width: '180px' }}>
                        {item.comment}
                      </Typography>
                    </Tooltip>
                  </Item>
                </Grid>
              </Grid>
            </Stack>
          )
      )}
    </Card>
  );
};

export default memo(ReviewMachineSelectionDetails);
