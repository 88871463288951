import { CmUserCurrencyMapping, CmUserCurrencySymbolMapping, CmUserLanguage, Currency, CurrencySymbol, CurrencytoCurrencySymbolMapping } from 'features/user/userModel';
import i18n from 'i18n/config';
import { ChangeEvent } from 'react';

export const fc = (currency: Currency, value: number) => {
  const lang = CmUserCurrencyMapping.find((el) => el.currency === currency)?.lang || CmUserLanguage.EnglishUS;
  if(!value) return `0 ${fcsl(lang)}`
  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency
  }).format(Number(removeCurrencyformat(value)));
};

// remove currency format and return unformatted string 
export const rcf = (
  val: number | string | undefined,
  lang1: Currency | undefined,
  lang2: CmUserLanguage | undefined
) : string => {
  if (!val) '';
  let formattedVal = (val && val.toString()) || '';
  const lang = lang1 || lang2;
  if (lang === (Currency.Euro || CmUserLanguage.Danish)) {
    formattedVal = formattedVal?.replaceAll('.', '').replaceAll(',', '.');
  } else {
    formattedVal = formattedVal?.replaceAll(',', '');
  }
  return formattedVal;
};

//Function to remove format
export const removeCurrencyformat = (value: string | number) => {
  if(value) {
    if (i18n.language === CmUserLanguage.Danish) {
      value = value.toString().replaceAll(".", "").replaceAll(",", ".");
    } else {
      value = value.toString().replaceAll(",", "");
    }
    return value;
  } else {
    return 0
  }
}

//Function for undefined curency and values
export const fcu = (currency: Currency | null | undefined, value: string | number | undefined) => {
  const lang = CmUserCurrencyMapping.find((el) => el.currency === currency)?.lang ?? CmUserLanguage.EnglishUS;
  if (!value) return `0 ${fcsl(lang)}`;
  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currency ? currency :  Currency.Doller,
    minimumFractionDigits: 0
  }).format(Number(removeCurrencyformat(value)));
};

export const fcl = (lang: CmUserLanguage, value: number) => {
  const currencyType = CmUserCurrencyMapping.find((el) => el.lang === lang)?.currency ?? Currency.Doller;
  return new Intl.NumberFormat(lang, {
    currency: currencyType
  }).format(Number(value));
};

//similar to fcl function with currency sign
export const fcls = (lang: CmUserLanguage, value: number) => {
  const currencyType = CmUserCurrencyMapping.find((el) => el.lang === lang)?.currency ?? Currency.Doller;
  if(!value) return `0 ${fcsl(lang)}`;
  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currencyType
  }).format(value);
};

//user language to currency symbol mapping
export const fcsl = (lang: CmUserLanguage | null | undefined) => CmUserCurrencySymbolMapping.find((el) => el.lang === lang)?.currency ?? CurrencySymbol.Doller;


//user language to currency  mapping
export const fltoc = (lang: CmUserLanguage | null | undefined) => CmUserCurrencyMapping.find((el) => el.lang === lang)?.currency ?? Currency.Doller;

//currency to currency Symbol
export const fctos = (currency: Currency) => CurrencytoCurrencySymbolMapping.find((el) => el.currency === currency)?.currencySign ?? CmUserLanguage.EnglishUS;

//currency to Lanugage
export const fctol = (currency: Currency) => CmUserCurrencyMapping.find((el) => el.currency === currency)?.lang ?? CmUserLanguage.EnglishUS;

// format number to US dollar
export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

// format number to British pounds
export const Pounds = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP'
});

// format number to Euro
export const Euro = new Intl.NumberFormat('en-DE', {
  style: 'currency',
  currency: 'EUR'
});

export function formatObjectValues<T>(obj: T): T {
  const formattedObj: T = {} as T;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (typeof value === "string") {
        formattedObj[key] = parseFloat(value) as T[typeof key];
      } else {
        formattedObj[key] = value as T[typeof key];
      }
    }
  }
  return formattedObj;
}
const commaDecimalLanguages: string[] = [CmUserLanguage.Danish, CmUserLanguage.French]

// CONVERT NUMBER TO CURRENCY
export const numberToFlowCurrency = (val: number | string | undefined, currency: string, symbol?: boolean) => {
  let value = val ? val.toString() : '0';

  // Keep track of whether the last character is a dot or comma
  const lastCharacterIsDotOrComma = value.endsWith('.') || value.endsWith(',');
  value = value.replace(/,/g, ''); // Remove commas

  // Format the value as currency using the fcl function
  const payload : {currency: string, style? : string} = { currency };
  if(symbol) payload.style = 'currency';
  value =  new Intl.NumberFormat(i18n.language, payload).format(Number(value));

  // If the last character was a dot or comma, add it back
  if (lastCharacterIsDotOrComma) {
    value += commaDecimalLanguages.includes(i18n.language) ? ',' : '.';
  }
  return value;
};

// CONVERT NUMBER TO CURRENCY
export const numberToCurrency = (val: number | string | undefined) => {
  let value = val ? val.toString() : '0';

  // Keep track of whether the last character is a dot or comma
  const lastCharacterIsDotOrComma = value.endsWith('.') || value.endsWith(',');
  value = value.replace(/,/g, ''); // Remove commas

  // Format the value as currency using the fcl function
  value = fcl(i18n.language as CmUserLanguage, parseFloat(value));

  // If the last character was a dot or comma, add it back
  if (lastCharacterIsDotOrComma) {
    value += commaDecimalLanguages.includes(i18n.language) ? ',' : '.';
  }
  return value;
};

// CONVERT CURRENCY TO NUMBER
export const currencyToNumber = (e: ChangeEvent<HTMLInputElement>) => {
  let parsedValue = e.target?.value;

  // FRENCH LANGUAGE SUPPORT
  if(i18n.language === CmUserLanguage.French){
    parsedValue = parsedValue.replace(/,/g, '.'); // Remove all commas with dots (thousands separators)
  }
  // DANISH LANGUAGE SUPPORT
  if (i18n.language === CmUserLanguage.Danish) {
    parsedValue = parsedValue
      .replace(/\./g, '') // Remove all dots (thousands separators)
      .replace(/,/g, '.'); // Replace commas with dots as decimal separators
  } else {
    parsedValue = parsedValue.replace(/,/g, ''); // Remove commas
  }
  // Detect the decimal separator based on the user's locale
  const decimalSeparator = '.';

  // Remove all non-numeric and non-decimal point characters except for the last one
  const decimalSeparatorRegex = new RegExp(`[^0-9${decimalSeparator}]`, 'g');
  parsedValue = parsedValue.replace(decimalSeparatorRegex, '');

  // Ensure that there is only one trailing decimal separator
  const dotCount = parsedValue.split(decimalSeparator).length - 1;
  if (dotCount > 1) {
    parsedValue = parsedValue.slice(0, parsedValue.lastIndexOf(decimalSeparator));
  }
  e.target.value = parsedValue;
  return e;
};

