
import axiosConfig from 'app/axiosConfig';
import { AxiosError } from 'axios';
import { AxiosAPIError, handleError } from 'utils/apiError';
import {  FeedbackResponseInterface, ReadTogglePayload } from './feedback-model';
import { GridRowId } from '@mui/x-data-grid-pro';

export const getFeedbackAsync = async (): Promise<{
  response: FeedbackResponseInterface[];
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.get<FeedbackResponseInterface[]>(`/api/feedback/all-feedbacks`);
    return { response: data?.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    const errr =  { data: null, error: handleError(errors) };
    throw errr
  }
};


export const deleteFeedbackAsync = async (feedbackId: GridRowId): Promise<{
  response: FeedbackResponseInterface;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.delete(`/api/feedback/${feedbackId}`);
    return { response: data?.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    const errr =  { data: null, error: handleError(errors) };
    throw errr
  }
};

export const UpdateReadUnreadToggle = async (
  payload: ReadTogglePayload, feedbackId: string
): Promise<{
  data: FeedbackResponseInterface | null;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.put<FeedbackResponseInterface>(`/api/feedback/${feedbackId}`, payload);
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    throw { data: null, error: handleError(errors) };
  }
};