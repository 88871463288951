import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AppDispatch } from 'app/store';
import { messages } from 'constants/message';
import { fetchCribSettingsByOrgId } from 'features/additionalCostFees/costFeesSlice';
import { Criteria } from 'features/guided/guidedModel';
import { AlertType } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiUpdateOrgSettings } from './criteriaSettingsApi';
import { fetchAllCriterias, selectAllCriterias, selectSelectedCriteriasSettings, setSelectedCriteriasSettings } from './criteriaSlice';

const CriteriaSettings = () => {
  const selectedCriterias = useSelector(selectSelectedCriteriasSettings);
  const allCriterias = useSelector(selectAllCriterias);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchCribSettingsByOrgId())
    dispatch(fetchAllCriterias())
  },[]);


  const handleToggle = (value: Criteria) => () => {
    const currentIndex = selectedCriterias.indexOf(value);
    const newChecked = [...selectedCriterias];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    dispatch(setSelectedCriteriasSettings(newChecked))
    try{
      ApiUpdateOrgSettings({criterias: newChecked})
    }catch(err){
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 }));
    }
  };

  return (
    <Box sx={{ pt: 2 }}>
        <Box mt="2">
        <List sx={{ width: '100%', maxWidth: 860 }}>
          {allCriterias?.map((criteria, i) => {
            const labelId = `checkbox-list-label-${criteria._id}`;
            const type = criteria.criteria_type.join(', ')

            return (
              <ListItem
                key={criteria._id + i}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleToggle(criteria.criteria_key)} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedCriterias?.includes(criteria.criteria_key) ? true: false}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{width: '500px'}} id={labelId} primary={criteria.criteria_name} secondary={criteria.description} />
                  <ListItemText sx={{textAlign: 'right', fontWeight: 600}}  id={criteria._id} primary={type} />
                  
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        </Box>
    </Box>
  )
}

export default CriteriaSettings