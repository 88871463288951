import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import { getMySolutionAsync } from './mySolutionsAPI';
import { CreateSolutionResponseModal, ExportCheckboxElement, ExportCheckboxInterface, StatusValues } from './mySolutionsModel';
import { GridRowId } from '@mui/x-data-grid-pro';

export interface MySolutionsState {
  mySolutionsList: CreateSolutionResponseModal[] | undefined;
  status: 'idle' | 'loading' | 'failed' | 'success';
  loading: boolean;
  openCreateModel: boolean;
  rowToDuplicate: CreateSolutionResponseModal | undefined;
  openCloneModel: boolean;
  openDeleteModel: boolean;
  currentRowId: GridRowId | undefined;
  openChooseExcelPopup: boolean;
  checkbox: ExportCheckboxInterface;
  tabValue: number;
  statusFilterSelected: string[],
  inactiveChecked: boolean,
  detailPanelExpandedRowId: boolean
}

const initialState: MySolutionsState = {
  mySolutionsList: [],
  status: 'idle',
  loading: true,
  openCreateModel: false,
  rowToDuplicate: undefined,
  openCloneModel: false,
  openDeleteModel: false,
  currentRowId: undefined,
  openChooseExcelPopup: false,
  checkbox: {
    opportunityView: false,
    customerView: false
  },
  tabValue: 0,
  statusFilterSelected: [StatusValues.active, StatusValues.onHold],
  inactiveChecked: false,
  detailPanelExpandedRowId: false
};

export const getMySolutionsList = createAsyncThunk(`${ReducerKeys.mySolutions}/getLocationList`, async (status: Array<string>) => {
  const { response } = await getMySolutionAsync(status);
  return response;
});

export const mySolutionsStateSlice = createSlice({
  name: ReducerKeys.mySolutions,
  initialState,
  reducers: {
    setOpenCreateModel: (state, action: PayloadAction<boolean>) => {
      state.openCreateModel = action.payload;
    },
    setRowToDuplicate: (state, action: PayloadAction<CreateSolutionResponseModal | undefined>) => {
      state.rowToDuplicate = action.payload;
    },
    setOpenCloneModel: (state, action: PayloadAction<boolean>) => {
      state.openCloneModel = action.payload;
    },
    setOpenChooseExcelPopup: (state, action: PayloadAction<boolean>) => {
      state.openChooseExcelPopup = action.payload;
    },

    setOpenDeleteModel: (state, action: PayloadAction<boolean>) => {
      state.openDeleteModel = action.payload;
    },
    setCurrentRowId: (state, action: PayloadAction<GridRowId | undefined>) => {
      state.currentRowId = action.payload;
    },
    setExportCheckbox: (state, action: PayloadAction<ExportCheckboxElement>) => {
      const { name, value } = action.payload;
      state.checkbox[name as keyof typeof initialState.checkbox] = value;
    },
    setExportCheckboxDefault: (state, action: PayloadAction<string>) => {
      state.checkbox[action.payload as keyof typeof initialState.checkbox] = false;
    },
    setTabValue: (state, action: PayloadAction<number>) => {
      state.tabValue = action.payload;
    },
    setStatusFilterSelected: (state, action: PayloadAction<string[]>) => {
      state.statusFilterSelected = action.payload;
    },
    setInactiveCheckbox: (state, action: PayloadAction<boolean>) => {
      state.inactiveChecked = action.payload;
    },
    setSelectedExpandedRowId: (state, action: PayloadAction<boolean>) => {
      state.detailPanelExpandedRowId = action.payload;
    }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMySolutionsList.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(getMySolutionsList.fulfilled, (state, action) => {
        state.status = 'success';
        state.loading = false;
        state.mySolutionsList = action.payload;
      })
      .addCase(getMySolutionsList.rejected, (state) => {
        state.status = 'failed';
        state.loading = false;
      });
  }
});

export const {
  setOpenCreateModel,
  setRowToDuplicate,
  setOpenCloneModel,
  setOpenDeleteModel,
  setCurrentRowId,
  setOpenChooseExcelPopup,
  setExportCheckbox,
  setTabValue,
  setExportCheckboxDefault,
  setStatusFilterSelected,
  setInactiveCheckbox,
  setSelectedExpandedRowId
} = mySolutionsStateSlice.actions;
export const selectMySolutionsState = (state: RootState) => state.mySolutions;
export const selectRowToDuplicateState = (state: RootState) => state.mySolutions.rowToDuplicate;
export const selectOpenCreateModelState = (state: RootState) => state.mySolutions.openCreateModel;
export const selectOpenCloneModelState = (state: RootState) => state.mySolutions.openCloneModel;
export const selectOpenDeleteModelState = (state: RootState) => state.mySolutions.openDeleteModel;
export const selectMySolutionsLoadingState = (state: RootState) => state.mySolutions.loading;
export const selectMySolutionsList = (state: RootState) => state.mySolutions.mySolutionsList;
export const selectCurrentRowId = (state: RootState) => state.mySolutions.currentRowId;
export const selectOpenChooseExcelPopup = (state: RootState) => state.mySolutions.openChooseExcelPopup;
export const selectExportCheckboxData = (state: RootState) => state.mySolutions.checkbox;
export const selectedTabValue = (state: RootState) => state.mySolutions.tabValue;
export const selectStatusFilterSelected = (state: RootState) => state.mySolutions.statusFilterSelected;
export const selectInactiveCheckboxStatus = (state: RootState) => state.mySolutions.inactiveChecked;
export const selectDetailPanelExpandedRowIds = (state: RootState) => state.mySolutions.detailPanelExpandedRowId;


export const { reducer: mySolutionsReducer, name: mySolutionsKey } = mySolutionsStateSlice;
