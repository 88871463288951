import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ReducerKeys } from 'app/reducerKeys';
import { RootState } from 'app/store';
import { ExcelParsedData, UpdatePayload, ProductAPIPayload } from './reviewProductModel';
import { getProductListAsync, updateRowDataAsync } from './reviewProductAPI';
import get from 'lodash/get';
export interface ReviewValidateProductData {
  productList: ExcelParsedData[] | undefined;
  pageSize: number | undefined;
  status: 'idle' | 'loading' | 'failed' | 'success';
  updateStatus: 'idle' | 'loading' | 'failed' | 'success';
  loading: boolean;
}

  const initialState: ReviewValidateProductData = {
   productList: undefined,
   pageSize: 10,
   status: 'idle',
   updateStatus: 'idle',
   loading: true,
  };

  export const getProductList = createAsyncThunk(`${ReducerKeys.reviewValidateProductData}/getProductList`, async (payload: ProductAPIPayload ) => {
    const { response } = await getProductListAsync(payload);
    return response;
  });

  export const updateRowDataList = createAsyncThunk( `${ReducerKeys.reviewValidateProductData}/updateRowData`, async (payload: UpdatePayload) => {
    const {response} = await updateRowDataAsync(payload);
    return response;
  });

export const reviewValidateProductDataSlice = createSlice({
  name: ReducerKeys.reviewValidateProductData,
  initialState,
  reducers: {
    updateProductListRow: (state,  action: PayloadAction<ExcelParsedData[]>) => {
        state.updateStatus = 'success';
          const updatedRes = action.payload[0];
          const updatedProductId = get(updatedRes,'_id',''); 
          if (updatedProductId) {
            state.productList = state.productList?.map((item) => {
              return item._id === updatedProductId ? 
              { 
                ...item, 
                rowData : updatedRes.rowData || [],
                rowErrors: updatedRes.rowErrors,
                columnErrors: updatedRes.columnErrors
              } : item
            });
          }
    },
    updateRowDataListStatus: (state, action: PayloadAction<('idle' | 'loading' | 'failed' | 'success')>) => {
      state.updateStatus = action.payload;
    },
    
    resetAPIcallStatus: (state) => {
      state.status = 'idle';
      state.updateStatus = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductList.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(getProductList.fulfilled, (state, action) => {
        state.status = 'success';
        state.loading = false;
        state.productList = action.payload?.data;
        state.pageSize = action.payload?.count;
      })
      .addCase(getProductList.rejected, (state) => {
        state.status = 'failed';
        state.loading = false;
      })
  }
});

export const selectProductData = (state: RootState) => state.reviewValidateProductData;

export const { resetAPIcallStatus, updateProductListRow, updateRowDataListStatus} = reviewValidateProductDataSlice.actions;

export const { reducer: reviewValidateProductReducer, name: reviewValidateProductDataKey } =
  reviewValidateProductDataSlice;
