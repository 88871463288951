import { GridRowId } from '@mui/x-data-grid-pro';
import axiosConfig from 'app/axiosConfig';
import { AxiosError } from 'axios';
import { AxiosAPIError, handleError } from 'utils/apiError';
import {
  AreaFetchingPayloadInterface,
  CreateAreaPayloadInterface,
  ResponseAreaObject,
  ResponseCriteriaList,
  ResponseProductCategoryObject,
  ResponseRecommendedSolutions,
  ResponseSolutionObject,
  SubmitFeedbackInterface,
  UpdateSolutionPayloadInterface
} from './guidedModel';
import { UpdateRoiPayloadInterface } from 'features/stepperRoi/stepperRoiModel';

export const ApiGetAreaGuided = async (
  payload: AreaFetchingPayloadInterface
): Promise<{
  data: ResponseAreaObject[] | null;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.get<ResponseAreaObject[]>(
      `/api/area?root_org_id=${payload.root_org_id}&org_id=${payload.org_id}&location_id=${payload.location_id}`
    );
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    return { data: null, error: handleError(errors) };
  }
};

export const ApiProductCategoryAsync = async (): Promise<{
  response: ResponseProductCategoryObject[] | undefined;
  error: AxiosAPIError | null;
}> => {
  const data = await axiosConfig.get<ResponseProductCategoryObject[]>(`/api/items`);
  return { response: data?.data, error: null };
};

export const ApiCreateAreaGuided = async (
  payload: CreateAreaPayloadInterface
): Promise<{
  data: ResponseAreaObject | null;
  error: AxiosAPIError | null;
}> => {
  try {
    console.log({ payload });
    const data = await axiosConfig.post<ResponseAreaObject>('/api/area', payload);
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    return { data: null, error: handleError(errors) };
  }
};
export const ApiCreateSolutionGuided = async (
  payload: UpdateSolutionPayloadInterface
): Promise<{
  data: ResponseSolutionObject | null;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.put<ResponseSolutionObject>(`/api/flow`, payload);
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    return { data: null, error: handleError(errors) };
  }
};
export const ApiUpdateSolutionGuided = async (
  payload: UpdateSolutionPayloadInterface | UpdateRoiPayloadInterface, step?: string | null
): Promise<{
  data: ResponseSolutionObject | null;
  error: AxiosAPIError | null;
}> => {
  try {
    let URL = `/api/flow/${payload.flowId}`;
    if(step) URL = URL + `?step=${step}`;
    const data = await axiosConfig.put<ResponseSolutionObject>(URL, payload);
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    const errr = { data: null, error: handleError(errors) };
    throw errr;
  }
};

export const ApiGetSolutionGuided = async (
  flowId: string | GridRowId
): Promise<{
  data: ResponseSolutionObject | null;
  error: AxiosAPIError | null;
}> => {
  try {
    //1
    const data = await axiosConfig.get<ResponseSolutionObject>(`/api/flow/${flowId}?crib_config=true`);
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    return { data: null, error: handleError(errors) };
  }
};

export const ApiSubmitFeedback = async (
  payload: SubmitFeedbackInterface
): Promise<{
  data: SubmitFeedbackInterface | null;
  error: AxiosAPIError | null;
}> => {
  try {
    console.log({ payload });
    const data = await axiosConfig.post<SubmitFeedbackInterface>('/api/feedback/create-feedback', payload);
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    return { data: null, error: handleError(errors) };
  }
};

export const apiGetRecommendedSolutionGuided = async (
  flowId: string
): Promise<{
  data: ResponseRecommendedSolutions | null;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.get<ResponseRecommendedSolutions>(`/api/flow/recommendations/${flowId}`);
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    const errr = { data: null, error: handleError(errors) };
    throw errr;
  }
};


export const ApiGetCriteriaList = async (queryString?: string): Promise<{
  data: ResponseCriteriaList[] | null;
  error: AxiosAPIError | null;
}> => {
  try {
    const URL = queryString ? `/api/criterias/all${queryString}`: '/api/criterias/all';
    const data = await axiosConfig.get<ResponseCriteriaList[]>(URL);
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    throw { data: null, error: handleError(errors) };
  }
};
