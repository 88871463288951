export enum InventoryType {
  Consumable = 'consumable',
  Durable = 'durable',
  Both = 'both'
}

export enum SecurityLevel {
  Low = 'low',
  Medium = 'medium',
  High = 'high'
}
export enum Item {
  ppe = 'PPE/Safety',
  large_bulky_items = 'Large Bulky Items',
  spareParts = 'Spare Parts',
  tools = 'Tools'
}

export interface ItemQuantity {
  type?: Item | '';
  quantity?: number;
  security?: string;
  time_window?: string;
  comment?: string;
}
