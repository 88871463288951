import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReducerKeys } from 'app/reducerKeys';
import { getFeedbackAsync } from './feedbackAPI';
import { FeedbackResponseInterface } from './feedback-model';
import { RootState } from 'app/store';

export interface FeedbackState {
  feedbackList: FeedbackResponseInterface[];
  status: 'idle' | 'loading' | 'failed' | 'success';
  loading: boolean;
}

const initialState: FeedbackState = {
  feedbackList: [],
  status: 'idle',
  loading: false,
};

export const getAllFeedbackLists = createAsyncThunk(
  `${ReducerKeys.feedback}/getAllFeedbackLists`,
  async () => {
    const { response, error } = await getFeedbackAsync();
    if (response) return { value: response };
    return { value: response, error };
  }
);

export const feedbackStateSlice = createSlice({
  name: ReducerKeys.feedback,
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFeedbackLists.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(getAllFeedbackLists.fulfilled, (state, action) => {
        state.status = 'success';
        state.loading = false;
        state.feedbackList = action.payload.value;
      })
      .addCase(getAllFeedbackLists.rejected, (state) => {
        state.status = 'failed';
        state.loading = false;
      })
  }
});

export const selectFeedbackList = (state: RootState) => state.feedback.feedbackList;
export const selectFeedbackListLoader = (state: RootState) => state.feedback.loading;


export const { reducer: feedbackReducer, name: feedbackKey } = feedbackStateSlice;
