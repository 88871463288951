import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import { CribSelectionPostPayload, CribSelectionUpdateResponse, MachineSelectionResponseData } from './machine-model';
import { updateMachinesSelectionAsync, getAllCribs } from './machineAPI';
import { ActiveConnections } from 'features/guided/guidedModel';

export interface MachineSelectionState {
  machine_selection_list: MachineSelectionResponseData[] | [];
  fetchAllCribListLoading: boolean;
  crib_list_loading: boolean;
  selected_machines: string[];
  express_machine_selected: boolean;
  rfid_machine_selected: boolean;
  accuPort_machine_selected: boolean;
  machine_dropdown_selected: string[];
  averageTime: number;
  maxTime: number;
  tabValue: number;
  status: 'idle' | 'loading' | 'failed' | 'success';
  connections: ActiveConnections;
}

const initialState: MachineSelectionState = {
  machine_selection_list: [],
  fetchAllCribListLoading: true,
  crib_list_loading: false,
  selected_machines: [],
  express_machine_selected: true,
  rfid_machine_selected: true,
  accuPort_machine_selected: true,
  machine_dropdown_selected: [],
  averageTime: 2,
  maxTime: 15,
  tabValue: 0,
  status: 'idle',
  connections: ActiveConnections.upto_100
};

export const fetchAllCribList = createAsyncThunk(`${ReducerKeys.machineSelection}/fetchAllCribs`, async () => {
  const { response } = await getAllCribs();
  return response;
});

export const updateMachineSelectionAsync = createAsyncThunk(
  `${ReducerKeys.machineSelection}/updateMachineSelectionAsyncs`,
  async (payload: CribSelectionPostPayload) => {
    const { response } = await updateMachinesSelectionAsync(payload);
    return response;
  }
);

export const machineSelectionSlice = createSlice({
  name: ReducerKeys.machineSelection,
  initialState,
  reducers: {
    setSelectedMachines: (state, action: PayloadAction<string[]>) => {
      state.selected_machines = action.payload;
    },
    setMachineSettingsOptions: (state, action: PayloadAction<CribSelectionUpdateResponse>) => {
      state.rfid_machine_selected = action.payload.rfid_machines;
      state.express_machine_selected = action.payload.express_machines;
      state.accuPort_machine_selected = action.payload.accuport_machines;
      state.connections = action.payload.connections;
    },
    setRfidMachine: (state, action: PayloadAction<boolean>) => {
      state.rfid_machine_selected = action.payload;
    },
    setExpressMachine: (state, action: PayloadAction<boolean>) => {
      state.express_machine_selected = action.payload;
    },
    setAccuPortMachine: (state, action: PayloadAction<boolean>) => {
      state.accuPort_machine_selected = action.payload;
    },
    setMachine_selection_list: (state, action: PayloadAction<MachineSelectionResponseData[]>) => {
      state.machine_selection_list = action.payload;
    },
    setAverageTime: (state, action: PayloadAction<number>) => {
      state.averageTime = action.payload;
    },
    setMaxTime: (state, action: PayloadAction<number>) => {
      state.maxTime = action.payload;
    },
    setTabValue: (state, action: PayloadAction<number>) => {
      state.tabValue = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCribList.pending, (state) => {
        state.fetchAllCribListLoading = true;
      })
      .addCase(fetchAllCribList.fulfilled, (state, action) => {
        state.fetchAllCribListLoading = false;
        state.machine_selection_list = action.payload || [];
      })
      .addCase(fetchAllCribList.rejected, (state) => {
        state.fetchAllCribListLoading = false;
      })

      .addCase(updateMachineSelectionAsync.pending, (state) => {
        state.crib_list_loading = true;
        state.status = 'loading';
      })
      .addCase(updateMachineSelectionAsync.fulfilled, (state, action) => {
        state.crib_list_loading = false;
        state.status = 'success';
        state.connections = action.payload.connections;
        state.selected_machines = action.payload.cribs;
      })
      .addCase(updateMachineSelectionAsync.rejected, (state) => {
        state.crib_list_loading = false;
        state.status = 'failed';
      });
  }
});

export const {
  setSelectedMachines,
  setRfidMachine,
  setExpressMachine,
  setAccuPortMachine,
  setMachineSettingsOptions,
  setMachine_selection_list,
  setAverageTime,
  setMaxTime,
  setTabValue
} = machineSelectionSlice.actions;

export const selectMachineSettingState = (state: RootState) => {
  return state.machineSelection;
};

export const selectMachineSelectionList = (state: RootState) => {
  return state.machineSelection.machine_selection_list;
};

export const selectMachineSelectedData = (state: RootState) => {
  return state.machineSelection.selected_machines;
};

export const selectRfidMachine = (state: RootState) => {
  return state.machineSelection.rfid_machine_selected;
};
export const selectExpressMachine = (state: RootState) => {
  return state.machineSelection.express_machine_selected;
};

export const selectAccuPortMachine = (state: RootState) => {
  return state.machineSelection.accuPort_machine_selected;
};

export const selectFetchAllCribListLoading = (state: RootState) => {
  return state.machineSelection.fetchAllCribListLoading;
};

export const selectMachineDropDownSelected = (state: RootState) => {
  return state.machineSelection.machine_dropdown_selected;
};

export const selectCribListByOrgLoading = (state: RootState) => {
  return state.machineSelection.crib_list_loading;
};

export const selectAverageTime = (state: RootState) => {
  return state.machineSelection.averageTime;
};
export const selectMaxTime = (state: RootState) => {
  return state.machineSelection.maxTime;
};

export const selectUpdateApiStatus = (state: RootState) => {
  return state.machineSelection.status;
};
export const selectedTabValue = (state: RootState) => state.machineSelection.tabValue;

export const selectActiveConnection = (state: RootState) => state.machineSelection.connections;

export const { reducer: machineSelectionReducer, name: machineSelectionKey } = machineSelectionSlice;
