import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

import MachineSelectionROI from 'features/machine-selection-roi/MachineSelectionROI';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';
import { MXP_PAGE_VIEW } from 'constants/mixpanel';

const MachineSelectionDetailROI = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const flowType = useParams().type as TabsInterface;
  useEffect(() => {
    // Send a default page view event with additional properties
    mixpanel.track_pageview({ page: MXP_PAGE_VIEW.MachineSelectionROI, flow: flowType });
  }, []);

  return (
    <>
      <Box sx={{ marginBottom: '10px', mt: 1, mb: 2 }}>
        <Typography variant="h5" sx={{ fontSize: '18px' }}>
          {t('Flow.ROITab.MachineSelectionStep.title') || 'Cost Information'}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, fontSize: '16px' }}>
          {t('Flow.ROITab.MachineSelectionStep.subTitle') ||
            'Provide information about your cost to serve, customer volumes and opportunity.'}
        </Typography>
        <MachineSelectionROI />
      </Box>
    </>
  );
};

export default MachineSelectionDetailROI;
