import { memo } from 'react';
import {
  Box,
  Select,
  MenuItem,
  Button,
  TextField,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { GridSearchIcon } from '@mui/x-data-grid';

import { severityM } from './reviewProductModel';

interface FilterComponentProps {
  setSelectedOption: (selectedOption: number) => void;
  selectedOption: number;
  handleExportButtonClick: () => void;
  excelDownloadStatus: 'loading' | 'idle' | 'failed';
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;
  isSearchActive: boolean;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  selectedOption,
  setSelectedOption,
  handleExportButtonClick,
  excelDownloadStatus,
  value,
  onChange,
  clearSearch,
  isSearchActive
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ marginBottom: '10px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '36px' }}>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            disabled={isSearchActive}
            variant="outlined"
            size="small"
            value={value}
            onChange={onChange}
            placeholder={t('Common.search') || 'Search'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <GridSearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  onClick={() => clearSearch()}
                  sx={{ visibility: value ? 'visible' : 'hidden' }}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )
            }}
          />
        </Grid>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Select
            size="small"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value as number)}
            defaultValue={0}
            sx={{ minWidth: '100px', height: '36px', marginRight: 1 }}
          >
            {severityM.map((data) => (
              <MenuItem value={data.val} key={data.val}>
                {data.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="text"
            startIcon={
              excelDownloadStatus === 'loading' ? <CircularProgress size={'13px'} /> : <FileDownloadOutlinedIcon />
            }
            onClick={handleExportButtonClick}
            sx={{ width: '100px' }}
            disabled={excelDownloadStatus === 'loading'}
          >
            Export
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(FilterComponent);
