import { AxiosError } from 'axios';
import get from 'lodash/get';
import { SERVER_ERROR } from 'constants/apiError';

export interface AxiosAPIError {
  type: string;
  message: string;
}

export const handleError = (error: Error | AxiosError): AxiosAPIError => {
  const errMessage = get(error, 'message', '');
  if (errMessage) {
    const result = { type: 'failure', message: errMessage };
    return result;
  }
  const result = { type: 'failure', message: SERVER_ERROR };
  return result;
};
