import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ThemeModes, darkTheme, lightTheme } from './index';
import { ReducerKeys } from 'app/reducerKeys';

export interface ThemeState {
  themeMode: ThemeModes;
}

const initialState: ThemeState = {
  themeMode: ThemeModes.light
};

export const themeSlice = createSlice({
  name: ReducerKeys.theme,
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<ThemeModes>) => {
      state.themeMode = action.payload;
    }
  }
});

export const { changeTheme } = themeSlice.actions;

export const selectTheme = (state: RootState) => {
  const { themeMode } = state.theme;
  return themeMode === ThemeModes.light ? lightTheme : darkTheme;
};
export const selectThemeMode = (state: RootState) => {
  return state.theme.themeMode;
};

export const { reducer: themeReducer, name: themeKey } = themeSlice;
