import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DOT_COLORS } from 'theme/customColors';
import {
  ExcelColumnError,
  ExcelParsedData,
  ExcelRowColumnError,
  SeverityKeys,
  severityMap
} from './reviewProductModel';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';

export const getDot = (color: string) => (
  <span
    style={{
      display: 'inline-block',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: color,
      marginRight: '5px',
      marginLeft: '5px'
    }}
  />
);

export const findLighterShade = (color: DOT_COLORS): string => {
  switch (color) {
    case DOT_COLORS.RED:
      return '#feeceb';
    case DOT_COLORS.ORANGE:
      return '#fce8da';
    case DOT_COLORS.YELLOW:
      return '#fffae7';
    case DOT_COLORS.LIGHT_YELLOW:
      return '#fffdf5';
    default:
      return '';
  }
};

export const getSeverityColor = (severity: number | null): string => {
  switch (severity) {
    case severityMap[SeverityKeys.MissingRequiredInvalidData]:
      return DOT_COLORS.RED;
    case severityMap[SeverityKeys.NoItemPlacement]:
      return DOT_COLORS.RED;
    case severityMap[SeverityKeys.PlacementDataMismatch]:
      return DOT_COLORS.ORANGE;
    case severityMap[SeverityKeys.OnlySizeAvailable]:
      return DOT_COLORS.YELLOW;
    case severityMap[SeverityKeys.ItemCostMissing]:
      return DOT_COLORS.LIGHT_YELLOW;
    default:
      return '';
  }
};

export const renderCellWithDot = (params: GridRenderCellParams, field: string, productList: ExcelParsedData[]) => {
  const row = productList[params.row.uniqueId - 1];
  let dotColor = '';
  if (row && row.columnErrors) {
    const colErrorsForField = row.columnErrors.filter((error) => error.colKey === field);

    if (colErrorsForField.length > 0) {
      const severity = colErrorsForField.reduce((prev, current) => Math.min(prev, current.severity), 10);
      dotColor = getSeverityColor(severity);

      return (
        <Tooltip
          title={
            <Typography variant="body1" sx={{ fontSize: '14px' }}>
              {colErrorsForField.map((error: ExcelRowColumnError) => error.message).join('\n')}
            </Typography>
          }
          arrow
        >
          <div
            style={{ display: 'flex', flex: 1, height: '100%', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <div style={{ display: 'flex', flex: 1 }}>{params.value}</div>
            {dotColor && getDot(dotColor)}
          </div>
        </Tooltip>
      );
    }
  }

  return (
    <Typography variant="inherit" sx={{ display: 'flex', alignItems: 'center' }}>
      {params.value}
    </Typography>
  );
};

export const renderCellWithUrlOrNa = (
  url: string,
  error: ExcelColumnError | undefined,
  dotColor: string,
  field: string
) => {
  return (
    <Box style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
      {url !== '' && !error ? (
        <IconButton href={url} target="_blank" rel="noopener noreferrer" color="primary">
          {field !== 'RepackingInstructions' ? <ImageIcon /> : <PictureAsPdfIcon />}
        </IconButton>
      ) : (
        <Tooltip
          title={
            <Typography variant="body1" sx={{ fontSize: '14px' }}>
              {error ? error.message : ''}
            </Typography>
          }
          arrow
        >
          <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flex: 1 }}>
              {url && (
                <IconButton disabled={!!error} href={url} target="_blank" rel="noopener noreferrer" color="primary">
                  {field !== 'RepackingInstructions' ? <ImageIcon /> : <PictureAsPdfIcon />}
                </IconButton>
              )}
            </div>
            {error && dotColor && getDot(dotColor)}
          </div>
        </Tooltip>
      )}
    </Box>
  );
};
