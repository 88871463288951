import axios, { AxiosError } from 'axios';
import { FileWithPath } from 'file-selector';
import {
  ResponseDataUpload,
  RequestSaveFileData,
  ResponseGetFlowDataByID,
  PayloadGetPreassignedApi,
  GetPreassignedURLResponse,
  GetFileParseResponse,
  RetrivePOUResponseList,
} from './uploadModel';
import { AxiosAPIError, handleError } from 'utils/apiError';
import axiosConfig from 'app/axiosConfig';

export const getPreasignedURL = async (
  inputData: PayloadGetPreassignedApi
): Promise<{
  response: GetPreassignedURLResponse | null;
  error: AxiosAPIError | null;
}> => {
  const { flow_id, file_name } = inputData;

  const data = await axiosConfig.get<GetPreassignedURLResponse>(
    `/api/flow/upload/link?flow_id=${flow_id}&file_name=${file_name}`
  );
  return { response: data?.data, error: null };
};

export const excelFileData = async (
  file: FileWithPath,
  getPreasignedURLData: GetPreassignedURLResponse,
  onProgress: (progress: number) => void
): Promise<{
  data: ResponseDataUpload | null;
  error: AxiosAPIError | null;
}> => {
  const headers = {
    'x-ms-blob-type': 'BlockBlob',
  };

  const { fileUploadURL } = getPreasignedURLData;

  const data = await axios.put<ResponseDataUpload>(fileUploadURL, file, {
    headers: headers,
    onUploadProgress: ({loaded, total}) => {
      const progress = total ? (loaded / total) * 100 : 0;
      onProgress(progress);
    },
  });

  return { data: data.data, error: null };
};

export const updateFlowData = async (
  payload: RequestSaveFileData
): Promise<{
  data: ResponseGetFlowDataByID | null;
  error: AxiosAPIError | null;
}> => {
  try {
    const res = await axiosConfig.put(`/api/flow/${payload.flowId}`, payload);
    return { data: res.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    throw { data: null, error: handleError(errors) };
  }
};

export const getFileParsingData = async (
  payload: string,
  onProgress: (progress: number) => void
): Promise<{
  response: GetFileParseResponse | undefined;
  error: AxiosAPIError | null;
}> => {
  const data = await axiosConfig.get<GetFileParseResponse>(
    `/api/flow/automated/parse-file/${payload.toString()}`,
    {
      onDownloadProgress: ({loaded, total}) => {
        const percentCompleted = total ? Math.floor((loaded / total) * 100) : 0;
        onProgress(percentCompleted);
      },
    }
  );

  return { response: data?.data, error: null };
};

export const retrivePouData = async ( flow_id: string | undefined): Promise<{
  response: RetrivePOUResponseList | undefined;
  error: AxiosAPIError | null;
}> => {
  const data = await axiosConfig.get<RetrivePOUResponseList>(
    `/api/pou?flow_id=${flow_id}`
  );
  return { response: data?.data, error: null };
};