export enum MXP_PAGE_VIEW {
  Criteria = 'Criteria',
  PCADetails = 'Product Category and Area Details',
  CostInfo = 'Cost Info',
  Review = 'Review',
  Upload = 'Upload',
  Result = 'results',
  SolutionDashboard = 'Solution Dashboard',
  MachineSelectionROI = 'ROI Machine Selection',
  CustomerLocations = 'Customer Locations'
}
