import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { ApiSubmitFeedback } from 'features/guided/guidedAPI';
import { selectedAreaGuided } from 'features/guided/guidedSlice';
import { AlertType } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { selectUserDetails, selectUserId } from 'features/user/userSlice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

interface CriteriaModalInterface {
  open: boolean;
  setOpen(name: boolean): void;
}
const CriteriaFeedbackModal = ({ open, setOpen }: CriteriaModalInterface) => {
  const area = useSelector(selectedAreaGuided);
  const { flowId } = useParams();
  const user_id = useSelector(selectUserId);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const userDetails = useAppSelector(selectUserDetails);
  const dispatch = useDispatch();

  const getRootUserParentOrg = (parent_id: string) => { 
    if(userDetails?.rootOrgId) {
      return parent_id ? parent_id : userDetails?.rootOrgId
    } else {
      return ''
    }
  }
  
  const submitFeedback = () => {
    if (!area || !user_id) {
      dispatch(setSnackAlert({ open: true, type: AlertType.warning, message: 'something went wrong!', timeout: 3000 }));
      return;
    }
  
    const payload = {
      feedback,
      root_org_id: area.root_org_id,
      root_org_name: area.root_org_name,
      org_id: area.org_id,
      org_name: area.org_name,
      flow_id: flowId,
      parent_org_id: getRootUserParentOrg(area.parent_org_id)
    };
  
    setLoading(true);
  
    ApiSubmitFeedback(payload)
      .then(({ data }) => {
        
        if (data) {
          setLoading(false);
          dispatch(setSnackAlert({ open: true, type: AlertType.success, message: 'Feedback Submitted', timeout: 3000 }));
          setOpen(false);
        }
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Cannot Submit Feedback, Please try again', timeout: 3000 }));
      });
  };

  
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      <DialogTitle sx={{ width: '500px' }}>
        <Typography variant="h6" fontSize={'16px'}>
          {t('Flow.CommonLabels.FeedBackModal.title') || 'Submit Feedback'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          required
          id="outlined-required"
          label={t('Flow.CommonLabels.FeedBackModal.feedBackLabel') || 'Feedback'}
          multiline
          rows={4}
          placeholder={t('Flow.CommonLabels.FeedBackModal.feedbackInputHelperText') || 'Submit your feedback here.'}
          fullWidth
          focused
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          sx={{
            marginTop: '10px',
            '& .MuiInputBase-input': {}
          }}
        />
      </DialogContent>
      <DialogActions>
            <Button
              onClick={() => !loading && setOpen(false)}
              variant="text"
              size="medium"
              sx={{ fontFamily: 'Helvetica Neue', fontStyle: 'normal', textTransform: 'capitalize' }}
            >
              {t('Common.cancelLabel') || 'Cancel'}
            </Button>
        <Button
          disabled={!feedback.trim()} 
          onClick={submitFeedback}
          variant="contained"
          size="medium"
          sx={{ fontFamily: 'Helvetica Neue', fontStyle: 'normal', textTransform: 'capitalize' }}
        >
           {`${t('Common.submitLabel') || 'Submit'} `}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CriteriaFeedbackModal;
