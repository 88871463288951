import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import ceil from 'lodash/ceil';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MXP_PAGE_VIEW } from 'constants/mixpanel';
import { Criteria } from 'features/guided/guidedModel';
import { selectGuidedState, selectUserOrgType, setApproved } from 'features/guided/guidedSlice';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';
import { UserOrgType } from 'features/user/userModel';
import useCurrency from 'hooks/currency-hook';
import useRoute from 'hooks/route-hook';
import ReviewAdditionalCostInfo from './ReviewAdditionalCostInfo';
import ReviewCategoryAndArea from './ReviewCategoryAndArea';
import ReviewCost from './ReviewCost';
import ReviewCriteria from './ReviewCriteria';
import ReviewCustomerSalesOpportunity from './ReviewCustomerSalesOpportunity';
import ReviewMachineSelectionDetails from './ReviewMachineSelectionDetails';

export const StyledItem = styled(ListItem)(() => ({
  height: '45px',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
  padding: '0 25px 0 15px',
  cursor: 'pointer',
  fontsize: '12px'
}));

export const StyledItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: '15px',
  color: theme.palette.text.secondary,
  '& .MuiListItemText-primary': {
    fontSize: '13px'
  }
}));

const ReviewGuided = () => {
  const guidedState = useSelector(selectGuidedState);
  const orgType = useSelector(selectUserOrgType);

  const criteriaList: Criteria[] = guidedState.criteria_ranking || [];
  const additionalCosts = defaultTo(guidedState.additional_costs, []);
  const additionalFees = defaultTo(guidedState.service_fees, []);
  const flowInventoryData = guidedState.flow_inventory_data;
  const salesInfo = guidedState.customer_sales_opportunity;
  const currency = guidedState.solutionBuiltCurrency;
  const isExpress = guidedState.express_machines;
  const isrfId = guidedState.rfid_machines;
  const isAccuport = guidedState.accuport_machines;
  const costInfo = guidedState.cost_info;
  const approved = guidedState.approved;

  const { type } = useParams();
  const flowType = (type || TabsInterface.roi) as TabsInterface;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { routeTo } = useRoute();
  const { formatToCurrencyWithSymbol } = useCurrency();

  useEffect(() => {
    // Send a default page view event with additional properties
    mixpanel.track_pageview({ page: MXP_PAGE_VIEW.Review, flow: flowType });
  }, []);

  const isDirectCustomer = orgType === UserOrgType.direct;

  const avgTimeTrans = get(guidedState, 'settings.machine_calculation_params.average_time_per_transaction', 2);
  const maxTimeStartShiftArea = get(guidedState, 'settings.machine_calculation_params.max_time_start_shift_area', 12);
  const itemToStartShift = get(guidedState, 'flow_inventory_info.items_to_start_the_shift', 0);

  const areaDetails = [
    {
      title: t('Flow.GuidedTab.ProductInfoStep.areaName'),
      value: guidedState.selectedArea?.area_name || ''
    },
    {
      title: t('Flow.GuidedTab.ProductInfoStep.capacityForGrowthInputLabel'),
      value: `${guidedState.flow_inventory_info.capacity_for_growth || 0}%`
    },
    {
      title: t('Flow.GuidedTab.ProductInfoStep.safetyStockInputLabel'),
      value: `${guidedState.flow_inventory_info.safety_stock_percentage || 0}%`
    },
    {
      title: `${t('Flow.GuidedTab.ResultsStep.CustomerViewSection.excelStrings.restockingFrequency')} *`,
      value: guidedState.flow_inventory_info.restocking_frequency || ''
    },
    {
      title: `${t('Flow.GuidedTab.ProductInfoStep.itemsToStartShift')} *`,
      value: guidedState.flow_inventory_info.items_to_start_the_shift || ''
    },
    {
      title: `${t('Flow.GuidedTab.ProductInfoStep.NumberOfMainMachine')} *`,
      value: ceil((itemToStartShift * avgTimeTrans) / maxTimeStartShiftArea) || 0
    }
  ];

  const costInfoBudgetDetails = criteriaList.includes(Criteria.budget)
    ? [
        {
          title: t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.budgetInputLabel'),
          value: formatToCurrencyWithSymbol(costInfo.budget)
        }
      ]
    : [];

  const costInfoDetails = [
    {
      title: t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.salesTaxInputLabel'),
      value: defaultTo(costInfo.state_sales, 0)
    },
    {
      title: t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.freightEstimateInputLabel'),
      value: formatToCurrencyWithSymbol(costInfo.freight_estimate)
    },
    {
      title: t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.stockingLaborInputLabel'),
      value: defaultTo(costInfo.stocking_labor, 0)
    },
    {
      title: t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.stockingLaborRateInputLabel'),
      value: formatToCurrencyWithSymbol(costInfo.labor_rate_per_hour)
    },
    {
      title: isDirectCustomer
        ? t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.DC.MUT')
        : t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.leaseAgreementTermInputLabel'),
      value: defaultTo(costInfo.lease_agreement, 0)
    },
    {
      title: t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.estimatedRepairCostsInputLabel'),
      value: formatToCurrencyWithSymbol(costInfo.estimated_repair)
    },
    ...costInfoBudgetDetails
  ];

  const machineDetail = [
    {
      title: t('Flow.GuidedTab.ProductInfoStep.expressMachinesTitle'),
      value: !!isExpress,
      enabled: !!get(guidedState, 'settings.express_machines')
    },
    {
      title: t('Flow.GuidedTab.ProductInfoStep.rfidMachinesTitle'),
      value: !!isrfId,
      enabled: !!get(guidedState, 'settings.rfid_machines')
    },
    {
      title: t('Flow.GuidedTab.ProductInfoStep.accuportMachinesTitle'),
      value: !!isAccuport,
      enabled: !!get(guidedState, 'settings.accuport_machines')
    }
  ];

  const itemDetailLabels = [
    t('Flow.GuidedTab.ReviewStep.sections.itemInfo.detail.itemCategory'),
    t('Flow.GuidedTab.ReviewStep.sections.itemInfo.detail.itemQuantity'),
    t('Flow.GuidedTab.ReviewStep.sections.itemInfo.detail.timeWindow'),
    t('Flow.GuidedTab.ReviewStep.sections.itemInfo.detail.sl')
  ];

  const costDetailLabels = [
    t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.detail.costInfo'),
    t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.detail.costType'),
    t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.detail.value')
  ];

  const costFeeDetailLabels = [
    t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.detailFees.costInfo'),
    t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.detailFees.costType'),
    t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.detailFees.value')
  ];

  const salesInfoTable = [
    {
      title: t('Flow.GuidedTab.ReviewStep.sections.customerSales.detail.12mr'),
      value: formatToCurrencyWithSymbol(salesInfo.estimated_revenue_twelve_months)
    },
    {
      title: t('Flow.GuidedTab.ReviewStep.sections.customerSales.detail.agpm'),
      value: `${salesInfo.average_gross_profit || 0}%`
    },

    {
      title: t('Flow.GuidedTab.ReviewStep.sections.customerSales.detail.ahr'),
      value: formatToCurrencyWithSymbol(salesInfo.additional_halo_revenue)
    },
    {
      title: t('Flow.GuidedTab.ReviewStep.sections.customerSales.detail.gpmhr'),
      value: `${salesInfo.gross_profit_margin_percentage_halo_revenue || 0}%`
    }
  ];
  const salesInfoTableDC = [
    {
      title: t('Flow.GuidedTab.ReviewStep.sections.customerSales.dc.ccpu12m'),
      value: formatToCurrencyWithSymbol(salesInfo.estimated_revenue_twelve_months)
    }
  ];

  const handleEdit = useCallback((stepNum: number) => {
    routeTo(stepNum);
  }, []);

  const handleApproved = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setApproved(event.target.checked));
  };

  const getLeftRowCards = () => {
    if (flowType === TabsInterface.roi) {
      return (
        <>
          <ReviewMachineSelectionDetails
            theme={theme}
            handleEdit={handleEdit}
            title={t('Flow.ROITab.ReviewStep.sections.msd.title') || ''}
            editTitle={t('Flow.GuidedTab.ReviewStep.edit') || ''}
            machines={guidedState.machine_selection_roi}
            labels={[
              t('Flow.ROITab.ReviewStep.sections.msd.detail.mt'),
              t('Flow.ROITab.ReviewStep.sections.msd.detail.mqt'),
              t('Flow.ROITab.ReviewStep.sections.msd.detail.aqt'),
              t('Flow.ROITab.ReviewStep.sections.msd.detail.comments')
            ]}
          />
          <ReviewCost
            theme={theme}
            handleEdit={handleEdit}
            title={t('Flow.GuidedTab.CostInfoStep.title') || ''}
            aciTitle={t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.title') || ''}
            editTitle={t('Flow.GuidedTab.ReviewStep.edit') || ''}
            costInfoDetails={costInfoDetails}
            cmHosting={!!guidedState.cm_hosting_data}
            cmHostingLabel={t('Flow.GuidedTab.ProductInfoStep.cm_hosting_data')}
            withCard
          />
        </>
      );
    }
    return (
      <>
        <ReviewCriteria
          theme={theme}
          criteriaList={criteriaList}
          handleEdit={handleEdit}
          title={t('Flow.GuidedTab.ReviewStep.sections.criteria.title')}
          editTitle={t('Flow.GuidedTab.ReviewStep.edit')}
        />
        <ReviewCategoryAndArea
          theme={theme}
          items={flowInventoryData}
          areaDetails={areaDetails}
          selectedMachines={{
            title: t('Flow.GuidedTab.ResultsStep.CustomerViewSection.excelStrings.machineSelection'),
            value: guidedState.machine_selection
          }}
          machineDetail={machineDetail}
          handleEdit={handleEdit}
          title={t('Flow.GuidedTab.ProductInfoStep.stepLabel')}
          areaTitle={t('Flow.GuidedTab.ReviewStep.sections.areaDetails.title')}
          itemInfoTitle={t('Flow.GuidedTab.ReviewStep.sections.itemInfo.title')}
          itemDetailLabels={itemDetailLabels}
          editTitle={t('Flow.GuidedTab.ReviewStep.edit')}
          flowType={flowType}
        />
      </>
    );
  };

  const getRightRowCards = () => {
    const title =
      flowType === TabsInterface.roi
        ? t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.title')
        : t('Flow.GuidedTab.CostInfoStep.title');
    const aciTitle =
      flowType === TabsInterface.roi ? '' : t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.title');
    const withCostInfo = !(flowType === TabsInterface.roi);
    return (
      <>
        <ReviewAdditionalCostInfo
          theme={theme}
          additionalCosts={additionalCosts}
          additionalFees={additionalFees}
          handleEdit={handleEdit}
          title={title}
          aciTitle={aciTitle}
          costDetailLabels={costDetailLabels}
          costFeeDetailLabels={costFeeDetailLabels}
          editTitle={t('Flow.GuidedTab.ReviewStep.edit')}
          currency={currency}
          costInfoDetails={costInfoDetails}
          cmHosting={!!guidedState.cm_hosting_data}
          cmHostingLabel={t('Flow.GuidedTab.ProductInfoStep.cm_hosting_data')}
          subTitles={[
            t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.costs'),
            t('Flow.GuidedTab.ReviewStep.sections.additionalCostInfo.fees')
          ]}
          isDirectCustomer={isDirectCustomer}
          withCostInfo={withCostInfo}
          flowType={flowType}
        />
        <ReviewCustomerSalesOpportunity
          theme={theme}
          salesInfo={isDirectCustomer ? salesInfoTableDC : salesInfoTable}
          handleEdit={handleEdit}
          title={
            isDirectCustomer
              ? t('Flow.GuidedTab.ReviewStep.sections.customerSales.dc.pu')
              : t('Flow.GuidedTab.ReviewStep.sections.customerSales.title')
          }
          editTitle={t('Flow.GuidedTab.ReviewStep.edit')}
          flowType={flowType}
        />
        <FormControlLabel
          value="end"
          control={<Checkbox checked={approved} onChange={handleApproved} />}
          label={t('Flow.GuidedTab.ReviewStep.ca')}
          labelPlacement="end"
        />
      </>
    );
  };

  return (
    <Box>
      <Grid container spacing={2} direction="column">
        <Grid item xs sx={{ mb: 1 }}>
          <Typography variant="h5" component="div" fontSize="18px">
            {t('Flow.GuidedTab.ReviewStep.title') || 'Review information and confirm entries and selections.'}
          </Typography>
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Typography variant="body1" component="div" fontSize="16px">
              {t('Flow.GuidedTab.ReviewStep.subTitle.part1') || 'To make a change, press the edit'}
            </Typography>
            &nbsp;
            <EditIcon sx={{ color: theme.palette.action.active, height: 15, width: 15 }} />
            &nbsp;
            <Typography variant="body1" component="div" fontSize="16px" sx={{ fontWeight: '700' }}>
              {t('Flow.GuidedTab.ReviewStep.subTitle.part2') || 'button'}
            </Typography>
            &nbsp;
            <Typography variant="body1" component="div" fontSize="16px">
              {t('Flow.GuidedTab.ReviewStep.subTitle.part3') || 'OR'}
            </Typography>
            &nbsp;
            <Typography
              variant="body1"
              component="div"
              fontSize="16px"
              color={theme.palette.success.dark}
              sx={{ fontWeight: '700' }}
            >
              {t('Flow.GuidedTab.ReviewStep.subTitle.part4') || 'check the checkbox'}
            </Typography>
            &nbsp;
            <Typography variant="body1" component="div" fontSize="16px">
              {t('Flow.GuidedTab.ReviewStep.subTitle.part5') || 'to approve and confirm all the information.'}
            </Typography>
          </Stack>
          <Stack spacing={{ xs: 1, sm: 2 }} direction="row" sx={{ mt: 3 }}>
            <Stack sx={{ flex: 1 }} spacing={2}>
              {getLeftRowCards()}
            </Stack>
            <Stack sx={{ flex: 1 }} spacing={2}>
              {getRightRowCards()}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewGuided;
