import { InfoOutlined } from '@mui/icons-material';
import {
  Backdrop,
  Badge,
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  styled,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useAppDispatch } from 'app/hooks';
import { Criteria, ResponseCriteriaList } from 'features/guided/guidedModel';
import {
  fetchAllCriteriaList,
  selectAllCriteriaList,
  selectAllCriteriaListLoading,
  selectedCriteriaList,
  selectGuidedFlowSettings,
  setSelectedCriteriaListGuided
} from 'features/guided/guidedSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const StyledItemText = styled(ListItemText)(() => ({
  marginLeft: '15px',
  // color: theme.palette.text.secondary,
  '& .MuiListItemText-primary': {
    // fontSize: '13px'
  }
}));

const CriteriaList = () => {
  const { t } = useTranslation();
  const markedCriteriaList: Criteria[] = useSelector(selectedCriteriaList);
  const settings = useSelector(selectGuidedFlowSettings);
  const theme = useTheme();
  const loading = useSelector(selectAllCriteriaListLoading);
  const dispatch = useAppDispatch();
  const [criteriaColumn1, setCriteriaColumn1] = useState<ResponseCriteriaList[]>([]);
  const [criteriaColumn2, setCriteriaColumn2] = useState<ResponseCriteriaList[]>([]);
  const criteriaList = useSelector(selectAllCriteriaList);

  const settingCriterias = settings?.criterias || [];
  const criteriaQuery = [...markedCriteriaList, ...settingCriterias].join(',');

  useEffect(() => {
    const URL = settingCriterias?.length ? `?criteriaKeys=${criteriaQuery}` : ``;
    dispatch(fetchAllCriteriaList(URL));
  }, []);

  const handleCriteriaOptionClick = (criteria: Criteria) => {
    dispatch(setSelectedCriteriaListGuided(criteria));
  };

  const height = 'calc(100vh - 420px)';

  useEffect(() => {
    if (criteriaList && criteriaList.length > 0) {
      const totalCriteria = criteriaList.length;
      const maxItemsPerColumn = Math.ceil(totalCriteria / 2);
      const column1 = criteriaList.slice(0, maxItemsPerColumn);
      const column2 = criteriaList.slice(maxItemsPerColumn);
      setCriteriaColumn1(column1);
      setCriteriaColumn2(column2);
    }
  }, [criteriaList]);

  const generateListItems = (criteriaList: ResponseCriteriaList[]) => {
    return criteriaList.map((item) => (
      <List key={item.criteria_key}>
        <ListItemText>
          <ListItem
            onClick={() => handleCriteriaOptionClick(item.criteria_key)}
            sx={{
              height: '45px',
              boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
              padding: '0 30px 0 15px',
              cursor: 'pointer'
            }}
          >
            <Tooltip title={item.description}>
              <InfoOutlined sx={{ color: theme.palette.text.secondary, fontSize: '18px' }} />
            </Tooltip>
            <StyledItemText>
              <Typography>
                {item.criteria_name}
                <span style={{ color: theme.palette.text.secondary, marginLeft: '8px' }}>
                  {item.criteria_type.join(', ')}
                </span>
              </Typography>
            </StyledItemText>
            {markedCriteriaList.indexOf(item.criteria_key) >= 0 && (
              <Box>
                <Badge badgeContent={markedCriteriaList.indexOf(item.criteria_key) + 1} color="primary" />
              </Box>
            )}
          </ListItem>
        </ListItemText>
      </List>
    ));
  };

  return (
    <Box>
      <Box>
        <Typography variant="h6">{t('Flow.GuidedTab.CriteriaStep.title') || 'Select End Customer Criteria'}</Typography>
        <Typography sx={{ fontSize: '13px', color: theme.palette.text.secondary }}>
          {t('Flow.GuidedTab.CriteriaStep.subTitle') ||
            'Each criteria will have a quantitative value that the user can adjust.'}{' '}
          <b>
            {t('Flow.GuidedTab.CriteriaStep.boldSubtitle') ||
              'The order in which you select should be from most important to least important.'}
          </b>
        </Typography>
      </Box>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <Grid container spacing={2} sx={{ maxHeight: height, overflowY: 'auto', paddingRight: '5px', pb: 2 }}>
          <Grid item xs={6}>
            {generateListItems(criteriaColumn1)}
          </Grid>
          <Grid item xs={6}>
            {generateListItems(criteriaColumn2)}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CriteriaList;
