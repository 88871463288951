import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ReducerKeys } from 'app/reducerKeys';
import { RootState } from 'app/store';
import { fetchCribSettingsByOrgId } from 'features/additionalCostFees/costFeesSlice';
import { ApiGetCriteriaList } from 'features/guided/guidedAPI';
import { Criteria } from 'features/guided/guidedModel';
import { CriteriaSettings } from './criteriaModel';

const initialState: CriteriaSettings = {
  criterias: [],
  selectedCriterias: []
};

export const fetchAllCriterias = createAsyncThunk(
  `${ReducerKeys.criteriaSettings}/fetchAllCriterias`,
  async () => {
    const { data, error } = await ApiGetCriteriaList();
    if (data) return { value: data };
    return { value: data, error };
  }
);

export const criteriaSettingsSlice = createSlice({
  name: ReducerKeys.criteriaSettings,
  initialState,
  reducers: {
    setSelectedCriteriasSettings: (state, action: PayloadAction<Criteria[]>) => {
      state.selectedCriterias = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchAllCriterias.fulfilled, (state, action) => {
        const data = action.payload.value;
        if(data) state.criterias = data;
      }).addCase(fetchCribSettingsByOrgId.fulfilled, (state, action) => {
        const settings = action.payload;
        if(!settings) return
        if(settings.criterias){
          state.selectedCriterias = settings.criterias
      }})
  }
});
export const { setSelectedCriteriasSettings } = criteriaSettingsSlice.actions;
export const selectAllCriterias = (state: RootState) => state.criteriaSettings.criterias;
export const selectSelectedCriteriasSettings = (state: RootState) => state.criteriaSettings.selectedCriterias;
export const { reducer: criteriaSettingsReducer, name: criteriaSettingsKey } = criteriaSettingsSlice;
