import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface GridIconButtonProps {
  icon: React.ReactNode;
  label: string;
  tooltip?: React.ReactNode; // Change the type to React.ReactNode
  onClick?: () => void;
  disabled?: boolean;
}

const GridIconButton: React.FC<GridIconButtonProps> = ({ icon, label, tooltip, onClick, disabled }) => {

  
  return (
    <Tooltip title={tooltip || label} placement="top">
      <IconButton
        size="small"
        role="menuitem"
        aria-label={label}
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default GridIconButton;
