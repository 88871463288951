import { Avatar, Box, Chip, ChipProps, Stack, styled, Toolbar, Typography, useTheme } from '@mui/material';
import { ResponseAreaObject } from 'features/guided/guidedModel';
import { selectedAreaGuided, selectSolutionNameGuided } from 'features/guided/guidedSlice';
import ReadMore from 'features/readMore';
import { useSelector } from 'react-redux';

const CustomChip = styled(Chip)<ChipProps>(() => ({
  position: 'relative',
  fontSize: '13px'
}));

interface RightToolbarInterface {
  areaName?: string;
  selectedAreaProp?: ResponseAreaObject | null;
}
const RightToolbar = ({ areaName, selectedAreaProp }: RightToolbarInterface) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  let selectedArea = useSelector(selectedAreaGuided);
  selectedArea = selectedAreaProp || selectedArea;
  let solutionName = useSelector(selectSolutionNameGuided);
  solutionName = areaName || solutionName;

  if (!selectedArea) return null;
  return (
    <Toolbar
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        backgroundColor: theme.palette.primary.light,
        justifyContent: 'space-between',
        position: 'relative'
        // minWidth: '630px'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          sx={{
            background: theme.palette.primary.main,
            width: '36px',
            height: '36px',
            fontSize: '14px'
          }}
        >
          {selectedArea?.org_name
            .slice(0, selectedArea?.org_name.indexOf('-'))
            .trim()
            .split(' ')
            .map((n) => n[0])
            .join('')}
        </Avatar>
        <Typography
          variant='body1'
          fontSize={'16px'}
          sx={{
            color: mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
            marginLeft: '10px',
            mr: 2
          }}
        >
          <ReadMore showRead={false} text={selectedArea?.root_org_name || 'N/A'} charactersToLoad={20} />
        </Typography>
      </Box>
      <Stack spacing={1} direction="row">
        <CustomChip label={`Solution: ${solutionName}`} variant="filled" color="primary" />
        <CustomChip label={`Location: ${selectedArea?.location_name || 'A1'}`} />
        <CustomChip label={`Area: ${selectedArea?.area_name || 'Breakroom 3'}`} />
      </Stack>
    </Toolbar>
  );
};

export default RightToolbar;
