import { Save } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
  useTheme
} from '@mui/material';
import { AppDispatch } from 'app/store';
import { selectStep } from 'features/stepper/stepperSlice';
import { ceil } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { machine_selected_automated, getAreaPayload } from '../guided-constants';
import { ApiCreateAreaGuided } from '../guidedAPI';
import { Criteria, FlowType, ItemInfoState, MachineSelection, RestockingFrequencyInterface } from '../guidedModel';

import {
  fetchAllCribList,
  selectAccuPortMachine,
  selectExpressMachine,
  selectMachineSelectionList,
  selectRfidMachine,
  setMachineSettingsOptions
} from 'features/machineSettings/machineSlice';
import { useTranslation } from 'react-i18next';
import { ItemInfoKeys } from '../guidedApiKeys';
import {
  fetchAreaAsync,
  selectAreasGuided,
  selectGuidedFlowSettings,
  selectItemsInfoGuided,
  selectMachineSelectionGuided,
  selectedAccuPortMachineGuided,
  selectedAreaGuided,
  selectedCriteriaList,
  selectedExpressMachineGuided,
  selectedRfidMachineGuided,
  setAccuPortMachineGuided,
  setAccuPortMachineGuidedDefault,
  setAreasGuided,
  setExpressMachineGuided,
  setExpressMachineGuidedDefault,
  setItemInfoGuided,
  setMachineSelection,
  setRfidMachineGuided,
  setRfidMachineGuidedDefault,
  setSelectedAreaGuided
} from '../guidedSlice';
import RecommendedMainMachinesComp from './recommendedMainMachinesField';
import { useParams } from 'react-router-dom';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';

const InfoDetailsGuided = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const itemInfo = useSelector(selectItemsInfoGuided);
  const areasGuided = useSelector(selectAreasGuided);
  const selectedArea = useSelector(selectedAreaGuided);
  const criteriaList = useSelector(selectedCriteriaList);
  const activeStep = useSelector(selectStep);
  const rfid = useSelector(selectedRfidMachineGuided);
  const express = useSelector(selectedExpressMachineGuided);
  const accuPort = useSelector(selectedAccuPortMachineGuided);
  const rfidRootUsr = useSelector(selectRfidMachine);
  const expressRootUsr = useSelector(selectExpressMachine);
  const accuPortRootUsr = useSelector(selectAccuPortMachine);
  const settings = useSelector(selectGuidedFlowSettings);
  const allCribData = useSelector(selectMachineSelectionList);
  const selectedValues = useSelector(selectMachineSelectionGuided);
  const { type } = useParams();

  const defaultMachineOptions = allCribData.map((crib) => crib.crib_id);
  const options =
    type === TabsInterface.automated
      ? settings && settings.cribs
        ? settings.cribs.filter((value) => machine_selected_automated.includes(value))
        : defaultMachineOptions
      : settings?.cribs || defaultMachineOptions;

  const disabled = activeStep === 3 ? true : false;
  const [areaName, setAreaName] = useState('');
  const [loading, setLoading] = useState<string | null>();
  const [recommendedMainMachines, setRecommendedMainMachines] = useState<number>(0);

  const isAllSelected = selectedValues.length === options.length;

  const CustomFormStyled = styled(FormControlLabel)(() => ({
    '& .MuiTypography-body1': {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary
    }
  }));

  useEffect(() => {
    if (!disabled) {
      dispatch(fetchAllCribList());
    }
  }, []);

  useEffect(() => {
    if (settings && !disabled) {
      dispatch(setMachineSettingsOptions(settings));

      if (!rfidRootUsr) {
        dispatch(setRfidMachineGuidedDefault(rfidRootUsr));
      }

      if (!expressRootUsr) {
        dispatch(setExpressMachineGuidedDefault(expressRootUsr));
      }

      if (!accuPortRootUsr) {
        dispatch(setAccuPortMachineGuidedDefault(accuPortRootUsr));
      }
    }
  }, [settings, rfidRootUsr, expressRootUsr, accuPortRootUsr]);

  useEffect(() => {
    if (itemInfo?.items_to_start_the_shift && settings?.machine_calculation_params) {
      const recommendedMainMs =
        (itemInfo?.items_to_start_the_shift * settings?.machine_calculation_params.average_time_per_transaction) /
        settings?.machine_calculation_params.max_time_start_shift_area;
      setRecommendedMainMachines(ceil(recommendedMainMs));
    }
  }, [itemInfo?.items_to_start_the_shift, settings]);

  // 1. FETCH AREA ON COMPONENT LOAD
  useEffect(() => {
    if (selectedArea) {
      const payload = {
        root_org_id: selectedArea.root_org_id,
        location_id: selectedArea.location_id,
        org_id: selectedArea.org_id
      };
      dispatch(fetchAreaAsync(payload));
    }
  }, []);

  // FUNC 1: HANDLE AREA SELECT-COMP CHANGE
  const handleAreaChange = (e: SelectChangeEvent) => {
    const clickedArea = areasGuided?.filter((item) => item.id === e.target.value);
    clickedArea?.length && dispatch(setSelectedAreaGuided(clickedArea[0]));
  };

  // FUNC 2: SAVE NEW AREA AND SELECT AREA RECEIVED AS RESPONSE
  const saveArea = async () => {
    setLoading('area');
    if (!selectedArea) return alert('something went wrong!');
    try {
      const { data } = await ApiCreateAreaGuided(getAreaPayload(areaName, selectedArea));
      if (areasGuided && data) {
        dispatch(setAreasGuided([data, ...areasGuided]));
      } else if (data) {
        dispatch(setAreasGuided([data]));
      }
      data && dispatch(setSelectedAreaGuided(data));
      setAreaName('');
      setLoading(null);
    } catch (err) {
      setLoading(null);
    }
  };

  // FUNC 3: HANDLE ALL INPUTS CHANGE
  const handleItemInfoChange = (e: ChangeEvent<HTMLInputElement>, max: number) => {
    const { name, value } = e.target;
    const numberedValue = Number(value);
    const dataParam = { name, value: numberedValue > max ? max : numberedValue };
    dispatch(setItemInfoGuided(dataParam));
  };

  // FUNC 4: HANDLE RESTOCKING SELECT CHANGE
  const handleRestockingChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    const dataParam = { name, value };
    dispatch(setItemInfoGuided(dataParam));
  };

  type SelectMachineSelection = MachineSelection | 'select-all';
  const handleSelect = (event: SelectChangeEvent<SelectMachineSelection[]>) => {
    const value = event.target.value as SelectMachineSelection[];
    if (value.findIndex((v) => v === 'select-all') >= 0) {
      if (isAllSelected) {
        handleDeselectAll();
      } else {
        handleSelectAll();
      }
      return;
    }
    const filteredVal = value.filter((v) => v !== 'select-all') as MachineSelection[];
    if (settings?.cribs) {
      const machineSeletion = filteredVal.filter((item) => settings.cribs.includes(item));
      dispatch(setMachineSelection(machineSeletion));
    }
  };

  type InfoFields = {
    label: string;
    key: keyof ItemInfoState;
    sx?: number;
    required: boolean;
    max?: number;
    defaultValue?: number;
  };

  const itemsInfoInputs: InfoFields[] = [
    {
      label: `${
        t('Flow.GuidedTab.ProductInfoStep.itemsToStartShiftInputLabel') || '# Issue Transactions To Start Shift'
      }`,
      key: ItemInfoKeys.items_to_start_the_shift,
      sx: 6,
      required: true
    },
    {
      label: `${t('Flow.GuidedTab.ProductInfoStep.capacityForGrowthInputLabel') || 'Capacity for Growth %'}`,
      key: ItemInfoKeys.capacity_for_growth,
      sx: 12,
      required: false,
      max: 50,
      defaultValue: 0
    },
    {
      label: `${t('Flow.GuidedTab.ProductInfoStep.restockingFrequencySelectLabel') || 'Restocking Frequency'}`,
      key: ItemInfoKeys.restocking_frequency,
      required: true
    },
    {
      label: `${t('Flow.GuidedTab.ProductInfoStep.safetyStockInputLabel') || 'Safety Stock %'}`,
      key: ItemInfoKeys.safety_stock_percentage,
      sx: 12,
      required: false,
      defaultValue: 0
    },
    {
      label: `${t('Flow.GuidedTab.ProductInfoStep.areaSize') || 'Area Size'}`,
      key: ItemInfoKeys.area_size,
      sx: 12,
      required: false,
      defaultValue: 0
    }
  ];

  const handleSelectAll = () => {
    let allOptions;

    if (type === TabsInterface.automated) {
      allOptions = (settings?.cribs || defaultMachineOptions).filter((machine) =>
        machine_selected_automated.includes(machine)
      );
    } else {
      allOptions = settings?.cribs || defaultMachineOptions;
    }

    dispatch(setMachineSelection(allOptions));
  };

  const handleDeselectAll = () => {
    dispatch(setMachineSelection([]));
  };

  const formatSelectedValues = (eachItem: string[]): string[] => {
    return eachItem.map((str) => {
      return str
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    });
  };
  const formatMachineSelection = (value: string) => {
    return value.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, (match) => {
      return match.toUpperCase();
    });
  };

  return (
    <Box sx={{ pt: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="areaguided-select-label" required={true}>
          {t('Flow.GuidedTab.ProductInfoStep.assignAreaSelectLabel')}
        </InputLabel>
        <Select
          labelId="areaguided-select-label"
          value={selectedArea ? selectedArea.id : ''}
          label="Assign Area"
          onChange={handleAreaChange}
          disabled={disabled}
          size="medium"
          error={!(selectedArea && selectedArea.id)}
        >
          {areasGuided?.map((item, i) => (
            <MenuItem value={item.id} key={item._id + i}>
              {item.area_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedArea?.id === 'manual' && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            fullWidth
            size="medium"
            placeholder="Enter area name"
            label="Enter area name"
            sx={{ paddingRight: '0' }}
            value={areaName}
            disabled={disabled}
            required={true}
            error={!areaName.trim()}
            onChange={(e) => setAreaName(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton color="primary" aria-label="add to shopping cart">
                  {!loading ? <Save onClick={() => saveArea()} /> : <CircularProgress size={20} />}
                </IconButton>
              )
            }}
          />
        </FormControl>
      )}
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {itemsInfoInputs.map((item, i) => {
          const key: keyof ItemInfoState = item.key;
          const value = itemInfo[key];
          if (item.key === ItemInfoKeys.area_size && !criteriaList.includes(Criteria.floor_space)) return null;
          if (item.key === ItemInfoKeys.restocking_frequency)
            return (
              <Grid item xs={12} key={item.key + i}>
                <FormControl fullWidth>
                  <InputLabel id={`restocking-select-label${item.label}`} required={item.required}>
                    {item.label}
                  </InputLabel>
                  <Select
                    size="medium"
                    id={`restocking-select-label${item.label}`}
                    value={itemInfo[ItemInfoKeys.restocking_frequency]}
                    label={item.label}
                    name="restocking_frequency"
                    onChange={handleRestockingChange}
                    disabled={disabled}
                    required={item.required}
                    error={!(itemInfo && itemInfo['restocking_frequency'])}
                  >
                    {Object.entries(RestockingFrequencyInterface).map(([key, value]) => (
                      <MenuItem value={value} key={value + key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            );
          if (item.key === ItemInfoKeys.items_to_start_the_shift)
            return (
              <RecommendedMainMachinesComp
                index={i}
                key={item.key}
                item={item}
                value={value}
                recommendedMainMachines={recommendedMainMachines}
                disabled={disabled}
                handleItemInfoChange={handleItemInfoChange}
              />
            );
          return (
            <Grid item xs={item.sx || 6} key={item.key + i}>
              <FormControl fullWidth key={item.label + 'guided-info' + i}>
                <TextField
                  name={item.key}
                  size="medium"
                  value={value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleItemInfoChange(e, Number(item.max))}
                  id={item.label + 'info'}
                  inputProps={{ pattern: '[0-9]*', min: 0, max: item.max, step: 'any' }}
                  label={item.label}
                  required={item.required}
                  error={item.required && !value}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          {settings && settings?.cribs.length > 0 ? (
            <FormControl fullWidth>
              <InputLabel
                id="machine-selection-label"
                required={true}
                error={!(selectedValues.length > 0 || express || rfid || accuPort)}
              >
                {t('Flow.GuidedTab.ProductInfoStep.machineSelectionLabel')}
              </InputLabel>
              <Select
                labelId="machine-selection-label"
                id="machine-selection"
                label="Machine selection"
                multiple
                disabled={disabled}
                value={
                  type === TabsInterface.automated
                    ? selectedValues.filter((value) => options.includes(value))
                    : selectedValues
                }
                onChange={handleSelect}
                renderValue={(selected) =>
                  selectedValues && selectedValues?.length > 0 ? formatSelectedValues(selected)?.join(', ') : ''
                }
              >
                {options.length > 1 && (
                  <MenuItem key="select-all" value="select-all">
                    <Checkbox indeterminate={!isAllSelected && selectedValues.length > 0} checked={isAllSelected} />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                )}
                {type === TabsInterface.automated
                  ? machine_selected_automated
                      .filter((option) => options.includes(option)) // Filter only machines present in Settings crib
                      .map((option) => (
                        <MenuItem key={option} value={option as MachineSelection}>
                          <Checkbox checked={selectedValues.includes(option)} />
                          <ListItemText primary={formatMachineSelection(option)} />
                        </MenuItem>
                      ))
                  : options.map((option) => (
                      <MenuItem key={option} value={option as MachineSelection}>
                        <Checkbox checked={selectedValues.includes(option)} />
                        <ListItemText primary={formatMachineSelection(option)} />
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          {type === TabsInterface.automated && options.length === 0 && (
            <FormControl required={true} error={true}>
              <FormLabel component={'legend'} style={{ color: 'red' }}>
                {t('Flow.GuidedTab.ProductInfoStep.warningMachineSelectionAutomated')}
              </FormLabel>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12}>
          {type !== TabsInterface.automated && FlowType.Guided && (
            <FormControl required={true} error={!(selectedValues.length > 0 || express || rfid || accuPort)}>
              {!(selectedValues.length > 0 || express || rfid || accuPort) && (
                <FormLabel component={'legend'}>
                  {t('Flow.GuidedTab.ProductInfoStep.warningMachineSelection')}
                </FormLabel>
              )}
              <FormGroup>
                {expressRootUsr && (
                  <CustomFormStyled
                    disabled={disabled}
                    control={
                      <Checkbox
                        checked={express}
                        onChange={(e) => dispatch(setExpressMachineGuided(e.target.checked))}
                      />
                    }
                    label={t('Flow.GuidedTab.ProductInfoStep.expressMachinesTitle')}
                  />
                )}
                {rfidRootUsr && (
                  <CustomFormStyled
                    disabled={disabled}
                    control={
                      <Checkbox checked={rfid} onChange={(e) => dispatch(setRfidMachineGuided(e.target.checked))} />
                    }
                    label={t('Flow.GuidedTab.ProductInfoStep.rfidMachinesTitle')}
                  />
                )}
                {accuPortRootUsr && (
                  <CustomFormStyled
                    disabled={disabled}
                    control={
                      <Checkbox
                        checked={accuPort}
                        onChange={(e) => dispatch(setAccuPortMachineGuided(e.target.checked))}
                      />
                    }
                    label={t('Flow.GuidedTab.ProductInfoStep.accuportMachinesTitle')}
                  />
                )}
              </FormGroup>
            </FormControl>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoDetailsGuided;
