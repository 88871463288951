import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import ProtectedLayout from 'components/protected-layout/ProtectedLayout';
import MyLocation from 'features/myLocation/myLocation';
import Feedback from 'features/userFeedbacks/feedback';
import ErrorPage from 'components/error/Error';
import ManageSettings from 'pages/setttings/ManageSettings';
import { CUSTOMER_SUPPORT_ORG_ID } from './constants';
import CustomerSupportRoute from 'components/custom-routes/CustomerSupportRoutes';
import ToolboxDesign from 'pages/toolbox/toolbox-design';


const HomePage = lazy(() => import('pages/home/Home'));
const DashboardPage = lazy(() => import('pages/dashboard/Dashboard'));

const isLocal = process.env.NODE_ENV === 'development';

export const router = createBrowserRouter([
  {
    element: <ProtectedLayout />,
    errorElement: isLocal ? null : <ErrorPage />,
    children: [
      {
        path: '/flow/:flowId/:type/:stepName',
        element: <HomePage />
      },
      {
        path: '/',
        element: <DashboardPage />
      },
      {
        path: '/toolbox',
        element: <ToolboxDesign/>
      },
      {
        path: '/locations',
        element: <MyLocation />
      },
      {
        path: '/settings',
        element: <CustomerSupportRoute element={<ManageSettings />} isSettings />,
      },
      {
        path: '/feedback',
        element: (
          <CustomerSupportRoute
            element={<Feedback />}
            orgId={CUSTOMER_SUPPORT_ORG_ID}
            isSettings={false}
          />
        )
      },
    ]
  }
]);
