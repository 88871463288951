import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useAppSelector } from 'app/hooks';
import ConfirmMui from 'components/confirm-box/confirm.mui';
import Loader from 'components/loader/Loader';
import {
  selectAllowShare,
  selectCostSliderDirty,
  selectCribConfig,
  selectGuidedState,
  selectIsEdited
} from 'features/guided/guidedSlice';
import { roiModalTexts } from 'features/stepperRoi/stepperRoiTexts';
import { selectUserId } from 'features/user/userSlice';
import useAutomated from 'hooks/automated-hook';
import ErrorDialogue from 'pages/review-guided/error-dialogue';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StepperAutomatedPages } from './stepperAutomatedModel';

interface Stepper {
  data: Array<DataInterface>;
  minHeight?: string;
  loading?: boolean;
}
interface DataInterface {
  label: string;
  component: React.ReactNode;
  skippable?: boolean;
  showOptional?: boolean;
  name: StepperAutomatedPages;
}
const pages: string[] = Object.values(StepperAutomatedPages);

export default function AutomatedLinearStepper(props: Stepper) {
  const [confirm, setConfirm] = useState(false);
  const {
    enabled,
    handleNext,
    handleBack,
    loading: nextLoading,
    handleFinish,
    handleRestore,
    handleDone
  } = useAutomated();
  const { data, minHeight, loading }: Stepper = props;

  const { t } = useTranslation();

  const { stepName = StepperAutomatedPages.CriteriaAutomated } = useParams();
  const isShared = useAppSelector(selectAllowShare);
  const activeStep = pages.indexOf(stepName);
  const configs = useAppSelector(selectCribConfig);
  const isCostSliderDirty = useAppSelector(selectCostSliderDirty);
  const edited = useAppSelector(selectIsEdited);
  const guidedState = useAppSelector(selectGuidedState);
  const userId = useAppSelector(selectUserId);
  const ownerId = guidedState.user_id;
  const loggedInUserIsCreator = ownerId === userId;

  const navigate = useNavigate();
  const isAPIError = configs?.Error;

  // if solution type is shared and user is creator of the solution only then allow user to save the solution
  const isUserAllowedToSave = loggedInUserIsCreator ? true : !isShared;

  const handleClose = () => {
    if (!isUserAllowedToSave) {
      navigate('/');
      return;
    }
    // CASE: 1 IF SOLUTION IS COMPLETED AND EDITED - OFFER RESET
    if (guidedState.completed && edited) return setConfirm(true);

    // CASE: 2 IF SOLUTION IS DIRTY OR, SLIDERS ARE PLAYED WTH OPEN POPUP
    if (edited || isCostSliderDirty) return setConfirm(true);
    return navigate('/');
  };
  const handleDisagree = () => {
    // CASE: 1 IF SOLUTION WAS COMPLETED BEFORE CHANGES ARE MADE AND CLOSE IS CLICKED ASK TO CONFIRM --
    // A. AGREE - SVAE SOLUTION CHANGES SOLUTION MARKED AS INCOMPLETE
    // B. DISAGREE - RESTORES ORIGINAL DATA FROM COMPLETED SOLUTION & SOLUTION REMAINS COMPLETED
    if (guidedState.completed) return handleRestore();

    // IF SOLUTION IS NOT COMPLETED
    // A. AGREE -  SAVE SOLUTION AND REDIRCT - HANDLEDONE() IS USED DIRECTLY
    // B. DISAGREE - HIDE POPUP AS BELOW
    return setConfirm(false);
  };

  const isGoBackDisabled =
    stepName === StepperAutomatedPages.CriteriaAutomated
      ? true
      : loggedInUserIsCreator
      ? false
      : isShared && guidedState.completed
      ? true
      : activeStep === 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {data.map((step) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Fragment>
        <Box
          sx={{
            padding: '10px 10px 0 10px',
            marginTop: '10px',
            minHeight: minHeight,
            boxSizing: 'border-box',
            maxHeight: minHeight,
            overflowY: 'auto',
            position: 'relative'
          }}
        >
          {loading ? (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <Loader />
            </Box>
          ) : (
            data[activeStep].component
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          {!isGoBackDisabled && (
            <Button
              color="primary"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1, fontFamily: 'Helvetica', fontWeight: '700', fontSize: '14px', textTransform: 'capitalize' }}
            >
              {t('Common.goBackLabel') || 'Go Back'}
            </Button>
          )}
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            sx={{
              mr: 1,
              textTransform: 'capitalize',
              fontFamily: 'Helvetica',
              fontWeight: '700',
              fontSize: '14px'
            }}
            variant="outlined"
            onClick={handleClose}
          >
            {t('Common.closeLabel') || 'Close'}
          </Button>
          {nextLoading ? (
            <Button>
              <CircularProgress size={25} thickness={8} />{' '}
            </Button>
          ) : (
            isUserAllowedToSave && (
              <Button
                sx={{ textTransform: 'capitalize', fontFamily: 'Helvetica', fontWeight: '700', fontSize: '14px' }}
                variant="contained"
                onClick={activeStep === data.length - 1 ? handleFinish : handleNext}
                disabled={!enabled}
              >
                {activeStep === data.length - 1
                  ? `${t('Common.finishSaveLabel') || 'Finish & Save'}`
                  : `${t('Common.nextLabel') || 'Next'}`}
              </Button>
            )
          )}
        </Box>
      </Fragment>
      {isAPIError && <ErrorDialogue error={configs.Error} />}
      <ConfirmMui
        open={confirm}
        agree={handleDone}
        disAgree={handleDisagree}
        setOpen={setConfirm}
        texts={roiModalTexts(t, guidedState.completed)}
      />
    </Box>
  );
}
