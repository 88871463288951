import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import Box from '@mui/material/Box';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
//import mixpanelConfig from 'app/mixpanel';
import mixpanel from 'mixpanel-browser';

import { AxiosInterceptorNavigate } from 'app/AxiosInterceptorNavigate';
// import Header from 'components/header/Header';
import { msalScope } from 'app/authConfig';
import axiosConfig from 'app/axiosConfig';
import { useAppDispatch } from 'app/hooks';
import LoadingContainer from 'components/loading-container/LoadingContainer';
import { messages } from 'constants/message';
import { AlertType } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { CmUserLanguage, UserDetailResponseModal } from 'features/user/userModel';
import { setUserLoginData } from 'features/user/userSlice';

const ProtectedLayout = () => {
  const [isLoading, setIsLoadig] = useState(true);
  const [token, setToken] = useState('');

  const { instance, inProgress, accounts } = useMsal();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  // initially value is false and then set true
  // const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const handleAuthentication = async () => {
      const accessTokenRequest = {
        scopes: msalScope,
        account: accounts[0]
      };
      if (!accounts[0]) {
        try {
          return await instance.loginRedirect(accessTokenRequest);
        } catch (err) {
          console.error('ERROR redirecting user 1', err);
          dispatch(
            setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 })
          );
        }
      }

      try {
        const authResult = await instance.acquireTokenSilent({
          ...accessTokenRequest,
          forceRefresh: false
        });

        const userId = get(authResult, ['idTokenClaims', 'extension_UserId'], '');
        const name = get(authResult, ['account', 'name'], '');
        const email = get(authResult, ['account', 'username'], '');
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${authResult.accessToken}` || ''
            }
          };
          const { data: userData } = await axiosConfig.get<UserDetailResponseModal>(`/api/user`, config);
          if (userData.warning) {
            dispatch(setSnackAlert({ open: true, type: AlertType.warning, message: userData.warning, timeout: 60000 }));
          }
          setToken(authResult.accessToken);
          const lang =
            userData.defaultLanguage === CmUserLanguage.English || !userData.defaultLanguage
              ? CmUserLanguage.EnglishUS
              : userData.defaultLanguage;
          i18n.changeLanguage(lang);

          dispatch(
            setUserLoginData({
              accessToken: authResult.accessToken,
              userId,
              name,
              email,
              lang,
              userDetails: userData
            })
          );
          instance.setActiveAccount(authResult.account);
          mixpanel.identify(userData.email);
          mixpanel.people.set({
            name: userData.fullName,
            $email: userData.email,
            $user_id: userData.email,
            orgIg: userData.orgId,
            orgName: userData.orgName,
            id: userData._id
          });
          setIsLoadig(false);
        } catch (err) {
          dispatch(
            setSnackAlert({
              open: true,
              type: AlertType.error,
              message: 'Failed to fetch user details. Please try refreshing.',
              timeout: 6000
            })
          );
          setIsLoadig(false);
          try {
            await instance.logoutRedirect();
          } catch (err) {
            console.error('ERROR logging out user 3', err);
            setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 });
          }
        }
      } catch (err) {
        console.error('ERROR authentication user', err);
        if (err instanceof InteractionRequiredAuthError) {
          try {
            return await instance.loginRedirect(accessTokenRequest);
          } catch (err) {
            console.error('ERROR redirecting user 2', err);
            dispatch(
              setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 })
            );
          }
        }
        setIsLoadig(false);
      }
    };
    if (!token && inProgress === InteractionStatus.None) {
      handleAuthentication();
    }
  }, [instance, accounts, inProgress, token]);

  return (
    <>
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <>
          <AxiosInterceptorNavigate />
          <Box sx={{ display: 'flex' }}>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Outlet />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default ProtectedLayout;
