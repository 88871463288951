import { CreateSolutionResponseModal } from './mySolutionsModel';
import { AxiosAPIError } from 'utils/apiError';
import axiosConfig from 'app/axiosConfig';

export const getMySolutionAsync = async (status: Array<string>): Promise<{
  response: CreateSolutionResponseModal[] | undefined;
  error: AxiosAPIError | null;
}> => {
  const statusVal = status.map(s => s);
  const data = await axiosConfig.get<CreateSolutionResponseModal[]>(`/api/user/flows?status=${statusVal}`);
  return { response: data?.data, error: null };
};
