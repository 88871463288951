import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Backdrop, Box, CircularProgress, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Upload } from 'features/upload/Upload';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { setSnackAlert, setStepperData } from 'features/stepper/stepperSlice';
import { AlertType, StepperPages } from 'features/stepper/stepperModel';
import {
  selectInventoryType,
  selectSecurityLevel,
  selectItemQuantityList
} from 'features/security/securitySlice';
import { selectCostInfoState } from 'features/costInfo/costInfoSlice';
import { selectUploadStatus } from 'features/upload/AutomatedUploadSlice';
import { BASE_API_URL } from 'app/constants';
import { useTranslation } from 'react-i18next';
import InfoDetailsGuided from 'features/guided/details/info-details';

const FileUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const security = useAppSelector(selectSecurityLevel);
  const inventoryType = useAppSelector(selectInventoryType);
  const itemQuantityList = useAppSelector(selectItemQuantityList);
  const { estimated_repair, freight_estimate, labor_rate_per_hour, state_sales, stocking_labor } =
    useAppSelector(selectCostInfoState);
  const uploadStatus = useAppSelector(selectUploadStatus);
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get('flowId');
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    const isSecurity = security && inventoryType && itemQuantityList.length >= 1;
    const isCostInfo =
      estimated_repair && freight_estimate && labor_rate_per_hour && state_sales && stocking_labor;
    const isUploadInfo = !!flowId;
    if (isSecurity && isCostInfo && isUploadInfo) {
      dispatch(setStepperData({ name: StepperPages.Upload, value: true }));
    } else {
      dispatch(setStepperData({ name: StepperPages.Upload, value: false }));
    }
  }, [
    security,
    inventoryType,
    itemQuantityList,
    estimated_repair,
    freight_estimate,
    labor_rate_per_hour,
    state_sales,
    stocking_labor,
    uploadStatus,
    flowId
  ]);

  const onTemplateDownloadClick = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${BASE_API_URL}/static-files/vendingTemplate.xlsx`);
  
      if (!response.ok) {
        dispatch(setSnackAlert({ open: true, type: AlertType.error, message: `${t('Flow.Errors.download_error')} `, timeout: 2000 }));
      } else {
        const blob = await response.blob();
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'VendingDataLoader.xlsx';
        alink.click();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: `${t('Flow.Errors.download_error')} `, timeout: 2000 }));
    }
  };

  return (
    <>
      <Box>
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        <Box sx={{ marginBottom: '10px' }}>
            <Typography variant="h5" fontSize={'18px'}>
              {t('Flow.GuidedTab.ProductInfoStep.title') || 'Add Product Category & Area Details'}
            </Typography>
          
          <Typography sx={{ color: theme.palette.text.secondary }} variant="subtitle1" fontSize={'16px'}>
            {t('Flow.GuidedTab.ProductInfoStep.subTitle') ||
              'Provide information about the items to manage, your cost to serve, customer volumes and opportunity. TIP: More items will better determine machine matches.'}
          </Typography>
        </Box>
      
      <Grid container columns={3} spacing={2}>
        <Grid item xs={0.75}>
          <InfoDetailsGuided />
        </Grid>
        <Grid item xs={1}>
          <Stack  direction="row"
            spacing={1}>
            <Typography variant="subtitle1" component="span">
            {t('Flow.AutomatedTab.AreaDetailsProductUpload.subtitle')}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: 'primary.main', cursor: 'pointer' }}
              onClick={onTemplateDownloadClick}
            >
              {t('Flow.AutomatedTab.AreaDetailsProductUpload.download_template')}
            </Typography>

          </Stack>
            <Box
              sx={{
                flex: 1,
                height: 320,
                display: 'flex',
                justifyContent: 'center',
                minWidth: '100px',
                borderRadius: '4px',
                borderWidth: '1px',
                borderStyle: 'dashed',
                borderColor: 'primary.main',
                backgroundColor: '#F5FAFD'
              }}
            >
              <Upload />
            </Box>
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default FileUpload;
