import axios from 'axios';
import get from 'lodash/get';
// import { MutableRefObject } from 'react';
// import { NavigateFunction } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';

import { BASE_API_URL } from './constants';
import { UNAUTHORIZED_ERROR_CODE_401 } from 'constants/apiError';
import { CmUserLanguage } from 'features/user/userModel';

const axiosConfig = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const handleErroredResponse = async (
  error: {
    response: {
      status: number;
      data: {
        name: string;
      };
    };
    message: string;
  },
  //navigate: MutableRefObject<NavigateFunction>,
  instance: IPublicClientApplication
) => {
  const statusCode = get(error, ['response', 'status'], undefined);
  const errData = get(error, ['response', 'data'], undefined);

  if (statusCode === UNAUTHORIZED_ERROR_CODE_401) {
    // logout user
    // navigate to login page
    // navigate.current('/signin');
    await instance.logoutRedirect();
    return Promise.reject(errData);
  }
  if (errData) {
    return Promise.reject(errData);
  }
  return Promise.reject(error.message);
};

axiosConfig.interceptors.request.use(
  (config) => {
    // handle request
    return config;
  },
  (error) => Promise.reject(error)
);

export const AxiosInterceptorsSetup = (
  //navigate: MutableRefObject<NavigateFunction>,
  instance: IPublicClientApplication,
  token: string,
  lang: CmUserLanguage
) => {
  axiosConfig.interceptors.request.use(
    (config) => {
      // handle request
      if (token && config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      // send user's lang in query parameter
      config.params = { ...config.params, lang };
      return config;
    },
    (error) => Promise.reject(error)
  );
  axiosConfig.interceptors.response.use(
    (response) => {
      // handle response
      return response;
    },
    async (error) => {
      return await handleErroredResponse(error, /*navigate,*/ instance);
    }
  );
};

export default axiosConfig;
