import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReducerKeys } from 'app/reducerKeys';
import { RootState } from 'app/store';
import { MachineSelection } from 'features/guided/guidedModel';
import { getAllCribsByOrg } from 'features/machineSettings/machineAPI';
import { AdditionalCostFeesEnum, Charge, CostFees, CostFeesDefault } from './additonalCostFeesModel';
import { combineCribTypeObjects, getCostFeesCategories } from './costFeesAPI';


export const fetchCribSettingsByOrgId = createAsyncThunk(
  `${ReducerKeys.machineSelection}/fetchAllCribsByOrg`,
  async () => {
    const { response } = await getAllCribsByOrg();
    return response;
  }
);


export const fetchCostFeesCategories = createAsyncThunk(
  `${ReducerKeys.costFees}/getCostFeesCategories`,
  async () => {
    const { data, error } = await getCostFeesCategories();
    if (data) return { value: data };
    return { value: data, error };
  }
);

const initialState: CostFees = {
  [AdditionalCostFeesEnum.additionalCosts]: [],
  [AdditionalCostFeesEnum.additionalFees]: [],
  categories: [],
  criterias: [],
  cribs: [],
  general_org: {
    organization_type: '',
    price_group: ''
  }
};

export const emptyCharge: Charge = {
  name: '',
  charges_key: '',
  value: '',
  org_level: false,
  cribs: [],
  cribType: 'All'
};

export const costFeesSlice = createSlice({
  name: ReducerKeys.costFees,
  initialState,
  reducers: {
    setDefaultAdditionalCharge: (state, action: PayloadAction<CostFeesDefault>) => {
      const payload = action.payload;
      !payload[AdditionalCostFeesEnum.additionalCosts]?.length ? state[AdditionalCostFeesEnum.additionalCosts].push(emptyCharge): state[AdditionalCostFeesEnum.additionalCosts] = payload[AdditionalCostFeesEnum.additionalCosts];
      !payload[AdditionalCostFeesEnum.additionalFees]?.length? state[AdditionalCostFeesEnum.additionalFees].push(emptyCharge) : state[AdditionalCostFeesEnum.additionalFees] = payload[AdditionalCostFeesEnum.additionalFees]
    },
    setAdditionalCost: (state, action: PayloadAction<{rowNo: number; item: Charge}>) => {
      const { rowNo, item } = action.payload;
      const isCrib = item?.charges_key?.includes('crib');
      const list = [...state[AdditionalCostFeesEnum.additionalCosts]];
      if(!isCrib) delete item.cribs
      list[rowNo] = item;
      state[AdditionalCostFeesEnum.additionalCosts] = list;
    },
    setAdditionalFees: (state, action: PayloadAction<{rowNo: number; item: Charge}>) => {
      const { rowNo, item } = action.payload;
      const isCrib = item?.charges_key?.includes('crib');
      const list = [...state[AdditionalCostFeesEnum.additionalFees]];
      if(!isCrib) delete item.cribs
      list[rowNo] = item;
      state[AdditionalCostFeesEnum.additionalFees] = list;
    },
    setAdditionalCostsCribs: (state, action: PayloadAction<{rowNo: number}>) => {
      const { rowNo } = action.payload;
      const list = [...state[AdditionalCostFeesEnum.additionalCosts]] || [];
      const cribs = list[rowNo].cribs || [];
      list[rowNo].cribs = [...cribs, {value: '', cribType: '', org_level: true }];
      state[AdditionalCostFeesEnum.additionalCosts] = list;
    },
    setAdditionalFeesCribs: (state, action: PayloadAction<{rowNo: number}>) => {
      const { rowNo } = action.payload;
      const list = state[AdditionalCostFeesEnum.additionalFees]
      const cribs = list[rowNo].cribs || [];
      list[rowNo].cribs = [...cribs, {value: '', cribType: '', org_level: true }];
      state[AdditionalCostFeesEnum.additionalFees] = list;
    },
    updateCribCostTypeValue: (state, action: PayloadAction<{rowNo: number, cribRowNo: number, value: string, type: string}>) => {
      const { rowNo, cribRowNo, type = '', value = '' } = action.payload;
      const selectedCost = state[AdditionalCostFeesEnum.additionalCosts][rowNo];
      if (selectedCost && selectedCost.cribs && selectedCost.cribs.length) {
        selectedCost.cribs[cribRowNo] = { cribType: type, value, org_level: true  };
        state[AdditionalCostFeesEnum.additionalCosts][rowNo] = selectedCost;
      }
    },
    updateCribFeesTypeValue: (state, action: PayloadAction<{rowNo: number, cribRowNo: number, value: string, type: string}>) => {
      const { rowNo, cribRowNo, type = '', value = '' } = action.payload;
      const selectedCost = state[AdditionalCostFeesEnum.additionalFees][rowNo];
      if (selectedCost && selectedCost.cribs && selectedCost.cribs.length) {
        selectedCost.cribs[cribRowNo] = { cribType: type, value, org_level: true };
        state[AdditionalCostFeesEnum.additionalFees][rowNo] = selectedCost;
      }
    },
    removeCribTypes: (state, action: PayloadAction<{type: string, cribRowNo: number, rowNo: number}>) => {
      const { rowNo, type, cribRowNo } = action.payload;
      if(type === 'costs'){
        const list = state[AdditionalCostFeesEnum.additionalCosts] || [];
        const cribs = list[rowNo].cribs || [];
        cribRowNo > -1 && cribRowNo < cribs.length && cribs.splice(cribRowNo, 1);
        state[AdditionalCostFeesEnum.additionalCosts][rowNo].cribs = cribs;
      }else{
        const list = state[AdditionalCostFeesEnum.additionalFees] || [];
        const cribs = list[rowNo].cribs || [];
        cribRowNo > -1 && cribRowNo < cribs.length && cribs.splice(cribRowNo, 1);
        state[AdditionalCostFeesEnum.additionalFees][rowNo].cribs = cribs;
      }
    },
    setGeneralOrg: (state, action: PayloadAction<{name: string; value: string}>) => {
      const {name, value} = action.payload;
      state.general_org = {...state.general_org, [name]: value}
    },
    changeAdditionalCostsRow: (
        state,
        action: PayloadAction<{ type: string; rowNo: number, key: AdditionalCostFeesEnum, org_level?: boolean }>
      ) => {
        const { type, rowNo, key, org_level = false } = action.payload;
        let arr: Charge[] = state[key];
        if (type === 'add') {
          arr.push({...emptyCharge, org_level}) 
        }
        if (type === 'remove') {
          arr.splice(rowNo, 1);
          if (state[key].length === 0) {
            arr = [{...emptyCharge, org_level}];
          }
        }
        state[key] = arr;
      }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchCostFeesCategories.fulfilled, (state, action) => {
      state.categories = action.payload.value;
    })
    .addCase(fetchCribSettingsByOrgId.fulfilled, (state, action) => {
      const settings = action.payload;
      if(!settings) return
      if(settings.general_org){
        state.general_org = settings.general_org;
      }
      if(settings.criterias){
        state.criterias = settings.criterias
      }
      if(settings[AdditionalCostFeesEnum.additionalCosts]?.length){
        state[AdditionalCostFeesEnum.additionalCosts] =  combineCribTypeObjects(settings[AdditionalCostFeesEnum.additionalCosts]) as Charge[];
      }else{
        state[AdditionalCostFeesEnum.additionalCosts] = [emptyCharge]
      }
      if(settings[AdditionalCostFeesEnum.additionalFees]?.length){
        state[AdditionalCostFeesEnum.additionalFees]=  combineCribTypeObjects(settings[AdditionalCostFeesEnum.additionalFees]) as Charge[];
      }else{
        state[AdditionalCostFeesEnum.additionalFees] = [emptyCharge]
      }
      if(settings.cribs?.length){
        // LOGIC TO ADD MACHINES AND CREATE LIST FOR ADDITONAL COST FEEES BASED ON SETTONGS SELECTION
        let cribs = settings.cribs;
        if(settings.express_machines) cribs = [...cribs, MachineSelection.express_toolbox, MachineSelection.express_locker];
        if(settings.rfid_machines) cribs = [...cribs, MachineSelection.accucab, MachineSelection.hybrid_accucab_double, MachineSelection.hybrid_accucab_single, MachineSelection.accudrawer];
        if(settings.accuport_machines) cribs = [...cribs, MachineSelection.accuport];
        state.cribs = cribs;
      }
    })
  }
});

export const { setAdditionalCost, setAdditionalFees, changeAdditionalCostsRow, updateCribCostTypeValue, updateCribFeesTypeValue, removeCribTypes, setDefaultAdditionalCharge, setGeneralOrg, setAdditionalCostsCribs, setAdditionalFeesCribs } = costFeesSlice.actions;

export const selectAdditionalCosts = (state: RootState) => state.costFees[AdditionalCostFeesEnum.additionalCosts];
export const selectAdditionalFees = (state: RootState) => state.costFees[AdditionalCostFeesEnum.additionalFees];
export const selectAdditionalCategories = (state: RootState) => state.costFees.categories;
export const selectGeneralOrg = (state: RootState) => state.costFees.general_org;
export const selectSelectedCriterias = (state: RootState) => state.costFees.criterias;
export const selectSettingsCribs = (state: RootState) => state.costFees.cribs;

export const { reducer: costFeesReducer, name: costFeesKey } = costFeesSlice;
