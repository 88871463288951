import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import {
  DataGridPro,
  GridColumnGroupHeaderParams,
  GridColumnGroupingModel,
  GridRenderCellParams,
  GridRowClassNameParams,
  useGridApiRef,
  GridRowModel
} from '@mui/x-data-grid-pro';
import { Box, Tooltip, Typography } from '@mui/material';
import GroupHeader from 'features/data-grid-table/GroupHeader';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getProductList,
  selectProductData,
  resetAPIcallStatus,
  updateProductListRow,
  updateRowDataListStatus
} from './reviewValidateProductSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { AlertType } from 'features/stepper/stepperModel';
import {
  coilPlacementColumns,
  columns,
  createRows,
  lockerPlacementColumns,
  productDimensionColumns,
  productInformationColumns
} from './gridConfig';
import {
  ExcelColumnData,
  ExcelColumnKey,
  ExcelParsedData,
  ExcelRowColumnError,
  ProductAPIPayload
} from './reviewProductModel';
import ErrorIcon from '@mui/icons-material/Error';
import { findLighterShade, getDot, getSeverityColor, renderCellWithDot, renderCellWithUrlOrNa } from './helper';
import FilterComponent from './FilterComponent';
import { getProductListBufferAsync, updateRowDataAsync } from './reviewProductAPI';
import toNumber from 'lodash/toNumber';
import omit from 'lodash/omit';
import { debounce, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DOT_COLORS } from 'theme/customColors';

const convertToNumber = (st: string | undefined | null) => {
  if (st === undefined || st === null || st === '') {
    return null;
  }
  const conveterdVal = toNumber(st);
  return Number.isNaN(conveterdVal) ? st : conveterdVal;
};

export default function ReviewValidateProduct() {
  const dispatch = useAppDispatch();
  const { flowId } = useParams();
  const [searchParams] = useSearchParams();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();

  const selected_pou = searchParams.get('selected_pou') || '';
  const automated_file_id = searchParams.get('automated_file_id') || '';

  const productData = useAppSelector(selectProductData);
  const productList = productData.productList as ExcelParsedData[];
  const productListLoading = productData.loading;
  const pageCount = productData.pageSize;
  const cellupdateLoading = productData.updateStatus === 'loading';

  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [expandedColumn, setExpandedColumn] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [excelDownloadStatus, setExcelDownloadStatus] = useState<'loading' | 'idle' | 'failed'>('idle');
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  useEffect(() => {
    if (selected_pou && flowId) {
      const severity = selectedOption;
      const offset = page * pageSize;
      const limit = pageSize;

      let inputpayload: ProductAPIPayload = {
        selected_pou,
        flowId,
        offset: Math.max(0, offset),
        limit,
        severity,
        searchValue: debouncedSearchValue
      };
      if (selectedOption === 0) {
        inputpayload = omit(inputpayload, ['severity']);
      }
      dispatch(getProductList(inputpayload));
    }
  }, [selected_pou, selectedOption, flowId, pageSize, dispatch, page, debouncedSearchValue]);

  useEffect(() => {
    if (productData.updateStatus === 'success') {
      dispatch(setSnackAlert({ open: true, type: AlertType.success, message: 'Successfully updated', timeout: 2000 }));
      dispatch(resetAPIcallStatus());
      return;
    }
    if (productData.updateStatus === 'failed') {
      dispatch(
        setSnackAlert({
          open: true,
          type: AlertType.error,
          message: 'Unable to update, Please try again',
          timeout: 2000
        })
      );
      dispatch(resetAPIcallStatus());
    }
  }, [productData.updateStatus, dispatch]);

  const rows = useMemo(() => {
    const processedRows = createRows(productList || []).map((row, index) => {
      return {
        ...row,
        uniqueId: index + 1
      };
    });
    return processedRows;
  }, [productList]);

  const renderHeaderGroup = (params: GridColumnGroupHeaderParams) => (
    <GroupHeader
      headerName={params.headerName}
      onToggleExpansion={() => handleButtonClick(params)}
      isExpanded={params.fields && params.fields.length > 0 && params.fields[0] === expandedColumn}
    />
  );

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'item',
      headerName: 'Item',
      description: 'Product Information',
      headerClassName: 'header-grey-background',
      children: productInformationColumns,
      renderHeaderGroup
    },
    {
      groupId: 'dimensions',
      headerName: 'Item Sizes & Usage',
      description: 'Product Dimensions',
      headerClassName: 'header-grey-background',
      children: productDimensionColumns,
      renderHeaderGroup
    },
    {
      groupId: 'capacity',
      headerName: 'Locker data',
      description: 'Product Capacity Information',
      headerClassName: 'header-grey-background',
      children: lockerPlacementColumns,
      renderHeaderGroup
    },
    {
      groupId: 'otherInfoCoil',
      headerName: 'Coil Data',
      headerClassName: 'header-grey-background',
      description: '',
      children: coilPlacementColumns,
      renderHeaderGroup
    },
    {
      groupId: 'otherInfoCarousel',
      headerName: 'Carousel Data',
      description: '',
      headerClassName: 'header-grey-background',
      children: [
        { field: 'CarouselVendable', headerName: 'Carousel Vendable Y/N' },
        { field: 'RepackForCarousel', headerName: 'Needs Repack for Carousel Y/N' },
        { field: 'SlotsPerItem', headerName: '# of Slots per Item' }
      ],
      renderHeaderGroup
    },
    {
      groupId: 'weighStation',
      headerName: 'Weigh Station',
      description: '',
      headerClassName: 'header-grey-background',
      children: [{ field: 'RiserRequiredWS', headerName: 'Riser Required - WS' }]
    },
    {
      groupId: 'itemImages',
      headerName: 'Image files',
      headerClassName: 'header-grey-background',
      description: '',
      children: [
        { field: 'RepackingInstructions', headerName: 'Instr. PDF' },
        { field: 'ItemImageURL', headerName: 'IMG URL' },
        { field: 'RepackedImageUrl', headerName: 'Rpk. URL' }
      ],
      renderHeaderGroup
    }
  ];

  const [columnVisibility, setColumnVisibility] = useState<{
    [field: string]: boolean;
  }>({});

  const handleButtonClick = useCallback(
    (params: GridColumnGroupHeaderParams) => {
      const clickedColumn = params.fields && params.fields[0];
      const newExpandedColumn = clickedColumn === expandedColumn ? null : clickedColumn;
      setExpandedColumn(newExpandedColumn);

      const newVisibility: { [field: string]: boolean } = {};
      if (params.fields) {
        params.fields.forEach((field) => {
          newVisibility[field] = params.fields[0] === field;
        });
      }
      setColumnVisibility(newVisibility);
    },
    [expandedColumn]
  );

  const getColumns = useCallback(() => {
    return columns.map((column) => {
      const renderCellContent = (params: GridRenderCellParams) => {
        const row = productList && productList[params.row.uniqueId - 1];
        let dotColor = '';
        let error = undefined;

        if (row && row.columnErrors && row.columnErrors.length > 0) {
          const severity = row.columnErrors.reduce(
            (prev, current) => (current.severity < prev ? current.severity : prev),
            10
          );
          dotColor = getSeverityColor(severity);
          error = row.columnErrors.find((error) => error.colKey === column.field);
        }

        if (
          column.field === 'RepackingInstructions' ||
          column.field === 'ItemImageURL' ||
          column.field === 'RepackedImageUrl'
        ) {
          const url = params.row[column.field];
          return renderCellWithUrlOrNa(url, error, dotColor, column.field);
        } else if (column.field === 'SKU') {
          const colErrorsForField = (row && row.columnErrors.filter((error) => error.colKey === 'SKU')) || '';

          const rowErrorSeverity =
            row && row.rowErrors && row.rowErrors.length > 0 ? row.rowErrors.map((error) => error.severity) : [];

          const maxRowErrorSeverity = Math.max(...rowErrorSeverity);

          return (
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              {row && row.rowErrors.length > 0 && (
                <Tooltip
                  title={
                    <Typography variant="body1" sx={{ fontSize: '14px', paddingRight: '10px' }}>
                      {row.rowErrors.map((error: ExcelRowColumnError) => 'Row Error: ' + error.message).join('\n')}
                    </Typography>
                  }
                  arrow
                >
                  <ErrorIcon style={{ color: getSeverityColor(maxRowErrorSeverity) || undefined }} fontSize="small" />
                </Tooltip>
              )}
              <Typography variant="inherit" sx={{ paddingLeft: '10px' }}>
                {params.row.SKU || ''}
              </Typography>
              {!params.row.SKU && colErrorsForField && (
                <Tooltip
                  title={
                    <Typography variant="body1" sx={{ fontSize: '14px' }}>
                      {colErrorsForField.map((error: ExcelRowColumnError) => error.message).join('\n')}
                    </Typography>
                  }
                  arrow
                >
                  {colErrorsForField ? (
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'end' }}>{getDot(dotColor)}</div>
                  ) : (
                    <></>
                  )}
                </Tooltip>
              )}
            </div>
          );
        } else {
          return productList && renderCellWithDot(params, column.field, productList);
        }
      };

      return {
        ...column,
        editable: column.field === 'POU' ? false : true,
        renderCell: renderCellContent,
        sortable: false,
        filterable: false
      };
    });
  }, [columnVisibility, productList]);

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const rowKeys = Object.keys(oldRow);
      const updatedFields: ExcelColumnData[] = [];
      const excelKeys = Object.values(ExcelColumnKey);
      rowKeys.forEach((key) => {
        const oldVal = oldRow[key];
        const newVal = newRow[key];
        if (oldVal !== newVal && excelKeys.includes(key as ExcelColumnKey)) {
          updatedFields.push({
            colKey: key as ExcelColumnKey,
            value: convertToNumber(newVal)
          });
        }
      });

      if (updatedFields.length === 0) {
        return oldRow;
      }
      dispatch(updateRowDataListStatus('loading'));
      const { response } = await updateRowDataAsync({
        records: [
          {
            pouRecordId: newRow.id,
            updateRowData: updatedFields
          }
        ],
        fileuploadId: automated_file_id,
        pouValue: selected_pou
      });
      if (response && response.length > 0) {
        dispatch(updateProductListRow(response));
      } else {
        dispatch(updateRowDataListStatus('failed'));
        throw new Error('Unable to update, Please try again');
      }
      return newRow;
    },
    [dispatch, selected_pou, automated_file_id]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    const rowId = params.row?.uniqueId - 1;
    const row = productList && productList[rowId];
    const severity = get(row, ['rowErrors', 0, 'severity'], null);
    const hasRowError = row && row.rowErrors && row.rowErrors.length > 0;
    return hasRowError ? `error-row-${severity}` : '';
  };

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    dispatch(updateRowDataListStatus('failed'));
    dispatch(
      setSnackAlert({
        open: true,
        type: AlertType.error,
        message: error.message,
        timeout: 2000
      })
    );
  }, []);

  const handleExportButtonClick = async () => {
    setExcelDownloadStatus('loading');
    try {
      const { response } = await getProductListBufferAsync({
        flow_id: flowId || '',
        pou: selected_pou,
        severity: selectedOption === 0 ? undefined : selectedOption,
        searchValue: debouncedSearchValue
      });
      const buf = new Uint8Array(response.data).buffer;
      const buftype = 'application/vnd.ms-excel;charset=utf-8';
      const blob = new Blob([buf], {
        type: buftype
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `vending-data-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      dispatch(
        setSnackAlert({
          open: true,
          type: AlertType.error,
          message: 'Failed to export, Please try again',
          timeout: 2000
        })
      );
    }
    setExcelDownloadStatus('idle');
  };

  const clearSearch = () => {
    setSearchValue('');
    debouncedSearchFn('');
  };

  const handleSearchChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    debouncedSearchFn(value);
  };

  const handleDebouncedSearch = (value: string) => {
    setDebouncedSearchValue(value);
  };

  const debouncedSearchFn = useCallback(debounce(handleDebouncedSearch, 1000), []);

  const CustomNoRowsOverlay = () => (
    <Typography sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {t('Flow.Errors.noData') || 'No Data to display'}
    </Typography>
  );

  return (
    <>
      <FilterComponent
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        handleExportButtonClick={handleExportButtonClick}
        excelDownloadStatus={excelDownloadStatus}
        value={searchValue}
        onChange={handleSearchChange}
        clearSearch={clearSearch}
        isSearchActive={productListLoading || cellupdateLoading}
      />
      <Box
        sx={{
          height: `calc(100vh - 406px)`,
          width: 'calc(100vw - 98px)',
          '& .header-grey-background': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <DataGridPro
          paginationMode="server"
          rows={rows || []}
          columns={getColumns()}
          columnVisibilityModel={{ ...columnVisibility }}
          checkboxSelection={false}
          showColumnVerticalBorder={true}
          showCellVerticalBorder={true}
          pagination
          paginationModel={{ pageSize, page }}
          rowCount={pageCount}
          getRowClassName={(params) => getRowClassName(params)}
          sx={{
            '& .error-row-10': {
              backgroundColor: findLighterShade(DOT_COLORS.RED),
              border: `1px dotted ${DOT_COLORS.RED}`
            },
            '& .error-row-20': {
              backgroundColor: findLighterShade(DOT_COLORS.RED),
              border: `1px dotted ${DOT_COLORS.RED}`
            },
            '& .error-row-30': {
              backgroundColor: findLighterShade(DOT_COLORS.ORANGE),
              border: `1px dotted ${DOT_COLORS.ORANGE}`
            },
            '& .error-row-40': {
              backgroundColor: findLighterShade(DOT_COLORS.YELLOW),
              border: `1px dotted ${DOT_COLORS.YELLOW}`
            },
            '& .error-row-50': {
              backgroundColor: findLighterShade(DOT_COLORS.LIGHT_YELLOW),
              border: `1px dotted ${DOT_COLORS.LIGHT_YELLOW}`
            }
            // '& .MuiDataGrid-footerContainer': {
            //   minHeight: '45px'
            // }
          }}
          getRowId={(row) => {
            return row.id;
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            setPageSize(pageSize);
            setPage(page);
          }}
          columnGroupingModel={columnGroupingModel}
          loading={productListLoading || cellupdateLoading}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          apiRef={apiRef}
          slotProps={{
            toolbar: { apiRef }
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay
          }}
          // initialState={{ pinnedColumns: { left: ['SKU'] } }}
        />
      </Box>
    </>
  );
}
