import { Stack, Card, Theme, Paper, styled, Grid, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { memo } from 'react';

interface Props {
  theme: Theme;
  handleEdit?: (stepNum: number) => void;
  title?: string;
  editTitle?: string;
  costInfoDetails: { title: string; value: string | number }[];
  cmHosting: boolean;
  cmHostingLabel: string;
  aciTitle?: string;
  withCard?: boolean;
}

const ReviewCost = ({
  theme,
  title,
  handleEdit = () => {},
  editTitle,
  costInfoDetails,
  cmHosting,
  cmHostingLabel,
  withCard
}: Props) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['200'],
    ...theme.typography.body2,
    padding: '6px 16px 4px 16px'
  }));

  const costs = () => {
    return (
      <Stack sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {costInfoDetails.map((ad) => (
            <Grid item xs={6} key={ad.title}>
              <Item>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                  {ad.title}
                </Typography>
                <Typography variant="body2">{ad.value}</Typography>
              </Item>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={0} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <FormControlLabel
              value="end"
              control={<Checkbox disabled checked={cmHosting} />}
              label={cmHostingLabel}
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </Stack>
    );
  };

  if (!withCard) {
    return <>{costs()}</>;
  }

  return (
    <Card>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['100'],
          padding: '8px 16px',
          alignItems: 'center'
        }}
      >
        <Typography variant="body1">{title}</Typography>
        <Tooltip title={`${editTitle} ${title}`}>
          <EditIcon onClick={() => handleEdit(1)} sx={{ color: theme.palette.action.active, height: 15, width: 15 }} />
        </Tooltip>
      </Stack>

      {costs()}
    </Card>
  );
};

export default memo(ReviewCost);
