import { CircularProgress, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';

interface ConfirmationInterface {
    loading: boolean,
    open: boolean,
    title: string,
    messages: string[],
    buttonTexts: string[],
    onAction: () =>  void,
    onClose: () =>  void,
}
function PaperComponent(props: PaperProps) {
  return (
    <Paper {...props} />
  );
}

export default function ConfirmComponent(props: ConfirmationInterface) {
  const { 
        loading,
        open = false,
        title = 'Confirmation Box', 
        messages = ['para one', 'para two'], 
        buttonTexts = ['Cancel', 'Confirm'], 
        onAction = () => {}, 
        onClose = () => {} 
    } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title}
        </DialogTitle>
        <DialogContent>
          {
            messages.map((message, i) => (
              <DialogContentText key={'confirmation-message' + i}>
                {message}
              </DialogContentText>
            ))
          }
        </DialogContent>
        <DialogActions>
          {
            loading &&
            <IconButton sx={{width: '80px'}}>
              <CircularProgress size={25} thickness={8} color='error' />
            </IconButton> 
          }
          {
            buttonTexts[0] && !loading && <Button variant="text"
            size="medium"
            sx={{ fontFamily: 'Helvetica Neue', fontStyle: 'normal', textTransform: 'capitalize' }} autoFocus onClick={handleClose}>
              {buttonTexts[0]}
            </Button> 
          }
          {
            buttonTexts[1] && !loading && <Button 
            size="medium"
            sx={{ fontFamily: 'Helvetica Neue', fontStyle: 'normal', textTransform: 'capitalize' }} onClick={onAction} color="error" variant="contained">
              {buttonTexts[1]}
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}