import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, Grid, Paper, Stack, Theme, Tooltip, styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { get } from 'lodash';
import startCase from 'lodash/startCase';
import { SyntheticEvent, memo, useState } from 'react';

import { Charge } from 'features/additionalCostFees/additonalCostFeesModel';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';
import { Currency } from 'features/user/userModel';
import useCurrency from 'hooks/currency-hook';
import ReviewCost from './ReviewCost';

interface Props {
  additionalCosts: Charge[];
  additionalFees: Charge[];
  theme: Theme;
  handleEdit: (stepNum: number) => void;
  title: string;
  costDetailLabels: string[];
  costFeeDetailLabels: string[];
  editTitle: string;
  currency: Currency;
  costInfoDetails: { title: string; value: string | number }[];
  cmHosting: boolean;
  cmHostingLabel: string;
  aciTitle: string;
  subTitles: string[];
  isDirectCustomer: boolean;
  withCostInfo?: boolean;
  flowType: TabsInterface;
}

enum CostType {
  cost = 'cost',
  fees = 'fees'
}

interface AdditionalCostFeesProps {
  type: CostType;
}

const ReviewCostInfo = ({
  additionalCosts,
  additionalFees,
  theme,
  title,
  handleEdit,
  costDetailLabels,
  costFeeDetailLabels,
  editTitle,
  costInfoDetails,
  cmHosting,
  cmHostingLabel,
  aciTitle,
  subTitles,
  isDirectCustomer,
  withCostInfo,
  flowType
}: Props) => {
  const isCosts = !!get(additionalCosts, [0, 'name']);
  const isFees = !!get(additionalFees, [0, 'name']);
  const isEmpty = !isCosts && !isFees;
  const { formatToCurrencyWithSymbol } = useCurrency();

  const [expanded, setExpanded] = useState<string | false>(isCosts ? 'costs' : 'fees');

  const handleChange = (panel: string) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['200'],
    ...theme.typography.body2,
    padding: '6px 16px 4px 16px'
  }));

  const AdditionalCostFees = ({ type }: AdditionalCostFeesProps) => {
    const costs = type === CostType.cost ? additionalCosts : additionalFees;
    const labels = type === CostType.cost ? costDetailLabels : costFeeDetailLabels;
    return (
      <>
        {costs.map(
          (item, i) =>
            item.name && (
              <Stack sx={{ p: 1 }} key={item.name + i}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Item>
                      <Typography variant="body2" sx={{ fontSize: '12px' }}>
                        {labels[0]}
                      </Typography>
                      <Typography variant="body2">{startCase(item.name)}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>
                      <Typography variant="body2" sx={{ fontSize: '12px' }}>
                        {labels[1]}
                      </Typography>
                      <Typography variant="body2">{startCase(item.charges_key)}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>
                      <Typography variant="body2" sx={{ fontSize: '12px' }}>
                        {labels[2]}
                      </Typography>
                      <Typography variant="body2">{formatToCurrencyWithSymbol(item.value)}</Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Stack>
            )
        )}
      </>
    );
  };

  return (
    <Card>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['100'],
          padding: '8px 16px',
          alignItems: 'center'
        }}
      >
        <Typography variant="body1">{title}</Typography>
        <Tooltip title={`${editTitle} ${title}`}>
          <EditIcon
            onClick={() => (flowType === TabsInterface.roi ? handleEdit(1) : handleEdit(2))}
            sx={{ color: theme.palette.action.active, height: 15, width: 15 }}
          />
        </Tooltip>
      </Stack>
      {withCostInfo && (
        <>
          <ReviewCost
            theme={theme}
            handleEdit={handleEdit}
            costInfoDetails={costInfoDetails}
            cmHosting={cmHosting}
            cmHostingLabel={cmHostingLabel}
          />
          <Typography variant="subtitle2" sx={{ fontWeight: 700, mt: 0, ml: 2, mb: 2 }}>
            {aciTitle}
          </Typography>
        </>
      )}

      {isEmpty && (
        <Grid container wrap="nowrap" spacing={2} sx={{ alignItems: 'center', p: 2 }}>
          <Grid item xs zeroMinWidth>
            <Typography variant="body2">No Data</Typography>
          </Grid>
        </Grid>
      )}

      {!isEmpty && (
        <Stack>
          <>
            {isCosts && (
              <Accordion expanded={expanded === 'costs'} onChange={handleChange('costs')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>{subTitles[0]}</Typography>
                </AccordionSummary>
                <AdditionalCostFees type={CostType.cost} />
              </Accordion>
            )}
            {isFees && !isDirectCustomer && (
              <Accordion expanded={expanded === 'fees'} onChange={handleChange('fees')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>{subTitles[1]}</Typography>
                </AccordionSummary>
                <AdditionalCostFees type={CostType.fees} />
              </Accordion>
            )}
          </>
        </Stack>
      )}
    </Card>
  );
};

export default memo(ReviewCostInfo);
