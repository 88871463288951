import { store } from "app/store";
import { Charge } from "features/additionalCostFees/additonalCostFeesModel";
import { CostInfoState } from "features/costInfo/costInfoSlice";
import { CostInfoKeys, customerSalesKeys } from "features/guided/guidedApiKeys";
import { CustomerSalesOpportunityInterface } from "features/guided/guidedModel";
import { UserOrgType } from "features/user/userModel";

export const validateCostFees = (costs: Charge[]) => {
    let isCostInfoValid = true;
    costs?.forEach((item) => {
        if (item.name || item.value || item.charges_key) if (!item.charges_key || !item.value || !item.name) isCostInfoValid = false;
        if(item.cribs){
            item.cribs.forEach(crib => {
                if((crib.value && !crib.cribType) || (!crib.value && crib.cribType)) isCostInfoValid = false;
            })
        }
    });
    return isCostInfoValid;
}

const isDirectCustomer = () => {
    const { user } = store.getState();
    const userOrgType = user.userDetails?.orgType;
    return userOrgType === UserOrgType.direct
}
    // COST INFO PAGE VALIDATORS FUNCTIONS BELOW
export const validateCostInfo = (sales: CostInfoState) => {
    const COST_INFO_REQUIRED = [
        CostInfoKeys.labor_rate_per_hour,
        CostInfoKeys.lease_agreement,
        CostInfoKeys.estimated_repair
    ];
    const isValid = COST_INFO_REQUIRED.filter(key => !sales[key]).length;
    return !isValid;
}
export const validateCustomerSales = (sales: CustomerSalesOpportunityInterface) => {
       
    const CUSTOMER_DATA_REQUIRED = [
        customerSalesKeys.estimated_revenue_twelve_months,
        customerSalesKeys.average_gross_profit
    ];
    if(isDirectCustomer()) CUSTOMER_DATA_REQUIRED.splice(1);
    const isValid = CUSTOMER_DATA_REQUIRED.filter(key => !sales[key]).length;
    return !isValid;
}
