import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
// import { createLocationResponseModal } from './cloneSolutionModel';
import { locationAPIPayload, orgResultModal } from 'features/createSolution/createSolutionModel';
import { ResponseAreaObject } from 'features/guided/guidedModel';
import { getManulArea, getManulLocation } from 'features/guided/guided-constants';
import { AreaPayload } from 'features/solutionBody/solutionBodyModel';
import { ApiGetArea } from 'features/solutionBody/solutionBodyAPI';
import { CreateSolutionResponseModal } from 'features/mySolution/mySolutionsModel';
import { getLocationListAsync } from 'features/createSolution/createSolutionAPI';
import { LocationDataInterface } from 'features/myLocation/location-model';

export interface CloneSolutionState {
  area: string | null;
  areas: Array<ResponseAreaObject> | null;
  locStatus: 'idle' | 'loading' | 'failed' | 'success';
  areaStatus: 'idle' | 'loading' | 'failed' | 'success';
  locLoading: boolean;
  orgLoading: boolean;
  locations: Array<LocationDataInterface>;
  solutionName: string;
  organizationVal: string;
  locationVal: string;
  areaVal: string;
  filteredOrgs: orgResultModal[];
  locationID: string | undefined;
  areaLoading: boolean;
  selectedArea: ResponseAreaObject | null;
  selectedLoc: LocationDataInterface | null;
  rowToDuplicate: CreateSolutionResponseModal | undefined;
  locationValue: string;
}

const initialState: CloneSolutionState = {
  area: null,
  areas: null,
  locStatus: 'idle',
  areaStatus: 'idle',
  locLoading: false,
  orgLoading: false,
  locations: [],
  solutionName: '',
  organizationVal: '',
  locationVal: '',
  areaVal: '',
  filteredOrgs: [],
  locationID: '',
  areaLoading: false,
  selectedArea: null,
  selectedLoc: null,
  rowToDuplicate: undefined,
  locationValue: '',
};

export const fetchAreaAsyncClone = createAsyncThunk(
  `${ReducerKeys.cloneSolution}/fetchArea`,
  async (params: AreaPayload) => {
    const { data } = await ApiGetArea(params);
    if (data) return { value: data };
    return { value: data };
  }
);

export const getLocationList = createAsyncThunk(
  `${ReducerKeys.createSolution}/getLocationList`,
  async (data: locationAPIPayload) => {
    const { response } = await getLocationListAsync(data);
    if (response) return { value: response };
    return { value: response };
  }
);

export const cloneSolutionSlice = createSlice({
  name: ReducerKeys.cloneSolution,
  initialState,
  reducers: {
    setOrgDefaultValues: (state, action: PayloadAction<string>) => {
      state.organizationVal = action.payload;
    },
    setSolutionDefaultValues: (state, action: PayloadAction<string>) => {
      state.solutionName = action.payload;
    },
    setRowToDuplicate: (state, action: PayloadAction<CreateSolutionResponseModal | undefined>) => {
      state.rowToDuplicate = action.payload;
    },
    setLocDefaultValues: (state, action: PayloadAction<string>) => {
      state.locationValue = action.payload;
    },
    setAreaDefaultValues: (state, action: PayloadAction<string>) => {
      state.areaVal = action.payload;
    },
    setLocations: (state, action: PayloadAction<string>) => {
      state.locationValue = action.payload;
    },
    setSelectedLocationGuided: (state, action: PayloadAction<LocationDataInterface>) => {
      state.selectedLoc = action.payload;
      state.locationID = action.payload.id;
    },
    setSelectedArea: (state, action: PayloadAction<ResponseAreaObject>) => {
      state.selectedArea = action.payload;
      state.area = action.payload.id;
    },
    setFilteredOrg: (state, action: PayloadAction<orgResultModal[] | undefined>) => {
      state.filteredOrgs = action.payload || [];
    },
    setLocationID: (state, action: PayloadAction<string | undefined>) => {
      state.locationID = action.payload;
    },
    setAreaLoading: (state, action: PayloadAction<boolean>) => {
      state.areaLoading = action.payload;
    },
    setAreasGuided: (state, action: PayloadAction<ResponseAreaObject[]>) => {
      state.areas = action.payload;
    },
    setLocationsClone: (state, action: PayloadAction<LocationDataInterface[]>) => {
      state.locations = action.payload;
    },
    // setLocations: (state, action: PayloadAction<string>) => {
    //   state.locationValue = action.payload;
    // },
  },

  extraReducers: (builder) => {
    builder

      .addCase(fetchAreaAsyncClone.pending, (state) => {
        state.areaStatus = 'loading';
        state.areaLoading = true;
      })
      .addCase(fetchAreaAsyncClone.fulfilled, (state, action) => {
        state.areaStatus = 'success';
        const areas = action.payload.value;
        if (areas && action.payload.value) {
          const defaultAreaObjSelected = areas?.filter((item) => {
            return item.area_name === state.areaVal;
          });
          state.areas = [...areas, getManulArea(action.payload.value[0])];
          state.selectedArea = defaultAreaObjSelected[0];
          state.areaLoading = false;
        }
      })
      .addCase(fetchAreaAsyncClone.rejected, (state) => {
        state.areaStatus = 'failed';
        state.areaLoading = false;
      })

      .addCase(getLocationList.pending, (state) => {
        state.locStatus = 'loading';
        state.locLoading = true;
      })
      .addCase(getLocationList.fulfilled, (state, action) => {
        state.locStatus = 'success';
        
        const locations = action.payload.value;
        if (locations && action.payload.value) {
          const defaultLocObjSelected = locations?.filter((item) => {
            return item.location_name === state.locationValue;
          });
          state.locations = [...locations, getManulLocation( action.payload.value[0])];
          state.selectedLoc = defaultLocObjSelected[0];
          state.locLoading = false;
        }
      })
      .addCase(getLocationList.rejected, (state) => {
        state.locStatus = 'failed';
        state.locLoading = false;
      })
  }
});

export const {
  setOrgDefaultValues,
  setRowToDuplicate,
  setSolutionDefaultValues,
  setLocDefaultValues,
  setAreaDefaultValues,
  setFilteredOrg,
  setLocationID,
  setAreaLoading,
  setSelectedArea,
  setAreasGuided,
  setSelectedLocationGuided,
  setLocationsClone,
  setLocations
} = cloneSolutionSlice.actions;
export const selectedAreaSelected = (state: RootState) => {
  return state.cloneSolution.selectedArea;
};
export const selectAreas = (state: RootState) => {
  return state.cloneSolution.areas;
};

export const selectCloneSolutionState = (state: RootState) => state.cloneSolution;
export const selectLocationVal = (state: RootState) => state.cloneSolution.locationValue;

export const selectFilteredOrg = (state: RootState) => state.cloneSolution.filteredOrgs;
export const selectLocationID = (state: RootState) => state.cloneSolution.locationID;
export const selectLocation = (state: RootState) => state.cloneSolution.locationVal;
export const selectAreaLoading = (state: RootState) => state.cloneSolution.areaLoading;
export const selectedLocationGuided = (state: RootState) => {
  return state.cloneSolution.selectedLoc;
};
export const selectOrgLoading = (state: RootState) => state.cloneSolution.orgLoading;
export const selectLocLoading = (state: RootState) => state.cloneSolution.locLoading;

export const selectAreaVal = (state: RootState) => state.cloneSolution.areaVal;
export const selectRowToDuplicate = (state: RootState) => state.cloneSolution.rowToDuplicate;

export const selectCloneSolutionLoc = (state: RootState) => state.cloneSolution.locations;

export const selectCloneEditLocation = (state: RootState) =>
  state.cloneSolution.locations;


export const { reducer: cloneSolutionReducer, name: cloneSolutionKey } = cloneSolutionSlice;
