import { CostInfoState } from "features/costInfo/costInfoSlice";
import { ActiveConnections, CustomerSalesOpportunityInterface } from "features/guided/guidedModel";
import { SnackAlertInterface } from "features/stepper/stepperModel";

export interface StepperRoiDataInterface {
    value: StepperRoiValueInterface;
    activeStep?: string;
    step: number;
    loading: boolean;
    snackAlert: SnackAlertInterface;
    isDialogueActive: boolean,
    nextEnabled: boolean
}
  
export enum StepperRoiPages {
    machineSelection = 'machine_selection_roi',
    costInfoGuided = 'cost_info',
    review = 'review',
    resultRoi = 'results',
}
export type StepperRoiValueInterface = {
    [key in StepperRoiPages]: boolean;
};

export interface UpdateRoiPayloadInterface {
    flowId: string,
    shared?: boolean,
    completed?: boolean,
    flow_type: string;
    cost_info: CostInfoState;
    customer_sales_opportunity?: CustomerSalesOpportunityInterface;
    connections?: ActiveConnections;
    include_consignment: boolean;
  }