import { ExcelColumnData, ExcelColumnKey } from './reviewProductModel';

export const createColumnMap = (rowData: ExcelColumnData[]): Record<ExcelColumnKey, string | number | null> => {
  const columnMap: Record<ExcelColumnKey, string | number | null> = {} as Record<ExcelColumnKey, string | number | null>;
  Object.values(ExcelColumnKey).forEach(key => {
    columnMap[key] = '';
  });

  if (rowData && rowData.length > 0) {
    rowData.forEach(col => {
      if (Object.values(ExcelColumnKey).includes(col.colKey)) {
        columnMap[col.colKey] = col.value;
      }
    });
  }

  return columnMap;
};
