import { createTheme, ThemeOptions } from '@mui/material/styles';
import { darkThemeConfig } from './darkTheme';
import { lightThemeConfig } from './lightTheme';

export const lightTheme: ThemeOptions = createTheme(lightThemeConfig);

export const darkTheme: ThemeOptions = createTheme(darkThemeConfig);

export enum ThemeModes {
  light = 'light',
  dark = 'dark'
}

export const themes = {
  light: lightTheme,
  dark: darkTheme
};
