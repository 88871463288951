
import axiosConfig from 'app/axiosConfig';
import { AxiosError } from 'axios';
import { AxiosAPIError, handleError } from 'utils/apiError';
import { LocationDataInterface, LocationPayloadInterface, LocationResponseInterface } from './location-model';
import { GridRowId } from '@mui/x-data-grid-pro';

export const createLocationAsync = async (payload: LocationPayloadInterface): Promise<{
    response: LocationDataInterface;
    error: AxiosAPIError | null;
  }> => {
    try {
      const data = await axiosConfig.post(`/api/location`, payload);
      return { response: data?.data, error: null };
    } catch (err) {
      const errors = err as Error | AxiosError;
      const errr =  { data: null, error: handleError(errors) };
      throw errr
    }
};
export const updateLocationAsync = async (locationId: string, payload: LocationPayloadInterface): Promise<{
  response: LocationDataInterface;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.put(`/api/location/${locationId}`, payload);
    return { response: data?.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    const errr =  { data: null, error: handleError(errors) };
    throw errr
  }
};
export const getLocationAsync = async (): Promise<{
  response: LocationResponseInterface;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.get<LocationResponseInterface>(`/api/location/user/orgs`);
    return { response: data?.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    const errr =  { data: null, error: handleError(errors) };
    throw errr
  }
};
export const deleteLocationAsync = async (locationId: GridRowId): Promise<{
  response: LocationDataInterface;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.delete(`/api/location/${locationId}`);
    return { response: data?.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    const errr =  { data: null, error: handleError(errors) };
    throw errr
  }
};