import { InfoOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { AppDispatch } from 'app/store';
import AdditionalCosts from 'features/additionalCostFees/additionalCost';
import { TitlesEnum } from 'features/additionalCostFees/additonalCostFeesModel';
import { fetchCostFeesCategories, selectAdditionalCategories } from 'features/additionalCostFees/costFeesSlice';
import { setStepperStep } from 'features/stepper/stepperSlice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdditionalFeesGuided, selectAdditonalCostGuided } from '../guidedSlice';
import { selectUserOrgType } from 'features/guided/guidedSlice';
import { UserOrgType } from 'features/user/userModel';

const AdditionalCostGuided = ({ disabled }: { disabled?: boolean }) => {
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const additionalCosts = useSelector(selectAdditonalCostGuided);
    const additionalFees = useSelector(selectAdditionalFeesGuided);
    const categories = useSelector(selectAdditionalCategories);
    const userOrgType = useSelector(selectUserOrgType);

    useEffect(() => {
        dispatch(fetchCostFeesCategories());
    },[])

    const handleCostInformationEdit = () => {
        dispatch(setStepperStep(2));
    };

    const feesCategories = categories.filter(item => item.category === TitlesEnum.fees);
    const costCategories = categories.filter(item => item.category === TitlesEnum.cost);

    return (
        <Box sx={{maxWidth: '100%'}}>
            <Stack direction={'row'} spacing={1}>
            <Typography variant="h6" fontSize={'16px'}>
                {`${t('Flow.GuidedTab.CostInfoStep.CostInfo.additionalCost') || 'Additional Cost'}`}
            </Typography>
            {disabled && (
                <Tooltip title="Edit Cost Information">
                <EditIcon onClick={handleCostInformationEdit}
                    sx={{ color: theme.palette.action.active }} fontSize='small' />
                </Tooltip>
            )}
            </Stack>
            {!disabled && (
                <Typography
                    sx={{
                    fontSize: '14px',
                    color: theme.palette.text.secondary,
                    display: 'flex',
                    alignItems: 'center'
                    }}
                >
                    <InfoOutlined
                    sx={{ color: theme.palette.text.secondary, fontSize: '15px', marginRight: '5px' }}
                    />
                    {t('Flow.GuidedTab.CostInfoStep.additionalTitle')}
                </Typography>
            )}
            <Box sx={{ mt: 3 }}>
                <AdditionalCosts disabled={disabled} type={TitlesEnum.cost} data={additionalCosts} categories={costCategories} org_level={false} />
            </Box>
            <br />
            {
                userOrgType !== UserOrgType.direct && <Box>
                <Stack direction={'row'} spacing={1}>
                        <Typography variant="h6" fontSize={'16px'}>
                            Service Fees Charged to End Customer
                        </Typography>
                        {disabled && (
                            <Tooltip title="Edit Cost Information">
                            <EditIcon onClick={handleCostInformationEdit}
                                sx={{ color: theme.palette.action.active }} fontSize='small' />
                            </Tooltip>
                        )}
                    </Stack>
                    <Box mt={2}>
                        <AdditionalCosts disabled={disabled} type={TitlesEnum.fees} data={additionalFees} categories={feesCategories} org_level={false} />
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default AdditionalCostGuided