import { useMsal } from '@azure/msal-react';
import FeedbackSharpIcon from '@mui/icons-material/FeedbackSharp';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import { Divider, Typography, styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { messages } from 'constants/message';
import { AlertType } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { selectUserDetails } from 'features/user/userSlice';

import { PlaceSharp } from '@mui/icons-material';
import DefaultAvatar from 'assets/DefaultAvatar.png';
import Logo from 'assets/logos/logo.svg';
import { Link } from 'react-router-dom';
import { fcsl, fltoc } from 'utils/currency';
import { isEqual } from 'lodash';
import { CUSTOMER_SUPPORT_ORG_ID } from 'app/constants';

interface DashboardHeaderProps {
  drawerWidth?: number;
}

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
  height: '21px'
});

const DashboardHeader: FC<DashboardHeaderProps> = () => {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector(selectUserDetails);
  const { t } = useTranslation();

  const handleLogoutBtnClick = async () => {
    try {
      await instance.logoutRedirect();
    } catch (err) {
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 }));
    }
  };

  return (
    <Box>
      <AppBar position="fixed" sx={{ backgroundColor: 'common.black' }}>
        <Toolbar disableGutters={true} sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <img src={Logo} alt="logo" />
          </Box>
          <Tooltip title={get(userDetails, 'email', '')}>
            <Stack direction={'row'} spacing={1}>
              {userDetails && (
                <Avatar
                  alt={userDetails.fullName}
                  src={userDetails.imageUrl || DefaultAvatar}
                  sx={{ width: 24, height: 24 }}
                />
              )}
              <Typography
                component={'span'}
                sx={{ textDecoration: 'underline', fontFamily: 'Helvetica', fontSize: '16px' }}
                variant="body1"
              >
                {userDetails?.fullName}
              </Typography>
            </Stack>
          </Tooltip>

          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{ backgroundColor: 'common.white', margin: '16px' }}
          />
          <Typography sx={{ marginRight: '5px' }}>{fltoc(userDetails?.defaultLanguage)}</Typography>
          <Typography sx={{ marginRight: '20px' }}>{fcsl(userDetails?.defaultLanguage)}</Typography>
          <Stack direction={'row'} spacing={1.5} sx={{ alignItems: 'center' }}>
            <Tooltip title={t('Header.helpIcon') || 'Help'}>
              <HelpIcon sx={{ fontSize: '16px' }} />
            </Tooltip>
            {userDetails?.rootOrgId === CUSTOMER_SUPPORT_ORG_ID && (
              <Tooltip title={t('Header.feedBackIcon') || `Feedbacks`}>
                <StyledLink to="/feedback">
                  <FeedbackSharpIcon sx={{ fontSize: '16px' }} />
                </StyledLink>
              </Tooltip>
            )}
            <Tooltip title={t('Header.locationIcon') || 'Location'}>
              <StyledLink to="/locations">
                <PlaceSharp sx={{ fontSize: '16px' }} />
              </StyledLink>
            </Tooltip>
            {(
              isEqual(userDetails?.orgId, userDetails?.rootOrgId) ||
              userDetails?.rootOrgId === CUSTOMER_SUPPORT_ORG_ID
            ) && (
              <Tooltip title={t('Header.settingsIcon') || 'Settings'}>
                <StyledLink to={isEqual(userDetails?.orgId, userDetails?.rootOrgId) ||
              userDetails?.rootOrgId === CUSTOMER_SUPPORT_ORG_ID ? '/settings' : ''}>
                  <SettingsSharpIcon sx={{ fontSize: '16px' }} />
                </StyledLink>
              </Tooltip>
            )}

            <Tooltip title={t('Header.logoutIcon') || 'Logout'}>
              <LogoutIcon sx={{ fontSize: '16px', cursor: 'pointer' }} onClick={handleLogoutBtnClick} />
            </Tooltip>
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
};

export default DashboardHeader;
