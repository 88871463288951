import { Typography, Stack, Card, Theme, Tooltip, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import startCase from 'lodash/startCase';
import { memo } from 'react';

import { Criteria } from 'features/guided/guidedModel';

interface Props {
  criteriaList: Criteria[];
  theme: Theme;
  handleEdit: (stepNum: number) => void;
  title: string;
  editTitle: string;
}

const ReviewCriteria = ({ criteriaList, theme, title, handleEdit, editTitle }: Props) => {
  const criteria = criteriaList.reduce(
    (cr, currenctCr, i) => `${cr} ${i + 1}.${'\xa0'.repeat(1)}${startCase(currenctCr)}${'\xa0'.repeat(3)}`,
    ''
  );
  return (
    <Card>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['100'],
          padding: '8px 16px',
          alignItems: 'center'
        }}
      >
        <Typography variant="body1">{title}</Typography>
        <Tooltip title={`${editTitle} ${title}`}>
          <EditIcon onClick={() => handleEdit(0)} sx={{ color: theme.palette.action.active, height: 15, width: 15 }} />
        </Tooltip>
      </Stack>

      <Grid container wrap="nowrap" spacing={2} sx={{ alignItems: 'center', p: 2 }}>
        <Grid item xs zeroMinWidth>
          <Typography style={{ overflowWrap: 'break-word' }} variant="body1" sx={{ mr: 2 }}>
            {criteria}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default memo(ReviewCriteria);
