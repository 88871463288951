import { Typography, Stack, Card, Theme, styled, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { memo } from 'react';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['200'],
  ...theme.typography.body2,
  padding: '6px 16px 4px 16px'
}));

interface Props {
  theme: Theme;
  handleEdit: (stepNum: number) => void;
  salesInfo: { title: string; value: number | string | undefined }[];
  title: string;
  editTitle: string;
  flowType: TabsInterface;
}
const ReviewCustomerSalesOpportunity = ({ salesInfo, theme, title, handleEdit, editTitle, flowType }: Props) => {
  return (
    <Card>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['100'],
          padding: '8px 16px',
          alignItems: 'center'
        }}
      >
        <Typography variant="body1">{title}</Typography>
        <Tooltip title={`${editTitle} ${title}`}>
          <EditIcon
            onClick={() => (flowType === TabsInterface.guided ? handleEdit(2) : handleEdit(1))}
            sx={{ color: theme.palette.action.active, height: 15, width: 15 }}
          />
        </Tooltip>
      </Stack>

      <Stack sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {salesInfo.map((si) => (
            <Grid item xs={6} key={si.title}>
              <Item>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                  {si.title}
                </Typography>
                <Typography variant="body2">{si.value}</Typography>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Card>
  );
};

export default memo(ReviewCustomerSalesOpportunity);
