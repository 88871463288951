import { Tooltip, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { FC, ReactNode, useEffect, useState } from 'react';

import { Card } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Header from 'components/header/Header';
import { messages } from 'constants/message';
import { ApiGetSolutionGuided } from 'features/guided/guidedAPI';
import { clearGuidedStates, setGuided } from 'features/guided/guidedSlice';
import HorizontalLinearStepper from 'features/stepper/stepper.mui';
import { AlertType, StepperGuidedPages } from 'features/stepper/stepperModel';
import { selectStep, setSnackAlert } from 'features/stepper/stepperSlice';
import RoiLinearStepper from 'features/stepperRoi/stepperRoi';
import { StepperRoiPages } from 'features/stepperRoi/stepperRoiModel';
import useExcelHook from 'hooks/excelHook';
import AddDetailsGuidedPage from 'pages/add-details-guided/AddDetailsGuided';
import CostInformationGuided from 'pages/cost-information/cost-information';
import ReviewValidateProductList from 'pages/review-validate-product-list/ReviewValidateProduct';
import CriteriaRankingGuided from 'pages/criteria-ranking-guided/CriteriaRankingGuided';
import FileUploadPage from 'pages/file-upload/FileUpload';

import AutomatedLinearStepper from 'features/stepperAutomated/stepperAutomated';
import useRoute from 'hooks/route-hook';
import MachineSelectionROI from 'pages/machine-selection-roi/MachineSelectionROI';
import ResultsGuided from 'pages/results-guided/ResultsGuided';
import ReviewGuided from 'pages/review-guided/ReviewGuided';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { getGuidedStateObject } from 'utils/helpers';
import { selectActiveTabIndex, setActiveTabIndex } from './recommendationTabSlice';
import { FlowType } from 'features/guided/guidedModel';
import { StepperAutomatedPages } from 'features/stepperAutomated/stepperAutomatedModel';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}
export enum TabsInterface {
  guided = 'guided',
  roi = 'roi',
  automated = 'automated'
}

//Mapping between Tab interface and solution flowtype
export const urlToFlowTypeMapping = [
  {
    flow_type: FlowType.Automated,
    flow_type_url: TabsInterface.automated
  },
  {
    flow_type: FlowType.Guided,
    flow_type_url: TabsInterface.guided
  },
  {
    flow_type: FlowType.ROI,
    flow_type_url: TabsInterface.roi
  }
];

interface recommendationTabProps {}

function TabPanel(props: TabPanelProps) {
  useExcelHook();

  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: '24px 0px 0px 0px' }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  };
}

const recommendationTab: FC<recommendationTabProps> = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [guidedLoading, setGuidedLoading] = useState(true);
  const activeStep = useAppSelector(selectStep);
  const value = useAppSelector(selectActiveTabIndex);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isShared = params.get('shared');

  const { t } = useTranslation();
  const { type, stepName, flowId } = useParams();
  const { switchTabTo } = useRoute();

  const flow_type = {
    guided: `${t('Flow.Tabs.tabOneLabel') || 'Guided'}`,
    automated: `${t('Flow.Tabs.tabTwoLabel') || 'Automated'}`,
    roi: `${t('ROI')}`
  };

  useEffect(() => {
    dispatch(setActiveTabIndex(type === TabsInterface.guided ? 0 : type === TabsInterface.roi ? 1 : 2));
    fetchGuidedData();
    return () => {
      setGuidedLoading(false);
      dispatch(clearGuidedStates());
    };
  }, [flowId]);

  const fetchGuidedData = async () => {
    if (!flowId) return;
    try {
      const res = await ApiGetSolutionGuided(flowId);
      if (res.data) {
        dispatch(setGuided(getGuidedStateObject(res.data)));
        setGuidedLoading(false);
      }
    } catch (err) {
      console.log(err);
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 }));
    }
  };

  const automatedFlow = [
    {
      label: `${t('Flow.GuidedTab.CriteriaStep.stepLabel') || 'Criteria'}`,
      component: <CriteriaRankingGuided />,
      name: StepperAutomatedPages.CriteriaAutomated
    },
    {
      label: `${t('Flow.AutomatedTab.AreaDetailsProductUpload.title')}`,
      showOptional: true,
      skippable: false,
      name: StepperAutomatedPages.Upload,
      component: <FileUploadPage />
    },
    {
      label: `${t('Flow.AutomatedTab.ReviewAndValidateStep.title')}`,
      component: <ReviewValidateProductList />,
      name: StepperAutomatedPages.ReviewValidateAutomated,
      showOptional: true,
      skippable: false
    },
    {
      label: `${t('Flow.AutomatedTab.CostInfoStep.title')}`,
      component: <CostInformationGuided />,
      name: StepperAutomatedPages.costInfoAutomated
    },
    {
      label: `${t('Flow.AutomatedTab.ReviewStep.title')}`,
      component: <ReviewGuided />,
      name: StepperAutomatedPages.ReviewAutomated
    },
    {
      label: `${t('Flow.AutomatedTab.ResultStep.title')}`,
      component: <ResultsGuided />,
      name: StepperAutomatedPages.ResultsAutomated
    }
  ];

  const roiFlow = [
    {
      label: 'Machine Selection',
      component: <MachineSelectionROI />,
      name: StepperRoiPages.machineSelection
    },
    {
      label: 'Cost Information',
      component: <CostInformationGuided />,
      name: StepperRoiPages.costInfoGuided
    },
    {
      label: 'Review',
      component: <ReviewGuided />,
      name: StepperRoiPages.review
    },
    {
      label: 'ROI Result',
      component: <ResultsGuided />,
      name: StepperRoiPages.resultRoi
    }
  ];

  const guidedFlow = [
    {
      label: `${t('Flow.GuidedTab.CriteriaStep.stepLabel') || 'Criteria'}`,
      component: <CriteriaRankingGuided />,
      name: StepperGuidedPages.CriteriaGuided
    },
    {
      label: `${t('Flow.GuidedTab.ProductInfoStep.stepLabel') || 'Product Category and Area Details'}`,
      showOptional: true,
      skippable: false,
      name: StepperGuidedPages.DetailsGuided,
      component: <AddDetailsGuidedPage />
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.stepLabel') || 'Cost Information'}`,
      component: <CostInformationGuided />,
      name: StepperGuidedPages.costInfoGuided
    },
    {
      label: `${t('Flow.GuidedTab.ReviewStep.stepLabel') || 'Review'}`,
      component: <ReviewGuided />,
      name: StepperGuidedPages.ReviewGuided
    },
    {
      label: `${t('Flow.GuidedTab.ResultsStep.stepLabel') || 'Results'}`,
      component: <ResultsGuided />,
      name: StepperGuidedPages.ResultsGuided
    }
  ];

  const mountedGuidedFlow = guidedFlow.map((flow) => {
    if (flow.name === stepName) return flow;
    return {
      ...flow,
      component: <Box></Box>
    };
  });
  const mountedAutomatedFlow = automatedFlow.map((flow) => {
    if (flow.name === stepName) return flow;
    return {
      ...flow,
      component: <Box></Box>
    };
  });

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    dispatch(setActiveTabIndex(newValue));
    switchTabTo(newValue);
  };

  const height = 'calc(100vh - 330px)';
  const automatedHeight = 'calc(100vh - 350px)';
  return (
    <>
      <Header />
      <Box sx={{ mr: 3, ml: 3 }}>
        <Tabs value={value} onChange={handleTabChange} aria-label="recommendation tab">
          <Tab
            style={{
              pointerEvents: 'auto',
              fontWeight: 'bold',
              padding: '0 20px',
              textTransform: 'capitalize'
            }}
            disabled={value === 1 && activeStep === 1 ? true : false}
            {...a11yProps(0)}
            label={
              <Tooltip
                title={
                  isShared === 'true'
                    ? `${t('Flow.Warnings.selectProcess') || 'For Shared solutions selected process cannot be changed'}`
                    : activeStep !== 0
                    ? `${
                        t('Flow.Warnings.processChange') ||
                        'In order to change the selected process you have to go back to Criteria screen'
                      }`
                    : ''
                }
              >
                <span>{flow_type.guided}</span>
              </Tooltip>
            }
          />
          <Tab
            style={{
              pointerEvents: 'auto',
              fontWeight: 'bold',
              padding: '0 20px',
              textTransform: 'capitalize'
            }}
            {...a11yProps(2)}
            label={
              <Tooltip
                title={
                  isShared === 'true'
                    ? `${t('Flow.Warnings.selectProcess') || 'For Shared solutions selected process cannot be changed'}`
                    : activeStep !== 0
                    ? `${
                        t('Flow.Warnings.processChange') ||
                        'In order to change the selected process you have to go back to Criteria screen'
                      }`
                    : ''
                }
              >
                <span>{flow_type.roi}</span>
              </Tooltip>
            }
          />
          <Tab
            style={{
              pointerEvents: 'auto',
              fontWeight: 'bold',
              padding: '0 20px',
              textTransform: 'capitalize'
            }}
            {...a11yProps(2)}
            label={
              <Tooltip
                title={
                  isShared === 'true'
                    ? `${t('Flow.Warnings.selectProcess') || 'For Shared solutions selected process cannot be changed'}`
                    : activeStep !== 0
                    ? `${
                        t('Flow.Warnings.processChange') ||
                        'In order to change the selected process you have to go back to Criteria screen'
                      }`
                    : ''
                }
              >
                <span>{flow_type.automated}</span>
              </Tooltip>
            }
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Card
            sx={{
              padding: '15px 15px 10px 15px',
              borderTop: `3px solid ${theme.palette.primary.main}`
            }}
          >
            {type === TabsInterface.guided && (
              <HorizontalLinearStepper data={mountedGuidedFlow} loading={guidedLoading} minHeight={height} />
            )}
          </Card>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Card sx={{ padding: '15px 15px 10px 15px', borderTop: `3px solid ${theme.palette.primary.main}` }}>
            {type === TabsInterface.roi && (
              <RoiLinearStepper data={roiFlow} loading={guidedLoading} minHeight={'calc(100vh - 350px)'} />
            )}
          </Card>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Card sx={{ padding: '15px 15px 10px 15px', borderTop: `3px solid ${theme.palette.primary.main}` }}>
            {type === TabsInterface.automated && (
              <AutomatedLinearStepper data={mountedAutomatedFlow} loading={guidedLoading} minHeight={automatedHeight} />
            )}
          </Card>
        </TabPanel>
      </Box>
    </>
  );
};

export default recommendationTab;
