import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import { counterReducer, counterKey } from 'features/counter/counterSlice';
import { uploadReducer, uploadKey } from 'features/upload/AutomatedUploadSlice';
import { themeReducer, themeKey } from 'theme/themeSlice';
import { criteriaRankingReducer, criteriaRankingKey } from 'features/criteria-ranking/criteriaRankingSlice';
import { parserReducer, parserKey } from 'components/parser/parserSlice';
import { securityReducer, securityKey } from 'features/security/securitySlice';
import { costInfoReducer, costInfoKey } from 'features/costInfo/costInfoSlice';
import { stepperKey, stepperReducer } from 'features/stepper/stepperSlice';
import { userReducer, userKey } from 'features/user/userSlice';
import { guidedReducer, guidedKey } from 'features/guided/guidedSlice';
import { recommendationTabReducer, recommendationTabKey } from 'features/recomendation-tab/recommendationTabSlice';
import { createSolutionKey, createSolutionReducer } from 'features/createSolution/createSolutionSlice';
import { mySolutionsKey, mySolutionsReducer } from './../features/mySolution/mySolutionsSlice';
import { cloneSolutionKey, cloneSolutionReducer } from 'features/cloneSolution/cloneSolutionSlice';
import { solutionBodyKey, solutionBodyReducer } from 'features/solutionBody/solutionBodySlice';
import { sharedSolutionsKey, sharedSolutionsReducer } from 'features/sharedSolutions/sharedSolutionsSlice';
import { machineSelectionKey, machineSelectionReducer } from 'features/machineSettings/machineSlice';
import { costFeesKey, costFeesReducer } from 'features/additionalCostFees/costFeesSlice';
import { generalSettingsKey, generalSettingsReducer } from 'pages/setttings/General/generalSlice';
import { criteriaSettingsKey, criteriaSettingsReducer } from 'pages/setttings/Criteria/criteriaSlice';
import { feedbackKey, feedbackReducer } from 'features/userFeedbacks/feedbackSlice';
import { stepperRoiKey, stepperRoiReducer } from 'features/stepperRoi/stepperRoiSlice';
import { machineSelectionRoiKey, machineSelectionRoiReducer} from 'features/machine-selection-roi/machineSelectionRoiSlice';
import { reviewValidateProductDataKey, reviewValidateProductReducer } from 'features/reviewValidateProduct/reviewValidateProductSlice';

const rootReducer = combineReducers({
  [counterKey]: counterReducer,
  [themeKey]: themeReducer,
  [parserKey]: parserReducer,
  [criteriaRankingKey]: criteriaRankingReducer,
  [uploadKey]: uploadReducer,
  [securityKey]: securityReducer,
  [costInfoKey]: costInfoReducer,
  [stepperKey]: stepperReducer,
  [userKey]: userReducer,
  [guidedKey]: guidedReducer,
  [recommendationTabKey]: recommendationTabReducer,
  [createSolutionKey]: createSolutionReducer,
  [mySolutionsKey]: mySolutionsReducer,
  [cloneSolutionKey]: cloneSolutionReducer,
  [solutionBodyKey]: solutionBodyReducer,
  [sharedSolutionsKey]: sharedSolutionsReducer,
  [machineSelectionKey]: machineSelectionReducer,
  [costFeesKey]: costFeesReducer,
  [generalSettingsKey]: generalSettingsReducer,
  [criteriaSettingsKey]: criteriaSettingsReducer,
  [feedbackKey]: feedbackReducer,
  [stepperRoiKey]: stepperRoiReducer,
  [machineSelectionRoiKey]: machineSelectionRoiReducer,
  [reviewValidateProductDataKey]: reviewValidateProductReducer
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
  whitelist: [themeKey, userKey]
};

const persistedCounterReducer = persistReducer(persistConfig, rootReducer);
const serializableCheck = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
  }
};
export { persistedCounterReducer, serializableCheck };
