import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { memo, useEffect, useState } from 'react';

import { useAppSelector } from 'app/hooks';
import {
  selectCustomerViewResults,
  selectIncludeConsignment,
  selectSolutionSpecificCurrency
} from 'features/guided/guidedSlice';
import useCurrency from 'hooks/currency-hook';
interface Column {
  id: 'reductionPM' | 'reductionPY' | 'wcPM' | 'wcPY' | 'epPM' | 'epPY' | 'omPM' | 'omPY' | 'tsPM' | 'tsPY';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
  translationKey?: string;
}

const defaultColumns: Column[] = [
  { id: 'reductionPM', label: 'Per Month', minWidth: 165, translationKey: 'perMonthLabel' },
  { id: 'reductionPY', label: 'Per Year', minWidth: 165, translationKey: 'perYearLabel' },
  { id: 'wcPM', label: 'Per Month', minWidth: 165, translationKey: 'perMonthLabel' },
  { id: 'wcPY', label: 'Per Year', minWidth: 165, translationKey: 'perYearLabel' },
  { id: 'epPM', label: 'Per Month', minWidth: 165, translationKey: 'perMonthLabel' },
  { id: 'epPY', label: 'Per Year', minWidth: 165, translationKey: 'perYearLabel' },
  { id: 'omPM', label: 'Per Month', minWidth: 165, translationKey: 'perMonthLabel' },
  { id: 'omPY', label: 'Per Year', minWidth: 165, translationKey: 'perYearLabel' },
  { id: 'tsPM', label: 'Per Month', minWidth: 165, translationKey: 'perMonthLabel' },
  { id: 'tsPY', label: 'Per Year', minWidth: 165, translationKey: 'perYearLabel' }
];

interface Data {
  reductionPM: number;
  reductionPY: number;
  wcPM?: number;
  wcPY?: number;
  epPM: number;
  epPY: number;
  omPM: number;
  omPY: number;
  tsPM: number;
  tsPY: number;
}

function createData(
  reductionPM: number,
  reductionPY: number,
  wcPM: number,
  wcPY: number,
  epPM: number,
  epPY: number,
  omPM: number,
  omPY: number,
  tsPM: number,
  tsPY: number
): Data {
  return { reductionPM, reductionPY, wcPM, wcPY, epPM, epPY, omPM, omPY, tsPM, tsPY };
}

const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: 700,
    border: `1px solid ${theme.palette.divider}`
  },
  '&:last-child': {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  }
}));

const StyledTableSubHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: 700,
    border: `1px solid ${theme.palette.divider}`
  }
}));

const StyledTablBodyCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    border: `1px solid ${theme.palette.divider}`
  }
  // '&:last-child': {
  //   border: `1px solid ${theme.palette.primary.main}`
  // }
}));

const SavingsTable = () => {
  const { t } = useTranslation();
  const { formatToCurrencyWithSymbol } = useCurrency();
  const customerViewResults = useAppSelector(selectCustomerViewResults);
  const solSpecificCurrency = useAppSelector(selectSolutionSpecificCurrency);
  const includeConsignment = useAppSelector(selectIncludeConsignment);

  const [columns, setcolumns] = useState<Column[]>(defaultColumns);

  const defaultHeader: string[] = [
    `${t('Flow.GuidedTab.ResultsStep.CustomerViewSection.consumptionReductionLabel') || 'Consumption Reduction'}`,
    `${t('Flow.GuidedTab.ResultsStep.CustomerViewSection.workingCapitalLabel') || 'Working Capital'}`,
    `${t('Flow.GuidedTab.ResultsStep.CustomerViewSection.employeeProductivityLabel') || 'Employee Productivity'}`,
    `${t('Flow.GuidedTab.ResultsStep.CustomerViewSection.orderManagementLabel') || 'Order Management'}`,
    `${t('Flow.GuidedTab.ResultsStep.CustomerViewSection.totalSavingsLabel') || 'TOTAL SAVINGS'}`
  ];

  const [header, setHeader] = useState<string[]>(defaultHeader);

  const { consumption_reduction, working_capital, employee_productivity, order_management, total_savings } =
    customerViewResults;
  const rows = [
    createData(
      consumption_reduction.per_month,
      consumption_reduction.per_year,
      working_capital.per_month,
      working_capital.per_year,
      employee_productivity.per_month,
      employee_productivity.per_year,
      order_management.per_month,
      order_management.per_year,
      total_savings.per_month,
      total_savings.per_year
    )
  ];

  useEffect(() => {
    setcolumns(includeConsignment ? defaultColumns : columns.filter((col) => col.id !== 'wcPY' && col.id !== 'wcPM'));
    setHeader(includeConsignment ? defaultHeader : header.filter((_el, i) => i !== 1));
  }, [includeConsignment]);

  return (
    <TableContainer component={Paper} sx={{ mt: 4 }}>
      <Table size="small" sx={{ width: '100%' }}>
        <TableHead>
          <StyledTableHeadRow>
            {header.map((title) => (
              <StyledTableHeadCell align="center" colSpan={2} key={title}>
                {title}
              </StyledTableHeadCell>
            ))}
          </StyledTableHeadRow>
          <TableRow>
            {columns.map((column) => {
              const translatedName = t(`Flow.CommonLabels.${column.translationKey}`);
              return (
                <StyledTableSubHeadCell key={column.id} align={column.align} style={{ top: 57 }}>
                  {translatedName}
                </StyledTableSubHeadCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            return (
              <TableRow hover key={i}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <StyledTablBodyCell key={column.id} align={column.align}>
                      {solSpecificCurrency && formatToCurrencyWithSymbol(value)}
                    </StyledTablBodyCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(SavingsTable);
