import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';
import { StepperGuidedPages } from 'features/stepper/stepperModel';
import { StepperAutomatedPages } from 'features/stepperAutomated/stepperAutomatedModel';
import { StepperRoiPages } from 'features/stepperRoi/stepperRoiModel';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// CONVERT PAGE ENUM TO ARRAY BASED ON POSITION AND IS ORDERED
const roiSteps: string[] = Object.values(StepperRoiPages);
const guidedSteps: string[] = Object.values(StepperGuidedPages);
const automatedSteps: string[] = Object.values(StepperAutomatedPages);

const useRoute = () => {
  // EXTRACT PARAMS FROM URL
  const { type, flowId } = useParams();
  const navigate = useNavigate();

  // FUNCTION TO HANDLE ROUTINGH
  const routeTo = useCallback(
    (stepNo: number) => {
      // HANDLE ROUTE BASED ON NUMBER
      const steps =
        type === TabsInterface.guided ? guidedSteps : type === TabsInterface.roi ? roiSteps : automatedSteps;

      // GET STEO NAME USING INDEXING OVER ARRAY
      const step = steps[stepNo];

      // NAVIGATE TO URL
      navigate(`/flow/${flowId}/${type}/${step}`);
    },
    [type, flowId]
  );

  const switchTabTo = useCallback((index: number) => {
    // GET TAB TYPE BASED ON INDEX
    const type = index === 0 ? TabsInterface.guided : index === 1 ? TabsInterface.roi : TabsInterface.automated;
    // HANDLE ROUTE BASED ON NUMBER
    const steps = type === TabsInterface.guided ? guidedSteps : type === TabsInterface.roi ? roiSteps : automatedSteps;

    // GET STEO NAME USING INDEXING OVER ARRAY
    const step = steps[0];
    navigate(`/flow/${flowId}/${type}/${step}`);
  }, []);

  return { routeTo, switchTabTo };
};

export default useRoute;
