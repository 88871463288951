import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import { getSharedSolutionAsync } from './sharedSolutionsAPI';
import { CreateSolutionResponseModal, StatusValues } from 'features/mySolution/mySolutionsModel';
import { GridRowId } from '@mui/x-data-grid-pro';

export interface SharedSolutionsState {
  sharedSolutionsList: CreateSolutionResponseModal[] | undefined;
  status: 'idle' | 'loading' | 'failed' | 'success';
  userDetailStatus: 'idle' | 'loading' | 'failed' | 'success';
  loading: boolean;
  orgLoading: boolean;
  currentRowId: GridRowId | undefined;
  openCloneModelShare: boolean;
  statusFilterSelected: string[],
  inactiveChecked: boolean,
}

const initialState: SharedSolutionsState = {
  sharedSolutionsList: [],
  status: 'idle',
  loading: false,
  userDetailStatus: 'idle',
  orgLoading: true,
  currentRowId: undefined,
  openCloneModelShare: false,
  statusFilterSelected: [StatusValues.active, StatusValues.onHold],
  inactiveChecked: false
};

export const getSharedSolutionsList = createAsyncThunk(
  `${ReducerKeys.sharedSolutions}/getSharedSolutionList`,
  async (payload: { orgArrayPayload: string[]; status: string[] }) => {
    const { orgArrayPayload, status } = payload;
    const { response } = await getSharedSolutionAsync(orgArrayPayload, status);
    return response;
  }
);

export const sharedSolutionsStateSlice = createSlice({
  name: ReducerKeys.sharedSolutions,
  initialState,
  reducers: {
    setOpenCloneModelShare: (state, action: PayloadAction<boolean>) => {
      state.openCloneModelShare = action.payload;
    },
    setCurrentRowId: (state, action: PayloadAction<GridRowId | undefined>) => {
      state.currentRowId = action.payload;
    },
    setStatusFilterSharedSelected: (state, action: PayloadAction<string[]>) => {
      state.statusFilterSelected = action.payload;
    },
    setSharedInactiveCheckbox: (state, action: PayloadAction<boolean>) => {
      state.inactiveChecked = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSharedSolutionsList.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(getSharedSolutionsList.fulfilled, (state, action) => {
        state.status = 'success';
        state.loading = false;
        state.sharedSolutionsList = action.payload;
      })
      .addCase(getSharedSolutionsList.rejected, (state) => {
        state.status = 'failed';
        state.loading = false;
      })
  }
});

export const { setOpenCloneModelShare, setCurrentRowId, setStatusFilterSharedSelected, setSharedInactiveCheckbox } = sharedSolutionsStateSlice.actions;

export const selectSharedSolutionsState = (state: RootState) => state.sharedSolutions;
export const selectSharedSolutionsLoadingState = (state: RootState) => state.sharedSolutions.loading;
export const selectSharedSolutionsList = (state: RootState) => state.sharedSolutions.sharedSolutionsList;
export const selectCurrentRowId = (state: RootState) => state.sharedSolutions.currentRowId;
export const selectStatusFiltersShared = (state: RootState) => state.sharedSolutions.statusFilterSelected;
export const selectSharedInactiveCheckboxStatus = (state: RootState) => state.sharedSolutions.inactiveChecked;

export const selectSharedSolutionOpenState = (state: RootState) => state.sharedSolutions.openCloneModelShare;

export const { reducer: sharedSolutionsReducer, name: sharedSolutionsKey } = sharedSolutionsStateSlice;
