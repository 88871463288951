import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReducerKeys } from 'app/reducerKeys';
import { RootState } from 'app/store';

export interface RecommendationTabState {
  activeTabIndex: number;
}

const initialState: RecommendationTabState = { activeTabIndex: 0 };

export const recommendationTabSlice = createSlice({
  name: ReducerKeys.recommendationTab,
  initialState,
  reducers: {
    setActiveTabIndex: (state, action: PayloadAction<number>) => {
      state.activeTabIndex = action.payload;
    }
  },
  extraReducers() {}
});

export const { setActiveTabIndex } = recommendationTabSlice.actions;

export const selectActiveTabIndex = (state: RootState) => state.recommendationTab.activeTabIndex;

export const { reducer: recommendationTabReducer, name: recommendationTabKey } =
  recommendationTabSlice;
