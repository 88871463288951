import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format as formatDate, isDate, formatRelative, formatDistance } from 'date-fns';
import { enUS, enGB, da, fr } from 'date-fns/locale';
import { CmUserLanguage, Currency, CmUserCurrencyMapping } from 'features/user/userModel';
//import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en/translations.json';
import daTranslation from './locales/da/translation.json';
import frTranslation from './locales/fr/translations.json'

export const locales = {
  [CmUserLanguage.EnglishUS]: enUS,
  [CmUserLanguage.English]: enUS,
  [CmUserLanguage.EnglishUK]: enGB,
  [CmUserLanguage.Danish]: da,
  [CmUserLanguage.French]: fr,
  [CmUserLanguage.EnglishEurope]: enUS
};

export const defaultNS = 'translations';
export const resources = {
  [CmUserLanguage.EnglishUS]: {
    translations: enTranslation
  },
  [CmUserLanguage.English]: {
    translations: enTranslation
  },
  [CmUserLanguage.EnglishUK]: {
    translations: enTranslation
  },
  [CmUserLanguage.Danish]: {
    translations: daTranslation
  },
  [CmUserLanguage.French]: {
    translations: frTranslation
  }
};

i18n
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: CmUserLanguage.EnglishUS,
    lng: CmUserLanguage.EnglishUS,
    resources,
    ns: ['translations'],
    defaultNS,
    interpolation: {
      format: function (value, format, lng) {
        const language = lng as CmUserLanguage;
        if (format === 'number') {
          return new Intl.NumberFormat(language).format(value);
        }
        if (format === 'currency') {
          const currencyType = CmUserCurrencyMapping.find((el) => el.lang === language)?.currency || Currency.Doller;
          return new Intl.NumberFormat(language, {
            style: 'currency',
            currency: currencyType
          }).format(value);
        }
        if (format === 'uppercase') return value.toUpperCase();
        if (isDate(value)) {
          const locale = locales[language];

          if (format === 'short') return formatDate(value, 'P', { locale });
          if (format === 'long') return formatDate(value, 'PPPP', { locale });
          if (format === 'relative') return formatRelative(value, new Date(), { locale });
          if (format === 'ago')
            return formatDistance(value, new Date(), {
              locale,
              addSuffix: true
            });

          return format && formatDate(value, format, { locale });
        }
        return value;
      },
      escapeValue: false // react is safe from xss
    }
  });

i18n.languages = [CmUserLanguage.EnglishUS, CmUserLanguage.EnglishUK, CmUserLanguage.Danish];

export default i18n;

/**
 * examples 
  t('g.ex.formatNumber', { num: 300000 }),
  t('g.ex.formatCurrency', { price: 69.99 }),
  t('g.ex.formatDate', { date: new Date() }),
 */
