import axiosConfig from 'app/axiosConfig';
import { AxiosError } from 'axios';
import { AxiosAPIError, handleError } from 'utils/apiError';
import { Charge, ChargePayload, CostFeesCategory } from './additonalCostFeesModel';


export const getCostFeesCategories = async (): Promise<{
  data: CostFeesCategory[] | [];
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.get<CostFeesCategory[]>(`/api/charges?chargesCategory=fees,costs`);
    return { data: data?.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    return { data: [], error: handleError(errors) };
  }
};

export const updateAdditionalCharges = async (payload: ChargePayload): Promise<{
  data: CostFeesCategory[] | [];
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axiosConfig.put(`/api/criborg`, payload);
    return { data: data?.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    return { data: [], error: handleError(errors) };
  }
};

export function combineCribTypeObjects(array: Charge[]): Charge[] {
  const combinedObjects: { [key: string]: Charge } = {};
  array.forEach(obj => {
      const key = `${obj.name}_${obj.charges_key}`;
      if (!combinedObjects[key]) {
          combinedObjects[key] = {
              ...obj,
              cribType: obj.cribType || 'All',
              cribs: [] as Charge[]
          };
      }
      if (obj.cribType === 'All') {
          combinedObjects[key].value = obj.value;
      }else {
        const cribs = combinedObjects[key].cribs || [];
        combinedObjects[key].cribs = [...cribs, {...obj, cribType: obj.cribType || 'All', value: obj.value }];
    } 
  });
  return Object.values(combinedObjects) || [];
}