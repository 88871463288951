import { useCallback, useState, FC, useEffect } from 'react';
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  SelectChangeEvent,
  Backdrop
} from '@mui/material';
import { useDropzone, FileWithPath, FileRejection } from 'react-dropzone';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useSelector } from 'react-redux';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { AlertType } from 'features/stepper/stepperModel';
import { useParams } from 'react-router-dom';
import ProgressBarWithText from 'components/circular-progress/ProgressBarWithText';
import { excelFileData, getFileParsingData, retrivePouData, getPreasignedURL } from './uploadAPI';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  selectGuidedState,
  selectPouData,
  setPouData,
  setSelectedPOU,
  set_is_pou_selected
} from 'features/guided/guidedSlice';
import ReplaceFileDialog from './ReplaceFileDialog';

interface UploadProps {}

const LoadingState = {
  Idle: 'idle',
  Preparing: 'preparing',
  Uploading: 'uploading',
  Parsing: 'parsing',
  Success: 'success'
};

export const Upload: FC<UploadProps> = () => {
  const { selected_pou } = useAppSelector(selectGuidedState);

  const uploadedFileData = useSelector(selectPouData);

  const [replaceFileDialogOpen, setReplaceFileDialogOpen] = useState(false);
  const { t } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [parsingProgress, setParsingProgress] = useState(0);
  const [files, setFiles] = useState<FileWithPath>();
  const [fileName, setFileName] = useState<string>();
  const { flowId } = useParams();
  const initiateUploadText = t('Flow.AutomatedTab.AreaDetailsProductUpload.initiate_upload');
  const uploadSuccessText = t('Flow.AutomatedTab.AreaDetailsProductUpload.upload_success');
  const uploadFailureText = t('Flow.AutomatedTab.AreaDetailsProductUpload.upload_failure');
  const [stepText, setStepText] = useState(initiateUploadText);
  const [loadingState, setLoadingState] = useState(LoadingState.Idle);
  const dispatch = useAppDispatch();
  const [isFetchPOUDataLoading, setIsFetchPOUDataLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        if (uploadedFileData && uploadedFileData.length > 0 && uploadedFileData[0] != 'null') {
          setFiles(acceptedFiles[0]);
          handleReplaceFileDialogOpen();
        } else {
          if (!isUploadingOrParsing) {
            await handleSubmit(acceptedFiles[0]);
          }
        }
      }

      if (fileRejections.length > 0) {
        dispatch(
          setSnackAlert({
            open: true,
            type: AlertType.error,
            message: `${t('Flow.Warnings.uploadInvalid')}`,
            timeout: 3000
          })
        );
      }
    },
    [uploadedFileData]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
    accept: {
      'text/*': ['.xls', '.xlsx']
    },
    maxFiles: 1
  });

  const fetchPOUData = async () => {
    try {
      const { response, error } = await retrivePouData(flowId);
      if (error) {
        dispatch(
          setSnackAlert({
            open: true,
            type: AlertType.error,
            message: `${t('Flow.Warnings.api_failure')}`,
            timeout: 3000
          })
        );
        return;
      }
      if (response) {
        dispatch(setPouData(response.pou_list));
        dispatch(setSelectedPOU(response.selected_pou));
        setFileName(response.file_name);
      }
    } catch (error) {
      dispatch(
        setSnackAlert({
          open: true,
          type: AlertType.error,
          message: `${t('Flow.Warnings.api_failure')}`,
          timeout: 3000
        })
      );
    }
  };

  useEffect(() => {
    setIsFetchPOUDataLoading(true);
    fetchPOUData()
      .then(() => setIsFetchPOUDataLoading(false))
      .catch(() => setIsFetchPOUDataLoading(false));
  }, [flowId]);

  const handleParsingProgress = (progress: number) => {
    setParsingProgress(progress);
  };

  const handleSubmit = async (file: FileWithPath) => {
    setFileName(file.name);
    if (!file) return;
    if (!flowId) return;
    dispatch(
      setSnackAlert({
        open: false,
        type: AlertType.info,
        message: ''
      })
    );

    setLoadingState(LoadingState.Preparing);
    setStepText(initiateUploadText);
    setUploadProgress(0);
    dispatch(setSelectedPOU(''));
    try {
      const payloadGetPreasignedURL = {
        flow_id: flowId,
        file_name: file.name
      };
      const resp = await getPreasignedURL(payloadGetPreasignedURL);
      if (resp.error) {
        dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'API Failed', timeout: 3000 }));
        return;
      }
      setLoadingState(LoadingState.Uploading);
      setStepText(`${t('Flow.AutomatedTab.AreaDetailsProductUpload.upload_file')}`);
      setUploadProgress(0);
      await simulateFileUpload(file, (progress) => setUploadProgress(progress));
      if (resp.response) {
        try {
          await excelFileData(file, resp.response, (progress) => {
            return setUploadProgress(progress);
          });
          setUploadProgress(100);
          setLoadingState(LoadingState.Parsing);
          setStepText(`${t('Flow.AutomatedTab.AreaDetailsProductUpload.parse_file')}`);
          setParsingProgress(0);
          await simulateFileUpload(file, (progress) => setParsingProgress(progress));
          const response = await getFileParsingData(resp.response.file_id, handleParsingProgress);

          if (response.response?.POU.length === 0) {
            dispatch(
              setSnackAlert({
                open: true,
                type: AlertType.error,
                message: 'No POU Data Available',
                timeout: 3000
              })
            );
            setLoadingState(LoadingState.Idle);
            return;
          }
          dispatch(setPouData(response.response?.POU ?? []));
          setParsingProgress(100);
          if (response) {
            setLoadingState(LoadingState.Success);
            dispatch(setSnackAlert({ open: true, type: AlertType.success, message: uploadSuccessText, timeout: 3000 }));
          } else {
            setLoadingState(LoadingState.Idle);
            dispatch(setSnackAlert({ open: true, type: AlertType.error, message: uploadFailureText, timeout: 3000 }));
          }
        } catch (error) {
          let errorMessage = uploadFailureText;
          if (error !== null && typeof error === 'object' && 'message' in error) {
            errorMessage = (error as { message: string }).message;
          }
          setLoadingState(LoadingState.Idle);
          dispatch(setSnackAlert({ open: true, type: AlertType.error, message: errorMessage, timeout: 15000 }));
        }
      }
    } catch (error) {
      setLoadingState(LoadingState.Idle);
      setFiles(undefined);
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: uploadFailureText, timeout: 3000 }));
    }
  };

  const simulateFileUpload = async (file: FileWithPath, onProgress: (progress: number) => void) => {
    const totalSize = file.size;
    let uploadedSize = 0;
    let progress = 0;

    const simulateUpload = () => {
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          uploadedSize += 1024 * 100;
          if (uploadedSize > totalSize) {
            uploadedSize = totalSize;
          }

          progress = (uploadedSize / totalSize) * 100;
          onProgress(progress);

          if (progress < 100) {
            resolve(simulateUpload());
          } else {
            resolve();
          }
        }, 500);
      });
    };

    await simulateUpload();
  };

  const isUploadingOrParsing =
    loadingState === LoadingState.Uploading ||
    loadingState === LoadingState.Parsing ||
    loadingState === LoadingState.Preparing;

  const handlePouChange = (event: SelectChangeEvent<string>) => {
    dispatch(setSelectedPOU(event.target.value));
    event.target.value && dispatch(set_is_pou_selected(true));
  };

  const handleReplaceFileDialogOpen = () => {
    setReplaceFileDialogOpen(true);
  };

  const handleReplaceFileDialogClose = () => {
    setReplaceFileDialogOpen(false);
  };

  const handleReplaceFileConfirm = async () => {
    setReplaceFileDialogOpen(false);
    files && (await handleSubmit(files));
    open();
  };

  const handleIconTextUploadClick = () => {
    if (!isUploadingOrParsing) {
      open();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 320,
        justifyContent: 'center',
        minWidth: '300px'
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} disabled={isUploadingOrParsing} />
      <Stack direction="row">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            sx={{
              width: '100%',
              padding: 1,
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isFetchPOUDataLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <CloudUploadOutlinedIcon
                onClick={handleIconTextUploadClick}
                sx={{ color: 'primary.main', fontSize: 50, cursor: isUploadingOrParsing ? 'not-allowed' : 'pointer' }}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {isDragActive ? (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Typography component="span">{t('Flow.AutomatedTab.AreaDetailsProductUpload.drop_file')}</Typography>
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    sx={{
                      textDecoration: 'underline',
                      color: 'primary.main',
                      cursor: 'pointer'
                    }}
                    component="span"
                    onClick={handleIconTextUploadClick}
                    variant="body1"
                  >
                    {t('Flow.AutomatedTab.AreaDetailsProductUpload.upload_btn')}
                  </Typography>
                  <Typography variant="body1"> {t('Flow.AutomatedTab.AreaDetailsProductUpload.drag_drop')} </Typography>
                </Stack>
              )}
            </Box>
            <Box>
              <FormControl
                fullWidth
                sx={{
                  width: '30%',
                  minWidth: '276px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                <InputLabel id="select-label-area" sx={{ marginTop: '20px' }}>
                  Select POU
                </InputLabel>
                <Select
                  variant="outlined"
                  sx={{
                    width: '276px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px'
                  }}
                  labelId="area-select-label"
                  id="area-select"
                  value={selected_pou || undefined}
                  disabled={isUploadingOrParsing}
                  label="Assign Area"
                  onChange={handlePouChange}
                  size="medium"
                >
                  {uploadedFileData &&
                    uploadedFileData.map((value, key) => (
                      <MenuItem value={value} key={value + key}>
                        {value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            {loadingState === LoadingState.Preparing ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
              >
                <CircularProgress />
                <Typography sx={{ paddingLeft: '20px' }} variant="body1" component="span">
                  {initiateUploadText}
                </Typography>
              </Box>
            ) : (
              <Stack
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {loadingState === LoadingState.Uploading ? (
                  <ProgressBarWithText progress={uploadProgress} text={stepText} />
                ) : loadingState === LoadingState.Parsing ? (
                  <ProgressBarWithText progress={parsingProgress} text={stepText} />
                ) : null}
              </Stack>
            )}
            <Typography component="span" color="success.main">
              {fileName || ''}
            </Typography>
          </Stack>
        </Box>
        <ReplaceFileDialog
          open={replaceFileDialogOpen}
          onConfirm={handleReplaceFileConfirm}
          onCancel={handleReplaceFileDialogClose}
        />
      </Stack>
    </Box>
  );
};
