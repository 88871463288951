import { AxiosError } from 'axios';
import axiosConfig from 'app/axiosConfig';
import { RowExcel } from 'components/parser/parserModel';
import { addData, selectExcelData } from 'components/parser/parserSlice';
import { selectCostInfoState, setCostInformation } from 'features/costInfo/costInfoSlice';
import { Criteria } from 'features/criteria-ranking/criteriaRankingModel';
import { selectCriteriaList, setCriteriaOnLoad } from 'features/criteria-ranking/criteriaRankingSlice';
import {
  selectInventoryType,
  selectItemQuantityList,
  selectSecurityLevel,
  setInventoryType,
  setItemQuantityListOnLoad,
  setSecurity
} from 'features/security/securitySlice';
import { selectStepToSave, setStepToSave } from 'features/stepper/stepperSlice';
import { selectFlowId, setFlowDataResult } from 'features/upload/AutomatedUploadSlice';
import { updateFlowData } from 'features/upload/uploadAPI';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { convertKeyToSnake, convertSnakeToKey } from 'utils/helpers';
import { handleError, AxiosAPIError } from 'utils/apiError';
import { DataType, CostPayload, ResponseItemData, ResponseGetFlowDataByID } from 'features/upload/uploadModel';
import { StepperPages } from 'features/stepper/stepperModel';
import { selectActiveTabIndex } from 'features/recomendation-tab/recommendationTabSlice';
import { formatObjectValues } from 'utils/currency';

const useExcelHook = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const flow_id = useSelector(selectFlowId);
  const stepToSave = useSelector(selectStepToSave);
  const security = useSelector(selectSecurityLevel);
  const selectedCriteriaList: Criteria[] = useSelector(selectCriteriaList);
  const itemQuantityList = useSelector(selectItemQuantityList);
  const review = useSelector(selectExcelData);
  const costInfo = useSelector(selectCostInfoState);
  const inventoryType = useSelector(selectInventoryType);
  const flowId = searchParams.get('flowId');
  const value = useSelector(selectActiveTabIndex);

  // On file upload add search params to url for retention
  useEffect(() => {
    if (flow_id) {
      setSearchParams({ flowId: flow_id });
    }
  }, [flow_id]);

  // On receiving flow Id fetch data on Load
  useEffect(() => {
    if (!flowId || value === 0) return;
    const getflowData = async (): Promise<{
      data: ResponseGetFlowDataByID | null;
      error: AxiosAPIError | null;
    }> => {
      try {
        //2
        const res = await axiosConfig.get<ResponseGetFlowDataByID>(`/api/flow/${flowId}?crib_config=true`);
        // 1. HANDLE CRITERIA
        res.data.criteria_ranking?.length && dispatch(setCriteriaOnLoad(res.data.criteria_ranking));

        // 2. HANDLE SECURITY
        res.data.flow_security && dispatch(setSecurity(res.data.flow_security));

        // 3. HANDLE INVENTORY DATA
        res.data.flow_inventory_data?.length && dispatch(setItemQuantityListOnLoad(res.data.flow_inventory_data));

        // 4. HANDLE ITEM DATA
        const data = res.data.item_data?.map((item: ResponseItemData) => {
          return convertSnakeToKey(item);
        });
        data?.length && dispatch(addData({ info: {}, table: data }));

        // 5. HANDLE INVENTORY TYPE
        res.data.flow_inventory_types?.length && dispatch(setInventoryType(res.data.flow_inventory_types[0]));
        const payload = createCostPayload({
          ...res.data.cost_info,
          type: DataType.Incoming
        });
        if (JSON.stringify(payload) !== '{}')
          for (const [name, value] of Object.entries(payload)) {
            const dataParam = { name, value: value?.toString() };
            dispatch(setCostInformation(dataParam));
          }
        return { data: res.data, error: null };
      } catch (err) {
        const errors = err as Error | AxiosError;
        return { data: null, error: handleError(errors) };
      }
    };
    value === 1 && getflowData();
  }, [searchParams, value]);

  // Handle next button click here
  useEffect(() => {
    if (!stepToSave || value === 0) return;
    dispatch(setStepToSave(undefined));
    const handleFlowDataUpdate = async () => {
      if (stepToSave === StepperPages.Upload) {
        const cost_info = createCostPayload({
          ...costInfo,
          type: DataType.Outgoing
        });
        const item_data = review?.table?.map((item: RowExcel) => {
          return convertKeyToSnake(item);
        });
        const payload = {
          security,
          criteria_ranking: selectedCriteriaList,
          flow_inventory_data: itemQuantityList,
          flow_inventory_types: [inventoryType],
          item_data,
          cost_info: formatObjectValues(cost_info),
          flowId
        };
        await updateFlowData(payload);
      }
      if (stepToSave === StepperPages.Review) {
        // Next on Review page
        const item_data = review?.table?.map((item: RowExcel) => convertKeyToSnake(item));
        const payload = {
          item_data,
          flowId
        };
        const updatedFlowRes = await updateFlowData(payload);
        dispatch(setFlowDataResult(updatedFlowRes.data?.crib_config));
      }
    };
    handleFlowDataUpdate();
  }, [stepToSave, costInfo, security, flowId, selectedCriteriaList, itemQuantityList, inventoryType, review]);
};

const createCostPayload = (data: CostPayload) => {
  if (data.type === DataType.Incoming) {
    return {
      stateSales: data.state_sales_text,
      laborRatePerHour: data.labor_rate_per_hour,
      stockingLabor: data.stocking_labor_per_week_hours,
      freightEstimate: data.freight_estimate,
      estimatedRepair: data.estimated_repair_mtc_cost_over_term
    };
  } else if (data.type === DataType.Outgoing) {
    return {
      state_sales_text: data.state_sales,
      labor_rate_per_hour: data.labor_rate_per_hour,
      stocking_labor_per_week_hours: data.stocking_labor,
      freight_estimate: data.freight_estimate,
      estimated_repair_mtc_cost_over_term: data.estimated_repair
    };
  } else return {};
};
export default useExcelHook;
