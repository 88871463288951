import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material';
import { MachineSelection } from 'features/guided/guidedModel';
import { changeGuidedAdditionalCostsRow, removeCribTypesInSolution, selectGuidedState, selectMachineSelectionGuided, selectedAccuPortMachineGuided, selectedExpressMachineGuided, selectedRfidMachineGuided, setAdditionalCostGuided, setAdditionalCostsCribsInSolution, setAdditionalFeesCribsInSolution, setAdditionalFeesGuided, updateCribCostTypeValueInSolution, updateCribFeesTypeValueInSolution } from 'features/guided/guidedSlice';
import { selectAccuPortMachine, selectExpressMachine, selectMachineSelectedData, selectRfidMachine } from 'features/machineSettings/machineSlice';
import { CmUserLanguage } from 'features/user/userModel';
import i18n from 'i18n/config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fcsl } from 'utils/currency';
import { getExtraCribs } from 'utils/helpers';
import { AdditionalCostFeesEnum, Charge, CostFeesCategory, TitlesEnum } from './additonalCostFeesModel';
import { updateAdditionalCharges } from './costFeesAPI';
import { changeAdditionalCostsRow, removeCribTypes, setAdditionalCost, setAdditionalCostsCribs, setAdditionalFees, setAdditionalFeesCribs, updateCribCostTypeValue, updateCribFeesTypeValue } from './costFeesSlice';
import { useAppSelector } from 'app/hooks';
import { useParams } from 'react-router-dom';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';

export interface Additional {
    type: TitlesEnum;
    data: Charge[] | [];
    categories: CostFeesCategory[];
    org_level?: boolean,
    disabled?: boolean
}

const AdditionalCosts = ({ type, data, categories, org_level = false, disabled = false }: Additional) => {
    const title: string = type?.charAt(0).toUpperCase() + type?.slice(1);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [save, setSave] = useState(false);
    const selectedValues = useSelector(selectMachineSelectionGuided);
    const selected_machines_settings = useSelector(selectMachineSelectedData);
    const rfid = useSelector(selectRfidMachine);
    const express = useSelector(selectExpressMachine);
    const accuPort = useSelector(selectAccuPortMachine);
    const rfidGuided = useSelector(selectedRfidMachineGuided) || false;
    const expressGuided = useSelector(selectedExpressMachineGuided) || false;
    const accuPortGuided = useSelector(selectedAccuPortMachineGuided) ||  false;
    const settingsMachines = getExtraCribs(selected_machines_settings || [], express, rfid, accuPort);
    const solutionMachines = getExtraCribs(selectedValues, expressGuided, rfidGuided, accuPortGuided);
    const guidedState = useAppSelector(selectGuidedState);
    const selectedMachinesROI = guidedState.machine_selection_roi?.map(item => item.machine_type);
    const { type: flowType } = useParams();
    const options = flowType === TabsInterface.roi ? selectedMachinesROI : org_level ? settingsMachines : solutionMachines || [] ;

    const formatMachineSelection = (value: string) => {
        return value.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, (match) => {
          return match.toUpperCase();
        });
    };
    const renderValue = (selected: string) => {
        const option = options.find((option) => option === selected);
        return option ? formatMachineSelection(option) : formatMachineSelection(selected);
      };

    useEffect(() => {
        const timerId = setTimeout(() => {
            if(org_level && save){
                let cribs : Charge[] | [] = [];
                const charges = data.filter(item => {
                    const itemCribs = item.cribs?.map(obj => ({ ...item, value: obj.value, cribType: obj.cribType }))
                    if(itemCribs?.length){
                        const validCribs = itemCribs.filter(item => item.charges_key && item.name && item.value && item.cribType).map(item => {
                            delete item.cribs;
                            return item;
                        })
                        cribs = [...cribs, ...validCribs];
                    }
                    return item.charges_key && item.name && item.value
                });
                // Code to run after the timeout
                let payload = {};
                if(type === TitlesEnum.cost){
                    payload = { [AdditionalCostFeesEnum.additionalCosts]: [...charges, ...cribs]}
                }else {
                    payload = { [AdditionalCostFeesEnum.additionalFees]: [...charges, ...cribs] }
                }
                updateAdditionalCharges(payload);
                setSave(false)
            }
        }, 2000); // Timeout duration in milliseconds (e.g., 2000 ms = 2 seconds)
    
        // Clean up the timer when the component unmounts or when the effect is re-run
        return () => {
            clearTimeout(timerId);
        };
      }, [save, data]);

    const handleKeyChange = (cost: Charge, key: string, value: string, rowNo: number) => {
        const item = {...cost, [key]: value};
        if(key === 'name' && value === '' && cost.value) item.value = '';
        const func = org_level ? {setCost: setAdditionalCost, setFees: setAdditionalFees}: {setCost: setAdditionalCostGuided, setFees: setAdditionalFeesGuided}
        setSave(true);
        type === TitlesEnum.cost ? dispatch(func.setCost({  rowNo, item })): dispatch(func.setFees({  rowNo, item }))
    }

    const handleCostTypeChange = (charge: Charge, charges_key: string, rowNo: number) => {
        const item = {...charge, charges_key, org_level};
        const func = org_level ? {setCost: setAdditionalCost, setFees: setAdditionalFees}: {setCost: setAdditionalCostGuided, setFees: setAdditionalFeesGuided}
        setSave(true);
        type === TitlesEnum.cost ? dispatch(func.setCost({  rowNo, item })): dispatch(func.setFees({  rowNo, item }))
    };
    const handleRow = (action: string, rowNo: number) => {
        if(disabled) return;
        const key = type === TitlesEnum.cost ?  AdditionalCostFeesEnum.additionalCosts: AdditionalCostFeesEnum.additionalFees;
        if(action === 'remove') setSave(true);
        org_level ? dispatch(changeAdditionalCostsRow({type: action, rowNo, key, org_level })): dispatch(changeGuidedAdditionalCostsRow({type: action, rowNo, key}));
    };
    const addCribType = (type: string, rowNo: number) => {
        if(org_level){
            type === 'costs' ?  dispatch(setAdditionalCostsCribs({ rowNo })):  dispatch(setAdditionalFeesCribs({ rowNo }))
        }else{
            type === 'costs' ?  dispatch(setAdditionalCostsCribsInSolution({ rowNo })):  dispatch(setAdditionalFeesCribsInSolution({ rowNo }))
        }
    }
    const removeCribFromRow = (type: string, rowNo: number, cribRowNo: number) => {
       if(org_level){
            setSave(true)
            dispatch(removeCribTypes({type, rowNo, cribRowNo}))
       }else{
            dispatch(removeCribTypesInSolution({type, rowNo, cribRowNo}))
       }
    }

    const updateCrib = (rowNo: number, cribRowNo: number, type: string, value: string) => {
        // rowNo: number, cribRowNo: number, value: string, type: string
        if(org_level){
        setSave(true)
        title?.toLowerCase() === TitlesEnum.cost ? dispatch(updateCribCostTypeValue({rowNo, cribRowNo, type, value})): dispatch(updateCribFeesTypeValue({rowNo, cribRowNo, type, value}))
       } else{
        title?.toLowerCase() === TitlesEnum.cost ? dispatch(updateCribCostTypeValueInSolution({rowNo, cribRowNo, type, value})): dispatch(updateCribFeesTypeValueInSolution({rowNo, cribRowNo, type, value}))
       }
    }

    const size = disabled ? 3: 2.8;
    return (
        <Box sx={{maxWidth: '100%'}}>
        {
            data.map((cost, i) => {
                if(disabled && !cost.charges_key && !cost.name && !cost.value) return 
                return <Grid columns={12} container spacing={2} key={'additional-cost' + i + title} sx={{maxWidth: '100%', '&:not(:last-child)': { mb: 2 }}}>
                    <Grid item xs={size}>
                        <TextField 
                            fullWidth
                            size='medium'
                            placeholder={`${title} Name`}
                            label={`${title} Name`}
                            value={data[i].name}
                            onChange={e => handleKeyChange(data[i],'name', e.target.value, i)}
                            disabled={cost.org_level && !org_level || disabled}
                            error={(cost.charges_key || cost.value) && !data[i].name ? true: false}
                        />
                    </Grid>
                    <Grid item xs={size}>
                        <FormControl fullWidth>
                        <InputLabel error={cost.name && !cost.charges_key ? true: false} required={data[i].name ? true: false} id={`restocking-select-label${"some"}`}> {`${title} Type`} </InputLabel>
                        <Select
                            size='medium'
                            id={`restocking-select-label${'some'}`}
                            value={data[i].charges_key}
                            label={`${title} Type`}
                            name="restocking_frequency"
                            onChange={(e) => handleCostTypeChange(cost, e.target.value, i)}
                            required={data[i].name ? true: false}
                            disabled={cost.org_level && !org_level || disabled}
                            error={(cost.name || cost.value) && !data[i].charges_key ? true: false}
                        >
                            {
                                categories.map((item) => (
                                    <MenuItem value={item.key} key={item._id}>
                                        {item.type}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={size}>
                        <TextField 
                        fullWidth
                        type='number'
                        size='medium'
                        placeholder="Value"
                        label="Value"
                        value={data[i].value}
                        disabled={disabled}
                        onChange={e => handleKeyChange(data[i],'value', e.target.value, i)}
                        required={data[i].name ? true: false}
                        error={(cost.name || cost.charges_key) && !data[i].value ? true: false}
                        InputProps={{
                            endAdornment: fcsl(i18n.language as CmUserLanguage) || undefined,
                          }}
                        />
                    </Grid>
                    <Grid item xs={size}>
                        <FormControl fullWidth>
                        <InputLabel id={`restocking-select-label${"some"}`}> Crib Type </InputLabel>
                            <Select
                                size='medium'
                                id={`restocking-select-label${'some'}`}
                                value={'all'}
                                label={`Crib Type`}
                                name="restocking_frequency"
                                onChange={(e) => handleCostTypeChange(cost, e.target.value, i)}
                                required={data[i].name ? true: false}
                                error={cost.name && !cost.charges_key ? true: false}
                                disabled={true}
                            >
                                {
                                    <MenuItem value={'all'}>
                                    All
                                    </MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        !disabled && <Grid xs={0.6} item sx={{ display: 'flex', alignItems: 'center'}}>
                            {
                                cost.org_level && org_level && <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => handleRow('remove', i)} />
                            }
                            {
                                !cost.org_level && !org_level && <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => handleRow('remove', i)} />
                            }
                        </Grid>
                    }
                  {
                    cost.charges_key?.includes('crib') && cost.cribs?.map((item, k) => {
                        const usedKeys = cost?.cribs?.map(item => item.cribType) || ['none'];
                        return  <Grid container columns={12} spacing={2} key={i + k + 'cribtype'} sx={{maxWidth: '100%', margin: 0}}  alignItems="center"   >
                        <Grid item xs={size} />
                        <Grid item xs={size} />
                        <Grid item xs={size}>
                            <TextField 
                            fullWidth
                            type='number'
                            size='medium'
                            placeholder="Value"
                            label="Value"
                            value={item.value}
                            disabled={disabled}
                            onChange={e => updateCrib(i, k, item.cribType, e.target.value)}
                            required={true}
                            error={(!item.value || parseInt(item.value) < 1) && item.cribType ? true: false}
                            InputProps={{
                                endAdornment: fcsl(i18n.language as CmUserLanguage) || undefined,
                            }}
                            />
                        </Grid>
                        <Grid item xs={size}>
                            <FormControl fullWidth>
                            <InputLabel id={`restocking-select-label${"some"}`}> Crib Type </InputLabel>
                            <Select
                                size='medium'
                                id={`restocking-select-label${'some'}`}
                                value={item.cribType}
                                renderValue={renderValue}
                                label={`Crib Type`}
                                name="crib_type"
                                onChange={e => updateCrib(i, k, e.target.value, item.value )}
                                required={true}
                                disabled={item.org_level && !org_level || disabled}
                                error={!item.cribType && item.value ? true: false}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option} value={option as MachineSelection} disabled={usedKeys.includes(option)}>
                                        <ListItemText primary={formatMachineSelection(option)} />
                                    </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={0.6} >
                        {(!(cost.org_level && !org_level) || (!item.org_level && !org_level)) &&  <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => removeCribFromRow(title?.toLowerCase(), i, k)} />}
                        </Grid>
                    </Grid>
                    })
                  }
                  
                    {cost.charges_key?.includes('crib') && <Grid sx={{display: 'flex', justifyContent: 'right', width: '100%', paddingRight: '7%', mb:  2, mt: 1}}>
                        <AddCircleOutlineOutlinedIcon color='primary' sx={{fontSize: '1rem', mr: 0.5}} /> 
                        <Typography sx={{ fontSize: '.7rem', cursor: 'pointer'}} color='primary' onClick={() => addCribType(type, i)}>Override on Crib Level</Typography>
                    </Grid>}
                </Grid>
            })
        }
        <Box mt={1}>
            <Button
                size='medium'
                color='primary'
                sx={{ textTransform: 'capitalize', fontWeight: '600' }}
                startIcon={<AddCircleOutlineOutlinedIcon color='primary' />}
                onClick={() => handleRow('add', 1)}
                disabled={disabled}
            >
                {type === TitlesEnum.cost ? t('Common.addCostLabel'): t('Common.addFeeLabel')} 
            </Button>
        </Box>
    </Box>
    )
}

export default AdditionalCosts