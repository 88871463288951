export enum InventoryType {
  Consumable = 'Consumable',
  Durable = 'Durable',
  Both = 'Both'
}

export enum Criteria {
  Budget = 'Budget',
  StockOut = 'Stock-Out',
  PayPerUse = 'Pay Per Use(Consignment)',
  OverUse = 'Overuse',
  Spoilage = 'Spoilage',
  TripTime = 'Trip Time',
  DuplicateSKUs = 'Duplicate SKUs',
  FloorSpace = 'Floor Space',
  UsagesTrend = 'Usage Trends',
  StockRotation = 'Stock Rotation',
  FoodFme = 'FOD/FME',
  Theft = 'Theft',
  CalibrationAndRepair = 'Calibration and Repair',
  JustInTimeReplenishment = 'Just In Time Replenishment'
}
