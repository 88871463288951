import { Box, Card, Grid, Typography } from '@mui/material'
import { useState } from 'react'

type Block = {
    name: string;
    fragment: number;
    machineNo: string;
    skuNo: string;
    description: string;
};  
  
type Row = {
    name: string;
    blocks: Block[];
    no: number
};
  
type Selected = {
    row: Row;
    block: Block;
};
  
const headers = [1,2,3,4,5,6,7,8,9,10]
const blockOne = [
    { name: '#1', fragment: 3, machineNo: 'M001', skuNo: 'SKU001', description: "This block is designed for heavy-duty tasks and offers optimal performance in various environments. It incorporates advanced technologies to enhance efficiency and productivity." },
    { name: '#2', fragment: 2, machineNo: 'M002', skuNo: 'SKU002', description: "Experience the power of innovation with this versatile block. It's crafted with precision and attention to detail, ensuring reliability and durability in every operation." },
    { name: '#3', fragment: 1, machineNo: 'M003', skuNo: 'SKU003', description: "Unlock new possibilities with this cutting-edge block. Its unique features and robust design make it a game-changer in the industry, providing unmatched performance." },
    { name: '#4', fragment: 1, machineNo: 'M004', skuNo: 'SKU004', description: "Discover the next level of engineering excellence with this block. It's engineered to perfection, combining functionality with elegance for a seamless user experience." },
    { name: '#5', fragment: 1, machineNo: 'M005', skuNo: 'SKU005', description: "Revolutionize your work processes with this high-performance block. It's designed to meet the demands of modern industries, offering superior efficiency and reliability." },
    { name: '#6', fragment: 2, machineNo: 'M006', skuNo: 'SKU006', description: "Experience unparalleled versatility with this state-of-the-art block. Its dynamic features and robust construction make it an ideal choice for a wide range of applications." },
];

const blockTwo = [
    { name: '#1', fragment: 2, machineNo: 'M007', skuNo: 'SKU007', description: "Embrace innovation with this advanced block. Its sleek design and powerful capabilities redefine industry standards, setting a new benchmark for performance and efficiency." },
    { name: '#2', fragment: 2, machineNo: 'M008', skuNo: 'SKU008', description: "Take your projects to new heights with this cutting-edge block. It's engineered for precision and reliability, ensuring seamless integration into your workflow." },
    { name: '#3', fragment: 2, machineNo: 'M009', skuNo: 'SKU009', description: "Elevate your operations with this versatile and high-performance block. Its intuitive design and intelligent features make it the perfect choice for demanding tasks." },
    { name: '#4', fragment: 2, machineNo: 'M010', skuNo: 'SKU010', description: "Unleash the potential of your projects with this groundbreaking block. Its innovative design and state-of-the-art technology redefine what's possible in the world of engineering." },
    { name: '#5', fragment: 2, machineNo: 'M011', skuNo: 'SKU011', description: "Experience the future of productivity with this forward-thinking block. Its robust construction and smart features make it a game-changing solution for modern industries." },
];

const blockThree = [
    { name: '#1', fragment: 5, machineNo: 'M012', skuNo: 'SKU012', description: "Immerse yourself in efficiency and performance with this high-capacity block. Its durable build and intelligent design make it an indispensable tool for demanding applications." },
    { name: '#2', fragment: 5, machineNo: 'M013', skuNo: 'SKU013', description: "Transform the way you work with this heavy-duty block. Its rugged construction and advanced features ensure reliability and precision in the most challenging environments." },
];

const blockFour = [
    { name: '#1', fragment: 6, machineNo: 'M014', skuNo: 'SKU014', description: "Embark on a journey of productivity with this powerhouse block. Its robust design and cutting-edge technology empower you to tackle even the most complex tasks with ease." },
    { name: '#2', fragment: 2, machineNo: 'M015', skuNo: 'SKU015', description: "Step into the future of engineering with this state-of-the-art block. Its sleek profile and advanced capabilities make it a standout choice for forward-thinking professionals." },
    { name: '#3', fragment: 2, machineNo: 'M016', skuNo: 'SKU016', description: "Revolutionize your workflow with this dynamic block. Its versatility and performance-driven features redefine industry standards, offering a superior solution for your projects." },
];

const rows = [
    {name: 'ROW 1', blocks: blockOne, no: 1},
    {name: 'ROW 2', blocks: blockFour, no: 2},
    {name: 'ROW 3', blocks: blockThree, no: 3},
    {name: 'ROW 4', blocks: blockOne, no: 4},
    {name: 'ROW 5', blocks: blockThree, no: 5},
    {name: 'ROW 6', blocks: blockTwo, no: 6},
]
const ToolboxDesign = () => {
    const [selected, setSelected] = useState<Selected | undefined>();
    console.log(selected)

  return (
    <Box sx={{minHeight: '100vh', display: 'flex', alignItems: 'center', background: '#F2F2F8', border: '10px solid #ddd'}}>
       <Box mx={6}>
        <Typography sx={{fontSize: '20px', fontWeight: 600, mb: 2}}>TOOLBOX - SLOT #003</Typography>
        <Box sx={{display: 'flex'}}>
            <Card>
                <Box sx={{height: '35px', width: '120px'}}></Box>
                {
                    rows.map((row, i) => {
                        return <Box key={row.name + 'side' + i} sx={{height: '100px', border: '1px solid #000', display: 'flex', width: '120px', background: '#DDD'}} >
                            <Grid container>
                                <Grid item xs={4} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>  <b>R{i + 1}</b></Grid>
                                <Grid item xs={8} sx={{fontSize: '12px', mt: 4.5, pl: 2}}>  
                                    <Box>Riser</Box>
                                    <Box>Pusher</Box>
                                    <Box>Coil Type</Box>
                                </Grid>
                            </Grid>
                        </Box>
                    })
                }
            </Card>
            <Card sx={{width: '900px'}}>
                <Box sx={{height: '35px', border: '1px solid #ddd', display: 'flex', width: '100%'}}>
                    {
                        headers.map((block, i) => {
                            return <Box key={block + i} sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: `10%`, border: block ?  '1px solid #000' : 'none', backgroundColor: block ? '#DDD': 'transparent'}}>
                                <b>{block ? block: ''}</b>
                            </Box>
                        })
                    }
                </Box>
                {
                    rows.map((row, i) => {
                        return <Box key={row.name + i} sx={{height: '100px', border: '1px solid #000', display: 'flex', width: '100%'}}>
                            {
                                row.blocks.map((block, i) => {
                                    return <Box key={block.name + i} sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: `${block.fragment}0%`, border: '1px solid #000', cursor: 'pointer', flexDirection: 'column'}} onClick={() => setSelected({row, block})}>
                                       <Typography sx={{fontSize: '12px'}}>Direction {i}</Typography>
                                       <Typography sx={{fontSize: '12px'}}>{block.skuNo}</Typography>
                                       <Typography sx={{fontSize: '12px', fontWeight: 600}}>
                                        {block.fragment > 3 ? 'Large': block.fragment > 1? 'Medium': 'Small'}</Typography>
                                    </Box>
                                })
                            }
                        </Box>
                    })
                }
            </Card>
        </Box>
       </Box>
       <Box>
        <Card sx={{width: '350px', aspectRatio: 1, minHeight: '450px'}}>
            <Box sx={{height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ddd'}}>
                {
                    <b>{selected && selected.row.name} - {selected && selected.block.name}</b>
                }
            </Box>
            <br />
            <Box sx={{fontSize: '13px', textAlign: 'center', p: 2}}>
                <Typography sx={{fontSize: '14px'}}><b>Machine No:</b>{selected?.block.machineNo}</Typography>
                <Typography sx={{fontSize: '14px'}}><b>SKU No:</b>{selected?.block.skuNo}</Typography>
                <br />
                <Typography sx={{fontSize: '13px'}}>{selected?.block.description}</Typography>
                <br />
                <br />
                {selected && selected.row.no % 2 === 0 ? <img src="/cutter.png" alt="cutter" />: <img src="/rubber.png" alt="cutter" />}
            </Box>
        </Card>
       </Box>
    </Box>
  )
}

export default ToolboxDesign