import { Box, Breadcrumbs, Link, Tab, Tabs, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import DashboardHeader from 'components/dashboard-header/DashboardHeader';
import { TabPanel } from 'components/tab-panel/TabPanel';
import AdditionalCosts from 'features/additionalCostFees';
import MachineSettings from 'features/machineSettings/MachineSettings';
import NumMainMachine from 'features/machineSettings/NumMainMachine';
import {
  selectedTabValue,
  setTabValue,
} from 'features/machineSettings/machineSlice';
import { UserOrgType } from 'features/user/userModel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CriteriaSettings from './Criteria/CriteriaSettings';
import GuidedGeneralSettings from './General/guidedGeneral';
import { selectUserOrgType } from 'features/guided/guidedSlice';

const ManageSettings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tabValue = useAppSelector(selectedTabValue);
  const userOrgType = useAppSelector(selectUserOrgType);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setTabValue(newValue));
  };

  return (
    <Box sx={{ overflow: 'none', margin: '10px' }} component="div">
      <DashboardHeader />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2, mx: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="text.primary">Settings</Typography>
          </Breadcrumbs>
          <Typography variant="h6" sx={{ paddingTop: '10px', display: 'flex' }}>
            {t('Settings.title') || 'Organization Settings'}
          </Typography>
          <Typography variant="body2" sx={{ display: 'flex' }}>
            {t('Settings.subtitle') || 'Configure options and settings for solutions'}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ margin: '0px 20px 0px 10px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="dashboard-tabs">
            <Tab label={t('Settings.Tabs.tabOneLabel') || 'General'} sx={{textTransform: 'none'}} />
            <Tab label={t('Settings.Tabs.tabTwoLabel') || 'Machine Settings'} sx={{textTransform: 'none'}} />
            <Tab label={t('Settings.Tabs.tabThreeLabel') || '# Main Machines'} sx={{textTransform: 'none'}}  />
            <Tab label={t(userOrgType === UserOrgType.direct ? 'Settings.Tabs.tabFLD': 'Settings.Tabs.tabFL')} sx={{textTransform: 'none'}}  />
            <Tab label={t('Settings.Tabs.tabFiveLabel')} sx={{textTransform: 'none'}}  />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <GuidedGeneralSettings />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <MachineSettings />
        </TabPanel>
        <TabPanel value={tabValue} index={2} >
          <NumMainMachine/>
        </TabPanel>
        <TabPanel value={tabValue} index={3} >
          <AdditionalCosts />
        </TabPanel>
        <TabPanel value={tabValue} index={4} >
         <CriteriaSettings />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default ManageSettings;