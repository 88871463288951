import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertType, SnackAlertInterface, StepperLoadingInterface } from "features/stepper/stepperModel";

import { ReducerKeys } from 'app/reducerKeys';
import { RootState } from 'app/store';
import { StepperRoiDataInterface, StepperRoiPages } from './stepperRoiModel';

interface PayloadInterface {
    name: StepperRoiPages;
    value: boolean;
}

const initialState: StepperRoiDataInterface = {
    value: {
      [StepperRoiPages.costInfoGuided]: true,
      [StepperRoiPages.machineSelection]: true,
      [StepperRoiPages.review]: true,
      [StepperRoiPages.resultRoi]: true,
    },
    loading: false,
    activeStep: undefined,
    step: 0,
    isDialogueActive: false, 
    nextEnabled: true,
    snackAlert: {
      open: false,
      type: AlertType.error,
      message: 'some message',
      timeout: 6000
    }
};
export const stepperRoiSlice = createSlice({
    name: ReducerKeys.stepperRoi,
    initialState,
    reducers: {
      setRoiStepperData: (state, action: PayloadAction<PayloadInterface>) => {
        const { payload } = action;
        state.value[payload.name] = payload.value;
      },
      setRoiStepToSave: (state, action) => {
        state.activeStep = action.payload;
      },
      setRoiStepperLoading: (state, action: PayloadAction<StepperLoadingInterface>) => {
        const { value, next } = action.payload
        state.loading = value;
        if(next === true) state.step = state.step + 1;
      },
      setRoiStep: (state, action) => {
        state.step = action.payload;
      },
      setRoiSnackAlert: (state, action: PayloadAction<SnackAlertInterface>) => {
        state.snackAlert = action.payload;
      },
      setRoiStepperGuidedDialogue: (state, action: PayloadAction<boolean>) => {
        state.isDialogueActive = action.payload;
      },
      setRoiNext: (state, action) => {
        state.nextEnabled = action.payload;
      },
      clearRoiStepperGuidedState: () => initialState
    },
  
    extraReducers: () => {
    }
  });
  export const { setRoiStepperData, 
    setRoiNext, 
    clearRoiStepperGuidedState, 
    setRoiStepToSave, 
    setRoiStep, 
    setRoiStepperLoading, 
    setRoiSnackAlert, 
    setRoiStepperGuidedDialogue } =
  stepperRoiSlice.actions;
export const selectRoiStepperData = (state: RootState) => state.stepperRoi.value;
export const selectRoiStepToSave = (state: RootState) => state.stepperRoi.activeStep;
export const selectRoiStep = (state: RootState) => state.stepperRoi.step;
export const selectRoiStepperLoading = (state: RootState) => state.stepperRoi.loading;
export const selectRoiSnackAlert = (state: RootState) => state.stepperRoi.snackAlert;
export const selectRoiNextEnabled = (state: RootState) => state.stepperRoi.nextEnabled;
export const selectRoiIsDialogueActive = (state: RootState) => state.stepperRoi.isDialogueActive;

export const { reducer: stepperRoiReducer, name: stepperRoiKey } = stepperRoiSlice;