import { useAppSelector } from 'app/hooks'
import { selectFlowCurrency } from 'features/guided/guidedSlice'
import { selectUserDetails } from 'features/user/userSlice'
import { useCallback } from 'react'
import { fltoc, numberToCurrency, numberToFlowCurrency } from 'utils/currency'

const useCurrency = () => {
    const solSpecificCurrency = useAppSelector(selectFlowCurrency);
    const userLanguage = useAppSelector(selectUserDetails)?.defaultLanguage;

    // SET CURRENCY BASED ON SOLUTION CURRENCY FIELDS PRESENSE OR ABSENSE
    const currency = solSpecificCurrency ? solSpecificCurrency : fltoc(userLanguage);

    // FORMATS TO I18 FETCHED CURRENCY
    const formatToCurrency = useCallback((value: string | number | undefined) => {
        return numberToCurrency(value || 0)
    }, [])
    
    // FORMAT TO CURRENCY OBTAINED FROM FROM WHILE CREATING IT
    const formatToFlowCurrency = useCallback((value: string | number | undefined) => {
        return numberToFlowCurrency(value || 0, currency);
    }, [currency])

    // FORMAT TO CURRENCY OBTAINED FROM FLOW AND ASLO ADDS CURRENCY SYMBOL
    const formatToCurrencyWithSymbol = useCallback((value: string | number | undefined) => {
        return numberToFlowCurrency(value || 0, currency, true);
    }, [currency])

    return {formatToCurrency, formatToCurrencyWithSymbol, formatToFlowCurrency}
}

export default useCurrency