import { AxiosError } from 'axios';

import axios from 'app/axiosConfig';
import { ResponseGetDummyProductsData } from './counterModel';
import { handleError, AxiosAPIError } from 'utils/apiError';

// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}
//example using axios
export const getDummyProductsData = async (): Promise<{
  data: ResponseGetDummyProductsData | null;
  error: AxiosAPIError | null;
}> => {
  try {
    const data = await axios.get<ResponseGetDummyProductsData>('/products');
    return { data: data.data, error: null };
  } catch (err) {
    const errors = err as Error | AxiosError;
    return { data: null, error: handleError(errors) };
  }
};
