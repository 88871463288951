import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControlLabel,
  styled,
  FormGroup,
  Checkbox,
  useTheme,
  ListItemText,
  Typography,
  FormLabel,
  Backdrop,
  CircularProgress
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'app/store';
import {
  fetchAllCribList,
  selectMachineSelectedData,
  selectMachineSelectionList,
  updateMachineSelectionAsync,
  setSelectedMachines,
  setRfidMachine,
  setExpressMachine,
  setAccuPortMachine,
  selectRfidMachine,
  selectExpressMachine,
  selectAccuPortMachine,
  selectFetchAllCribListLoading,
  selectCribListByOrgLoading,
  setMachineSettingsOptions,
  setAverageTime,
  setMaxTime,
  selectUpdateApiStatus
} from './machineSlice';
import { MachineCheckboxOptions, MachineSelectionResponseData } from './machine-model';
import { useTranslation } from 'react-i18next';
import { MachineSelection } from 'features/guided/guidedModel';
import { getAllCribsByOrg } from './machineAPI';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { AlertType } from 'features/stepper/stepperModel';

const MachineSettings = () => {
  const { t } = useTranslation();
  const rfid = useSelector(selectRfidMachine);
  const express = useSelector(selectExpressMachine);
  const accuPort = useSelector(selectAccuPortMachine);
  const updateAPIStatus = useSelector(selectUpdateApiStatus);

  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);

  const crib_list_loading = useSelector(selectCribListByOrgLoading);
  const select_all_crib_list_loading = useSelector(selectFetchAllCribListLoading);

  useEffect(() => {
    fetchCribByOrgData();
  }, []);

  const fetchCribByOrgData = useCallback(async () => {
    try {
      const res = await getAllCribsByOrg();
      if (res.error) {
        throw res.error;
      }
      if (res.response) {
        dispatch(setMachineSettingsOptions(res.response));
        dispatch(setSelectedMachines(res.response.cribs));
        const { average_time_per_transaction, max_time_start_shift_area } = res.response.machine_calculation_params;
        dispatch(setAverageTime(average_time_per_transaction));
        dispatch(setMaxTime(max_time_start_shift_area));
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Failed to fetch!', timeout: 6000 }));
    }
  }, [dispatch]);

  const entireMachineSelections = useSelector(selectMachineSelectionList);
  const removeCribIds = [
    MachineSelection.accuport,
    MachineSelection.accucab,
    MachineSelection.accudrawer,
    MachineSelection.hybrid_accucab_double,
    MachineSelection.hybrid_accucab_single,
    MachineSelection.express_locker,
    MachineSelection.express_toolbox
  ];

  const machineSelections = entireMachineSelections.filter((obj) => !removeCribIds.includes(obj.crib_id));
  const selected_machine = useSelector(selectMachineSelectedData);

  const isAllSelected = selected_machine.length === machineSelections.length;

  const theme = useTheme();
  useEffect(() => {
    dispatch(fetchAllCribList());
  }, []);

  const CustomFormStyled = styled(FormControlLabel)(() => ({
    '& .MuiTypography-body1': {
      fontSize: '0.9rem',
      color: theme.palette.text.secondary
    }
  }));

  const handleSelect = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    if (value.findIndex((v) => v === 'select-all') >= 0) {
      if (isAllSelected) {
        handleDeselectAll();
      } else {
        handleSelectAll();
      }
      return;
    }
    const updatedSelectedMachines = machineSelections
      .filter((item) => value.includes(item.crib_id))
      .map((item) => item.crib_id);
    updateMachineSelectionAPI(updatedSelectedMachines);
  };

  const handleSelectAll = () => {
    const allMachines = machineSelections.map((data) => data.crib_id);
    if (allMachines.length > 0) {
      updateMachineSelectionAPI(allMachines);
    }
  };

  const handleDeselectAll = () => {
    updateMachineSelectionAPI([]);
  };

  const updateMachineSelectionAPI = async (value: string[]) => {
    const payload = {
      cribs: value,
      rfid_machines: rfid,
      express_machines: express,
      accuport_machines: accuPort
    };
    dispatch(updateMachineSelectionAsync(payload));
  };

  function checkChecked(checkedItem: MachineSelectionResponseData): boolean {
    const arr = selected_machine.filter((item) => item === checkedItem.crib_id);
    if (arr.length) return true;
    return false;
  }

  const handleCheckboxChange = async (optionName: string) => {
    let updatedValue;

    switch (optionName) {
      case MachineCheckboxOptions.express:
        updatedValue = !express;
        dispatch(setExpressMachine(updatedValue));
        break;
      case MachineCheckboxOptions.rfid:
        updatedValue = !rfid;
        dispatch(setRfidMachine(updatedValue));
        break;
      case MachineCheckboxOptions.accuPort:
        updatedValue = !accuPort;
        dispatch(setAccuPortMachine(updatedValue));
        break;
      default:
        return;
    }

    const payload = {
      cribs: selected_machine,
      rfid_machines: optionName === MachineCheckboxOptions.rfid ? updatedValue : rfid,
      express_machines: optionName === MachineCheckboxOptions.express ? updatedValue : express,
      accuport_machines: optionName === MachineCheckboxOptions.accuPort ? updatedValue : accuPort
    };
    dispatch(updateMachineSelectionAsync(payload));
  };

  const formatSelectedValues = (eachItem: string[]): string[] => {
    return eachItem.map((str) => {
      return str
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    });
  };

  return (
    <Box component="div" sx={{ pt: 2 }}>
      <Grid item xs={2}>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
          open={loading || crib_list_loading || select_all_crib_list_loading || updateAPIStatus === 'loading'}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <FormControl sx={{ m: 0, width: 500, my: 1 }}>
          <InputLabel
            id="machine-selection-label"
            required={true}
            error={!((machineSelections && machineSelections.length > 0) || express || rfid || accuPort)}
          >
            {t('Flow.GuidedTab.ProductInfoStep.machineSelectionLabel')}
          </InputLabel>
          <Select
            labelId="machine-selection-label"
            id="machine-selection"
            label="Machine selection"
            multiple
            value={selected_machine}
            renderValue={(selected) =>
              selected_machine && selected_machine?.length > 0 ? formatSelectedValues(selected)?.join(', ') : ''
            }
            onChange={handleSelect}
            size="medium"
            error={!((selected_machine && selected_machine.length > 0) || express || rfid || accuPort)}
          >
            {machineSelections.length > 1 && (
              <MenuItem key="select-all" value="select-all">
                <Checkbox indeterminate={!isAllSelected && selected_machine.length > 0} checked={isAllSelected} />
                <ListItemText primary="Select All" />
              </MenuItem>
            )}
            {machineSelections &&
              machineSelections.map((item: MachineSelectionResponseData, i) => (
                <MenuItem key={i} value={item.crib_id}>
                  <Checkbox checked={checkChecked(item)} />
                  <ListItemText>
                    <Typography>{item.crib_name}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl
          required={true}
          error={!((machineSelections && machineSelections.length > 0) || express || rfid || accuPort)}
        >
          {!((machineSelections && machineSelections.length > 0) || express || rfid || accuPort) && (
            <FormLabel component={'legend'}> {t('Flow.GuidedTab.ProductInfoStep.warningMachineSelection')}</FormLabel>
          )}
          <FormGroup>
            <CustomFormStyled
              control={
                <Checkbox checked={express} onChange={() => handleCheckboxChange(MachineCheckboxOptions.express)} />
              }
              label={t('Flow.GuidedTab.ProductInfoStep.expressMachinesTitle')}
            />
            <CustomFormStyled
              control={<Checkbox checked={rfid} onChange={() => handleCheckboxChange(MachineCheckboxOptions.rfid)} />}
              label={t('Flow.GuidedTab.ProductInfoStep.rfidMachinesTitle')}
            />
            <CustomFormStyled
              control={
                <Checkbox checked={accuPort} onChange={() => handleCheckboxChange(MachineCheckboxOptions.accuPort)} />
              }
              label={t('Flow.GuidedTab.ProductInfoStep.accuportMachinesTitle')}
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </Box>
  );
};

export default MachineSettings;
