import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { selectStep } from 'features/stepper/stepperSlice';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { ItemQuantityGuided, SecurityGuided, timeWindowGuided } from '../guidedModel';
import { selectedProductCategoryGuided, setItemQuantityListGuided } from '../guidedSlice';
import { useAppSelector } from 'app/hooks';
import { pick } from 'lodash';

interface ItemSelectPropInterface {
  kei?: string; // key of list i.e. quantity, time_window, item, security.
  label: string;
  options: { key: string; name: string }[];
  rowNo?: number; // Index of Row in list.
  value?: string | number | '' | SecurityGuided | timeWindowGuided | undefined | null;
  row?: ItemQuantityGuided;
  isDescriptionNeed?: boolean;
  description?: string[];
  hide?: boolean;
  category_type?: string | null | undefined;
  required?: boolean;
  error?: boolean;
}
export default function ItemSelect(props: ItemSelectPropInterface) {
  const {
    label,
    options,
    rowNo = 1,
    value,
    kei,
    row,
    description,
    isDescriptionNeed,
    category_type,
    required = false,
    error = false
  } = props;
  const activeStep = useSelector(selectStep);

  const productCategory = useAppSelector(selectedProductCategoryGuided);

  const disabled = activeStep === 3 ? true : false;
  const dispatch = useDispatch();
  const dataObj = { ...description };

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    const cateType = productCategory?.filter((data) => data.item_key === value);

    const payload = {
      ...row,
      [name]: value,
      category_type: cateType && cateType[0]?.item_type ? cateType && cateType[0]?.item_type : row?.category_type
    };
    const durabaleRow = pick(row, ['type', 'quantity', 'security', 'comment']);

    const payloadDurable = {
      ...durabaleRow,
      [name]: value,
      category_type: cateType && cateType[0]?.item_type ? cateType && cateType[0]?.item_type : row?.category_type
    };

    if (cateType && cateType[0]?.item_type === 'durables') {
      dispatch(setItemQuantityListGuided({ item: payloadDurable, rowNo }));
    } else {
      dispatch(setItemQuantityListGuided({ item: payload, rowNo }));
    }
  };

  const renderElement = () => {
    return (
      <>
        <InputLabel id="product-category-select-label" required={required} error={error}>
          {label}
        </InputLabel>
        <Select
          labelId="product-category-select-label"
          id="product-category-select"
          value={value as string}
          label={label}
          onChange={handleChange}
          name={kei}
          defaultValue=""
          disabled={disabled}
          size="medium"
          renderValue={(selected) => {
            const selectedOption = options.find((el) => el.key === selected);
            return selectedOption?.name;
          }}
        >
          {options.map((item, i) => (
            <MenuItem
              value={item.key}
              key={item.key + i + rowNo}
              sx={{
                padding: '5px',
                marginLeft: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                overflowY: 'auto',
                width: isDescriptionNeed ? '400px' : 'auto'
              }}
            >
              <Typography
                sx={{
                  display: 'flex',
                  width: '100%'
                }}
              >
                {item.name}
              </Typography>
              {isDescriptionNeed && (
                <Typography style={{ whiteSpace: 'break-spaces' }} variant="caption">
                  {dataObj[i]}
                </Typography>
              )}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  return (
    <FormControl fullWidth>
      {kei === 'type' || kei === 'security' ? (
        renderElement()
      ) : kei === 'time_window' && category_type === 'consumables' ? (
        renderElement()
      ) : (
        <></>
      )}
    </FormControl>
  );
}
