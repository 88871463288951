import { styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import toNumber from 'lodash/toNumber';
import React, { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Solution } from 'features/guided/guidedModel';
import { selectUserOrgType } from 'features/guided/guidedSlice';
import { CmUserLanguage, UserOrgType } from 'features/user/userModel';
import useCurrency from 'hooks/currency-hook';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { currencyToNumber, fcl, fcsl, fctol, removeCurrencyformat } from 'utils/currency';
import { getOpportunityValue } from 'utils/opportunityView-helpers';
import { CostInfoKeys, customerSalesKeys } from '../guidedApiKeys';
import {
  GuidedState,
  selectCostInfoGuided,
  selectCustomerSalesOpportunityGuided,
  selectGuidedState,
  selectSolutionSpecificCurrency,
  setCostInformationGuided,
  setCostSliderDirty,
  setCribConfig,
  setCustomerSalesOpportunityGuided
} from '../guidedSlice';

const StyledSlider = styled(Slider)({
  height: 4,
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  }
});

interface SalesOpportunityFields {
  label: string;
  key: customerSalesKeys;
  min: number;
  max: number;
  sign?: string;
  currency?: boolean;
}

interface CostInformationFields {
  label: string;
  key: CostInfoKeys;
  min: number;
  max: number;
  sign?: string;
}

enum Fieldtype {
  SALES = 'Sales',
  COST = 'Cost'
}

const CostInformation = ({ pdfView }:{pdfView?: boolean;}) => {
  const dispatch = useAppDispatch();
  const salesInfo = useAppSelector(selectCustomerSalesOpportunityGuided);
  const costInfo = useAppSelector(selectCostInfoGuided);
  const { t, i18n } = useTranslation();
  const [executeCode, setExecuteCode] = React.useState(false);
  const [, setOpen] = React.useState(false);

  const solSpecificCurrency = useAppSelector(selectSolutionSpecificCurrency);
  const guided = useAppSelector(selectGuidedState);
  const userOrgType = useSelector(selectUserOrgType);
  const { formatToFlowCurrency } = useCurrency();

  const {
    additional_halo_revenue,
    average_gross_profit,
    gross_profit_margin_percentage_halo_revenue,
    estimated_revenue_twelve_months
  } = salesInfo;

  const { stocking_labor, lease_agreement } = costInfo;

  const salesOpportunityFields: SalesOpportunityFields[] = [
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.estimatedRevenueShortInputLabel')}`,
      key: customerSalesKeys.estimated_revenue_twelve_months,
      min: 1,
      max: 10000000,
      sign: fcsl(fctol(solSpecificCurrency)),
      currency: true
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.avgGrossProfitMarginInputLabel')}`,
      key: customerSalesKeys.average_gross_profit,
      min: 1,
      max: 100
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.additionalHaloRevenueInputLabel')}`,
      key: customerSalesKeys.additional_halo_revenue,
      min: 1,
      max: 1000000,
      sign: fcsl(fctol(solSpecificCurrency)),
      currency: true
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.CustomerSalesOpportunitySection.grossProfitMarginForHaloInputLabel')}`,
      key: customerSalesKeys.gross_profit_margin_percentage_halo_revenue,
      min: 1,
      max: 200
    }
  ];

  const costInfoFields: CostInformationFields[] = [
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.stockingLaborInputLabel')}`,
      key: CostInfoKeys.stocking_labor,
      min: 1,
      max: 500
    },
    {
      label: `${t('Flow.GuidedTab.CostInfoStep.SolutionCostSection.leaseAgreementTermInputLabel')}`,
      key: CostInfoKeys.lease_agreement,
      min: 1,
      max: 10
    }
  ];

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, max: number, type?: Fieldtype, key?: CostInfoKeys) => {
    const { name, value } = e.target;
    let val = Number(value) > max ? max.toString() : value;
    if (key && key === CostInfoKeys.lease_agreement) {
      val = +val < 1 ? '1' : val;
    }
    const isValidInput = /^\d*\.?\d*$/.test(val);
    if (isValidInput) {
      const numberedValue = val.replace(/,/g, '');
      const dataParam = { name, value: numberedValue };
      if (type === Fieldtype.SALES) {
        dispatch(setCustomerSalesOpportunityGuided(dataParam));
      }
      if (type === Fieldtype.COST) {
        dispatch(setCostInformationGuided(dataParam));
      }
    }
  };

  const handleSliderChange = (newValue: number | number[], name: string, type?: Fieldtype) => {
    setExecuteCode(true);
    const numberedValue = newValue.toString().replace(/,/g, '');
    const dataParam = { name, value: numberedValue };
    if (type === Fieldtype.SALES) {
      dispatch(setCustomerSalesOpportunityGuided(dataParam));
    }
    if (type === Fieldtype.COST) {
      dispatch(setCostInformationGuided(dataParam));
    }
  };

  const handleSliderAway = (item: CostInfoKeys | customerSalesKeys, type?: Fieldtype) => {
    if (executeCode) {
      setOpen(false);
      if (type === Fieldtype.COST) {
        const key = item as CostInfoKeys;
        const formattedVal = key && costInfo[key]?.toString();
        const dataParam = {
          name: key,
          value: formattedVal ? fcl(i18n.language as CmUserLanguage, Number(formattedVal)) : ''
        };
        dispatch(setCostInformationGuided(dataParam));
      }
      if (type === Fieldtype.SALES) {
        const key = item as customerSalesKeys;
        const formattedVal = key && salesInfo[key]?.toString();
        const dataParam = {
          name: key,
          value: formattedVal ? fcl(i18n.language as CmUserLanguage, Number(formattedVal)) : ''
        };
        dispatch(setCustomerSalesOpportunityGuided(dataParam));
      }
    }
    setExecuteCode(false);
  };

  const handleCurrencyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isValidInput = /^\d*\.?\d*$/.test(value);
    if (isValidInput) {
      const numberedValue = value.replace(/,/g, '');
      const dataParam = { name, value: numberedValue };
      dispatch(setCustomerSalesOpportunityGuided(dataParam));
    }
  };

  useEffect(() => {
    if (guided && userOrgType === UserOrgType.distributor) {
      opportunityCostCalculation(guided);
    }
  }, [
    additional_halo_revenue,
    average_gross_profit,
    gross_profit_margin_percentage_halo_revenue,
    estimated_revenue_twelve_months,
    stocking_labor,
    lease_agreement
  ]);

  const opportunityCostCalculation = async (guidedData: GuidedState) => {
   try{
    const { customer_sales_opportunity, cost_info, crib_config, initial } = guidedData;
    const guidedCostSliderObject = {
      additional_halo_revenue: additional_halo_revenue && +removeCurrencyformat(additional_halo_revenue),
      average_gross_profit: Number(average_gross_profit),
      gross_profit_margin_percentage_halo_revenue: Number(gross_profit_margin_percentage_halo_revenue),
      estimated_revenue_twelve_months:
        estimated_revenue_twelve_months && +removeCurrencyformat(estimated_revenue_twelve_months),
      stocking_labor: Number(stocking_labor),
      lease_agreement: Number(lease_agreement)
    };

    if (!initial) return false;
    const initialCostSliderObject = {
      additional_halo_revenue: customer_sales_opportunity.additional_halo_revenue,
      average_gross_profit: customer_sales_opportunity.average_gross_profit,
      gross_profit_margin_percentage_halo_revenue:
        customer_sales_opportunity.gross_profit_margin_percentage_halo_revenue,
      estimated_revenue_twelve_months: customer_sales_opportunity.estimated_revenue_twelve_months,
      stocking_labor: cost_info.stocking_labor,
      lease_agreement: cost_info.lease_agreement
    };

    dispatch(setCostSliderDirty(!isEqual(initialCostSliderObject, guidedCostSliderObject)));
    const { Solutions } = crib_config;

    if (Solutions.length > 0) {
      const SolCost: Solution[] = [];
      Solutions.forEach((Sol, index) => {
        const { Solution_Cost } = Sol;
        const {
          ROS,
          ROA,
          ROI,
          Payback,
          TOTALSolutionCost,
          RecurringRecurringEndCustomerFeesOverTerm,
          Total_LicensingSubscription_SupportCostsOverTerm,
          TotalStockingLaborCostsOverTerm,
          CribMasterHostingCostOverTerm,
          AdditionalRecurringCostOverTerm,
          LicenseAndSupportCostOverTerm,
          AllAdditionalCostsAndFees
        } = getOpportunityValue(Solution_Cost, customer_sales_opportunity, cost_info, initial, index);
        SolCost.push({
          ...Sol,
          Solution_Cost: {
            ...Solution_Cost,
            ROS,
            ROA,
            ROI,
            Payback,
            TOTALSolutionCost,
            RecurringRecurringEndCustomerFeesOverTerm,
            Total_LicensingSubscription_SupportCostsOverTerm,
            TotalStockingLaborCostsOverTerm,
            CribMasterHostingCostOverTerm,
            AdditionalRecurringCostOverTerm,
            LicenseAndSupportCostOverTerm,
            AllAdditionalCostsAndFees
          }
        });
      });
      dispatch(setCribConfig({ ...crib_config, Solutions: SolCost }));
    }
   }catch(err){
    console.log(err)
   }
  };

  return (
    <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
      {salesOpportunityFields.map((c) => {
        const key = c.key;
        if (c.currency)
          return (
            <Stack spacing={1} direction="column" sx={{ flex: 1 }}>
              <TextField
                value={formatToFlowCurrency(salesInfo[key])}
                name={key}
                label={c.label}
                variant="outlined"
                size="small"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleCurrencyChange(currencyToNumber(e))}
                sx={{ flex: 1 }}
              />
            </Stack>
          );
        return (
          <Stack spacing={1} direction="column" key={key} sx={{ flex: 1 }}>
            <TextField
              value={salesInfo[key]}
              key={key}
              name={key}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e, c.max, Fieldtype.SALES)}
              sx={{ width: '100%' }}
              label={c.label}
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: c.sign ? <InputAdornment position="start">{c.sign}</InputAdornment> : null
              }}
            />
            {!pdfView && <StyledSlider
              size="medium"
              aria-label="price slider"
              value={toNumber(salesInfo[key] || '')}
              name={key}
              onChangeCommitted={() => (c.sign ? handleSliderAway(key, Fieldtype.SALES) : null)}
              onChange={(_e: Event, newValue: number | number[]) => handleSliderChange(newValue, key, Fieldtype.SALES)}
              min={c.min}
              max={c.max}
            />}
          </Stack>
        );
      })}
      {costInfoFields.map((c) => {
        const key = c.key;
        return (
          <Stack spacing={1} direction="column" key={key} sx={{ flex: 1 }}>
            <TextField
              value={costInfo[key]}
              key={key}
              name={key}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e, c.max, Fieldtype.COST, key)}
              sx={{ width: '100%' }}
              label={c.label}
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: c.sign ? <InputAdornment position="start">{c.sign}</InputAdornment> : null
              }}
            />
            {!pdfView && <StyledSlider
              size="medium"
              aria-label="price slider"
              value={toNumber(costInfo[key] || '')}
              name={key}
              onChangeCommitted={() => (c.sign ? handleSliderAway(key, Fieldtype.COST) : null)}
              onChange={(_e: Event, newValue: number | number[]) => handleSliderChange(newValue, key, Fieldtype.COST)}
              min={c.min}
              max={c.max}
            />}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default CostInformation;
