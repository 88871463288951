import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { City, Country, ICity, ICountry, IState, State } from 'country-state-city';
import { getOrganizationListAsync } from 'features/createSolution/createSolutionAPI';
import { orgResultModal } from 'features/createSolution/createSolutionModel';
import { useEffect, useState } from 'react';
import { LocationDataInterface, LocationPayloadInterface } from './location-model';
import { createLocationAsync, updateLocationAsync } from './locationAPI';
import { AlertType } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { useDispatch } from 'react-redux';

interface ManageLocationInterface {
    closeDrawer : () => void,
    addLocation: (paramOne : LocationDataInterface, paramTwo: string) => void,
    selectedLocation: LocationDataInterface | null;
}
const ManageLocation = ({ closeDrawer, addLocation, selectedLocation } : ManageLocationInterface) => {
    const [orgs, setOrgs] = useState<orgResultModal[]>();
    const [selectedOrg, setSelectedOrg] = useState<string>('')
    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [selectedState, setSelectedState] = useState<string>('');
    const [selectedCity, setSelectedCity] = useState<string>('');
    const [locationName, setLocationName] = useState<string>('');
    const [zip, setZip] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [contactInfo, setContactInfo] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const theme =  useTheme();
    const dispatch = useDispatch();
    const size = 'medium';

    useEffect(() => {
        if(selectedLocation){
            const {country = '', state = '', city = '', zip = '', address = '', location_name = '', org_id = '', contact_info = ''} = selectedLocation;
            setLocationName(location_name);
            setAddress(address);
            setSelectedCountry(country);
            setSelectedState(state);
            setSelectedCity(city);
            setZip(zip);
            setSelectedOrg(org_id);
            setContactInfo(contact_info);
        }
    },[selectedLocation])

    useEffect(() => {
        getOrganizationListAsync().then(({error, response}) => {
            if(error) throw error;
            response?.result && setOrgs(response.result);
            setLoading(false);
        }).catch(() => {
            dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Failed to fetch list of Orgs!', timeout: 6000 }));
        })
    },[]);

    const countries: ICountry[] = Country.getAllCountries();
    const states: IState[] = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
    const cities: ICity[] = selectedState && selectedCountry ? City.getCitiesOfState(selectedCountry, selectedState) : [];

    const handleCountryChange = (countryCode: string) => {
        setSelectedCountry(countryCode);
        setSelectedState('');
        setSelectedCity('');
      };
    
    const handleStateChange = (stateCode: string) => {
        setSelectedState(stateCode);
        setSelectedCity('');
    };
    const handleCityChange = (cityName: string) => {
        setSelectedCity(cityName);
    };

    const emailIsValid = (email: string) => {
        if(!email.length) return true;
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const filteredOrgs: orgResultModal[] | undefined = orgs?.filter(org => org._id === selectedOrg);
        const org: orgResultModal | undefined = filteredOrgs ? filteredOrgs[0] : undefined;
        if(contactInfo && contactInfo.length && !emailIsValid(contactInfo)) return dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Please provide a valid Email!', timeout: 6000 }))
        if(org){
            const payload: LocationPayloadInterface = {
                root_org_id: org.rootOrgId,
                root_org_name: org.name,
                org_id: org._id,
                org_name: org.name,
                location_name: locationName,
                address: address,
                city: selectedCity,
                state: selectedState,
                zip: zip,
                country: selectedCountry,
                contact_info: contactInfo,
                parent_org_id: org.parentId
            }
            setSaving(true)
            try{
                if(!selectedLocation){
                    await createLocationAsync(payload).then(res => {
                        if(res.response){
                            closeDrawer();
                            addLocation(res.response, 'add');
                            dispatch(setSnackAlert({ open: true, type: AlertType.success, message: 'Location Created', timeout: 6000 }));
                        }
                    })
                }else if(selectedLocation?._id){
                    await updateLocationAsync(selectedLocation._id, payload).then(res => {
                        if(res.response){
                            closeDrawer();
                            addLocation(res.response, 'update');
                            dispatch(setSnackAlert({ open: true, type: AlertType.success, message: 'Location Updated', timeout: 6000 }));
                        }
                    })
                }
                setSaving(false);
            }catch(err){
                console.log(err)
                setSaving(false);
            }
        }
    }
   
    return (
        <Box sx={{width: '450px', height: '100vh'}} component="form" autoComplete="off" onSubmit={handleSubmit}>
            <Grid sx={{borderTop: `3px solid ${theme.palette.primary.main}`, height: '55px', px: 2}} container>
                <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography>{selectedLocation ? 'Update': 'Create'} Location</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <CloseIcon onClick={closeDrawer} sx={{fontSize: '19px', cursor: 'pointer'}} />
                </Grid>
            </Grid>
            <Divider />
            <Box>
                <Box sx={{p: 2,'& .MuiTextField-root': { my: 1 }}} >
                    <Typography sx={{fontSize: '14px', fontWeight: '700', letterSpacing: '0.1px', opacity: 0.7, mb: .5}}>Basic Information</Typography>           
                    <TextField fullWidth label="Customer Location Name" size={size} required value={locationName} onChange={e => setLocationName(e.target.value)} />
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <InputLabel id="demo-simple-select-label">Distributor Org*</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedOrg}
                            label="Distributor Org*"
                            onChange={e => setSelectedOrg(e.target.value)}
                            disabled={!orgs?.length || loading}
                        >
                            {
                            orgs?.map(org => (
                                    <MenuItem key={org._id} value={org._id}>{org.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{p: 2,'& .MuiTextField-root': { my: 1 }}} >
                    <Typography sx={{fontSize: '14px', fontWeight: '700', letterSpacing: '0.1px', opacity: 0.7, mb: .5}}>Location Details</Typography>           
                    <TextField fullWidth label="Address" size={size} value={address} onChange={e => setAddress(e.target.value)} />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth sx={{ my: 1 }}>
                            <InputLabel id="demo-simple-select-label">Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedCountry}
                                label="Country"
                                onChange={e => handleCountryChange(e.target.value)}
                            >
                                {
                                    countries.map((country) => (
                                        <MenuItem key={country.name} value={country.isoCode}>
                                            {country.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} >
                        <FormControl fullWidth sx={{ my: 1 }}>
                                <InputLabel id="demo-simple-select-label">State/Province</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedState}
                                    label="State/Province"
                                    onChange={e => handleStateChange(e.target.value)}
                                    disabled={states.length ? false: true}
                                >
                                    {
                                    states.map((state) => (
                                        <MenuItem key={state.name} value={state.isoCode}>
                                            {state.name}
                                        </MenuItem>
                                    ))
                                }
                                </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                        <FormControl fullWidth sx={{ my: 1 }}>
                                <InputLabel id="demo-simple-select-label">City</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedCity}
                                    label="City"
                                    onChange={e => handleCityChange(e.target.value)}
                                    disabled={cities.length ? false: true}
                                >
                                    {
                                    cities.map((city) => (
                                        <MenuItem key={city.name} value={city.name}>
                                            {city.name}
                                        </MenuItem>
                                    ))
                                }
                                </Select>
                        </FormControl>
                        </Grid>
                    
                        <Grid item xs={6}>
                        <TextField type='number' fullWidth label="Zip" size={size} value={zip} onChange={e => setZip(e.target.value)} />
                        </Grid>
                    </Grid>
                    <TextField 
                        error={!emailIsValid(contactInfo)}
                        fullWidth 
                        label="Contact Info" 
                        size={size} 
                        value={contactInfo} 
                        onChange={e => setContactInfo(e.target.value)} 
                    />
                </Box>  
            </Box>    
            <Grid sx={{borderTop: `1px solid ${theme.palette.divider}`, height: '55px', px: 2, position: 'absolute', bottom: 0, left: 0, background: theme.palette.background.paper, zIndex: 9}} container>
                <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                    <Button sx={{textTransform: 'initial', fontWeight: 600}} onClick={closeDrawer}>Cancel</Button>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {
                        saving ? <Button><CircularProgress size={25} thickness={8}  /> </Button>: 
                        <Button type="submit" variant='contained' sx={{textTransform: 'initial'}}>
                            {selectedLocation ? 'Update': 'Save'}
                        </Button>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

export default ManageLocation