import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import CircularProgress from '@mui/material/CircularProgress';
import mixpanel from 'mixpanel-browser';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { PersistGate } from 'redux-persist/integration/react';

import App from 'app/App';
import { msalConfig } from 'app/authConfig';
import reportWebVitals from './reportWebVitals';

import { MIX_PANEL_PROJECT_TOKEN } from 'app/constants';
import { persistor, store } from 'app/store';
import { Provider } from 'react-redux';

import './i18n/config';
import './index.scss';

mixpanel.init(MIX_PANEL_PROJECT_TOKEN, {
  debug: process.env.NODE_ENV !== 'production',
  persistence: 'localStorage'
});

if (process.env.NODE_ENV === 'development') {
  // Disable Mixpanel event tracking in development environment
  mixpanel.disable();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={<CircularProgress />} persistor={persistor}>
      <HelmetProvider>
        <App pca={msalInstance} />
      </HelmetProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
