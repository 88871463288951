export enum ReducerKeys {
  counter = 'counter',
  parser = 'parser',
  theme = 'theme',
  criteriaRanking = 'criteriaRanking',
  upload = 'upload',
  security = 'security',
  costInfo = 'costInfo',
  stepper = 'stepper',
  login = 'login',
  user = 'user',
  guided = 'guided',
  recommendationTab = 'recommendationTab',
  areaGuided = 'areaGuided',
  createSolution = 'createSolution',
  cloneSolution = 'cloneSolution',
  deleteASolution = 'deleteASolution',
  mySolutions = 'mySolutions',
  solutionBody = 'solutionBody',
  sharedSolutions = 'sharedSolutions',
  machineSelection = 'machineSelection',
  costFees = 'costFees',
  generalSettings = 'generalSettings',
  criteriaSettings = 'criteriaSettings',
  feedback = 'feedback',
  stepperRoi = 'stepperRoi',
  machineSelectionRoi = 'machineSelectionRoi',
  reviewValidateProductData = 'reviewValidateProductData',
}
