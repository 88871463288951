import { Grid, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MXP_PAGE_VIEW } from 'constants/mixpanel';
import InfoDetails from 'features/guided/details/info-details';
import ItemList from 'features/guided/details/item-list';
import { selectStep } from 'features/stepper/stepperSlice';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';

const AddDetailsGuided = () => {
  const flowType = useParams().type as TabsInterface;
  useEffect(() => {
    // Send a default page view event with additional properties
    mixpanel.track_pageview({ page: MXP_PAGE_VIEW.PCADetails, flow: flowType });
  }, []);
  const theme = useTheme();
  const activeStep = useSelector(selectStep);

  const disabled = activeStep === 3 ? true : false;
  const { t } = useTranslation();

  return (
    <Box>
      {!disabled && (
        <Box sx={{ marginBottom: '10px' }}>
          {!disabled && (
            <Typography variant="h5" fontSize={'18px'}>
              {t('Flow.GuidedTab.ProductInfoStep.title') || 'Add Product Category & Area Details'}
            </Typography>
          )}
          <Typography sx={{ color: theme.palette.text.secondary }} variant="subtitle1" fontSize={'16px'}>
            {t('Flow.GuidedTab.ProductInfoStep.subTitle') ||
              'Provide information about the items to manage, your cost to serve, customer volumes and opportunity. TIP: More items will better determine machine matches.'}
          </Typography>
        </Box>
      )}
      <Grid container columns={3} spacing={2}>
        <Grid item xs={0.75}>
          <InfoDetails />
        </Grid>
        <Grid item xs={2.25}>
          <ItemList />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddDetailsGuided;
