import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReducerKeys } from 'app/reducerKeys';
import { RootState } from 'app/store';

export interface MachineSelectionRoiState {
  errorMessage: string;
  isNextBtnEnabled: boolean;
}

const initialState: MachineSelectionRoiState = { errorMessage: '',isNextBtnEnabled: false};

export const machineSelectionRoiSlice = createSlice({
  name: ReducerKeys.machineSelectionRoi,
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setNextBtnStatus: (state,action: PayloadAction<boolean>) => {
      state.isNextBtnEnabled = action.payload
    }
  },
  extraReducers() {}
});

export const { setErrorMessage,setNextBtnStatus } = machineSelectionRoiSlice.actions;

export const selectMachineSelectionRoiState= (state: RootState) => state.machineSelectionRoi;

export const { reducer: machineSelectionRoiReducer, name: machineSelectionRoiKey } =
machineSelectionRoiSlice;
