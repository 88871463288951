import { ResponseAreaObject, ResponseSolutionObject } from "features/guided/guidedModel";
import { AreaPayload, UpdateSolutionPayload, ValidateOrgPayload, ValidateOrgResponse } from "./solutionBodyModel";
import { AxiosAPIError, handleError } from 'utils/apiError';
import axiosConfig from 'app/axiosConfig';
import { AxiosError } from 'axios';



export const UpdateSolutionAPI = async (
    payload: UpdateSolutionPayload, flowId: string
  ): Promise<{
    data: ResponseSolutionObject | null;
    error: AxiosAPIError | null;
  }> => {
    try {
      const data = await axiosConfig.put<ResponseSolutionObject>(`/api/flow/${flowId}`, payload);
      return { data: data.data, error: null };
    } catch (err) {
      const errors = err as Error | AxiosError;
      throw { data: null, error: handleError(errors) };
    }
  };
  

  export const ApiGetArea = async (params: AreaPayload): Promise<{
    data: ResponseAreaObject[] | null;
    error: AxiosAPIError | null;
  }> => {
    const { root_org_id, location_id , org_id, parent_org_id} = params;
    try {
      const data = await axiosConfig.get<ResponseAreaObject[]>(`/api/area?root_org_id=${root_org_id}&location_id=${location_id}&org_id=${org_id}&parent_org_id=${parent_org_id}`);
      return { data: data.data, error: null };
    } catch (err) {
      const errors = err as Error | AxiosError;
      throw { data: null, error: handleError(errors) };
    }
  };

  export const ValidateOrg = async (params: ValidateOrgPayload): Promise<{
    res: ValidateOrgResponse | null;
    error: AxiosAPIError | null;
  }> => {
    const { org_id, flow_id} = params;
    try {
      const data = await axiosConfig.get<ValidateOrgResponse>(`/api/flow/validate/organisation?org_id=${org_id}&flow_id=${flow_id}`);
      return { res: data.data, error: null };
    } catch (err) {
      const errors = err as Error | AxiosError;
      throw { res: null, error: handleError(errors) };
    }
  };