import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import ceil from 'lodash/round';
import uniq from 'lodash/uniq';

import { useAppSelector } from 'app/hooks';
import { selectCribConfig } from 'features/guided/guidedSlice';
import { useTranslation } from 'react-i18next';
import { CribConfig, CribDetails, Solution } from '../guidedModel';
import CostInformation from './CostInformation';
import OpportunityViewCard from './OpportunityViewCard';
import { parseNameValue } from './opportunityViewModel';
import { startCase } from 'lodash';

const formatTitle = (dataArray: string[]) => {
  const formattedArray = dataArray.map((item) => startCase(item));
  return formattedArray.join(' + ');
};

const getMachineData = (solution: Solution, cribDetails?: CribDetails) => {
  const unique = uniq([...solution.Main, ...solution.Aux]);

  return unique.map((title) => {
    const mainCost = get(cribDetails, [title, 'cost'], []).find(
      (c) => c.crib_sub_type === 'main'
    )?.crib_certified_price;
    const auxCost = get(cribDetails, [title, 'cost'], []).find((c) => c.crib_sub_type === 'aux')?.crib_certified_price;
    const auxQuantity = solution.Aux.filter((m) => m === title).length;
    const mainQuantity = solution.Main.filter((m) => m === title).length;

    let totalCost = 0;
    if (mainCost) totalCost = totalCost + mainCost * mainQuantity;
    if (auxCost) totalCost = totalCost + auxCost * auxQuantity;

    const averageCostPerMachine = totalCost ? totalCost / (auxQuantity + mainQuantity) : undefined;

    return {
      title,
      auxQuantity,
      mainQuantity,
      pricePerMachine: averageCostPerMachine,
      total: totalCost,
      url: get(cribDetails, [title, 'imageUrl'], ''),
      description: get(cribDetails, [title, 'description'], ''),
      key: get(cribDetails, [title, 'id'], '')
    };
  });
};

const OpportunityView = ({ pdfView, results }: { pdfView?: boolean; results?: CribConfig }) => {
  let cribConfig = useAppSelector(selectCribConfig);
  cribConfig = results || cribConfig;
  const solLength = get(cribConfig, ['Solutions', 'length'], 0);
  const solErr = cribConfig?.Error;
  const { t } = useTranslation();
  if (!solLength || solErr) {
    return (
      <Container
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          mt: 4
        }}
      >
        <Typography component="div" color="InfoText">
          {solErr ? solErr : t('Flow.Warnings.noMatchFound') || 'No Match Found'}
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <CostInformation pdfView={pdfView} />
      <Grid container spacing={3}>
        {(cribConfig?.Solutions || []).map((solution, i) => {
          const c = solution.Solution_Cost;
          const parsedCosts = parseNameValue(c);

          const matchingCribDetailsKeys = solution.Main.filter((mainMachine) => cribConfig?.CribDetails[mainMachine]);
          const matchingCribDetailsAux = solution.Aux.filter((auxMachine) => cribConfig?.CribDetails[auxMachine]);
          const combinedArray = [...matchingCribDetailsKeys, ...matchingCribDetailsAux];

          const matchingCribDetailsIds = combinedArray.map((key) => cribConfig?.CribDetails[key]?.id).filter(Boolean);
          const title = formatTitle(uniq(matchingCribDetailsIds));

          return (
            <Grid item xs={pdfView ? 6 : 4} key={i}>
              <OpportunityViewCard
                title={title}
                pdfView={pdfView}
                option={`Option ${i + 1}`}
                paybackData={[
                  { title: 'ROA', value: `${ceil(c.ROA, 1)}%` },
                  { title: 'ROS', value: `${ceil(c.ROS, 1)}%` },
                  { title: 'ROI', value: `${ceil(c.ROI, 1)}%` },
                  {
                    title: t('Flow.GuidedTab.ResultsStep.OpportunityViewSection.paybackLabel'),
                    value: `${ceil(c.Payback, 1)} MO`
                  }
                ]}
                machineData={getMachineData(solution, cribConfig?.CribDetails)}
                totalCost={c.TOTALSolutionCost}
                hardwareCost={c.HardwareCost}
                costs={parsedCosts}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default OpportunityView;
