import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

import { useAppSelector } from 'app/hooks';
import { selectCribConfig } from 'features/guided/guidedSlice';
import { CribConfig, CribDetails, Solution } from '../guidedModel';
import CostInfo from './CostInfo';
import CustomerViewCard from './CustomerViewCard';
import SavingsTable from './SavingsTable';

const formatTitle = (origData: string[]) => uniq(origData).join(' + ');

const getMachineData = (solution: Solution, cribDetails?: CribDetails) => {
  const unique = uniq([...solution.Main, ...solution.Aux]);
  return unique.map((title) => ({
    title,
    auxQuantity: solution.Aux.filter((m) => m === title).length,
    mainQuantity: solution.Main.filter((m) => m === title).length,
    pricePerMachine: undefined,
    total: undefined,
    url: get(cribDetails, [title, 'imageUrl'], ''),
    description: get(cribDetails, [title, 'description'], '')
  }));
};

const CustomerView = ({ pdfView, results }: { pdfView?: boolean; results?: CribConfig }) => {
  let cribConfig = useAppSelector(selectCribConfig);
  cribConfig = results || cribConfig;
  const solLength = get(cribConfig, ['Solutions', 'length'], 0);
  const solErr = cribConfig?.Error;
  if (!solLength || solErr) {
    return (
      <Container
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          mt: 4
        }}
      >
        <Typography component="div" color="InfoText">
          {solErr ? solErr: 'No Match Found'}
        </Typography>
      </Container>
    );
  }
  return (
    <>
      <CostInfo pdfView={pdfView} />
      <SavingsTable />
      <Grid container spacing={3}>
        {(cribConfig?.Solutions || []).map((solution, i) => {
          const title = formatTitle(solution.Main);
          return (
            <Grid item xs={4} key={i}>
              <CustomerViewCard
                title={title}
                pdfView={pdfView}
                option={`Option ${i + 1}`}
                machineData={getMachineData(solution, cribConfig?.CribDetails)}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default CustomerView;
