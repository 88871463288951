import { Stack, Card, Theme, Paper, styled, Grid, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import startCase from 'lodash/startCase';
import { memo, useState, SyntheticEvent } from 'react';

import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ItemQuantityGuided } from 'features/guided/guidedModel';
import { TabsInterface } from 'features/recomendation-tab/RecomendationTab';

interface Props {
  items: ItemQuantityGuided[];
  theme: Theme;
  handleEdit: (stepNum: number) => void;
  title: string;
  areaTitle: string;
  itemInfoTitle: string;
  itemDetailLabels: string[];
  editTitle: string;
  areaDetails: { title: string; value: number | string }[];
  machineDetail: { title: string; value: boolean; enabled: boolean }[];
  selectedMachines: { title: string; value: string[] };
  flowType: TabsInterface;
}

const ItemInfo = ({
  items,
  theme,
  title,
  handleEdit,
  itemDetailLabels,
  editTitle,
  areaDetails,
  machineDetail,
  areaTitle,
  itemInfoTitle,
  selectedMachines,
  flowType
}: Props) => {
  const [expanded, setExpanded] = useState<string | false>('area');

  const handleChange = (panel: string) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['200'],
    ...theme.typography.body2,
    padding: '6px 16px 4px 16px'
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  return (
    <Card>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.grey['100'],
          padding: '8px 16px',
          alignItems: 'center'
        }}
      >
        <Typography variant="body1">{title}</Typography>
        <Tooltip title={`${editTitle} ${title}`}>
          <EditIcon onClick={() => handleEdit(1)} sx={{ color: theme.palette.action.active, height: 15, width: 15 }} />
        </Tooltip>
      </Stack>

      <Accordion expanded={expanded === 'area'} onChange={handleChange('area')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>{areaTitle}</Typography>
        </AccordionSummary>
        <Stack sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {areaDetails.map((ad, i) => (
              <Grid item xs={4} key={ad.title + i}>
                <Item>
                  <Typography variant="body2" sx={{ fontSize: '12px' }}>
                    {ad.title}
                  </Typography>
                  <Typography variant="body2">{ad.value}</Typography>
                </Item>
              </Grid>
            ))}
            <Grid item sx={{ width: 400 }}>
              <Item>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                  {selectedMachines.title}
                </Typography>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectedMachines.value}
                    input={<Input />}
                    MenuProps={MenuProps}
                  >
                    {selectedMachines.value.map((name, i) => (
                      <MenuItem value={name} disabled key={'selectedmachine' + name + i}>
                        {startCase(name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Item>
            </Grid>
          </Grid>
          <Grid container spacing={0} sx={{ mt: 1 }}>
            {machineDetail.map(
              (el, i) =>
                el.enabled && (
                  <Grid item xs={12} key={'machineDetails' + i}>
                    <FormControlLabel
                      value="end"
                      control={<Checkbox disabled checked={el.value} />}
                      label={el.title}
                      labelPlacement="end"
                    />
                  </Grid>
                )
            )}
          </Grid>
        </Stack>
      </Accordion>

      {flowType !== TabsInterface.automated && (
        <Accordion expanded={expanded === 'pc'} onChange={handleChange('pc')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography sx={{ width: '33%', flexShrink: 0 }}>{itemInfoTitle}</Typography>
          </AccordionSummary>
          <Stack>
            {items.map((item, i) => (
              <Stack sx={{ p: '8px 16px' }} key={'item_info' + i + item?.type}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Item>
                      <Typography variant="body2" sx={{ fontSize: '12px' }}>
                        {itemDetailLabels[0]}
                      </Typography>
                      <Typography variant="body2">{startCase(item.type)}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={3}>
                    <Item>
                      <Typography variant="body2" sx={{ fontSize: '12px' }}>
                        {itemDetailLabels[1]}
                      </Typography>
                      <Typography variant="body2">{item.quantity}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={3}>
                    <Item>
                      <Typography variant="body2" sx={{ fontSize: '12px' }}>
                        {itemDetailLabels[2]}
                      </Typography>
                      <Typography variant="body2">{item.time_window}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={3}>
                    <Item>
                      <Typography variant="body2" sx={{ fontSize: '12px' }}>
                        {itemDetailLabels[3]}
                      </Typography>
                      <Typography variant="body2">{item.security}</Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Stack>
            ))}
          </Stack>
        </Accordion>
      )}
    </Card>
  );
};

export default memo(ItemInfo);
