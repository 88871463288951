import { useCallback, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Card, useTheme, Link, Typography } from '@mui/material';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GridColDef, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import DashboardHeader from 'components/dashboard-header/DashboardHeader';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { format, parseISO } from 'date-fns';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { AlertType } from 'features/stepper/stepperModel';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { CmUserLanguage } from 'features/user/userModel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { locales } from 'i18n/config';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import ConfirmComponent from 'features/myLocation/confirmationBox';
import DataGridCustom from 'components/custom-grid/DataGridCustom';
import GridIconButton from 'features/data-grid-table/GridIconButton';
import { UpdateReadUnreadToggle, deleteFeedbackAsync } from './feedbackAPI';
import { FeedbackResponseInterface, ReadTogglePayload } from './feedback-model';
import FeedbackPanelContent from './feedbackPanelContent';
import { getAllFeedbackLists, selectFeedbackList, selectFeedbackListLoader } from './feedbackSlice';
import { AppDispatch } from 'app/store';
import GridActionFeedbackItem from './GridActionFeedbackItem';

const Feedback = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const [confirmationId, setConfirmationId] = useState<GridRowId>('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false); // Track whether the feedback panel is open

  const [toggleLoader, setReadToggleLoader] = useState(false);
  const feedbacks = useSelector(selectFeedbackList);
  const loading = useSelector(selectFeedbackListLoader);

  useEffect(() => {
    dispatch(getAllFeedbackLists());
  }, []);

  const getRowClassName = (params: GridRowParams) => {
    if (params.row.read_status) {
      return 'readRow'; // Use the 'readRow' class for read rows
    } else {
      return 'unreadRow'; // Use the 'unreadRow' class for unread rows
    }
  };

  const handleMsgReadToggle = (params: GridRowParams) => {
    const payload: ReadTogglePayload = {
      read_status: params.row.read_status ? false : true
    };

    setReadToggleLoader(true);

    UpdateReadUnreadToggle(payload, params.row.id)
      .then((res) => {
        if (res.data) {
          dispatch(getAllFeedbackLists());
          setReadToggleLoader(false);
          dispatch(
            setSnackAlert({ open: true, type: AlertType.success, message: 'Updated Successfully', timeout: 2000 })
          );
        }
        if (res.error) {
          dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Unable to update', timeout: 2000 }));
        }
      })
      .catch(() => {
        dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Failed to update', timeout: 2000 }));
      });
  };

  const handlePanelClose = () => {
    setIsPanelOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false
    },
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      headerName: t('FeedbackTab.FeedbackTable.name') || 'Name',
      renderHeader: () => <strong>{t('FeedbackTab.FeedbackTable.name') || 'Name'}</strong>,
      editable: false,
      width: 250,
      valueGetter: (_params, { user_name }: FeedbackResponseInterface) => user_name || ''
    },
    {
      field: 'feedback_message',
      headerName: t('FeedbackTab.FeedbackTable.feedbackMsg') || 'Feedback Message',
      renderHeader: () => <strong>{t('FeedbackTab.FeedbackTable.feedbackMsg') || 'Feedback Message'}</strong>,
      editable: false,
      flex: 1,
      valueGetter: (_params, { feedback }: FeedbackResponseInterface) => feedback || ''
    },
    {
      field: 'org_name',
      headerName: t('FeedbackTab.FeedbackTable.organizationLabel') || 'Distributor Org',
      renderHeader: () => <strong>{t('FeedbackTab.FeedbackTable.organizationLabel') || 'Distributor Org'}</strong>,
      flex: 1,
      editable: false,
      valueGetter: (_params, { org_name }: FeedbackResponseInterface) => org_name || ''
    },
    {
      field: 'root_org_name',
      flex: 1,
      editable: false,
      valueGetter: (_params, { parent_org_name }: FeedbackResponseInterface) => parent_org_name || '',
      headerName: t('FeedbackTab.FeedbackTable.parentOrganizationLabel') || 'Distributor Parent Org',
      renderHeader: () => (
        <strong>{t('FeedbackTab.FeedbackTable.parentOrganizationLabel') || 'Distributor Parent Org'}</strong>
      )
    },
    {
      field: 'submissionDate',
      headerName: t('FeedbackTab.FeedbackTable.submissionDateLabel') || 'Submission Date',
      renderHeader: () => <strong>{t('FeedbackTab.FeedbackTable.submissionDateLabel') || 'Submission Date'}</strong>,
      sortable: false,
      flex: 1,
      width: 100,
      valueGetter: (_params, { createdAt }: FeedbackResponseInterface) => createdAt || '',
      valueFormatter: (_params, { createdAt }: FeedbackResponseInterface) =>
        createdAt
          ? format(parseISO(createdAt), 'dd/MM/yyyy hh:mm a', { locale: locales[i18n.language as CmUserLanguage] })
          : ''
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 1,
      getActions: (params: GridRowParams) => {
        return [
          <GridIconButton
            disabled={false}
            onClick={() => handleMsgReadToggle(params)}
            icon={params.row.read_status ? <MarkEmailReadIcon /> : <MarkEmailUnreadIcon />}
            label={
              params.row.read_status
                ? t('FeedbackTab.FeedbackTable.Actions.readTooltip')
                : t('FeedbackTab.FeedbackTable.Actions.unreadTooltip')
            }
          />,
          <GridActionFeedbackItem
            label="View Feedback"
            params={params}
            icons={[<OpenInNewIcon />, <CallReceivedIcon />]}
            onPanelClose={handlePanelClose}
            isPanelOpen={isPanelOpen}
          />,

          <GridIconButton
            icon={<DeleteForeverIcon />}
            label={t('FeedbackTab.FeedbackTable.Actions.deleteTooltip')}
            onClick={() => handleOpenConfirmation(params.id)}
          />
        ];
      }
    }
  ];

  const handleOpenConfirmation = (id: GridRowId) => {
    setConfirmationId(id);
  };

  const options = {
    rows: feedbacks,
    rowsPerPageOptions: [10, 20, 30],
    columns: columns,
    hideFooterSelectedRowCount: true,
    showCellRightBorder: false,
    showColumnRightBorder: false,
    pagination: true,
    checkboxSelection: true,
    isFeedbackDataGrid: true,
    getRowClassName: getRowClassName,
    getDetailPanelContent: (params: GridRowParams) => <FeedbackPanelContent params={params} />,
    columnVisibilityModel: { id: false }
  };

  const StyledDataGrid = styled(DataGridCustom)(() => ({
    '& .readRow': {
      backgroundColor: theme.palette.background.paper, // Change this color to the desired color for read rows
      fontWeight: 'normal'
    },
    '& .unreadRow': {
      backgroundColor: theme.palette.background.default, // Change this color to the desired color for unread rows
      fontWeight: 600
    }
  }));

  const handleDeleteRow = useCallback(async () => {
    try {
      setButtonLoading(true);
      await deleteFeedbackAsync(confirmationId);
      dispatch(getAllFeedbackLists());
      setButtonLoading(false);
      setConfirmationId('');
      dispatch(setSnackAlert({ open: true, type: AlertType.success, message: 'Feedback Deleted', timeout: 6000 }));
    } catch (error) {
      setButtonLoading(false);
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'An error occurred', timeout: 6000 }));
    }
  }, [confirmationId]);

  const confirmationOptions = {
    open: confirmationId ? true : false,
    title: t('FeedbackTab.FeedbackTable.Actions.deleteActionLabel'),
    messages: [t('FeedbackTab.FeedbackTable.Actions.deleteConfirmMsg')],
    onClose: () => setConfirmationId(''),
    onAction: handleDeleteRow,
    buttonTexts: [
      t('FeedbackTab.FeedbackTable.Actions.cancelLabel'),
      t('FeedbackTab.FeedbackTable.Actions.deleteLabel')
    ]
  };

  return (
    <Box sx={{ margin: '10px' }}>
      <DashboardHeader />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2, mx: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
            <Link underline="hover" color="inherit" href="/">
              {t('Flow.CommonLabels.dashBoardBreadcrumbLabel')}
            </Link>
            <Typography color="text.primary">{t('FeedbackTab.FeedbackTable.FeedbackLabel')}</Typography>
          </Breadcrumbs>
          <Typography component={'span'} variant="h6">
            {t('FeedbackTab.FeedbackTable.FeedbackSubLabel')}
          </Typography>
        </Box>
      </Box>
      <Card sx={{ mx: 2 }}>
        <Box component={'div'} sx={{ marginTop: '20px', height: 'calc(100vh - 185px)' }}>
          <StyledDataGrid {...options} loading={loading || toggleLoader} />
        </Box>
      </Card>
      <ConfirmComponent loading={buttonLoading} {...confirmationOptions} />
    </Box>
  );
};

export default Feedback;
