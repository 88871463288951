import { Configuration, LogLevel } from '@azure/msal-browser';
import { BASE_API_URL } from './constants';

const loginLogoutUri = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : BASE_API_URL;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = 
{
  auth: {
    clientId: 'b2bf8d64-4136-4a77-8c38-f018ab3e6e03',
    authority: 'https://login.ecribmaster.com/2176aa39-d994-45ae-8816-47fe8d6e7718/B2C_1_latest7192023',
    redirectUri: loginLogoutUri,
    postLogoutRedirectUri: loginLogoutUri, //The redirect URI where the window navigates after a successful logout.
    navigateToLoginRequestUrl: true, // Boolean indicating whether to navigate to the original request URL after the auth server navigates to the redirect URL.
    knownAuthorities: [
      'login.ecribmaster.com'
    ]
  },
  cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
      secureCookies: true
  },
  system: {
      loggerOptions: {
        loggerCallback: (_level, message) => {
          if (process.env.NODE_ENV === 'development') {
            console.info('---- msal logger ---- ', message);
          }
        },
        logLevel: LogLevel.Verbose
      }
  }
};

export const msalScope: string[] = [
  'openid',
  'offline_access',
  'https://CribMasterCMDB2C.onmicrosoft.com/CMCloudApi/user_impersonation',
  'https://CribMasterCMDB2C.onmicrosoft.com/CMCloudApi/read',
  'https://CribMasterCMDB2C.onmicrosoft.com/CMCloudApi/write'
];

// Endpoints for MS Graph API services.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me'
};
