import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ReducerKeys } from 'app/reducerKeys';
import { CmUserLanguage, UserDetailResponseModal } from 'features/user/userModel';

export interface userState {
  email: string;
  idToken: string;
  accessToken: string;
  userId: string;
  name: string;
  lang: CmUserLanguage;
  userDetails: UserDetailResponseModal | undefined;
}

const initialState: userState = {
  email: '',
  idToken: '',
  accessToken: '',
  userId: '',
  name: '',
  lang: CmUserLanguage.EnglishUS,
  userDetails: undefined
};

export const loginSlice = createSlice({
  name: ReducerKeys.user,
  initialState,
  reducers: {
    setUserLoginData: (
      state,
      action: PayloadAction<{
        email: string;
        accessToken: string;
        userId: string;
        name: string;
        lang: CmUserLanguage;
        userDetails: UserDetailResponseModal;
      }>
    ) => {
      const { email, userId, name, accessToken, lang, userDetails } = action.payload;
      state.accessToken = accessToken;
      state.email = email;
      state.userId = userId;
      state.name = name;
      state.lang = lang;
      state.userDetails = userDetails;
    }
  }
});

export const { setUserLoginData } = loginSlice.actions;

export const selectUserId = (state: RootState) => {
  return state.user.userId;
};

export const selectName = (state: RootState) => {
  return state.user.name;
};

export const selectUser = (state: RootState) => {
  return state.user;
};
// export const selectUserOrgType = (state: RootState) => {
//   return state.user.userDetails?.orgType
// }
export const selectUserDetails = (state: RootState) => {
  return state.user.userDetails
}

export const { reducer: userReducer, name: userKey } = loginSlice;
