import { Criteria } from "features/guided/guidedModel";

export enum TitlesEnum {
    cost = 'costs',
    fees = 'fees',
}
export enum AdditionalCostFeesEnum {
    additionalCosts = 'additional_costs',
    additionalFees = 'service_fees'
}
  
export interface OrganizationAndPriceGroup {
    organization_type: string;
    price_group: string;
}
export interface CostFees {
    [AdditionalCostFeesEnum.additionalCosts]: Charge[];
    [AdditionalCostFeesEnum.additionalFees]: Charge[];
    cribs: string[];
    categories: CostFeesCategory[] | [];
    general_org: OrganizationAndPriceGroup;
    criterias: Criteria[] | [];
    [key: string]: Charge[] | CostFeesCategory[] | [] | OrganizationAndPriceGroup | Criteria[] | string[];
}

export interface CostFeesDefault {
    [AdditionalCostFeesEnum.additionalCosts]: Charge[];
    [AdditionalCostFeesEnum.additionalFees]: Charge[];
}

export interface ChargePayload {
    additionalCosts?: Charge[] | [];
    additionalFees?: Charge[] | [];
}
export interface CostFeesCategory {
    _id: string;
    key: string;
    category: string;
    type: string;
    type_lang: {
        [locale: string]: string;
    };
}

export interface ChargeCrib {
    value: string;
    cribType: string; // You need to replace 'something' with the appropriate type
    name?: string;
    charges_key?: string;
    org_level?: boolean;
    cribs?: Charge[] | [];
  }
export interface Charge {
    value: string;
    cribType: string
    name?: string;
    charges_key?: string;
    org_level: boolean;
    cribs?: Charge[] | [];
}