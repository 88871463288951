import { Stack, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconBreadcrumbs from './crumb';
import RightToolbar from './rightToolbar';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  drawerWidth?: number;
}

const Header: FC<HeaderProps> = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const { t } = useTranslation();

  return (
    <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginX: '24px', marginTop: '24px' }}>
      <Stack spacing={1}>
        <IconBreadcrumbs />
        <Typography
          variant="h5"
          noWrap
          fontSize={'18px'}
          sx={{
            color: mode === 'light' ? theme.palette.common.black : theme.palette.common.white
          }}
        >
          {t('Flow.CommonLabels.wizardTitle') || 'Solution Generator Wizard'} 
        </Typography>
      </Stack>
      <RightToolbar />
    </Stack>
  );
};

export default Header;
