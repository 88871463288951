import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CircularProgress, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Loader from 'components/loader/Loader';
import { messages } from 'constants/message';
import { ApiUpdateSolutionGuided } from 'features/guided/guidedAPI';
import {
  selectAllowShare,
  selectCostSliderDirty,
  selectCribConfig,
  selectGuidedState,
  selectIsEdited
} from 'features/guided/guidedSlice';
import useGuided from 'hooks/guided-hook';
import CriteriaFeedbackModal from 'pages/criteria-ranking-guided/Criteria-feedback';
import ErrorDialogue from 'pages/review-guided/error-dialogue';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generateGuidedFlowPayload } from 'utils/helpers';
import { AlertType, StepperGuidedPages, StepperPages } from './stepperModel';
import {
  clearStepperGuidedState,
  selectIsDialogueActive,
  setSnackAlert,
  setStepperGuidedDialogue
} from './stepperSlice';
import { selectUserId } from 'features/user/userSlice';
import { FlowType } from 'features/guided/guidedModel';

interface Stepper {
  data: Array<DataInterface>;
  minHeight?: string;
  loading?: boolean;
}

interface DataInterface {
  label: string;
  component: React.ReactNode;
  skippable?: boolean;
  showOptional?: boolean;
  name: StepperGuidedPages;
}
const pages: string[] = Object.values(StepperGuidedPages);
export default function HorizontalLinearStepper(props: Stepper) {
  const theme = useTheme();
  const { data, minHeight, loading }: Stepper = props;
  const steps = data.map((item) => item.label);
  const [openFeedback, setOpenFeedback] = useState(false);
  const edited = useSelector(selectIsEdited);
  const { handleNext, handleBack, handleFinish, handleDone, loading: nextLoading, enabled } = useGuided();

  const isDialogOpen = useAppSelector(selectIsDialogueActive);
  const isCostSliderDirty = useAppSelector(selectCostSliderDirty);
  const guidedState = useAppSelector(selectGuidedState);
  const isShared = useAppSelector(selectAllowShare);
  const configs = useSelector(selectCribConfig);
  const userId = useAppSelector(selectUserId);
  const ownerId = guidedState.user_id;
  const loggedInUserIsCreator = ownerId === userId;

  const { t } = useTranslation();

  const { flowId, stepName = StepperGuidedPages.CriteriaGuided } = useParams();
  const activeStep = pages.indexOf(stepName);

  const disableNext = stepName === StepperGuidedPages.ReviewGuided && configs?.Error;

  // if solution type is shared and user is creator of the solution only then allow user to save the solution
  const isUserAllowedToSave = loggedInUserIsCreator ? true : !isShared;

  useEffect(() => {
    return () => {
      dispatch(clearStepperGuidedState());
    };
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickOpen = async () => {
    const wasCompleted = guidedState.initial?.completed;
    if (wasCompleted && edited) return dispatch(setStepperGuidedDialogue(true));
    if (edited) return dispatch(setStepperGuidedDialogue(true));
    navigate('/');
  };

  const handleClose = async () => {
    dispatch(setStepperGuidedDialogue(false));
  };
  const handleAgree = () => (isCostSliderDirty && guidedState.initial?.completed ? handleFinish() : handleDone());

  const handleDisagree = async () => {
    try {
      dispatch(setStepperGuidedDialogue(false));
      if (!guidedState.initial?.completed) {
        return navigate('/');
      }
      if (guidedState.initial) {
        const payload = generateGuidedFlowPayload(guidedState.initial);
        await ApiUpdateSolutionGuided({ ...payload, flowId, flow_type: FlowType.Guided }, stepName);
        navigate('/');
      }
    } catch (err) {
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: messages.error_message, timeout: 6000 }));
    }
  };

  const isGoBackDisabled =
    stepName === StepperPages.CriteriaGuided
      ? true
      : loggedInUserIsCreator
      ? false
      : isShared && guidedState.completed
      ? true
      : activeStep === 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {data.map((step) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Fragment>
        <Box
          sx={{
            padding: '10px 10px 0 10px',
            marginTop: '10px',
            minHeight: minHeight,
            boxSizing: 'border-box',
            maxHeight: minHeight,
            overflowY: 'auto',
            position: 'relative'
          }}
        >
          {loading ? (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <Loader />
            </Box>
          ) : (
            data[activeStep].component
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', p: '0 1' }}>
          {!isGoBackDisabled && (
            <Button
              color="primary"
              disabled={loggedInUserIsCreator ? false : isShared && guidedState.completed ? true : activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1, fontFamily: 'Helvetica', fontWeight: '700', fontSize: '14px', textTransform: 'capitalize' }}
            >
              {t('Common.goBackLabel') || 'Go Back'}
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '44%',
              transform: 'translateX(-50%)'
            }}
          >
            <Typography>
              {t('Flow.CommonLabels.feedBackLabel') || 'Are we missing anything?'}
              <span
                style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                onClick={() => setOpenFeedback((prev) => !prev)}
              >
                &nbsp;<u>{t('Flow.CommonLabels.feedBackLinkLabel') || 'Let us know.'}</u>
              </span>
            </Typography>
            {openFeedback && <CriteriaFeedbackModal open={openFeedback} setOpen={setOpenFeedback} />}
          </Box>
          <Box sx={{ flex: '1 1 auto' }} />
          {stepName === StepperGuidedPages.ResultsGuided && (
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={() => {
                if (!isUserAllowedToSave) {
                  navigate('/');
                  return;
                }
                handleClickOpen();
              }}
            >
              {t('Common.closeLabel') || 'Close'}
            </Button>
          )}
          {stepName != StepperGuidedPages.ResultsGuided && (
            <Button
              sx={{
                mr: 1,
                textTransform: 'capitalize',
                fontFamily: 'Helvetica',
                fontWeight: '700',
                fontSize: '14px'
              }}
              variant="outlined"
              onClick={handleClickOpen}
            >
              {t('Common.closeLabel') || 'Close'}
            </Button>
          )}
          {nextLoading ? (
            <Button>
              <CircularProgress size={25} thickness={8} />{' '}
            </Button>
          ) : (
            isUserAllowedToSave && (
              <Button
                sx={{ textTransform: 'capitalize', fontFamily: 'Helvetica', fontWeight: '700', fontSize: '14px' }}
                variant="contained"
                onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
                disabled={!enabled}
              >
                {activeStep === steps.length - 1
                  ? `${t('Common.finishSaveLabel') || 'Finish & Save'}`
                  : `${t('Common.nextLabel') || 'Next'}`}
              </Button>
            )
          )}
        </Box>
      </Fragment>
      <Dialog open={isDialogOpen} keepMounted onClose={handleClose} aria-describedby="alert-dialog-close-solution">
        <Box sx={{ pt: 3 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {!guidedState.initial?.completed && (
          <DialogTitle>
            {t('Flow.CommonLabels.CloseFlowModal.title') || 'Are you sure you want to close Unfinished Solution?'}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-close-solution">
            {guidedState.initial?.completed
              ? t('Flow.CommonLabels.CloseFlowModal.warnBody')
              : t('Flow.CommonLabels.CloseFlowModal.draftBody')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: 'capitalize', fontFamily: 'Helvetica', fontWeight: '700', fontSize: '14px' }}
            onClick={handleAgree}
          >
            {t('Common.saveLabel') || 'Save'}
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', fontFamily: 'Helvetica', fontWeight: '700', fontSize: '14px' }}
            onClick={handleDisagree}
          >
            {guidedState.initial?.completed ? t('Common.cancelLabel') : 'Continue without saving'}
          </Button>
        </DialogActions>
      </Dialog>
      {disableNext && !nextLoading && <ErrorDialogue error={configs?.Error} />}
    </Box>
  );
}
