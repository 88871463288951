import {Buffer} from 'exceljs';
import { AxiosAPIError } from 'utils/apiError';
import axiosConfig from 'app/axiosConfig';
import { ExcelParsedData, ProductListAPIResponse, UpdatePayload, ProductAPIPayload } from './reviewProductModel';

export const getProductListAsync = async (payload: ProductAPIPayload): Promise<{
  response: ProductListAPIResponse | undefined;
  error: AxiosAPIError | null;
}> => {
  const { selected_pou, flowId, offset, limit, severity,searchValue } = payload;

  const response = await axiosConfig.get<ProductListAPIResponse>(`/api/pou/product-list`, {
    params: {
      flow_id: flowId,
      pou: selected_pou,
      offset,
      limit,
      severity,
      searchValue
    },
  });
  return { response: response?.data, error: null };
};

export const updateRowDataAsync = async (payload: UpdatePayload): Promise<{
  response: ExcelParsedData[] | undefined;
  error: AxiosAPIError | null;
}> => {
  const { records , fileuploadId, pouValue } = payload;
  const payloadData = {
    records: [...records]
  };

  const response = await axiosConfig.put<ExcelParsedData[]>(`/api/automated/fileuploads/${fileuploadId}/pou/${pouValue}`, payloadData);
  return { response: response.data, error: null };
};

export const getProductListBufferAsync = async (payload: {flow_id: string, pou: string, severity?: number,searchValue?: string}): Promise<{
  response: {type: string, data: Buffer}
  error: AxiosAPIError | null;
}> => {
  const {  flow_id, pou, severity, searchValue } = payload;

  const response = await axiosConfig.get<{type: string, data: Buffer}>(`/api/pou/download`, {
    params: {
      flow_id,
      pou,
      severity,
      searchValue
    }
  });
  return { response: response?.data, error: null };
};