import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReducerKeys } from 'app/reducerKeys';
import { RootState } from 'app/store';
import { AdditionalCostFeesEnum, Charge } from 'features/additionalCostFees/additonalCostFeesModel';
import { emptyCharge } from 'features/additionalCostFees/costFeesSlice';
import { costInfoElement, CostInfoState } from 'features/costInfo/costInfoSlice';
import { CribSelectionUpdateResponse } from 'features/machineSettings/machine-model';
import { Currency, UserOrgType } from 'features/user/userModel';
import { emptyItemRow, getManulArea } from './guided-constants';
import { ApiGetAreaGuided, ApiGetCriteriaList, ApiProductCategoryAsync } from './guidedAPI';
import {
  ActiveConnections,
  AreaFetchingPayloadInterface,
  CribConfig,
  Criteria,
  CustomerDataElement,
  CustomerDataInterface,
  customerSalesInfoElement,
  CustomerSalesOpportunityInterface,
  CustomerViewResults,
  FlowType,
  ItemInfoState,
  ItemInfoStateNumbers,
  ItemQuantityGuided,
  itemsInfoElement,
  MachineSelection,
  MachineSelectionROI,
  ResponseAreaObject,
  ResponseCriteriaList,
  ResponseProductCategoryObject,
  RestockingFrequencyInterface
} from './guidedModel';

export interface GuidedState {
  initial?: GuidedState | undefined;
  name: string;
  flow_type: FlowType;
  area: string | undefined;
  areas?: Array<ResponseAreaObject> | null;
  selectedArea?: ResponseAreaObject | null;
  status?: string | undefined;
  criteria_ranking: Criteria[];
  criteria_entire_list: ResponseCriteriaList[] | null;
  fetchAllCriteriaLoading: boolean;
  flow_inventory_data: ItemQuantityGuided[];
  flow_inventory_info: ItemInfoState;
  cost_info: CostInfoState;
  customer_sales_opportunity: CustomerSalesOpportunityInterface;
  machine_selection: MachineSelection[];
  machine_selection_roi: MachineSelectionROI[];
  shared: boolean;
  completed?: boolean;
  express_machines?: boolean;
  rfid_machines?: boolean;
  crib_config: CribConfig;
  productStatus?: string | undefined;
  productCategory?: Array<ResponseProductCategoryObject> | null;
  accuport_machines?: boolean;
  customer_data: CustomerDataInterface;
  customer_view_results: CustomerViewResults;
  openCommentModel?: boolean;
  solutionBuiltCurrency: Currency;
  isDirty?: boolean;
  toSave?: boolean;
  edited?: boolean;
  cm_hosting_data?: boolean;
  settings?: CribSelectionUpdateResponse | null;
  isCostSliderDirty?: boolean;
  currency: Currency;
  approved?: boolean;
  org_type?: UserOrgType;
  price_group?: string;
  [AdditionalCostFeesEnum.additionalCosts]: Charge[] | [];
  [AdditionalCostFeesEnum.additionalFees]: Charge[] | [];
  user_id?: string;
  is_pou_selected?: boolean;
  pouData?: string[];
  selected_pou: string;
  connections: ActiveConnections;
  automated_file_id: string;
  include_consignment: boolean;
}

export const fetchAreaAsync = createAsyncThunk(
  `${ReducerKeys.guided}/fetchArea`,
  async (payload: AreaFetchingPayloadInterface) => {
    const { data } = await ApiGetAreaGuided(payload);
    if (data) return { value: data };
    return { value: data };
  }
);

export const fetchAllCriteriaList = createAsyncThunk(
  `${ReducerKeys.guided}/fetchCriteriaList`,
  async (queryString: string) => {
    const { data } = await ApiGetCriteriaList(queryString);
    return { value: data };
  }
);

export const fetchProductCategory = createAsyncThunk(`${ReducerKeys.guided}/fetchProductCategory`, async () => {
  const { response } = await ApiProductCategoryAsync();
  return response;
});

export const customerSalesDefault = {
  estimated_revenue_twelve_months: undefined,
  average_gross_profit: undefined,
  additional_halo_revenue: undefined,
  gross_profit_margin_percentage_halo_revenue: undefined
};

export const flowInventoryInfoDefault = {
  employees_per_shift: undefined,
  items_to_start_the_shift: undefined,
  capacity_for_growth: undefined,
  restocking_frequency: RestockingFrequencyInterface.weekly,
  safety_stock_percentage: undefined,
  area_size: undefined
};

export const costInfoDefault = {
  state_sales: undefined,
  freight_estimate: undefined,
  stocking_labor: undefined,
  labor_rate_per_hour: 20,
  estimated_repair: 1000,
  lease_agreement: 5,
  total_trip_time: 15,
  budget: undefined
};

export const customerDataDefault = {
  consumption_reduction: 25,
  employees_per_shift: 0,
  avg_labor_rate_per_hour: 0,
  total_trip_time: 0,
  po_per_month: 0,
  cost_per_po: 0
};

export const customerViewResultDefault = {
  consumption_reduction: { per_month: 0, per_year: 0 },
  working_capital: { per_month: 0, per_year: 0 },
  employee_productivity: { per_month: 0, per_year: 0 },
  order_management: { per_month: 0, per_year: 0 },
  total_savings: { per_month: 0, per_year: 0 }
};

export const cribConfigDefault = {
  Solutions: [],
  CribDetails: {},
  flowId: ''
};

const initialState: GuidedState = {
  initial: undefined,
  name: '',
  flow_type: FlowType.Guided,
  area: undefined,
  areas: null,
  selectedArea: null, // send string id (area id)
  status: undefined,
  criteria_ranking: [],
  criteria_entire_list: [],
  fetchAllCriteriaLoading: false,
  flow_inventory_info: flowInventoryInfoDefault,
  flow_inventory_data: [emptyItemRow],
  cost_info: costInfoDefault,
  customer_sales_opportunity: customerSalesDefault,
  machine_selection: [],
  machine_selection_roi: [],
  shared: false,
  completed: false,
  rfid_machines: false,
  express_machines: false,
  accuport_machines: false,
  crib_config: cribConfigDefault,
  productCategory: null,
  customer_data: customerDataDefault,
  customer_view_results: customerViewResultDefault,
  openCommentModel: false,
  solutionBuiltCurrency: Currency.Doller,
  currency: Currency.Doller,
  isDirty: false,
  toSave: false,
  edited: false,
  settings: null,
  cm_hosting_data: false,
  isCostSliderDirty: false,
  approved: false,
  [AdditionalCostFeesEnum.additionalCosts]: [],
  [AdditionalCostFeesEnum.additionalFees]: [],
  user_id: '',
  pouData: [],
  selected_pou: '',
  connections: ActiveConnections.upto_100,
  automated_file_id: '',
  include_consignment: false
};

export const guidedSlice = createSlice({
  name: ReducerKeys.guided,
  initialState,
  reducers: {
    setGuided: (state, action: PayloadAction<GuidedState>) => {
      const payload = action.payload;
      Object.entries(payload).forEach((entry) => {
        const [kei, value] = entry;
        state = { ...state, [kei]: value };
      });
      state.initial = payload;
      return state;
    },
    setInitial: (state) => {
      state.initial = state;
    },
    setAreasGuided: (state, action: PayloadAction<ResponseAreaObject[]>) => {
      state.areas = action.payload;
    },
    setProductCategoryGuided: (state, action: PayloadAction<ResponseProductCategoryObject[]>) => {
      state.productCategory = action.payload;
    },

    setSelectedAreaGuided: (state, action: PayloadAction<ResponseAreaObject>) => {
      state.selectedArea = action.payload;
      state.area = action.payload.id;
    },
    setSelectedCriteriaListGuided: (state, action: PayloadAction<Criteria>) => {
      const criteria = action.payload;
      const selectedCriteriaList = state.criteria_ranking;
      if (selectedCriteriaList.indexOf(criteria) !== -1) {
        const newSelectedCriteriaList = selectedCriteriaList.filter((e) => e !== criteria);
        state.criteria_ranking = newSelectedCriteriaList;
      } else {
        state.criteria_ranking = [...selectedCriteriaList, criteria];
      }
    },
    setRearrangedCriteriaList: (state, action: PayloadAction<Criteria[]>) => {
      state.criteria_ranking = action.payload;
    },
    setAllCriteriaList: (state, action: PayloadAction<ResponseCriteriaList[] | null>) => {
      state.criteria_entire_list = action.payload;
    },

    setItemQuantityListGuided: (state, action: PayloadAction<{ rowNo: number; item: ItemQuantityGuided }>) => {
      const { rowNo, item } = action.payload;
      const list = [...state.flow_inventory_data];
      list[rowNo] = item;
      state.flow_inventory_data = list;
    },

    updateItemQuantityListRows: (state, action: PayloadAction<{ type: string; rowNo: number }>) => {
      const { type, rowNo } = action.payload;
      if (type === 'add') state.flow_inventory_data = [...state.flow_inventory_data, emptyItemRow];
      if (type === 'remove') {
        let reducedRows = state.flow_inventory_data.filter((row, i) => i !== rowNo);
        if (!reducedRows?.length) reducedRows = [emptyItemRow];
        state.flow_inventory_data = reducedRows;
      }
    },
    setItemQuantityListGuidedOnLoad: (state, action) => {
      state.flow_inventory_data = action.payload;
    },
    setItemInfoGuided: (state, action: PayloadAction<itemsInfoElement>) => {
      const { name, value } = action.payload;
      if (typeof value === 'string') {
        state.flow_inventory_info['restocking_frequency'] = value;
      } else if (typeof value === 'number') {
        state.flow_inventory_info[name as keyof ItemInfoStateNumbers] = value;
      }
    },
    setCostInformationGuided: (state, action: PayloadAction<costInfoElement>) => {
      const { name, value } = action.payload;
      const parsedValue = value || 0;
      state.cost_info[name as keyof typeof initialState.cost_info] = parsedValue;
    },
    setCustomerSalesOpportunityGuided: (state, action: PayloadAction<customerSalesInfoElement>) => {
      const { name, value } = action.payload;
      state.customer_sales_opportunity[name as keyof typeof initialState.customer_sales_opportunity] = value;
    },
    setMachineSelection: (state, action: PayloadAction<MachineSelection[]>) => {
      state.machine_selection = action.payload;
    },
    setAllowShare: (state, action: PayloadAction<boolean>) => {
      state.shared = action.payload;
    },
    setApproved: (state, action: PayloadAction<boolean>) => {
      state.approved = action.payload;
    },
    set_is_pou_selected: (state, action: PayloadAction<boolean>) => {
      state.is_pou_selected = action.payload;
    },
    setPouData: (state, action: PayloadAction<string[]>) => {
      state.pouData = action.payload;
    },
    setSelectedPOU: (state, action: PayloadAction<string>) => {
      state.selected_pou = action.payload;
    },
    setRfidMachineGuided: (state, action: PayloadAction<boolean>) => {
      state.rfid_machines = action.payload;
    },
    setExpressMachineGuided: (state, action: PayloadAction<boolean>) => {
      state.express_machines = action.payload;
    },
    setRfidMachineGuidedDefault: (state, action: PayloadAction<boolean>) => {
      state.rfid_machines = action.payload;
    },
    setExpressMachineGuidedDefault: (state, action: PayloadAction<boolean>) => {
      state.express_machines = action.payload;
    },
    setAccuPortMachineGuidedDefault: (state, action: PayloadAction<boolean>) => {
      state.accuport_machines = action.payload;
    },
    setCribConfig: (state, action: PayloadAction<CribConfig>) => {
      state.crib_config = action.payload;
    },
    setInitialCribConfig: (state, action: PayloadAction<CribConfig>) => {
      const payload = action.payload;
      state.initial = { ...state, crib_config: payload };
    },
    setAccuPortMachineGuided: (state, action: PayloadAction<boolean>) => {
      state.accuport_machines = action.payload;
    },
    setCustomerDataGuided: (state, action: PayloadAction<CustomerDataElement>) => {
      const { name, value } = action.payload;
      state.customer_data[name as keyof typeof initialState.customer_data] = value || 0;
    },
    setCustomerViewResultsGuided: (state, action: PayloadAction<CustomerViewResults>) => {
      state.customer_view_results = action.payload;
    },
    setOpenCommentModel: (state, action: PayloadAction<boolean>) => {
      state.openCommentModel = action.payload;
    },
    setSolutionBuiltCurrency: (state, action: PayloadAction<Currency>) => {
      state.solutionBuiltCurrency = action.payload;
    },
    setSolutionIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
    setCostSliderDirty: (state, action: PayloadAction<boolean>) => {
      state.isCostSliderDirty = action.payload;
    },
    setSolutionRequireUpdate: (state, action: PayloadAction<boolean>) => {
      state.toSave = action.payload;
    },
    clearGuidedStates: () => initialState,

    setCribMasterHostingData: (state, action: PayloadAction<boolean>) => {
      state.cm_hosting_data = action.payload;
    },
    setAdditionalCostGuided: (state, action: PayloadAction<{ rowNo: number; item: Charge }>) => {
      const { rowNo, item } = action.payload;
      const isCrib = item?.charges_key?.includes('crib');
      if (!isCrib) delete item.cribs;
      const list = [...state[AdditionalCostFeesEnum.additionalCosts]];
      list[rowNo] = item;
      state[AdditionalCostFeesEnum.additionalCosts] = list;
    },
    setAdditionalFeesGuided: (state, action: PayloadAction<{ rowNo: number; item: Charge }>) => {
      const { rowNo, item } = action.payload;
      const isCrib = item?.charges_key?.includes('crib');
      if (!isCrib) delete item.cribs;
      const list = [...state[AdditionalCostFeesEnum.additionalFees]];
      list[rowNo] = item;
      state[AdditionalCostFeesEnum.additionalFees] = list;
    },
    changeGuidedAdditionalCostsRow: (
      state,
      action: PayloadAction<{ type: string; rowNo: number; key: AdditionalCostFeesEnum }>
    ) => {
      const { type, rowNo, key } = action.payload;
      let arr: Charge[] = state[key];
      if (type === 'add') {
        arr.push(emptyCharge);
      }
      if (type === 'remove') {
        arr.splice(rowNo, 1);
        if (state[key].length === 0) {
          arr = [emptyCharge];
        }
      }
      state[key] = arr;
    },
    setSelectedMachinesROI: (state, action: PayloadAction<MachineSelectionROI[]>) => {
      state.machine_selection_roi = action.payload;
    },
    setFlowActiveConnection: (state, action: PayloadAction<ActiveConnections>) => {
      state.connections = action.payload;
    },
    setIncludeConsignment: (state, action: PayloadAction<boolean>) => {
      state.include_consignment = action.payload;
    },
    setAdditionalCostsCribsInSolution: (state, action: PayloadAction<{ rowNo: number }>) => {
      const { rowNo } = action.payload;
      const list = [...state[AdditionalCostFeesEnum.additionalCosts]] || [];
      console.log(list, [...state[AdditionalCostFeesEnum.additionalCosts]]);
      const cribs = list[rowNo].cribs || [];
      list[rowNo].cribs = [...cribs, { value: '', cribType: '', org_level: false }];
      state[AdditionalCostFeesEnum.additionalCosts] = list;
    },
    setAdditionalFeesCribsInSolution: (state, action: PayloadAction<{ rowNo: number }>) => {
      const { rowNo } = action.payload;
      const list = state[AdditionalCostFeesEnum.additionalFees];
      const cribs = list[rowNo].cribs || [];
      list[rowNo].cribs = [...cribs, { value: '', cribType: '', org_level: false }];
      state[AdditionalCostFeesEnum.additionalFees] = list;
    },
    updateCribCostTypeValueInSolution: (
      state,
      action: PayloadAction<{ rowNo: number; cribRowNo: number; value: string; type: string }>
    ) => {
      const { rowNo, cribRowNo, type = '', value = '' } = action.payload;
      const selectedCost = state[AdditionalCostFeesEnum.additionalCosts][rowNo];
      if (selectedCost && selectedCost.cribs && selectedCost.cribs.length) {
        selectedCost.cribs[cribRowNo] = { cribType: type, value, org_level: false };
        state[AdditionalCostFeesEnum.additionalCosts][rowNo] = selectedCost;
      }
    },
    updateCribFeesTypeValueInSolution: (
      state,
      action: PayloadAction<{ rowNo: number; cribRowNo: number; value: string; type: string }>
    ) => {
      const { rowNo, cribRowNo, type = '', value = '' } = action.payload;
      const selectedCost = state[AdditionalCostFeesEnum.additionalFees][rowNo];
      if (selectedCost && selectedCost.cribs && selectedCost.cribs.length) {
        selectedCost.cribs[cribRowNo] = { cribType: type, value, org_level: false };
        state[AdditionalCostFeesEnum.additionalFees][rowNo] = selectedCost;
      }
    },
    removeCribTypesInSolution: (state, action: PayloadAction<{ type: string; cribRowNo: number; rowNo: number }>) => {
      const { rowNo, type, cribRowNo } = action.payload;
      if (type === 'costs') {
        const list = state[AdditionalCostFeesEnum.additionalCosts] || [];
        const cribs = list[rowNo].cribs || [];
        cribRowNo > -1 && cribRowNo < cribs.length && cribs.splice(cribRowNo, 1);
        state[AdditionalCostFeesEnum.additionalCosts][rowNo].cribs = cribs;
      } else {
        const list = state[AdditionalCostFeesEnum.additionalFees] || [];
        const cribs = list[rowNo].cribs || [];
        cribRowNo > -1 && cribRowNo < cribs.length && cribs.splice(cribRowNo, 1);
        state[AdditionalCostFeesEnum.additionalFees][rowNo].cribs = cribs;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAreaAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAreaAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const areas = action.payload.value;
        if (areas && state.selectedArea) state.areas = [...areas, getManulArea(state.selectedArea)];
      })
      .addCase(fetchAreaAsync.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(fetchProductCategory.pending, (state) => {
        state.productStatus = 'loading';
      })
      .addCase(fetchProductCategory.fulfilled, (state, action) => {
        state.productStatus = 'idle';
        state.productCategory = action.payload || undefined;
      })
      .addCase(fetchProductCategory.rejected, (state) => {
        state.productStatus = 'failed';
      })
      .addCase(fetchAllCriteriaList.pending, (state) => {
        state.fetchAllCriteriaLoading = true;
      })
      .addCase(fetchAllCriteriaList.fulfilled, (state, action) => {
        state.fetchAllCriteriaLoading = false;
        state.criteria_entire_list = action.payload.value || null;
      })
      .addCase(fetchAllCriteriaList.rejected, (state) => {
        state.fetchAllCriteriaLoading = false;
      })
      .addMatcher(
        (action) => {
          const actionType = action.type?.split('/')[1];

          // Actions that change Inputs Required for Recommendation
          const includeActionTypes = [
            'setSelectedCriteriaListGuided',
            'setRearrangedCriteriaList',
            'setSelectedAreaGuided',
            'setCustomerDataGuided',
            'setMachineSelection',
            'setCustomerDataGuided',
            'setAllCriteriaList',
            'setItemQuantityListGuided',
            'setCostInformationGuided',
            'setCustomerSalesOpportunityGuided',
            'updateItemQuantityListRows',
            'setAdditionalCostGuided',
            'setAdditionalFeesGuided',
            'changeGuidedAdditionalCostsRow',
            'setCribMasterHostingData',
            'setExpressMachineGuided',
            'setRfidMachineGuided',
            'setAccuPortMachineGuided',
            'setItemInfoGuided',
            'setSelectedMachinesROI',
            'setPouData',
            'setSelectedPOU',
            'setFlowActiveConnection',
            'updateCribFeesTypeValueInSolution',
            'removeCribTypesInSolution',
            'updateCribCostTypeValueInSolution'
          ];
          const isIncludedActionType = includeActionTypes.includes(actionType);
          const isGuidedActionType = action.type?.startsWith(ReducerKeys.guided);
          return isGuidedActionType && isIncludedActionType;
        },
        (state) => {
          if (!state.isDirty) state.isDirty = true;
          if (!state.toSave) state.toSave = true;
          if (!state.edited) state.edited = true;
          return state;
        }
      );
  }
});

export const {
  setGuided,
  setSelectedCriteriaListGuided,
  setRearrangedCriteriaList,
  setItemQuantityListGuided,
  setItemQuantityListGuidedOnLoad,
  setCostInformationGuided,
  setCustomerSalesOpportunityGuided,
  setMachineSelection,
  setItemInfoGuided,
  setSelectedAreaGuided,
  setAreasGuided,
  updateItemQuantityListRows,
  setAllowShare,
  setRfidMachineGuided,
  setExpressMachineGuided,
  setCribConfig,
  setProductCategoryGuided,
  setAccuPortMachineGuided,
  setCustomerDataGuided,
  setCustomerViewResultsGuided,
  setOpenCommentModel,
  setSolutionBuiltCurrency,
  setAllCriteriaList,
  setSolutionIsDirty,
  clearGuidedStates,
  setCribMasterHostingData,
  setAdditionalFeesGuided,
  setAdditionalCostGuided,
  changeGuidedAdditionalCostsRow,
  setSolutionRequireUpdate,
  setRfidMachineGuidedDefault,
  setExpressMachineGuidedDefault,
  setAccuPortMachineGuidedDefault,
  setCostSliderDirty,
  setInitialCribConfig,
  setApproved,
  setSelectedMachinesROI,
  set_is_pou_selected,
  setPouData,
  setSelectedPOU,
  setFlowActiveConnection,
  setAdditionalCostsCribsInSolution,
  setAdditionalFeesCribsInSolution,
  updateCribCostTypeValueInSolution,
  updateCribFeesTypeValueInSolution,
  removeCribTypesInSolution,
  setIncludeConsignment,
  setInitial
} = guidedSlice.actions;

export const selectGuidedState = (state: RootState) => {
  return state.guided;
};
export const selectIsDirty = (state: RootState) => {
  return state.guided.isDirty;
};
export const selectIsEdited = (state: RootState) => {
  return state.guided.edited;
};
export const selectGuidedToSave = (state: RootState) => {
  return state.guided.toSave;
};
export const selectSolutionNameGuided = (state: RootState) => {
  return state.guided.name;
};
export const selectAreasGuided = (state: RootState) => {
  return state.guided.areas;
};
export const selectedAreaGuided = (state: RootState) => {
  return state.guided.selectedArea;
};
export const selectedCriteriaList = (state: RootState) => {
  return state.guided.criteria_ranking;
};
export const selectedItemQuantityListGuided = (state: RootState) => {
  return state.guided.flow_inventory_data;
};
export const selectCostInfoGuided = (state: RootState) => {
  return state.guided.cost_info;
};
export const selectCustomerSalesOpportunityGuided = (state: RootState) => {
  return state.guided.customer_sales_opportunity;
};
export const selectMachineSelectionGuided = (state: RootState) => {
  return state.guided.machine_selection;
};
export const selectItemsInfoGuided = (state: RootState) => {
  return state.guided.flow_inventory_info;
};
export const selectedRfidMachineGuided = (state: RootState) => {
  return state.guided.rfid_machines;
};
export const selectedExpressMachineGuided = (state: RootState) => {
  return state.guided.express_machines;
};
export const selectedProductCategoryGuided = (state: RootState) => {
  return state.guided.productCategory;
};
export const selectedAccuPortMachineGuided = (state: RootState) => {
  return state.guided.accuport_machines;
};
export const selectCostSliderDirty = (state: RootState) => {
  return state.guided.isCostSliderDirty;
};
export const selectAllowShare = (state: RootState) => state.guided.shared;

export const selectCribConfig = (state: RootState) => state.guided.crib_config;

export const selectCustomerData = (state: RootState) => state.guided.customer_data;
export const selectCustomerViewResults = (state: RootState) => state.guided.customer_view_results;
export const selectOpenCommentModelState = (state: RootState) => state.guided.openCommentModel;

export const selectSolutionSpecificCurrency = (state: RootState) => state.guided.solutionBuiltCurrency;
export const selectAllCriteriaList = (state: RootState) => state.guided.criteria_entire_list;
export const selectAllCriteriaListLoading = (state: RootState) => state.guided.fetchAllCriteriaLoading;
export const selectCribMasterHostingData = (state: RootState) => {
  return state.guided.cm_hosting_data;
};
export const selectUserOrgType = (state: RootState) => {
  return state.guided.org_type ? state.guided.org_type : state.user.userDetails?.orgType;
};
export const selectGuidedFlowSettings = (state: RootState) => {
  return state.guided.settings;
};
export const selectInitialCribConfig = (state: RootState) => {
  return state.guided.crib_config;
};
export const selectPouData = (state: RootState) => state.guided.pouData;
export const selectSelectedPOU = (state: RootState) => state.guided.selected_pou;

export const selectAdditonalCostGuided = (state: RootState) => state.guided[AdditionalCostFeesEnum.additionalCosts];
export const selectAdditionalFeesGuided = (state: RootState) => state.guided[AdditionalCostFeesEnum.additionalFees];
export const selectFlowCurrency = (state: RootState) => state.guided.currency;
export const selectFlowActiveConnection = (state: RootState) => state.guided.connections;
export const selectIncludeConsignment = (state: RootState) => state.guided.include_consignment;

export const { reducer: guidedReducer, name: guidedKey } = guidedSlice;
