import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import MachineActiveConnections from 'components/machine-active-connection/MachineActiveConnections';
import { ActiveConnections } from 'features/guided/guidedModel';
import {
  selectActiveConnection,
  selectCribListByOrgLoading,
  selectUpdateApiStatus,
  updateMachineSelectionAsync
} from 'features/machineSettings/machineSlice';
import { AlertType } from 'features/stepper/stepperModel';
import { setSnackAlert } from 'features/stepper/stepperSlice';
import { selectUserDetails } from 'features/user/userSlice';
import { capitalize } from 'lodash';

const GuidedGeneralSettings = () => {
  const user = useAppSelector(selectUserDetails);
  const updateAPIStatus = useAppSelector(selectUpdateApiStatus);
  const activeConnection = useAppSelector(selectActiveConnection);
  const isLoading = useAppSelector(selectCribListByOrgLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (updateAPIStatus === 'success') {
      dispatch(setSnackAlert({ open: true, type: AlertType.success, message: 'Updated Successfully', timeout: 1000 }));
    } else if (updateAPIStatus === 'failed') {
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Failed to update!', timeout: 1000 }));
    }
  }, [updateAPIStatus]);

  const updateAPI = async (connectionId: ActiveConnections) => {
    try {
      dispatch(updateMachineSelectionAsync({ connections: connectionId }));
    } catch (error) {
      dispatch(setSnackAlert({ open: true, type: AlertType.error, message: 'Failed to update!', timeout: 1000 }));
    }
  };

  const setSelectedConnectionId = (connectionId: ActiveConnections) => {
    updateAPI(connectionId);
  };

  return (
    <Box sx={{ pt: 2 }}>
      <Box mt={2}>
        <FormControl sx={{ m: 1, width: 400 }}>
          <InputLabel id="distributor-select-label">Organization Type</InputLabel>
          <Select
            labelId="distributor-select-label"
            id="distributor-select"
            value={user?.orgType}
            label="Organization Type"
            disabled
          >
            <MenuItem value={user?.orgType}>{capitalize(user?.orgType)}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mt={1}>
        <FormControl sx={{ m: 1, width: 400 }}>
          <InputLabel id="pricegroup-select-label">Price Group</InputLabel>
          <Select
            labelId="pricegroup-select-label"
            id="pricegroup-select"
            value={user?.priceGroup}
            label="Price Group"
            disabled
          >
            <MenuItem value={user?.priceGroup}>{capitalize(user?.priceGroup)}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {user?.priceGroup === 'CUSTOM' && (
        <Box mt={1}>
          <FormControl sx={{ m: 1, width: 400 }}>
            <TextField label="Discount" disabled value={user.discount} />
          </FormControl>
        </Box>
      )}
      <Box width={400} ml={1}>
        <MachineActiveConnections
          selectedConnectionId={activeConnection}
          setSelectedConnectionId={setSelectedConnectionId}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default GuidedGeneralSettings;
